/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as InteractionTypes from '../interactions/types'
import * as SchemaTypes from '../schema/types'
import * as UtilityTypes from '../utilities/types'

import * as TwilioTypes from './types'

export type GetVoiceAccessTokenKey = ['twilio', 'auth', 'voice-token', unknown]

export type GetVoiceAccessTokenResponse = {
  /** A JWT-token providing access to various Twilio systems. */
  voiceToken?: string
}

export type GetVoiceAccessTokenVariables = {
  body?: void
  query?: void
}

export const getVoiceAccessToken = makeData<
  GetVoiceAccessTokenResponse,
  GetVoiceAccessTokenKey,
  GetVoiceAccessTokenVariables
>({
  method: 'post',
  name: 'getVoiceAccessToken',
  summary: 'Get Twilio voice access token (DEPRECATED)',
  path: '/twilio/auth/voice-token',
  queryKey: ({ query }) => ['twilio', 'auth', 'voice-token', query],
})

export type GetMultiAccessTokensKey = ['twilio', 'auth', 'access-tokens', unknown]

export type GetMultiAccessTokensResponse = UtilityTypes.SingletonResponse &
  TwilioTypes.TwilioAccessTokens & {
    /** Contains an error message (if any) for each type of token. */
    errorMessages?: {
      [key: string]: any
    }
    data?: TwilioTypes.TwilioAccessTokens
  }

export type GetMultiAccessTokensVariables = {
  body?: void
  query?: void
}

export const getMultiAccessTokens = makeData<
  GetMultiAccessTokensResponse,
  GetMultiAccessTokensKey,
  GetMultiAccessTokensVariables
>({
  method: 'post',
  name: 'getMultiAccessTokens',
  summary: 'Get all Twilio access tokens',
  path: '/twilio/auth/access-tokens',
  queryKey: ({ query }) => ['twilio', 'auth', 'access-tokens', query],
})

export type WebhookVoiceKey = ['twilio', 'webhooks', 'voice', unknown]

export type WebhookVoiceResponse = void

export type WebhookVoiceVariables = {
  body?: void
  query?: void
}

export const webhookVoice = makeData<WebhookVoiceResponse, WebhookVoiceKey, WebhookVoiceVariables>({
  method: 'post',
  name: 'webhookVoice',
  summary: 'Twilio webhook for Twilio voice calls (outbound and inbound)',
  path: '/twilio/webhooks/voice',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'voice', query],
})

export type WebhookPlayBeepTwimlKey = ['twilio', 'webhooks', 'webhook-play-beep-twiml', unknown]

export type WebhookPlayBeepTwimlResponse = void

export type WebhookPlayBeepTwimlVariables = {
  body?: void
  query?: void
}

export const webhookPlayBeepTwiml = makeData<
  WebhookPlayBeepTwimlResponse,
  WebhookPlayBeepTwimlKey,
  WebhookPlayBeepTwimlVariables
>({
  method: 'post',
  name: 'webhookPlayBeepTwiml',
  summary: 'Twilio webhook to return TwiML to play a beep',
  path: '/twilio/webhooks/webhook-play-beep-twiml',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'webhook-play-beep-twiml', query],
})

export type WebhookVoiceDupeKey = ['twilio', 'webhooks', 'outbound-voice', unknown]

export type WebhookVoiceDupeResponse = void

export type WebhookVoiceDupeVariables = {
  body?: void
  query?: void
}

export const webhookVoiceDupe = makeData<WebhookVoiceDupeResponse, WebhookVoiceDupeKey, WebhookVoiceDupeVariables>({
  method: 'post',
  name: 'webhookVoiceDupe',
  summary: 'Twilio webbook for outbound voice (DEPRECATED)',
  path: '/twilio/webhooks/outbound-voice',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'outbound-voice', query],
})

export type UpdateVoiceCallKey = ['twilio', 'update-voice-call', unknown]

export type UpdateVoiceCallResponse = void

export type UpdateVoiceCallVariables = {
  /** An object describing the desired state of the live voice call */
  body?: {
    /** The recording status of the live call. */
    recordingStatus?: 'paused' | 'in-progress'
  }
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const updateVoiceCall = makeData<UpdateVoiceCallResponse, UpdateVoiceCallKey, UpdateVoiceCallVariables>({
  method: 'post',
  name: 'updateVoiceCall',
  summary: "Update an active Twilio call's state",
  path: '/twilio/update-voice-call',
  queryKey: ({ query }) => ['twilio', 'update-voice-call', query],
})

export type UpdateBorrowerHoldStatusKey = ['twilio', 'update-borrower-hold-status', unknown]

export type UpdateBorrowerHoldStatusResponse = void

export type UpdateBorrowerHoldStatusVariables = {
  /** An object describing the desired hold status of the live voice call */
  body?: {
    /** The hold status of the live call. */
    holdStatus?: 'onHold' | 'offHold'
  }
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const updateBorrowerHoldStatus = makeData<
  UpdateBorrowerHoldStatusResponse,
  UpdateBorrowerHoldStatusKey,
  UpdateBorrowerHoldStatusVariables
>({
  method: 'post',
  name: 'updateBorrowerHoldStatus',
  summary: "Update an active Twilio call's hold status",
  path: '/twilio/update-borrower-hold-status',
  queryKey: ({ query }) => ['twilio', 'update-borrower-hold-status', query],
})

export type PlayBeepOnUserConsentAndStartRecordingKey = [
  'twilio',
  'play-beep-on-user-consent-and-start-recording',
  unknown,
]

export type PlayBeepOnUserConsentAndStartRecordingResponse = void

export type PlayBeepOnUserConsentAndStartRecordingVariables = {
  body?: void
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const playBeepOnUserConsentAndStartRecording = makeData<
  PlayBeepOnUserConsentAndStartRecordingResponse,
  PlayBeepOnUserConsentAndStartRecordingKey,
  PlayBeepOnUserConsentAndStartRecordingVariables
>({
  method: 'post',
  name: 'playBeepOnUserConsentAndStartRecording',
  summary: 'Play a beep on user consent and start recording',
  path: '/twilio/play-beep-on-user-consent-and-start-recording',
  queryKey: ({ query }) => ['twilio', 'play-beep-on-user-consent-and-start-recording', query],
})

export type MakeOutboundVoiceParamsKey = ['twilio', 'make-outbound-voice-params', unknown]

export type MakeOutboundVoiceParamsResponse = UtilityTypes.SingletonResponse & {
  data?: {
    interaction?: InteractionTypes.Interaction
    twilioConnect?: {
      digest?: string
      message?: string
    }
  }
}

export type MakeOutboundVoiceParamsVariables = {
  /**
   * Either a `contactId` or a `phone` is required.
   * Either an `interactionTheme` or `interactionSubject` is required.
   */
  body?: {
    contactId?: string
    personId?: string
    phone?: string
    interactionTheme?: InteractionTypes.InteractionTheme
    interactionSubject?: InteractionTypes.InteractionSubject
    caseId?: string
    loanId?: string
    loanIds?: Array<string>
    sensitive?: boolean
  }
  query?: void
}

export const makeOutboundVoiceParams = makeData<
  MakeOutboundVoiceParamsResponse,
  MakeOutboundVoiceParamsKey,
  MakeOutboundVoiceParamsVariables
>({
  method: 'post',
  name: 'makeOutboundVoiceParams',
  summary: 'Make params Twilio JS client outbound voice calls',
  path: '/twilio/make-outbound-voice-params',
  queryKey: ({ query }) => ['twilio', 'make-outbound-voice-params', query],
})

export type AnswerQueuedCallKey = ['twilio', 'answer-queued-call', unknown]

export type AnswerQueuedCallResponse = UtilityTypes.SingletonResponse & {
  data?: {
    twilioConnect?: {
      digest?: string
      message?: string
    }
  }
}

export type AnswerQueuedCallVariables = {
  body?: {
    /** Boolean to choose whether the call should be recorded or not. */
    record?: boolean
    /**
     * The Taskrouter task's "reservation" data. The data should come from
     * the Twilio JS frontend's websocket connection to Taskrouter.
     */
    reservation?: {
      /** The Taskrouter task data. */
      task?: {
        /** The Taskrouter task's attributes */
        attributes?: {}
      }
    }
  }
  query?: void
}

export const answerQueuedCall = makeData<AnswerQueuedCallResponse, AnswerQueuedCallKey, AnswerQueuedCallVariables>({
  method: 'post',
  name: 'answerQueuedCall',
  summary: 'Answer queued (on hold) call',
  path: '/twilio/answer-queued-call',
  queryKey: ({ query }) => ['twilio', 'answer-queued-call', query],
})

export type WebhookCallStatusKey = ['twilio', 'webhooks', 'call-status', unknown]

export type WebhookCallStatusResponse = void

export type WebhookCallStatusVariables = {
  body?: void
  query?: void
}

export const webhookCallStatus = makeData<WebhookCallStatusResponse, WebhookCallStatusKey, WebhookCallStatusVariables>({
  method: 'post',
  name: 'webhookCallStatus',
  summary: 'Twilio webhook for updating call status',
  path: '/twilio/webhooks/call-status',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'call-status', query],
})

export type WebhookConferenceStatusKey = ['twilio', 'webhooks', 'conference-status', unknown]

export type WebhookConferenceStatusResponse = void

export type WebhookConferenceStatusVariables = {
  body?: void
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const webhookConferenceStatus = makeData<
  WebhookConferenceStatusResponse,
  WebhookConferenceStatusKey,
  WebhookConferenceStatusVariables
>({
  method: 'post',
  name: 'webhookConferenceStatus',
  summary: 'Twilio webhook for updating conference status',
  path: '/twilio/webhooks/conference-status',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'conference-status', query],
})

export type WebhookTaskAssignmentKey = ['twilio', 'webhooks', 'task-assignment', unknown]

export type WebhookTaskAssignmentResponse = {}

export type WebhookTaskAssignmentVariables = {
  body?: void
  query?: void
}

export const webhookTaskAssignment = makeData<
  WebhookTaskAssignmentResponse,
  WebhookTaskAssignmentKey,
  WebhookTaskAssignmentVariables
>({
  method: 'post',
  name: 'webhookTaskAssignment',
  summary: 'Twilio webhook for the Taskrouter Task Assignment Callback',
  path: '/twilio/webhooks/task-assignment',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'task-assignment', query],
})

export type WebhookTaskrouterEventsKey = ['twilio', 'webhooks', 'taskrouter-events', unknown]

export type WebhookTaskrouterEventsResponse = void

export type WebhookTaskrouterEventsVariables = {
  body?: void
  query?: {
    companyId?: string
  }
}

export const webhookTaskrouterEvents = makeData<
  WebhookTaskrouterEventsResponse,
  WebhookTaskrouterEventsKey,
  WebhookTaskrouterEventsVariables
>({
  method: 'post',
  name: 'webhookTaskrouterEvents',
  summary: 'Twilio webhook for the Taskrouter Task Assignment Callback',
  path: '/twilio/webhooks/taskrouter-events',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'taskrouter-events', query],
})

export type WebhookIncomingMessageKey = ['twilio', 'webhooks', 'incoming-message', unknown]

export type WebhookIncomingMessageResponse = void

export type WebhookIncomingMessageVariables = {
  body?: void
  query?: void
}

export const webhookIncomingMessage = makeData<
  WebhookIncomingMessageResponse,
  WebhookIncomingMessageKey,
  WebhookIncomingMessageVariables
>({
  method: 'post',
  name: 'webhookIncomingMessage',
  summary: 'Twilio webhook for incoming message',
  path: '/twilio/webhooks/incoming-message',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'incoming-message', query],
})

export type WebhookPerConversationEventKey = ['twilio', 'webhooks', 'per-conversation-event', unknown]

export type WebhookPerConversationEventResponse = void

export type WebhookPerConversationEventVariables = {
  body?: void
  query?: {
    companyId?: string
  }
}

export const webhookPerConversationEvent = makeData<
  WebhookPerConversationEventResponse,
  WebhookPerConversationEventKey,
  WebhookPerConversationEventVariables
>({
  method: 'post',
  name: 'webhookPerConversationEvent',
  summary: 'Twilio webhook for per-conversation conversation events',
  path: '/twilio/webhooks/per-conversation-event',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'per-conversation-event', query],
})

export type CloseConvoKey = ['twilio', 'close-convo', unknown]

export type CloseConvoResponse = {}

export type CloseConvoVariables = {
  body?: {
    /** The ID of the interaction to close (must be a "conversation" interaction) */
    interactionId: string
  }
  query?: void
}

export const closeConvo = makeData<CloseConvoResponse, CloseConvoKey, CloseConvoVariables>({
  method: 'post',
  name: 'closeConvo',
  summary: 'Close active Twilio conversation',
  path: '/twilio/close-convo',
  queryKey: ({ query }) => ['twilio', 'close-convo', query],
})

export type JoinConvoKey = ['twilio', 'join-convo', unknown]

export type JoinConvoResponse = {}

export type JoinConvoVariables = {
  body?: {
    /** The ID of the interaction to join (must be a "conversation" interaction) */
    interactionId: string
    /**
     * Time of inactivity until conversation will be automatically closed. By default, conversations
     * are created with a 24-hour inactivity close timer.
     *
     * Format is an ISO 8601 duration string—e.g., `PT30M` for 30 minutes; `P15D` for 15 days.
     *
     * Minimum duration is 10 minutes.
     */
    closeTimer?: string
  }
  query?: void
}

export const joinConvo = makeData<JoinConvoResponse, JoinConvoKey, JoinConvoVariables>({
  method: 'post',
  name: 'joinConvo',
  summary: 'Join active Twilio conversation',
  path: '/twilio/join-convo',
  queryKey: ({ query }) => ['twilio', 'join-convo', query],
})

export type HandleStartBorrowerWebchatKey = ['twilio', 'borrower-start-webchat', unknown]

export type HandleStartBorrowerWebchatResponse = {
  /** ID of the borrower */
  borrowerId?: string
  interaction?: InteractionTypes.Interaction
}

export type HandleStartBorrowerWebchatVariables = {
  body?: {
    borrowerSelectedTheme: InteractionTypes.InteractionTheme
    /**
     * The first message in the webchat conversation. Should be a summary
     * of the reason why the webchat is being started.
     */
    message: string
  }
  query?: void
}

export const handleStartBorrowerWebchat = makeData<
  HandleStartBorrowerWebchatResponse,
  HandleStartBorrowerWebchatKey,
  HandleStartBorrowerWebchatVariables
>({
  method: 'post',
  name: 'handleStartBorrowerWebchat',
  summary: 'Start a webchat',
  path: '/twilio/borrower-start-webchat',
  queryKey: ({ query }) => ['twilio', 'borrower-start-webchat', query],
})

export type HandleCloseBorrowerWebchatKey = ['twilio', 'borrower-close-webchat', unknown]

export type HandleCloseBorrowerWebchatResponse = {
  /** The Twilio conversation SID */
  convoSid?: string
}

export type HandleCloseBorrowerWebchatVariables = {
  body?: {
    /**
     * Optional interaction ID identifying the webchat to close. If no ID is
     * supplied then the current active webchat is assumed. If the ID specified
     * does not match the currently actively webchat fail with `400` error.
     *
     * This is safety mechanism to ensure the wrong webchat is not closed.
     */
    interactionId?: string
  }
  query?: void
}

export const handleCloseBorrowerWebchat = makeData<
  HandleCloseBorrowerWebchatResponse,
  HandleCloseBorrowerWebchatKey,
  HandleCloseBorrowerWebchatVariables
>({
  method: 'post',
  name: 'handleCloseBorrowerWebchat',
  summary: 'Close borrower active webchat',
  path: '/twilio/borrower-close-webchat',
  queryKey: ({ query }) => ['twilio', 'borrower-close-webchat', query],
})

export type HandleGetActiveBorrowerWebchatKey = ['twilio', 'borrower-get-active-webchat', unknown]

export type HandleGetActiveBorrowerWebchatResponse = {
  /** ID of the borrower */
  borrowerId?: string
  interaction?: InteractionTypes.Interaction
}

export type HandleGetActiveBorrowerWebchatVariables = {
  body?: void
  query?: void
}

export const handleGetActiveBorrowerWebchat = makeData<
  HandleGetActiveBorrowerWebchatResponse,
  HandleGetActiveBorrowerWebchatKey,
  HandleGetActiveBorrowerWebchatVariables
>({
  method: 'get',
  name: 'handleGetActiveBorrowerWebchat',
  summary: 'Get active borrower webchat',
  path: '/twilio/borrower-get-active-webchat',
  queryKey: ({ query }) => ['twilio', 'borrower-get-active-webchat', query],
})

export type GetBorrowerMultiAccessTokensKey = ['twilio', 'auth', 'borrower-access-tokens', unknown]

export type GetBorrowerMultiAccessTokensResponse = {
  /**
   * A JWT-token providing access to the borrower's Twilio conversation
   * for webchat. The borrower's user must have the `webchat:update` permission.
   */
  accessToken?: string
}

export type GetBorrowerMultiAccessTokensVariables = {
  body?: void
  query?: void
}

export const getBorrowerMultiAccessTokens = makeData<
  GetBorrowerMultiAccessTokensResponse,
  GetBorrowerMultiAccessTokensKey,
  GetBorrowerMultiAccessTokensVariables
>({
  method: 'post',
  name: 'getBorrowerMultiAccessTokens',
  summary: 'Get all Twilio access tokens for borrower',
  path: '/twilio/auth/borrower-access-tokens',
  queryKey: ({ query }) => ['twilio', 'auth', 'borrower-access-tokens', query],
})

export type HandleBorrowerReportAgentDisconnectKey = ['twilio', 'borrower-report-agent-disconnect', unknown]

export type HandleBorrowerReportAgentDisconnectResponse = {}

export type HandleBorrowerReportAgentDisconnectVariables = {
  body?: void
  query?: void
}

export const handleBorrowerReportAgentDisconnect = makeData<
  HandleBorrowerReportAgentDisconnectResponse,
  HandleBorrowerReportAgentDisconnectKey,
  HandleBorrowerReportAgentDisconnectVariables
>({
  method: 'post',
  name: 'handleBorrowerReportAgentDisconnect',
  summary: 'Report agent disconnect',
  path: '/twilio/borrower-report-agent-disconnect',
  queryKey: ({ query }) => ['twilio', 'borrower-report-agent-disconnect', query],
})

export type SyncTaskHistoryKey = ['twilio', 'sync-task-history', unknown]

export type SyncTaskHistoryResponse = void

export type SyncTaskHistoryVariables = {
  body?: {}
  query?: {
    companyId?: string
  }
}

export const syncTaskHistory = makeData<SyncTaskHistoryResponse, SyncTaskHistoryKey, SyncTaskHistoryVariables>({
  method: 'post',
  name: 'syncTaskHistory',
  summary: "Sync from Twilio Taskrouter to Peach's task history",
  path: '/twilio/sync-task-history',
  queryKey: ({ query }) => ['twilio', 'sync-task-history', query],
})

export type WebhookRecordingStatusKey = ['twilio', 'webhooks', 'recording-status', unknown]

export type WebhookRecordingStatusResponse = void

export type WebhookRecordingStatusVariables = {
  body?: void
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const webhookRecordingStatus = makeData<
  WebhookRecordingStatusResponse,
  WebhookRecordingStatusKey,
  WebhookRecordingStatusVariables
>({
  method: 'post',
  name: 'webhookRecordingStatus',
  summary: 'Twilio webhook for updating call recording status',
  path: '/twilio/webhooks/recording-status',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'recording-status', query],
})

export type WebhookVoicemailStatusKey = ['twilio', 'webhooks', 'voicemail-status', unknown]

export type WebhookVoicemailStatusResponse = void

export type WebhookVoicemailStatusVariables = {
  body?: void
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const webhookVoicemailStatus = makeData<
  WebhookVoicemailStatusResponse,
  WebhookVoicemailStatusKey,
  WebhookVoicemailStatusVariables
>({
  method: 'post',
  name: 'webhookVoicemailStatus',
  summary: 'Twilio webhook for updating voicemail recording status',
  path: '/twilio/webhooks/voicemail-status',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'voicemail-status', query],
})

export type WebhookVoicemailCompletedKey = ['twilio', 'webhooks', 'voicemail-completed', unknown]

export type WebhookVoicemailCompletedResponse = void

export type WebhookVoicemailCompletedVariables = {
  body?: void
  query?: {
    interactionId: SchemaTypes.AnyId
  }
}

export const webhookVoicemailCompleted = makeData<
  WebhookVoicemailCompletedResponse,
  WebhookVoicemailCompletedKey,
  WebhookVoicemailCompletedVariables
>({
  method: 'post',
  name: 'webhookVoicemailCompleted',
  summary: 'Twilio webhook for receiving completed voicemail',
  path: '/twilio/webhooks/voicemail-completed',
  queryKey: ({ query }) => ['twilio', 'webhooks', 'voicemail-completed', query],
})
