/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as AiCaseSummarizerTypes from './types'

export type CaseSummarizerGeneratePromptHashKey = ['ai', 'case-summarizer', 'prompt-hash', unknown]

export type CaseSummarizerGeneratePromptHashResponse = AiCaseSummarizerTypes.ResponsePromptHashGenerate

export type CaseSummarizerGeneratePromptHashVariables = {
  body?: AiCaseSummarizerTypes.RequestBodyCaseSummarizerPromptHashPost
  query?: void
}

export const caseSummarizerGeneratePromptHash = makeData<
  CaseSummarizerGeneratePromptHashResponse,
  CaseSummarizerGeneratePromptHashKey,
  CaseSummarizerGeneratePromptHashVariables
>({
  method: 'post',
  name: 'caseSummarizerGeneratePromptHash',
  summary: 'Generate prompt hash',
  path: '/ai/case-summarizer/prompt-hash',
  queryKey: ({ query }) => ['ai', 'case-summarizer', 'prompt-hash', query],
})

export type CaseSummarizerResultsCreateKey = ['ai', 'case-summarizer', 'results', unknown]

export type CaseSummarizerResultsCreateResponse = AiCaseSummarizerTypes.ResponseCaseSummarizerResult

export type CaseSummarizerResultsCreateVariables = {
  body?: AiCaseSummarizerTypes.RequestBodyCaseSummarizerPost
  query?: {
    forceCreate?: boolean
  }
}

export const caseSummarizerResultsCreate = makeData<
  CaseSummarizerResultsCreateResponse,
  CaseSummarizerResultsCreateKey,
  CaseSummarizerResultsCreateVariables
>({
  method: 'post',
  name: 'caseSummarizerResultsCreate',
  summary: 'Start case summarizer',
  path: '/ai/case-summarizer/results',
  queryKey: ({ query }) => ['ai', 'case-summarizer', 'results', query],
})

export type CaseSummarizerResultsGetKey = ['ai', 'case-summarizer', 'results', string | undefined, unknown]

export type CaseSummarizerResultsGetResponse = AiCaseSummarizerTypes.ResponseCaseSummarizerResult

export type CaseSummarizerResultsGetVariables = {
  aICaseSummarizerResultId: string | undefined
  body?: void
  query?: void
}

export const caseSummarizerResultsGet = makeData<
  CaseSummarizerResultsGetResponse,
  CaseSummarizerResultsGetKey,
  CaseSummarizerResultsGetVariables
>({
  method: 'get',
  name: 'caseSummarizerResultsGet',
  summary: 'Get case summarizer result',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}',
  queryKey: ({ query, aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    query,
  ],
})

export type CaseSummarizerResultsUserFeedbackGetKey = [
  'ai',
  'case-summarizer',
  'results',
  string | undefined,
  'user-feedback',
  unknown,
]

export type CaseSummarizerResultsUserFeedbackGetResponse = AiCaseSummarizerTypes.ResponseCaseSummarizerResultFeedback

export type CaseSummarizerResultsUserFeedbackGetVariables = {
  aICaseSummarizerResultId: string | undefined
  body?: void
  query?: void
}

export const caseSummarizerResultsUserFeedbackGet = makeData<
  CaseSummarizerResultsUserFeedbackGetResponse,
  CaseSummarizerResultsUserFeedbackGetKey,
  CaseSummarizerResultsUserFeedbackGetVariables
>({
  method: 'get',
  name: 'caseSummarizerResultsUserFeedbackGet',
  summary: 'Set feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ query, aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
    query,
  ],
})

export type CaseSummarizerResultsUserFeedbackSetKey = [
  'ai',
  'case-summarizer',
  'results',
  string | undefined,
  'user-feedback',
  unknown,
]

export type CaseSummarizerResultsUserFeedbackSetResponse = AiCaseSummarizerTypes.ResponseCaseSummarizerResultFeedback

export type CaseSummarizerResultsUserFeedbackSetVariables = {
  aICaseSummarizerResultId: string | undefined
  body?: AiCaseSummarizerTypes.RequestBodyCaseSummarizerResultFeedbackPut
  query?: void
}

export const caseSummarizerResultsUserFeedbackSet = makeData<
  CaseSummarizerResultsUserFeedbackSetResponse,
  CaseSummarizerResultsUserFeedbackSetKey,
  CaseSummarizerResultsUserFeedbackSetVariables
>({
  method: 'put',
  name: 'caseSummarizerResultsUserFeedbackSet',
  summary: 'Set feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ query, aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
    query,
  ],
})

export type CaseSummarizerResultsUserFeedbackDeleteKey = [
  'ai',
  'case-summarizer',
  'results',
  string | undefined,
  'user-feedback',
  unknown,
]

export type CaseSummarizerResultsUserFeedbackDeleteResponse = void

export type CaseSummarizerResultsUserFeedbackDeleteVariables = {
  aICaseSummarizerResultId: string | undefined
  body?: void
  query?: void
}

export const caseSummarizerResultsUserFeedbackDelete = makeData<
  CaseSummarizerResultsUserFeedbackDeleteResponse,
  CaseSummarizerResultsUserFeedbackDeleteKey,
  CaseSummarizerResultsUserFeedbackDeleteVariables
>({
  method: 'delete',
  name: 'caseSummarizerResultsUserFeedbackDelete',
  summary: 'Delete feedback',
  path: '/ai/case-summarizer/results/{aICaseSummarizerResultId}/user-feedback',
  queryKey: ({ query, aICaseSummarizerResultId }) => [
    'ai',
    'case-summarizer',
    'results',
    aICaseSummarizerResultId,
    'user-feedback',
    query,
  ],
})
