import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query'

import * as R from 'core/helpers/remeda'
import defaultQueryConfig from 'core/system/queries/defaultQueryConfig'
import { NewPaginator, Paging, SingletonResponse } from 'core/types/generated/utilities/types'
import getDefaultOptions from 'core/types/hooks/defaultOptions'

import { OperationData, callAPI } from './makeMutationHook'

export type PaginatedOperation = NewPaginator & { data?: Array<unknown> }

export type LegacyPaginatedOperation = Paging & { data?: Array<unknown> }

export type SingletonOperation = SingletonResponse & { data?: unknown }

export type OperationResponse<Resp> =
  Required<Resp> extends Required<PaginatedOperation> ?
    Resp extends PaginatedOperation ?
      UseQueryResult<Resp['data']> & { pagination: NewPaginator | undefined }
    : never
  : Required<Resp> extends Required<LegacyPaginatedOperation> ?
    Resp extends LegacyPaginatedOperation ?
      UseQueryResult<Resp['data']> & { pagination: Paging | undefined }
    : never
  : Resp extends SingletonOperation ? UseQueryResult<Resp['data']>
  : UseQueryResult<Resp>

export type QueryHookArguments<Resp, Key extends QueryKey, Vars> = Vars & {
  options?: Partial<UseQueryOptions<Resp, unknown, Resp, Key>>
}

export const getQueryOptions = <
  Resp,
  Key extends QueryKey,
  Vars extends { query?: unknown },
  Options extends { enabled?: boolean; queryKey?: Key },
>(
  data: OperationData<Resp, Key, Vars>,
  args: Vars & { options?: Options },
): Options & {
  queryKey: Key
  queryFn: () => Promise<Resp>
  throwOnError: boolean
  meta: { operation: string }
} => {
  const defaultOptions = getDefaultOptions(data)
  const { options, ...parameters } = defaultOptions ? (R.mergeDeep(defaultOptions, args) as typeof args) : args

  const queryKey = options?.queryKey ?? data.queryKey({ query: {}, ...parameters } as Vars)

  return {
    ...defaultQueryConfig,
    queryKey,
    queryFn: () => callAPI(data, parameters as Vars),
    throwOnError: true,
    meta: {
      operation: data.summary,
    },
    ...(options ?? ({} as Options)),
    enabled: !queryKey.some((variable) => !variable) && options?.enabled,
  }
}

const makeQueryHook =
  <Resp, Key extends QueryKey, Vars extends { query?: unknown }>(data: OperationData<Resp, Key, Vars>) =>
  (args: QueryHookArguments<Resp, Key, Vars>): OperationResponse<Resp> => {
    const result = useQuery(getQueryOptions(data, args))

    return (
      result.data && typeof result.data === 'object' && 'data' in result.data ?
        {
          ...result,
          data: result.data.data,
          pagination: R.pick(result.data as any, ['total', 'count', 'nextUrl', 'previousUrl']),
        }
      : result) as any
  }

export default makeQueryHook
