/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'

import * as LoanLabelTypes from './types'

export type LoanLabelsListKey = ['companies', string | undefined, 'loan-labels', unknown]

export type LoanLabelsListResponse = LoanLabelTypes.ResponseLoanLabelPagingResponse

export type LoanLabelsListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    createdBefore?: string
    createdAfter?: string
    loanId?: SchemaTypes.AnyId
  }
}

export const loanLabelsList = makeData<LoanLabelsListResponse, LoanLabelsListKey, LoanLabelsListVariables>({
  method: 'get',
  name: 'loanLabelsList',
  summary: 'Get loan labels',
  path: '/companies/{companyId}/loan-labels',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loan-labels', query],
})

export type LoanLabelCreateKey = ['companies', string | undefined, 'loan-labels', unknown]

export type LoanLabelCreateResponse = LoanLabelTypes.ResponseLoanLabelResponse

export type LoanLabelCreateVariables = {
  companyId: string | undefined
  body?: LoanLabelTypes.RequestBodyLoanLabelPost
  query?: void
}

export const loanLabelCreate = makeData<LoanLabelCreateResponse, LoanLabelCreateKey, LoanLabelCreateVariables>({
  method: 'post',
  name: 'loanLabelCreate',
  summary: 'Create loan label',
  path: '/companies/{companyId}/loan-labels',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'loan-labels', query],
})

export type LoanLabelGetKey = ['companies', string | undefined, 'loan-labels', string | undefined, unknown]

export type LoanLabelGetResponse = LoanLabelTypes.ResponseLoanLabelResponse

export type LoanLabelGetVariables = {
  companyId: string | undefined
  loanLabelId: string | undefined
  body?: void
  query?: void
}

export const loanLabelGet = makeData<LoanLabelGetResponse, LoanLabelGetKey, LoanLabelGetVariables>({
  method: 'get',
  name: 'loanLabelGet',
  summary: 'Get loan label by ID',
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ query, companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId, query],
})

export type LoanLabelUpdateKey = ['companies', string | undefined, 'loan-labels', string | undefined, unknown]

export type LoanLabelUpdateResponse = LoanLabelTypes.ResponseLoanLabelResponse

export type LoanLabelUpdateVariables = {
  companyId: string | undefined
  loanLabelId: string | undefined
  body?: LoanLabelTypes.RequestBodyLoanLabelPut
  query?: void
}

export const loanLabelUpdate = makeData<LoanLabelUpdateResponse, LoanLabelUpdateKey, LoanLabelUpdateVariables>({
  method: 'put',
  name: 'loanLabelUpdate',
  summary: 'Update loan label',
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ query, companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId, query],
})

export type LoanLabelDeleteKey = ['companies', string | undefined, 'loan-labels', string | undefined, unknown]

export type LoanLabelDeleteResponse = void

export type LoanLabelDeleteVariables = {
  companyId: string | undefined
  loanLabelId: string | undefined
  body?: void
  query?: void
}

export const loanLabelDelete = makeData<LoanLabelDeleteResponse, LoanLabelDeleteKey, LoanLabelDeleteVariables>({
  method: 'delete',
  name: 'loanLabelDelete',
  summary: 'Delete loan label',
  path: '/companies/{companyId}/loan-labels/{loanLabelId}',
  queryKey: ({ query, companyId, loanLabelId }) => ['companies', companyId, 'loan-labels', loanLabelId, query],
})

export type LoanLabelsGetLoansKey = [
  'companies',
  string | undefined,
  'loan-labels',
  string | undefined,
  'loans',
  unknown,
]

export type LoanLabelsGetLoansResponse = LoanLabelTypes.ResponseLoanIdsResponse

export type LoanLabelsGetLoansVariables = {
  companyId: string | undefined
  loanLabelIds: string | undefined
  body?: void
  query?: void
}

export const loanLabelsGetLoans = makeData<
  LoanLabelsGetLoansResponse,
  LoanLabelsGetLoansKey,
  LoanLabelsGetLoansVariables
>({
  method: 'get',
  name: 'loanLabelsGetLoans',
  summary: 'Get loans by loan labels',
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans',
  queryKey: ({ query, companyId, loanLabelIds }) => [
    'companies',
    companyId,
    'loan-labels',
    loanLabelIds,
    'loans',
    query,
  ],
})

export type LoanLabelsAddLoansKey = [
  'companies',
  string | undefined,
  'loan-labels',
  string | undefined,
  'loans',
  unknown,
]

export type LoanLabelsAddLoansResponse = void

export type LoanLabelsAddLoansVariables = {
  companyId: string | undefined
  loanLabelIds: string | undefined
  body?: LoanLabelTypes.RequestBodyLoanIdsPut
  query?: void
}

export const loanLabelsAddLoans = makeData<
  LoanLabelsAddLoansResponse,
  LoanLabelsAddLoansKey,
  LoanLabelsAddLoansVariables
>({
  method: 'put',
  name: 'loanLabelsAddLoans',
  summary: 'Add labels to loans',
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans',
  queryKey: ({ query, companyId, loanLabelIds }) => [
    'companies',
    companyId,
    'loan-labels',
    loanLabelIds,
    'loans',
    query,
  ],
})

export type LoanLabelsRemoveLoansKey = [
  'companies',
  string | undefined,
  'loan-labels',
  string | undefined,
  'loans',
  'remove',
  unknown,
]

export type LoanLabelsRemoveLoansResponse = void

export type LoanLabelsRemoveLoansVariables = {
  companyId: string | undefined
  loanLabelIds: string | undefined
  body?: LoanLabelTypes.RequestBodyLoanIdsPut
  query?: void
}

export const loanLabelsRemoveLoans = makeData<
  LoanLabelsRemoveLoansResponse,
  LoanLabelsRemoveLoansKey,
  LoanLabelsRemoveLoansVariables
>({
  method: 'put',
  name: 'loanLabelsRemoveLoans',
  summary: 'Remove labels from loans',
  path: '/companies/{companyId}/loan-labels/{loanLabelIds}/loans/remove',
  queryKey: ({ query, companyId, loanLabelIds }) => [
    'companies',
    companyId,
    'loan-labels',
    loanLabelIds,
    'loans',
    'remove',
    query,
  ],
})
