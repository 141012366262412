/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'

import * as FrontendTypes from './types'

export type BootstrapKey = ['bootstrap', unknown]

export type BootstrapResponse = FrontendTypes.FrontendPreauthBootstrap

export type BootstrapVariables = {
  body?: void
  query?: {
    companyId?: string
    domain?: string
    appDomainType?: SchemaTypes.AppDomainType
  }
}

export const bootstrap = makeData<BootstrapResponse, BootstrapKey, BootstrapVariables>({
  method: 'get',
  name: 'bootstrap',
  summary: 'Get bootstrap data',
  path: '/bootstrap',
  queryKey: ({ query }) => ['bootstrap', query],
})

export type GetFrontendConfigKey = ['frontend-config', unknown]

export type GetFrontendConfigResponse = FrontendTypes.FrontendConfig

export type GetFrontendConfigVariables = {
  body?: void
  query?: void
}

export const getFrontendConfig = makeData<GetFrontendConfigResponse, GetFrontendConfigKey, GetFrontendConfigVariables>({
  method: 'get',
  name: 'getFrontendConfig',
  summary: 'Get frontend configuration',
  path: '/frontend-config',
  queryKey: ({ query }) => ['frontend-config', query],
})
