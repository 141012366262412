/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as PromiseToPayTypes from './types'

export type GetPromiseToPayPlansByCompanyKey = ['promise-to-pay-plans', unknown]

export type GetPromiseToPayPlansByCompanyResponse = PromiseToPayTypes.ResponsePromisetoPayPlansPaging

export type GetPromiseToPayPlansByCompanyVariables = {
  body?: void
  query?: {
    status?: Array<'scheduled' | 'active' | 'kept' | 'broken' | 'canceled'>
    isOverdue?: boolean
    startDateAfter?: string
    endDateBefore?: string
  }
}

export const getPromiseToPayPlansByCompany = makeData<
  GetPromiseToPayPlansByCompanyResponse,
  GetPromiseToPayPlansByCompanyKey,
  GetPromiseToPayPlansByCompanyVariables
>({
  method: 'get',
  name: 'getPromiseToPayPlansByCompany',
  summary: 'Get Promise to Pay Plans for a company',
  path: '/promise-to-pay-plans',
  queryKey: ({ query }) => ['promise-to-pay-plans', query],
})

export type GetPromiseToPayPlansByLoanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  unknown,
]

export type GetPromiseToPayPlansByLoanResponse = PromiseToPayTypes.ResponsePromisetoPayPlansPaging

export type GetPromiseToPayPlansByLoanVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    status?: Array<'scheduled' | 'active' | 'kept' | 'broken' | 'canceled'>
    isOverdue?: boolean
    startDateAfter?: string
    endDateBefore?: string
  }
}

export const getPromiseToPayPlansByLoan = makeData<
  GetPromiseToPayPlansByLoanResponse,
  GetPromiseToPayPlansByLoanKey,
  GetPromiseToPayPlansByLoanVariables
>({
  method: 'get',
  name: 'getPromiseToPayPlansByLoan',
  summary: 'Get Promise to Pay Plans for a Loan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'promise-to-pay-plans', query],
})

export type CreatePromiseToPayPlanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  unknown,
]

export type CreatePromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type CreatePromiseToPayPlanVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: PromiseToPayTypes.RequestBodyPromiseToPayPlanPost
  query?: void
}

export const createPromiseToPayPlan = makeData<
  CreatePromiseToPayPlanResponse,
  CreatePromiseToPayPlanKey,
  CreatePromiseToPayPlanVariables
>({
  method: 'post',
  name: 'createPromiseToPayPlan',
  summary: 'Create Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'promise-to-pay-plans', query],
})

export type GetPromiseToPayPlanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  string | undefined,
  unknown,
]

export type GetPromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type GetPromiseToPayPlanVariables = {
  personId: string | undefined
  loanId: string | undefined
  promiseToPayPlanId: string | undefined
  body?: void
  query?: void
}

export const getPromiseToPayPlan = makeData<
  GetPromiseToPayPlanResponse,
  GetPromiseToPayPlanKey,
  GetPromiseToPayPlanVariables
>({
  method: 'get',
  name: 'getPromiseToPayPlan',
  summary: 'Get Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}',
  queryKey: ({ query, personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    query,
  ],
})

export type CancelPromiseToPayPlanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  string | undefined,
  'cancel',
  unknown,
]

export type CancelPromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type CancelPromiseToPayPlanVariables = {
  personId: string | undefined
  loanId: string | undefined
  promiseToPayPlanId: string | undefined
  body?: PromiseToPayTypes.RequestBodyPromiseToPayPlanCancel
  query?: void
}

export const cancelPromiseToPayPlan = makeData<
  CancelPromiseToPayPlanResponse,
  CancelPromiseToPayPlanKey,
  CancelPromiseToPayPlanVariables
>({
  method: 'put',
  name: 'cancelPromiseToPayPlan',
  summary: 'Cancel Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/cancel',
  queryKey: ({ query, personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'cancel',
    query,
  ],
})

export type EvaluatePromiseToPayPlanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  string | undefined,
  'evaluate',
  unknown,
]

export type EvaluatePromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type EvaluatePromiseToPayPlanVariables = {
  personId: string | undefined
  loanId: string | undefined
  promiseToPayPlanId: string | undefined
  body?: void
  query?: void
}

export const evaluatePromiseToPayPlan = makeData<
  EvaluatePromiseToPayPlanResponse,
  EvaluatePromiseToPayPlanKey,
  EvaluatePromiseToPayPlanVariables
>({
  method: 'put',
  name: 'evaluatePromiseToPayPlan',
  summary: 'Evaluate Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/evaluate',
  queryKey: ({ query, personId, loanId, promiseToPayPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'evaluate',
    query,
  ],
})

export type InternalGetPromiseToPayPlansByCompanyKey = [
  'companies',
  string | undefined,
  'promise-to-pay-plans',
  unknown,
]

export type InternalGetPromiseToPayPlansByCompanyResponse = PromiseToPayTypes.ResponsePromisetoPayPlansPaging

export type InternalGetPromiseToPayPlansByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    status?: Array<'scheduled' | 'active' | 'kept' | 'broken' | 'canceled'>
    isOverdue?: boolean
    startDateAfter?: string
    endDateBefore?: string
  }
}

export const internalGetPromiseToPayPlansByCompany = makeData<
  InternalGetPromiseToPayPlansByCompanyResponse,
  InternalGetPromiseToPayPlansByCompanyKey,
  InternalGetPromiseToPayPlansByCompanyVariables
>({
  method: 'get',
  name: 'internalGetPromiseToPayPlansByCompany',
  summary: 'Get Promise to Pay Plans for a company',
  path: '/companies/{companyId}/promise-to-pay-plans',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'promise-to-pay-plans', query],
})

export type InternalEvaluatePromiseToPayPlanKey = [
  'companies',
  string | undefined,
  'loans',
  string | undefined,
  'promise-to-pay-plans',
  string | undefined,
  'evaluate',
  unknown,
]

export type InternalEvaluatePromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type InternalEvaluatePromiseToPayPlanVariables = {
  companyId: string | undefined
  loanId: string | undefined
  promiseToPayPlanId: string | undefined
  body?: void
  query?: void
}

export const internalEvaluatePromiseToPayPlan = makeData<
  InternalEvaluatePromiseToPayPlanResponse,
  InternalEvaluatePromiseToPayPlanKey,
  InternalEvaluatePromiseToPayPlanVariables
>({
  method: 'put',
  name: 'internalEvaluatePromiseToPayPlan',
  summary: 'Evaluate Promise to Pay Plan, does not require personId',
  path: '/companies/{companyId}/loans/{loanId}/promise-to-pay-plans/{promiseToPayPlanId}/evaluate',
  queryKey: ({ query, companyId, loanId, promiseToPayPlanId }) => [
    'companies',
    companyId,
    'loans',
    loanId,
    'promise-to-pay-plans',
    promiseToPayPlanId,
    'evaluate',
    query,
  ],
})

export type InternalCreateBackdatedPromiseToPayPlanKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'internal-backdated-promise-to-pay-plans',
  unknown,
]

export type InternalCreateBackdatedPromiseToPayPlanResponse = PromiseToPayTypes.ResponsePromiseToPayPlan

export type InternalCreateBackdatedPromiseToPayPlanVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: PromiseToPayTypes.RequestBodyPromiseToPayPlanPost
  query?: void
}

export const internalCreateBackdatedPromiseToPayPlan = makeData<
  InternalCreateBackdatedPromiseToPayPlanResponse,
  InternalCreateBackdatedPromiseToPayPlanKey,
  InternalCreateBackdatedPromiseToPayPlanVariables
>({
  method: 'post',
  name: 'internalCreateBackdatedPromiseToPayPlan',
  summary: 'Internal - Create Backdated Promise to Pay Plan',
  path: '/people/{personId}/loans/{loanId}/internal-backdated-promise-to-pay-plans',
  queryKey: ({ query, personId, loanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'internal-backdated-promise-to-pay-plans',
    query,
  ],
})
