import { FC, ReactNode } from 'react'

import { styled } from 'styled-components'

import Icon from 'core/components/Icon'
import { IconName } from 'core/components/Icon/iconDefinitions'
import { ClickableBase } from 'core/components/lib/Clickable/Clickable'
import variables from 'core/styles/variables'

type AlertProps = {
  /** The visual style of the alert. */
  variant: 'info' | 'warning' | 'success' | 'error' | 'dim'
  /** A short string of text summarizing the alert. */
  title?: string
  /** A React Node, or string, describing the alert in detail. */
  description: ReactNode
  /** Overrides the icon to display alongside the title. */
  icon?: IconName
  /** An optinal call-to-action to display below the description. */
  action?: ReactNode
  margin?: string
}

/**
 * An attention grabbing box of text. Used to present the user with important information.
 */
const Alert: FC<AlertProps> = ({
  variant,
  icon = variant === 'success' ? 'info' : 'warning',
  title,
  description,
  margin = '0',
  action,
}) => (
  <Box
    $background={
      variant === 'info' ? variables.colorYellowLighten
      : variant === 'warning' ?
        variables.colorOrangeLighten
      : variant === 'success' ?
        variables.colorGreenLighten
      : variant === 'error' ?
        variables.colorRedLighten
      : variant === 'dim' ?
        variables.colorBlack20
      : 'transparent'
    }
    $margin={margin}
  >
    {title && (
      <Title>
        <Icon color={variables.colorBlack80} fontSize='24px' name={icon} />
        {title}
      </Title>
    )}

    <Body $hasTitle={!!title}>{description}</Body>

    {action}
  </Box>
)

export default Alert

export const Box = styled.div<{ $margin: string; $background: string }>`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  margin: ${(p) => p.$margin};
  border-radius: 8px;
  background-color: ${(p) => p.$background};
  padding: 16px;

  ${ClickableBase} {
    align-self: end;
    margin-top: 8px;
    background-color: ${(p) => p.$background};
  }
`

const Title = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 500;
`

const Body = styled.div<{ $hasTitle?: boolean }>`
  margin-left: ${({ $hasTitle }) => ($hasTitle ? '32px' : '0')};
  line-height: 24px;
  font-size: 1.6rem;
`
