/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'

import * as BorrowerTypes from './types'

export type InternalBorrowersListKey = ['companies', string | undefined, 'people', unknown]

export type InternalBorrowersListResponse = BorrowerTypes.ResponsePersonPaging

export type InternalBorrowersListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
    status?: 'active' | 'inactive'
    /**
     * @default
     *     25
     */
    limit?: number
    sortBy?: Array<string>
    startingAfter?: string
    endingBefore?: string
    borrowerType?: 'business' | 'person'
    ids?: Array<string>
  }
}

export const internalBorrowersList = makeData<
  InternalBorrowersListResponse,
  InternalBorrowersListKey,
  InternalBorrowersListVariables
>({
  method: 'get',
  name: 'internalBorrowersList',
  summary: 'Get borrowers',
  path: '/companies/{companyId}/people',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'people', query],
})

export type InternalBorrowerCreateKey = ['companies', string | undefined, 'people', unknown]

export type InternalBorrowerCreateResponse = BorrowerTypes.ResponsePerson

export type InternalBorrowerCreateVariables = {
  companyId: string | undefined
  body?: BorrowerTypes.RequestBodyPersonRequest
  query?: void
}

export const internalBorrowerCreate = makeData<
  InternalBorrowerCreateResponse,
  InternalBorrowerCreateKey,
  InternalBorrowerCreateVariables
>({
  method: 'post',
  name: 'internalBorrowerCreate',
  summary: 'Create borrower',
  path: '/companies/{companyId}/people',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'people', query],
})

export type AdminBorrowersReindexKey = ['companies', string | undefined, 'people', 'index-docs', unknown]

export type AdminBorrowersReindexResponse = void

export type AdminBorrowersReindexVariables = {
  companyId: string | undefined
  body?: {
    /**
     * @default
     *     async-fetch-async-write-per-borrower
     */
    method:
      | 'sync-fetch-and-write'
      | 'sync-fetch-async-write-per-borrower'
      | 'async-fetch-async-write-per-borrower'
      | 'async-fetch-and-write'
  }
  query?: {
    status?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    borrowerType?: 'business' | 'person'
    ids?: Array<string>
  }
}

export const adminBorrowersReindex = makeData<
  AdminBorrowersReindexResponse,
  AdminBorrowersReindexKey,
  AdminBorrowersReindexVariables
>({
  method: 'post',
  name: 'adminBorrowersReindex',
  summary: 'Recreate search index for borrowers',
  path: '/companies/{companyId}/people/index-docs',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'people', 'index-docs', query],
})

export type InternalBorrowersListIdsKey = ['companies', string | undefined, 'people', 'ids', unknown]

export type InternalBorrowersListIdsResponse = void

export type InternalBorrowersListIdsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
    status?: string
    /**
     * @default
     *     100
     */
    limit?: number
    sortBy?: Array<string>
    startingAfter?: string
    endingBefore?: string
    borrowerType?: 'business' | 'person'
    ids?: Array<string>
  }
}

export const internalBorrowersListIds = makeData<
  InternalBorrowersListIdsResponse,
  InternalBorrowersListIdsKey,
  InternalBorrowersListIdsVariables
>({
  method: 'get',
  name: 'internalBorrowersListIds',
  summary: 'Get borrowers',
  path: '/companies/{companyId}/people/ids',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'people', 'ids', query],
})

export type InternalBorrowerGetKey = ['companies', string | undefined, 'people', string | undefined, unknown]

export type InternalBorrowerGetResponse = BorrowerTypes.ResponsePerson

export type InternalBorrowerGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
    includeIdentity?: boolean
  }
}

export const internalBorrowerGet = makeData<
  InternalBorrowerGetResponse,
  InternalBorrowerGetKey,
  InternalBorrowerGetVariables
>({
  method: 'get',
  name: 'internalBorrowerGet',
  summary: 'Get borrowers by ID',
  path: '/companies/{companyId}/people/{personId}',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, query],
})

export type InternalBorrowerUpdateKey = ['companies', string | undefined, 'people', string | undefined, unknown]

export type InternalBorrowerUpdateResponse = BorrowerTypes.ResponsePerson

export type InternalBorrowerUpdateVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyPersonRequest
  query?: void
}

export const internalBorrowerUpdate = makeData<
  InternalBorrowerUpdateResponse,
  InternalBorrowerUpdateKey,
  InternalBorrowerUpdateVariables
>({
  method: 'put',
  name: 'internalBorrowerUpdate',
  summary: 'Update borrower',
  path: '/companies/{companyId}/people/{personId}',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, query],
})

export type AdminGetBorrowerIndexKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'index-docs',
  unknown,
]

export type AdminGetBorrowerIndexResponse = void

export type AdminGetBorrowerIndexVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: void
}

export const adminGetBorrowerIndex = makeData<
  AdminGetBorrowerIndexResponse,
  AdminGetBorrowerIndexKey,
  AdminGetBorrowerIndexVariables
>({
  method: 'get',
  name: 'adminGetBorrowerIndex',
  summary: "Get a borrower's search index docs",
  path: '/companies/{companyId}/people/{personId}/index-docs',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'index-docs', query],
})

export type AdminBorrowerReindexKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'index-docs',
  unknown,
]

export type AdminBorrowerReindexResponse = void

export type AdminBorrowerReindexVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: {
    async?: boolean
  }
  query?: void
}

export const adminBorrowerReindex = makeData<
  AdminBorrowerReindexResponse,
  AdminBorrowerReindexKey,
  AdminBorrowerReindexVariables
>({
  method: 'post',
  name: 'adminBorrowerReindex',
  summary: "Recreate a borrower's search index doc",
  path: '/companies/{companyId}/people/{personId}/index-docs',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'index-docs', query],
})

export type InternalBorrowerSearchKey = ['companies', string | undefined, 'people', 'search', unknown]

export type InternalBorrowerSearchResponse = BorrowerTypes.ResponsePersonSearchPaging

export type InternalBorrowerSearchVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    _hasTheWords?: string
    _notTheWords?: string
    address?: string
    bankruptcyCourtCase?: string
    borrowerType?: 'business' | 'person'
    businessName?: string
    caseId?: string
    city?: string
    dateOfBirth?: string
    email?: string
    emailExact?: string
    employeeEmail?: string
    employeeEmailExact?: string
    employeePhone?: string
    employeeName?: string
    firstName?: string
    firstNamePartial?: string
    fullName?: string
    incorporatedState?: string
    id?: string
    interactionId?: string
    lastName?: string
    lastNamePartial?: string
    legalRepFirstName?: string
    legalRepLastName?: string
    legalRepEmail?: string
    legalRepPhone?: string
    loanId?: string
    loanIdPartial?: string
    maidenName?: string
    maidenNamePartial?: string
    middleName?: string
    middleNamePartial?: string
    paymentMethod?: string
    phone?: string
    postalCode?: string
    state?: string
    taxId?: string
    _forceIndex?: string
  }
}

export const internalBorrowerSearch = makeData<
  InternalBorrowerSearchResponse,
  InternalBorrowerSearchKey,
  InternalBorrowerSearchVariables
>({
  method: 'get',
  name: 'internalBorrowerSearch',
  summary: 'Search people',
  path: '/companies/{companyId}/people/search',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'people', 'search', query],
})

export type BorrowersListKey = ['people', unknown]

export type BorrowersListResponse = BorrowerTypes.ResponsePersonPaging

export type BorrowersListVariables = {
  body?: void
  query?: {
    searchable?: boolean
    status?: 'active' | 'inactive'
    /**
     * @default
     *     25
     */
    limit?: number
    sortBy?: Array<string>
    startingAfter?: string
    endingBefore?: string
    borrowerType?: 'business' | 'person'
    ids?: Array<string>
  }
}

export const borrowersList = makeData<BorrowersListResponse, BorrowersListKey, BorrowersListVariables>({
  method: 'get',
  name: 'borrowersList',
  summary: 'Get borrowers',
  path: '/people',
  queryKey: ({ query }) => ['people', query],
})

export type BorrowerCreateKey = ['people', unknown]

export type BorrowerCreateResponse = BorrowerTypes.ResponsePerson

export type BorrowerCreateVariables = {
  body?: BorrowerTypes.RequestBodyPersonRequest
  query?: void
}

export const borrowerCreate = makeData<BorrowerCreateResponse, BorrowerCreateKey, BorrowerCreateVariables>({
  method: 'post',
  name: 'borrowerCreate',
  summary: 'Create borrower',
  path: '/people',
  queryKey: ({ query }) => ['people', query],
})

export type BorrowerGetKey = ['people', string | undefined, unknown]

export type BorrowerGetResponse = BorrowerTypes.ResponsePerson

export type BorrowerGetVariables = {
  personId: string | undefined
  body?: void
  query?: {
    includeIdentity?: boolean
  }
}

export const borrowerGet = makeData<BorrowerGetResponse, BorrowerGetKey, BorrowerGetVariables>({
  method: 'get',
  name: 'borrowerGet',
  summary: 'Get borrower by ID',
  path: '/people/{personId}',
  queryKey: ({ query, personId }) => ['people', personId, query],
})

export type BorrowerUpdateKey = ['people', string | undefined, unknown]

export type BorrowerUpdateResponse = BorrowerTypes.ResponsePersonNoIdentity

export type BorrowerUpdateVariables = {
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyPersonPutRequest
  query?: void
}

export const borrowerUpdate = makeData<BorrowerUpdateResponse, BorrowerUpdateKey, BorrowerUpdateVariables>({
  method: 'put',
  name: 'borrowerUpdate',
  summary: 'Update borrower',
  path: '/people/{personId}',
  queryKey: ({ query, personId }) => ['people', personId, query],
})

export type BorrowerSearchKey = ['people', 'search', unknown]

export type BorrowerSearchResponse = BorrowerTypes.ResponsePersonSearchPaging

export type BorrowerSearchVariables = {
  body?: void
  query?: {
    _hasTheWords?: string
    _notTheWords?: string
    address?: string
    bankruptcyCourtCase?: string
    borrowerType?: 'business' | 'person'
    businessName?: string
    caseId?: string
    city?: string
    dateOfBirth?: string
    email?: string
    emailExact?: string
    employeeEmail?: string
    employeeEmailExact?: string
    employeePhone?: string
    employeeName?: string
    firstName?: string
    firstNamePartial?: string
    fullName?: string
    incorporatedState?: string
    id?: string
    interactionId?: string
    lastName?: string
    lastNamePartial?: string
    legalRepFirstName?: string
    legalRepLastName?: string
    legalRepEmail?: string
    legalRepPhone?: string
    loanId?: string
    loanIdPartial?: string
    maidenName?: string
    maidenNamePartial?: string
    middleName?: string
    middleNamePartial?: string
    paymentMethod?: string
    phone?: string
    postalCode?: string
    state?: string
    taxId?: string
    _forceIndex?: string
  }
}

export const borrowerSearch = makeData<BorrowerSearchResponse, BorrowerSearchKey, BorrowerSearchVariables>({
  method: 'get',
  name: 'borrowerSearch',
  summary: 'Search borrowers',
  path: '/people/search',
  queryKey: ({ query }) => ['people', 'search', query],
})

export type InternalGetPrimaryIdentityKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identity',
  unknown,
]

export type InternalGetPrimaryIdentityResponse = BorrowerTypes.ResponseIdentity

export type InternalGetPrimaryIdentityVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: void
}

export const internalGetPrimaryIdentity = makeData<
  InternalGetPrimaryIdentityResponse,
  InternalGetPrimaryIdentityKey,
  InternalGetPrimaryIdentityVariables
>({
  method: 'get',
  name: 'internalGetPrimaryIdentity',
  summary: 'Get primary identity',
  path: '/companies/{companyId}/people/{personId}/identity',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'identity', query],
})

export type InternalListIdentitiesKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identities',
  unknown,
]

export type InternalListIdentitiesResponse = BorrowerTypes.ResponseIdentityPaging

export type InternalListIdentitiesVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    includeArchived?: boolean
  }
}

export const internalListIdentities = makeData<
  InternalListIdentitiesResponse,
  InternalListIdentitiesKey,
  InternalListIdentitiesVariables
>({
  method: 'get',
  name: 'internalListIdentities',
  summary: 'Get identities',
  path: '/companies/{companyId}/people/{personId}/identities',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'identities', query],
})

export type InternalCreateIdentityKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identities',
  unknown,
]

export type InternalCreateIdentityResponse = BorrowerTypes.ResponseIdentity

export type InternalCreateIdentityVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyIdentityRequest
  query?: void
}

export const internalCreateIdentity = makeData<
  InternalCreateIdentityResponse,
  InternalCreateIdentityKey,
  InternalCreateIdentityVariables
>({
  method: 'post',
  name: 'internalCreateIdentity',
  summary: 'Create identity',
  path: '/companies/{companyId}/people/{personId}/identities',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'identities', query],
})

export type InternalGetIdentityKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identities',
  string | undefined,
  unknown,
]

export type InternalGetIdentityResponse = BorrowerTypes.ResponseIdentity

export type InternalGetIdentityVariables = {
  companyId: string | undefined
  personId: string | undefined
  personIdentityId: string | undefined
  body?: void
  query?: void
}

export const internalGetIdentity = makeData<
  InternalGetIdentityResponse,
  InternalGetIdentityKey,
  InternalGetIdentityVariables
>({
  method: 'get',
  name: 'internalGetIdentity',
  summary: 'Get identity by ID',
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
    query,
  ],
})

export type InternalUpdateIdentityKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identities',
  string | undefined,
  unknown,
]

export type InternalUpdateIdentityResponse = BorrowerTypes.ResponseIdentity

export type InternalUpdateIdentityVariables = {
  companyId: string | undefined
  personId: string | undefined
  personIdentityId: string | undefined
  body?: BorrowerTypes.RequestBodyIdentityPutRequest
  query?: void
}

export const internalUpdateIdentity = makeData<
  InternalUpdateIdentityResponse,
  InternalUpdateIdentityKey,
  InternalUpdateIdentityVariables
>({
  method: 'put',
  name: 'internalUpdateIdentity',
  summary: 'Update identity',
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
    query,
  ],
})

export type InternalDeleteIdentityKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'identities',
  string | undefined,
  unknown,
]

export type InternalDeleteIdentityResponse = void

export type InternalDeleteIdentityVariables = {
  companyId: string | undefined
  personId: string | undefined
  personIdentityId: string | undefined
  body?: void
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const internalDeleteIdentity = makeData<
  InternalDeleteIdentityResponse,
  InternalDeleteIdentityKey,
  InternalDeleteIdentityVariables
>({
  method: 'delete',
  name: 'internalDeleteIdentity',
  summary: 'Archive identity',
  path: '/companies/{companyId}/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, companyId, personId, personIdentityId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'identities',
    personIdentityId,
    query,
  ],
})

export type GetPrimaryIdentityKey = ['people', string | undefined, 'identity', unknown]

export type GetPrimaryIdentityResponse = BorrowerTypes.ResponseIdentity

export type GetPrimaryIdentityVariables = {
  personId: string | undefined
  body?: void
  query?: void
}

export const getPrimaryIdentity = makeData<
  GetPrimaryIdentityResponse,
  GetPrimaryIdentityKey,
  GetPrimaryIdentityVariables
>({
  method: 'get',
  name: 'getPrimaryIdentity',
  summary: 'Get primary identity',
  path: '/people/{personId}/identity',
  queryKey: ({ query, personId }) => ['people', personId, 'identity', query],
})

export type ListIdentitiesKey = ['people', string | undefined, 'identities', unknown]

export type ListIdentitiesResponse = BorrowerTypes.ResponseIdentityPaging

export type ListIdentitiesVariables = {
  personId: string | undefined
  body?: void
  query?: {
    includeArchived?: boolean
  }
}

export const listIdentities = makeData<ListIdentitiesResponse, ListIdentitiesKey, ListIdentitiesVariables>({
  method: 'get',
  name: 'listIdentities',
  summary: 'Get identities',
  path: '/people/{personId}/identities',
  queryKey: ({ query, personId }) => ['people', personId, 'identities', query],
})

export type CreateIdentityKey = ['people', string | undefined, 'identities', unknown]

export type CreateIdentityResponse = BorrowerTypes.ResponseIdentity

export type CreateIdentityVariables = {
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyIdentityRequest
  query?: void
}

export const createIdentity = makeData<CreateIdentityResponse, CreateIdentityKey, CreateIdentityVariables>({
  method: 'post',
  name: 'createIdentity',
  summary: 'Create identity',
  path: '/people/{personId}/identities',
  queryKey: ({ query, personId }) => ['people', personId, 'identities', query],
})

export type GetIdentityKey = ['people', string | undefined, 'identities', string | undefined, unknown]

export type GetIdentityResponse = BorrowerTypes.ResponseIdentity

export type GetIdentityVariables = {
  personId: string | undefined
  personIdentityId: string | undefined
  body?: void
  query?: void
}

export const getIdentity = makeData<GetIdentityResponse, GetIdentityKey, GetIdentityVariables>({
  method: 'get',
  name: 'getIdentity',
  summary: 'Get identity by ID',
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId, query],
})

export type UpdateIdentityKey = ['people', string | undefined, 'identities', string | undefined, unknown]

export type UpdateIdentityResponse = BorrowerTypes.ResponseIdentity

export type UpdateIdentityVariables = {
  personId: string | undefined
  personIdentityId: string | undefined
  body?: BorrowerTypes.RequestBodyIdentityPutRequest
  query?: void
}

export const updateIdentity = makeData<UpdateIdentityResponse, UpdateIdentityKey, UpdateIdentityVariables>({
  method: 'put',
  name: 'updateIdentity',
  summary: 'Update identity',
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId, query],
})

export type DeleteIdentityKey = ['people', string | undefined, 'identities', string | undefined, unknown]

export type DeleteIdentityResponse = void

export type DeleteIdentityVariables = {
  personId: string | undefined
  personIdentityId: string | undefined
  body?: void
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const deleteIdentity = makeData<DeleteIdentityResponse, DeleteIdentityKey, DeleteIdentityVariables>({
  method: 'delete',
  name: 'deleteIdentity',
  summary: 'Archive identity',
  path: '/people/{personId}/identities/{personIdentityId}',
  queryKey: ({ query, personId, personIdentityId }) => ['people', personId, 'identities', personIdentityId, query],
})

export type CanInteractKey = ['people', string | undefined, 'can-interact', unknown]

export type CanInteractResponse = BorrowerTypes.ResponseCanInteract

export type CanInteractVariables = {
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyCanInteract
  query?: void
}

export const canInteract = makeData<CanInteractResponse, CanInteractKey, CanInteractVariables>({
  method: 'post',
  name: 'canInteract',
  summary: 'Can interact',
  path: '/people/{personId}/can-interact',
  queryKey: ({ query, personId }) => ['people', personId, 'can-interact', query],
})

export type InternalCanInteractKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'can-interact',
  unknown,
]

export type InternalCanInteractResponse = BorrowerTypes.ResponseCanInteract

export type InternalCanInteractVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: BorrowerTypes.RequestBodyCanInteract
  query?: void
}

export const internalCanInteract = makeData<
  InternalCanInteractResponse,
  InternalCanInteractKey,
  InternalCanInteractVariables
>({
  method: 'post',
  name: 'internalCanInteract',
  summary: 'Can interact',
  path: '/companies/{companyId}/people/{personId}/can-interact',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'can-interact', query],
})
