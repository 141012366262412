/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as UtilityTypes from '../utilities/types'

import * as WorkflowTypes from './types'

export type GetWorkflowTypesKey = ['companies', string | undefined, 'workflow-types', unknown]

export type GetWorkflowTypesResponse = UtilityTypes.NewPaginator & {
  data?: Array<WorkflowTypes.WorkflowType>
}

export type GetWorkflowTypesVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const getWorkflowTypes = makeData<GetWorkflowTypesResponse, GetWorkflowTypesKey, GetWorkflowTypesVariables>({
  method: 'get',
  name: 'getWorkflowTypes',
  summary: 'Get workflow types',
  path: '/companies/{companyId}/workflow-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-types', query],
})

export type CreateWorkflowTypeKey = ['companies', string | undefined, 'workflow-types', unknown]

export type CreateWorkflowTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowType
}

export type CreateWorkflowTypeVariables = {
  companyId: string | undefined
  body?: WorkflowTypes.WorkflowType
  query?: void
}

export const createWorkflowType = makeData<
  CreateWorkflowTypeResponse,
  CreateWorkflowTypeKey,
  CreateWorkflowTypeVariables
>({
  method: 'post',
  name: 'createWorkflowType',
  summary: 'Create workflow type',
  path: '/companies/{companyId}/workflow-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-types', query],
})

export type GetWorkflowTypeByIdKey = ['companies', string | undefined, 'workflow-types', string | undefined, unknown]

export type GetWorkflowTypeByIdResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowType
}

export type GetWorkflowTypeByIdVariables = {
  companyId: string | undefined
  workflowTypeId: string | undefined
  body?: void
  query?: {
    expand?: boolean
  }
}

export const getWorkflowTypeById = makeData<
  GetWorkflowTypeByIdResponse,
  GetWorkflowTypeByIdKey,
  GetWorkflowTypeByIdVariables
>({
  method: 'get',
  name: 'getWorkflowTypeById',
  summary: 'Get workflow type by ID',
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ query, companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId, query],
})

export type UpdateWorkflowTypeKey = ['companies', string | undefined, 'workflow-types', string | undefined, unknown]

export type UpdateWorkflowTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowType
}

export type UpdateWorkflowTypeVariables = {
  companyId: string | undefined
  workflowTypeId: string | undefined
  body?: WorkflowTypes.WorkflowType
  query?: void
}

export const updateWorkflowType = makeData<
  UpdateWorkflowTypeResponse,
  UpdateWorkflowTypeKey,
  UpdateWorkflowTypeVariables
>({
  method: 'put',
  name: 'updateWorkflowType',
  summary: 'Update workflow type',
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ query, companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId, query],
})

export type DeleteWorkflowTypeKey = ['companies', string | undefined, 'workflow-types', string | undefined, unknown]

export type DeleteWorkflowTypeResponse = void

export type DeleteWorkflowTypeVariables = {
  companyId: string | undefined
  workflowTypeId: string | undefined
  body?: void
  query?: void
}

export const deleteWorkflowType = makeData<
  DeleteWorkflowTypeResponse,
  DeleteWorkflowTypeKey,
  DeleteWorkflowTypeVariables
>({
  method: 'delete',
  name: 'deleteWorkflowType',
  summary: 'Delete workflow type',
  path: '/companies/{companyId}/workflow-types/{workflowTypeId}',
  queryKey: ({ query, companyId, workflowTypeId }) => ['companies', companyId, 'workflow-types', workflowTypeId, query],
})

export type GetWorkflowStepTypesKey = ['companies', string | undefined, 'workflow-step-types', unknown]

export type GetWorkflowStepTypesResponse = UtilityTypes.NewPaginator & {
  data?: Array<WorkflowTypes.WorkflowStepType>
}

export type GetWorkflowStepTypesVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const getWorkflowStepTypes = makeData<
  GetWorkflowStepTypesResponse,
  GetWorkflowStepTypesKey,
  GetWorkflowStepTypesVariables
>({
  method: 'get',
  name: 'getWorkflowStepTypes',
  summary: 'Get workflow step types',
  path: '/companies/{companyId}/workflow-step-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-step-types', query],
})

export type CreateWorkflowStepTypeKey = ['companies', string | undefined, 'workflow-step-types', unknown]

export type CreateWorkflowStepTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowStepType
}

export type CreateWorkflowStepTypeVariables = {
  companyId: string | undefined
  body?: WorkflowTypes.WorkflowStepType
  query?: void
}

export const createWorkflowStepType = makeData<
  CreateWorkflowStepTypeResponse,
  CreateWorkflowStepTypeKey,
  CreateWorkflowStepTypeVariables
>({
  method: 'post',
  name: 'createWorkflowStepType',
  summary: 'Create workflow step type',
  path: '/companies/{companyId}/workflow-step-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-step-types', query],
})

export type GetWorkflowStepTypeByIdKey = [
  'companies',
  string | undefined,
  'workflow-step-types',
  string | undefined,
  unknown,
]

export type GetWorkflowStepTypeByIdResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowStepType
}

export type GetWorkflowStepTypeByIdVariables = {
  companyId: string | undefined
  workflowStepTypeId: string | undefined
  body?: void
  query?: {
    expand?: boolean
  }
}

export const getWorkflowStepTypeById = makeData<
  GetWorkflowStepTypeByIdResponse,
  GetWorkflowStepTypeByIdKey,
  GetWorkflowStepTypeByIdVariables
>({
  method: 'get',
  name: 'getWorkflowStepTypeById',
  summary: 'Get workflow step type',
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ query, companyId, workflowStepTypeId }) => [
    'companies',
    companyId,
    'workflow-step-types',
    workflowStepTypeId,
    query,
  ],
})

export type UpdateWorkflowStepTypeKey = [
  'companies',
  string | undefined,
  'workflow-step-types',
  string | undefined,
  unknown,
]

export type UpdateWorkflowStepTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowStepType
}

export type UpdateWorkflowStepTypeVariables = {
  companyId: string | undefined
  workflowStepTypeId: string | undefined
  body?: WorkflowTypes.WorkflowStepType
  query?: void
}

export const updateWorkflowStepType = makeData<
  UpdateWorkflowStepTypeResponse,
  UpdateWorkflowStepTypeKey,
  UpdateWorkflowStepTypeVariables
>({
  method: 'put',
  name: 'updateWorkflowStepType',
  summary: 'Update workflow step type',
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ query, companyId, workflowStepTypeId }) => [
    'companies',
    companyId,
    'workflow-step-types',
    workflowStepTypeId,
    query,
  ],
})

export type DeleteWorkflowStepTypeKey = [
  'companies',
  string | undefined,
  'workflow-step-types',
  string | undefined,
  unknown,
]

export type DeleteWorkflowStepTypeResponse = void

export type DeleteWorkflowStepTypeVariables = {
  companyId: string | undefined
  workflowStepTypeId: string | undefined
  body?: void
  query?: void
}

export const deleteWorkflowStepType = makeData<
  DeleteWorkflowStepTypeResponse,
  DeleteWorkflowStepTypeKey,
  DeleteWorkflowStepTypeVariables
>({
  method: 'delete',
  name: 'deleteWorkflowStepType',
  summary: 'Delete workflow step type',
  path: '/companies/{companyId}/workflow-step-types/{workflowStepTypeId}',
  queryKey: ({ query, companyId, workflowStepTypeId }) => [
    'companies',
    companyId,
    'workflow-step-types',
    workflowStepTypeId,
    query,
  ],
})

export type GetWorkitemTypesKey = ['companies', string | undefined, 'workflow-workitem-types', unknown]

export type GetWorkitemTypesResponse = UtilityTypes.NewPaginator & {
  data?: Array<WorkflowTypes.WorkflowWorkitemType>
}

export type GetWorkitemTypesVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const getWorkitemTypes = makeData<GetWorkitemTypesResponse, GetWorkitemTypesKey, GetWorkitemTypesVariables>({
  method: 'get',
  name: 'getWorkitemTypes',
  summary: 'Get work item types',
  path: '/companies/{companyId}/workflow-workitem-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-workitem-types', query],
})

export type CreateWorkitemTypeKey = ['companies', string | undefined, 'workflow-workitem-types', unknown]

export type CreateWorkitemTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowWorkitemType
}

export type CreateWorkitemTypeVariables = {
  companyId: string | undefined
  body?: WorkflowTypes.WorkflowWorkitemType
  query?: void
}

export const createWorkitemType = makeData<
  CreateWorkitemTypeResponse,
  CreateWorkitemTypeKey,
  CreateWorkitemTypeVariables
>({
  method: 'post',
  name: 'createWorkitemType',
  summary: 'Create work item type',
  path: '/companies/{companyId}/workflow-workitem-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-workitem-types', query],
})

export type GetWorkitemTypeByIdKey = [
  'companies',
  string | undefined,
  'workflow-workitem-types',
  string | undefined,
  unknown,
]

export type GetWorkitemTypeByIdResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowWorkitemType
}

export type GetWorkitemTypeByIdVariables = {
  companyId: string | undefined
  workflowWorkitemTypeId: string | undefined
  body?: void
  query?: void
}

export const getWorkitemTypeById = makeData<
  GetWorkitemTypeByIdResponse,
  GetWorkitemTypeByIdKey,
  GetWorkitemTypeByIdVariables
>({
  method: 'get',
  name: 'getWorkitemTypeById',
  summary: 'Get work item type',
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ query, companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
    query,
  ],
})

export type UpdateWorkitemTypeKey = [
  'companies',
  string | undefined,
  'workflow-workitem-types',
  string | undefined,
  unknown,
]

export type UpdateWorkitemTypeResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.WorkflowWorkitemType
}

export type UpdateWorkitemTypeVariables = {
  companyId: string | undefined
  workflowWorkitemTypeId: string | undefined
  body?: WorkflowTypes.WorkflowWorkitemType
  query?: void
}

export const updateWorkitemType = makeData<
  UpdateWorkitemTypeResponse,
  UpdateWorkitemTypeKey,
  UpdateWorkitemTypeVariables
>({
  method: 'put',
  name: 'updateWorkitemType',
  summary: 'Update work item type',
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ query, companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
    query,
  ],
})

export type DeleteWorkitemTypeKey = [
  'companies',
  string | undefined,
  'workflow-workitem-types',
  string | undefined,
  unknown,
]

export type DeleteWorkitemTypeResponse = void

export type DeleteWorkitemTypeVariables = {
  companyId: string | undefined
  workflowWorkitemTypeId: string | undefined
  body?: void
  query?: void
}

export const deleteWorkitemType = makeData<
  DeleteWorkitemTypeResponse,
  DeleteWorkitemTypeKey,
  DeleteWorkitemTypeVariables
>({
  method: 'delete',
  name: 'deleteWorkitemType',
  summary: 'Delete work item type',
  path: '/companies/{companyId}/workflow-workitem-types/{workflowWorkitemTypeId}',
  queryKey: ({ query, companyId, workflowWorkitemTypeId }) => [
    'companies',
    companyId,
    'workflow-workitem-types',
    workflowWorkitemTypeId,
    query,
  ],
})

export type GetCaseWorkflowKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'workflow',
  unknown,
]

export type GetCaseWorkflowResponse = UtilityTypes.SingletonResponse & {
  data?: WorkflowTypes.Workflow
}

export type GetCaseWorkflowVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: void
}

export const getCaseWorkflow = makeData<GetCaseWorkflowResponse, GetCaseWorkflowKey, GetCaseWorkflowVariables>({
  method: 'get',
  name: 'getCaseWorkflow',
  summary: 'Get case workflow',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow',
  queryKey: ({ query, companyId, personId, caseId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
    query,
  ],
})

export type UpdateWorkflowStepKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'workflow',
  'steps',
  string | undefined,
  unknown,
]

export type UpdateWorkflowStepResponse = void

export type UpdateWorkflowStepVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  workflowStepId: string | undefined
  body?: {
    status?: WorkflowTypes.WorkflowStepStatus
  }
  query?: void
}

export const updateWorkflowStep = makeData<
  UpdateWorkflowStepResponse,
  UpdateWorkflowStepKey,
  UpdateWorkflowStepVariables
>({
  method: 'put',
  name: 'updateWorkflowStep',
  summary: 'Update workflow step status',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow/steps/{workflowStepId}',
  queryKey: ({ query, companyId, personId, caseId, workflowStepId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
    'steps',
    workflowStepId,
    query,
  ],
})

export type UpdateWorkflowWorkitemKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'workflow',
  'steps',
  string | undefined,
  'workitems',
  string | undefined,
  unknown,
]

export type UpdateWorkflowWorkitemResponse = void

export type UpdateWorkflowWorkitemVariables = {
  companyId: string | undefined
  personId: string | undefined
  caseId: string | undefined
  workflowStepId: string | undefined
  workflowWorkitemId: string | undefined
  body?: {
    status?: WorkflowTypes.WorkflowWorkitemStatus
  }
  query?: void
}

export const updateWorkflowWorkitem = makeData<
  UpdateWorkflowWorkitemResponse,
  UpdateWorkflowWorkitemKey,
  UpdateWorkflowWorkitemVariables
>({
  method: 'put',
  name: 'updateWorkflowWorkitem',
  summary: 'Update workflow workitem status',
  path: '/companies/{companyId}/people/{personId}/cases/{caseId}/workflow/steps/{workflowStepId}/workitems/{workflowWorkitemId}',
  queryKey: ({ query, companyId, personId, caseId, workflowStepId, workflowWorkitemId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'cases',
    caseId,
    'workflow',
    'steps',
    workflowStepId,
    'workitems',
    workflowWorkitemId,
    query,
  ],
})

export type ExportWorkflowTypesKey = ['companies', string | undefined, 'workflow-types', 'export', unknown]

export type ExportWorkflowTypesResponse = Array<{}>

export type ExportWorkflowTypesVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     json
     */
    format?: 'json' | 'yaml'
  }
}

export const exportWorkflowTypes = makeData<
  ExportWorkflowTypesResponse,
  ExportWorkflowTypesKey,
  ExportWorkflowTypesVariables
>({
  method: 'post',
  name: 'exportWorkflowTypes',
  summary: 'Export workflow, step and work item types.',
  path: '/companies/{companyId}/workflow-types/export',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-types', 'export', query],
})

export type ImportWorkflowTypesKey = ['companies', string | undefined, 'workflow-types', 'import', unknown]

export type ImportWorkflowTypesResponse = void

export type ImportWorkflowTypesVariables = {
  companyId: string | undefined
  body?: FormData
  query?: void
}

export const importWorkflowTypes = makeData<
  ImportWorkflowTypesResponse,
  ImportWorkflowTypesKey,
  ImportWorkflowTypesVariables
>({
  method: 'post',
  name: 'importWorkflowTypes',
  summary: 'Restore previously exported workflow, step and work item types.',
  path: '/companies/{companyId}/workflow-types/import',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'workflow-types', 'import', query],
})
