import { ReactNode, useEffect } from 'react'

import ReactSelect, { GroupBase, OnChangeValue, Props as ReactSelectProps } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { styled } from 'styled-components'

import customStyles from 'core/components/legacy/Select/customStyles'
import { R } from 'core/helpers'
import { useDebounce, PendingEventHandler, usePendingCallback } from 'core/hooks'

import {
  DropdownIndicator,
  MenuList,
  Option,
  Placeholder,
  PlaceholderProps,
  SelectOption,
  ValueContainer,
} from './components'

export type CommonProps<IsMulti extends boolean, Value> = Omit<
  ReactSelectProps<SelectOption<Value>, IsMulti, GroupBase<SelectOption<Value>>>,
  'onChange' | 'isDisabled' | 'onBlur' | 'styles' | 'defaultValue' | 'placeholder'
> & {
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  fetchNextPage?: () => void
  disabled?: boolean
  className?: string
  width?: string
  prefix?: ReactNode
  placeholder?: string | PlaceholderProps
  isCreatable?: boolean
}

export type BaseSelectProps<IsMulti extends boolean, Value> = CommonProps<IsMulti, Value> & {
  onChange?: PendingEventHandler<OnChangeValue<SelectOption<Value>, IsMulti> | null>
  wrap?: boolean
}

const BaseSelect = <IsMulti extends boolean, Value = string>({
  name,
  value,
  disabled,
  onChange: rawOnChange,
  className,
  width,
  options,
  isLoading,
  isFetchingNextPage,
  fetchNextPage,
  onMenuScrollToBottom,
  onInputChange: rawOnInputChange,
  isCreatable,
  placeholder,
  ...props
}: BaseSelectProps<IsMulti, Value>) => {
  const onInputChange = useDebounce(rawOnInputChange ?? R.noop, 500)

  useEffect(() => {
    if (!isLoading && value === undefined) {
      void rawOnChange?.(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const [isPending, onChange] = usePendingCallback((newValue: OnChangeValue<SelectOption<Value>, IsMulti>) =>
    rawOnChange?.(newValue),
  )

  const Component = isCreatable ? CreatableSelect : ReactSelect

  return (
    <Box className={className} width={width}>
      <Component
        captureMenuScroll
        components={{
          DropdownIndicator,
          MenuList,
          Option,
          ValueContainer,
        }}
        filterOption={rawOnInputChange ? () => true : undefined}
        isDisabled={isPending || isLoading || disabled}
        isLoading={isPending || isFetchingNextPage || isLoading}
        isSearchable={!fetchNextPage || !!rawOnInputChange}
        loadingMessage={() => <span />}
        menuPlacement='auto'
        onChange={onChange}
        onInputChange={onInputChange}
        onMenuScrollToBottom={(e) => {
          if (!isFetchingNextPage && props.hasNextPage) {
            fetchNextPage?.()
          }

          onMenuScrollToBottom?.(e)
        }}
        options={options}
        placeholder={
          !placeholder ? undefined
          : R.isString(placeholder) ?
            placeholder
          : <Placeholder icon={placeholder.icon} label={placeholder.label} />
        }
        styles={customStyles}
        value={value}
        {...props}
      />
    </Box>
  )
}

export default BaseSelect

const Box = styled.div<{ width?: string }>`
  width: ${(p) => p.width ?? 'initial'};
`
