/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as CaseTypes from '../cases/types'

import * as InteractionTypes from './types'

export type UnboundInteractionGetAllKey = ['interactions', unknown]

export type UnboundInteractionGetAllResponse = InteractionTypes.ResponseInteractionsPaging

export type UnboundInteractionGetAllVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    hasNoPerson?: boolean
    channel?: Array<InteractionTypes.InteractionChannel>
    direction?: InteractionTypes.InteractionDirection
    status?: Array<InteractionTypes.InteractionStatus>
    statusDetails?: Array<InteractionTypes.InteractionStatusDetails>
    agentOutcome?: Array<InteractionTypes.InteractionAgentOutcome>
    subject?: Array<string>
    theme?: Array<InteractionTypes.InteractionTheme>
    caseIds?: Array<string>
    contentContains?: string
    sortBy?: Array<string>
    createdAtBefore?: string
    createdAtAfter?: string
    startedAtBefore?: string
    startedAtAfter?: string
    scheduledAtFromBefore?: string
    scheduledAtFromAfter?: string
    scheduledAtToBefore?: string
    scheduledAtToAfter?: string
    createdBy?: string | null
    hasVoicemail?: boolean
    hasRecording?: boolean
    include?: Array<'borrower' | 'contact' | 'lastTaskMeta'>
    sensitive?: boolean | null
    isExternal?: boolean | null
  }
}

export const unboundInteractionGetAll = makeData<
  UnboundInteractionGetAllResponse,
  UnboundInteractionGetAllKey,
  UnboundInteractionGetAllVariables
>({
  method: 'get',
  name: 'unboundInteractionGetAll',
  summary: 'Get interactions',
  path: '/interactions',
  queryKey: ({ query }) => ['interactions', query],
})

export type UnboundInteractionGetByIdKey = ['interactions', string | undefined, unknown]

export type UnboundInteractionGetByIdResponse = InteractionTypes.ResponseInteraction

export type UnboundInteractionGetByIdVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
  }
}

export const unboundInteractionGetById = makeData<
  UnboundInteractionGetByIdResponse,
  UnboundInteractionGetByIdKey,
  UnboundInteractionGetByIdVariables
>({
  method: 'get',
  name: 'unboundInteractionGetById',
  summary: 'Get interaction by ID',
  path: '/interactions/{interactionId}',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, query],
})

export type UnboundInteractionDeleteKey = ['interactions', string | undefined, unknown]

export type UnboundInteractionDeleteResponse = void

export type UnboundInteractionDeleteVariables = {
  interactionId: string | undefined
  body?: void
  query?: void
}

export const unboundInteractionDelete = makeData<
  UnboundInteractionDeleteResponse,
  UnboundInteractionDeleteKey,
  UnboundInteractionDeleteVariables
>({
  method: 'delete',
  name: 'unboundInteractionDelete',
  summary: 'Delete unbound interaction',
  path: '/interactions/{interactionId}',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, query],
})

export type UnboundGetManyPreviousInteractionsKey = ['interactions', string | undefined, 'previous', unknown]

export type UnboundGetManyPreviousInteractionsResponse = InteractionTypes.ResponseInteractionsManyNoPaging

export type UnboundGetManyPreviousInteractionsVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    include?: Array<'borrower' | 'contact' | 'lastTaskMeta'>
  }
}

export const unboundGetManyPreviousInteractions = makeData<
  UnboundGetManyPreviousInteractionsResponse,
  UnboundGetManyPreviousInteractionsKey,
  UnboundGetManyPreviousInteractionsVariables
>({
  method: 'get',
  name: 'unboundGetManyPreviousInteractions',
  summary: 'Get previous interactions',
  path: '/interactions/{interactionId}/previous',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'previous', query],
})

export type InteractionConvoGetKey = ['interactions', string | undefined, 'convo', unknown]

export type InteractionConvoGetResponse = void

export type InteractionConvoGetVariables = {
  interactionId: string | undefined
  body?: void
  query?: void
}

export const interactionConvoGet = makeData<
  InteractionConvoGetResponse,
  InteractionConvoGetKey,
  InteractionConvoGetVariables
>({
  method: 'get',
  name: 'interactionConvoGet',
  summary: 'Get interaction convo',
  path: '/interactions/{interactionId}/convo',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'convo', query],
})

export type InteractionConvoJoinKey = ['interactions', string | undefined, 'convo', 'join', unknown]

export type InteractionConvoJoinResponse = void

export type InteractionConvoJoinVariables = {
  interactionId: string | undefined
  body?: InteractionTypes.RequestBodyConvoJoin
  query?: void
}

export const interactionConvoJoin = makeData<
  InteractionConvoJoinResponse,
  InteractionConvoJoinKey,
  InteractionConvoJoinVariables
>({
  method: 'post',
  name: 'interactionConvoJoin',
  summary: 'Join interaction convo',
  path: '/interactions/{interactionId}/convo/join',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'convo', 'join', query],
})

export type InteractionConvoLeaveKey = ['interactions', string | undefined, 'convo', 'leave', unknown]

export type InteractionConvoLeaveResponse = void

export type InteractionConvoLeaveVariables = {
  interactionId: string | undefined
  body?: void
  query?: void
}

export const interactionConvoLeave = makeData<
  InteractionConvoLeaveResponse,
  InteractionConvoLeaveKey,
  InteractionConvoLeaveVariables
>({
  method: 'post',
  name: 'interactionConvoLeave',
  summary: 'Leave interaction convo',
  path: '/interactions/{interactionId}/convo/leave',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'convo', 'leave', query],
})

export type InteractionConvoCloseKey = ['interactions', string | undefined, 'convo', 'close', unknown]

export type InteractionConvoCloseResponse = void

export type InteractionConvoCloseVariables = {
  interactionId: string | undefined
  body?: void
  query?: void
}

export const interactionConvoClose = makeData<
  InteractionConvoCloseResponse,
  InteractionConvoCloseKey,
  InteractionConvoCloseVariables
>({
  method: 'post',
  name: 'interactionConvoClose',
  summary: 'Close interaction convo',
  path: '/interactions/{interactionId}/convo/close',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'convo', 'close', query],
})

export type UnboundInteractionDownloadRecordingKey = ['interactions', string | undefined, 'recording-content', unknown]

export type UnboundInteractionDownloadRecordingResponse = InteractionTypes.Response200ReturnUrl

export type UnboundInteractionDownloadRecordingVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const unboundInteractionDownloadRecording = makeData<
  UnboundInteractionDownloadRecordingResponse,
  UnboundInteractionDownloadRecordingKey,
  UnboundInteractionDownloadRecordingVariables
>({
  method: 'get',
  name: 'unboundInteractionDownloadRecording',
  summary: 'Download interaction recording',
  path: '/interactions/{interactionId}/recording-content',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'recording-content', query],
})

export type UnboundInteractionDownloadVoicemailKey = ['interactions', string | undefined, 'voicemail-content', unknown]

export type UnboundInteractionDownloadVoicemailResponse = InteractionTypes.Response200ReturnUrl

export type UnboundInteractionDownloadVoicemailVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const unboundInteractionDownloadVoicemail = makeData<
  UnboundInteractionDownloadVoicemailResponse,
  UnboundInteractionDownloadVoicemailKey,
  UnboundInteractionDownloadVoicemailVariables
>({
  method: 'get',
  name: 'unboundInteractionDownloadVoicemail',
  summary: 'Download interaction voicemail',
  path: '/interactions/{interactionId}/voicemail-content',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'voicemail-content', query],
})

export type UnboundInteractionCallTranscriptionResultsGetByInteractionKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type UnboundInteractionCallTranscriptionResultsGetByInteractionResponse =
  InteractionTypes.ResponseCallTranscriptionResultsPaging

export type UnboundInteractionCallTranscriptionResultsGetByInteractionVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
  }
}

export const unboundInteractionCallTranscriptionResultsGetByInteraction = makeData<
  UnboundInteractionCallTranscriptionResultsGetByInteractionResponse,
  UnboundInteractionCallTranscriptionResultsGetByInteractionKey,
  UnboundInteractionCallTranscriptionResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'unboundInteractionCallTranscriptionResultsGetByInteraction',
  summary: 'Get call transcription results for an interaction',
  path: '/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'call-transcripts', query],
})

export type UnboundInteractionSubmitCallTranscriptionRequestKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type UnboundInteractionSubmitCallTranscriptionRequestResponse = InteractionTypes.ResponseCallTranscriptionResult

export type UnboundInteractionSubmitCallTranscriptionRequestVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    skipBlankCheck?: boolean
  }
}

export const unboundInteractionSubmitCallTranscriptionRequest = makeData<
  UnboundInteractionSubmitCallTranscriptionRequestResponse,
  UnboundInteractionSubmitCallTranscriptionRequestKey,
  UnboundInteractionSubmitCallTranscriptionRequestVariables
>({
  method: 'post',
  name: 'unboundInteractionSubmitCallTranscriptionRequest',
  summary: 'Submit a request for call transcription',
  path: '/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'call-transcripts', query],
})

export type UnboundInteractionCallTranscriptionResultsGetKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type UnboundInteractionCallTranscriptionResultsGetResponse = InteractionTypes.ResponseCallTranscriptionResult

export type UnboundInteractionCallTranscriptionResultsGetVariables = {
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const unboundInteractionCallTranscriptionResultsGet = makeData<
  UnboundInteractionCallTranscriptionResultsGetResponse,
  UnboundInteractionCallTranscriptionResultsGetKey,
  UnboundInteractionCallTranscriptionResultsGetVariables
>({
  method: 'get',
  name: 'unboundInteractionCallTranscriptionResultsGet',
  summary: 'Get call transcription results',
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, interactionId, aICallTranscriptionResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type UnboundInteractionCallTranscriptionResultsUpdateKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type UnboundInteractionCallTranscriptionResultsUpdateResponse = InteractionTypes.ResponseCallTranscriptionResult

export type UnboundInteractionCallTranscriptionResultsUpdateVariables = {
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallTranscriptionResultPut
  query?: void
}

export const unboundInteractionCallTranscriptionResultsUpdate = makeData<
  UnboundInteractionCallTranscriptionResultsUpdateResponse,
  UnboundInteractionCallTranscriptionResultsUpdateKey,
  UnboundInteractionCallTranscriptionResultsUpdateVariables
>({
  method: 'put',
  name: 'unboundInteractionCallTranscriptionResultsUpdate',
  summary: 'Update call transcription results',
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, interactionId, aICallTranscriptionResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type UnboundInteractionSubmitCallSummaryRequestKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  unknown,
]

export type UnboundInteractionSubmitCallSummaryRequestResponse = InteractionTypes.ResponseCallSummaryResult

export type UnboundInteractionSubmitCallSummaryRequestVariables = {
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const unboundInteractionSubmitCallSummaryRequest = makeData<
  UnboundInteractionSubmitCallSummaryRequestResponse,
  UnboundInteractionSubmitCallSummaryRequestKey,
  UnboundInteractionSubmitCallSummaryRequestVariables
>({
  method: 'post',
  name: 'unboundInteractionSubmitCallSummaryRequest',
  summary: 'Submit a request for call summary',
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
  queryKey: ({ query, interactionId, aICallTranscriptionResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    query,
  ],
})

export type UnboundInteractionCallSummaryResultsGetKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type UnboundInteractionCallSummaryResultsGetResponse = InteractionTypes.ResponseCallSummaryResult

export type UnboundInteractionCallSummaryResultsGetVariables = {
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: void
  query?: void
}

export const unboundInteractionCallSummaryResultsGet = makeData<
  UnboundInteractionCallSummaryResultsGetResponse,
  UnboundInteractionCallSummaryResultsGetKey,
  UnboundInteractionCallSummaryResultsGetVariables
>({
  method: 'get',
  name: 'unboundInteractionCallSummaryResultsGet',
  summary: 'Get call summary results',
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type UnboundInteractionCallSummaryResultsUpdateKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type UnboundInteractionCallSummaryResultsUpdateResponse = InteractionTypes.ResponseCallSummaryResult

export type UnboundInteractionCallSummaryResultsUpdateVariables = {
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallSummaryResultPut
  query?: void
}

export const unboundInteractionCallSummaryResultsUpdate = makeData<
  UnboundInteractionCallSummaryResultsUpdateResponse,
  UnboundInteractionCallSummaryResultsUpdateKey,
  UnboundInteractionCallSummaryResultsUpdateVariables
>({
  method: 'put',
  name: 'unboundInteractionCallSummaryResultsUpdate',
  summary: 'Update call summary results',
  path: '/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type UnboundInteractionCallSummaryResultsGetByInteractionKey = [
  'interactions',
  string | undefined,
  'call-transcripts',
  'summaries',
  unknown,
]

export type UnboundInteractionCallSummaryResultsGetByInteractionResponse =
  InteractionTypes.ResponseCallSummaryResultsPaging

export type UnboundInteractionCallSummaryResultsGetByInteractionVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
    aICallTranscriptionResultId?: string
  }
}

export const unboundInteractionCallSummaryResultsGetByInteraction = makeData<
  UnboundInteractionCallSummaryResultsGetByInteractionResponse,
  UnboundInteractionCallSummaryResultsGetByInteractionKey,
  UnboundInteractionCallSummaryResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'unboundInteractionCallSummaryResultsGetByInteraction',
  summary: 'Get interaction call summaries',
  path: '/interactions/{interactionId}/call-transcripts/summaries',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'call-transcripts', 'summaries', query],
})

export type InteractionGetAllKey = ['people', string | undefined, 'interactions', unknown]

export type InteractionGetAllResponse = InteractionTypes.ResponseInteractionsPaging

export type InteractionGetAllVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    channel?: Array<InteractionTypes.InteractionChannel>
    direction?: InteractionTypes.InteractionDirection
    status?: Array<InteractionTypes.InteractionStatus>
    statusDetails?: Array<InteractionTypes.InteractionStatusDetails>
    agentOutcome?: Array<InteractionTypes.InteractionAgentOutcome>
    subject?: Array<string>
    theme?: Array<InteractionTypes.InteractionTheme>
    caseIds?: Array<string>
    contentContains?: string
    sortBy?: Array<string>
    include?: Array<'borrower' | 'contact' | 'lastTaskMeta'>
    createdAtBefore?: string
    createdAtAfter?: string
    startedAtBefore?: string
    startedAtAfter?: string
    scheduledAtFromBefore?: string
    scheduledAtFromAfter?: string
    scheduledAtToBefore?: string
    scheduledAtToAfter?: string
    createdBy?: string | null
    hasVoicemail?: boolean
    hasRecording?: boolean
    sensitive?: boolean | null
    isExternal?: boolean | null
  }
}

export const interactionGetAll = makeData<InteractionGetAllResponse, InteractionGetAllKey, InteractionGetAllVariables>({
  method: 'get',
  name: 'interactionGetAll',
  summary: 'Get borrower interactions',
  path: '/people/{personId}/interactions',
  queryKey: ({ query, personId }) => ['people', personId, 'interactions', query],
})

export type InteractionAddKey = ['people', string | undefined, 'interactions', unknown]

export type InteractionAddResponse = InteractionTypes.ResponseInteraction

export type InteractionAddVariables = {
  personId: string | undefined
  body?: InteractionTypes.RequestBodyInteractionPost
  query?: {
    searchable?: boolean
  }
}

export const interactionAdd = makeData<InteractionAddResponse, InteractionAddKey, InteractionAddVariables>({
  method: 'post',
  name: 'interactionAdd',
  summary: 'Create interaction',
  path: '/people/{personId}/interactions',
  queryKey: ({ query, personId }) => ['people', personId, 'interactions', query],
})

export type InteractionGetByIdKey = ['people', string | undefined, 'interactions', string | undefined, unknown]

export type InteractionGetByIdResponse = InteractionTypes.ResponseInteraction

export type InteractionGetByIdVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
  }
}

export const interactionGetById = makeData<
  InteractionGetByIdResponse,
  InteractionGetByIdKey,
  InteractionGetByIdVariables
>({
  method: 'get',
  name: 'interactionGetById',
  summary: 'Get interaction by ID',
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ query, personId, interactionId }) => ['people', personId, 'interactions', interactionId, query],
})

export type InteractionUpdateKey = ['people', string | undefined, 'interactions', string | undefined, unknown]

export type InteractionUpdateResponse = InteractionTypes.ResponseInteraction

export type InteractionUpdateVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: InteractionTypes.RequestBodyInteractionPut
  query?: void
}

export const interactionUpdate = makeData<InteractionUpdateResponse, InteractionUpdateKey, InteractionUpdateVariables>({
  method: 'put',
  name: 'interactionUpdate',
  summary: 'Update interaction',
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ query, personId, interactionId }) => ['people', personId, 'interactions', interactionId, query],
})

export type InteractionDeleteKey = ['people', string | undefined, 'interactions', string | undefined, unknown]

export type InteractionDeleteResponse = void

export type InteractionDeleteVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: void
}

export const interactionDelete = makeData<InteractionDeleteResponse, InteractionDeleteKey, InteractionDeleteVariables>({
  method: 'delete',
  name: 'interactionDelete',
  summary: 'Delete interaction',
  path: '/people/{personId}/interactions/{interactionId}',
  queryKey: ({ query, personId, interactionId }) => ['people', personId, 'interactions', interactionId, query],
})

export type GetManyPreviousInteractionsKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'previous',
  unknown,
]

export type GetManyPreviousInteractionsResponse = InteractionTypes.ResponseInteractionsManyNoPaging

export type GetManyPreviousInteractionsVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    include?: Array<'borrower' | 'contact' | 'lastTaskMeta'>
  }
}

export const getManyPreviousInteractions = makeData<
  GetManyPreviousInteractionsResponse,
  GetManyPreviousInteractionsKey,
  GetManyPreviousInteractionsVariables
>({
  method: 'get',
  name: 'getManyPreviousInteractions',
  summary: 'Get previous interactions',
  path: '/people/{personId}/interactions/{interactionId}/previous',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'previous',
    query,
  ],
})

export type InteractionDownloadRecordingKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'recording-content',
  unknown,
]

export type InteractionDownloadRecordingResponse = InteractionTypes.Response200ReturnUrl

export type InteractionDownloadRecordingVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const interactionDownloadRecording = makeData<
  InteractionDownloadRecordingResponse,
  InteractionDownloadRecordingKey,
  InteractionDownloadRecordingVariables
>({
  method: 'get',
  name: 'interactionDownloadRecording',
  summary: 'Download interaction recording',
  path: '/people/{personId}/interactions/{interactionId}/recording-content',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'recording-content',
    query,
  ],
})

export type InteractionDownloadVoicemailKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'voicemail-content',
  unknown,
]

export type InteractionDownloadVoicemailResponse = InteractionTypes.Response200ReturnUrl

export type InteractionDownloadVoicemailVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const interactionDownloadVoicemail = makeData<
  InteractionDownloadVoicemailResponse,
  InteractionDownloadVoicemailKey,
  InteractionDownloadVoicemailVariables
>({
  method: 'get',
  name: 'interactionDownloadVoicemail',
  summary: 'Download interaction voicemail',
  path: '/people/{personId}/interactions/{interactionId}/voicemail-content',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'voicemail-content',
    query,
  ],
})

export type CallTranscriptionResultsGetByInteractionKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type CallTranscriptionResultsGetByInteractionResponse = InteractionTypes.ResponseCallTranscriptionResultsPaging

export type CallTranscriptionResultsGetByInteractionVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
  }
}

export const callTranscriptionResultsGetByInteraction = makeData<
  CallTranscriptionResultsGetByInteractionResponse,
  CallTranscriptionResultsGetByInteractionKey,
  CallTranscriptionResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'callTranscriptionResultsGetByInteraction',
  summary: 'Get call transcription results for an interaction',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    query,
  ],
})

export type SubmitCallTranscriptionRequestKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type SubmitCallTranscriptionRequestResponse = InteractionTypes.ResponseCallTranscriptionResult

export type SubmitCallTranscriptionRequestVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    skipBlankCheck?: boolean
  }
}

export const submitCallTranscriptionRequest = makeData<
  SubmitCallTranscriptionRequestResponse,
  SubmitCallTranscriptionRequestKey,
  SubmitCallTranscriptionRequestVariables
>({
  method: 'post',
  name: 'submitCallTranscriptionRequest',
  summary: 'Submit a request for call transcription',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    query,
  ],
})

export type CallTranscriptionResultsGetKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type CallTranscriptionResultsGetResponse = InteractionTypes.ResponseCallTranscriptionResult

export type CallTranscriptionResultsGetVariables = {
  personId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const callTranscriptionResultsGet = makeData<
  CallTranscriptionResultsGetResponse,
  CallTranscriptionResultsGetKey,
  CallTranscriptionResultsGetVariables
>({
  method: 'get',
  name: 'callTranscriptionResultsGet',
  summary: 'Get call transcription results',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type CallTranscriptionResultsUpdateKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type CallTranscriptionResultsUpdateResponse = InteractionTypes.ResponseCallTranscriptionResult

export type CallTranscriptionResultsUpdateVariables = {
  personId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallTranscriptionResultPut
  query?: void
}

export const callTranscriptionResultsUpdate = makeData<
  CallTranscriptionResultsUpdateResponse,
  CallTranscriptionResultsUpdateKey,
  CallTranscriptionResultsUpdateVariables
>({
  method: 'put',
  name: 'callTranscriptionResultsUpdate',
  summary: 'Update call transcription results',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type SubmitCallSummaryRequestKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  unknown,
]

export type SubmitCallSummaryRequestResponse = InteractionTypes.ResponseCallSummaryResult

export type SubmitCallSummaryRequestVariables = {
  personId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const submitCallSummaryRequest = makeData<
  SubmitCallSummaryRequestResponse,
  SubmitCallSummaryRequestKey,
  SubmitCallSummaryRequestVariables
>({
  method: 'post',
  name: 'submitCallSummaryRequest',
  summary: 'Submit a request for call summary',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
  queryKey: ({ query, personId, interactionId, aICallTranscriptionResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    query,
  ],
})

export type CallSummaryResultsGetKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type CallSummaryResultsGetResponse = InteractionTypes.ResponseCallSummaryResult

export type CallSummaryResultsGetVariables = {
  personId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: void
  query?: void
}

export const callSummaryResultsGet = makeData<
  CallSummaryResultsGetResponse,
  CallSummaryResultsGetKey,
  CallSummaryResultsGetVariables
>({
  method: 'get',
  name: 'callSummaryResultsGet',
  summary: 'Get call summary results',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, personId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type CallSummaryResultsUpdateKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type CallSummaryResultsUpdateResponse = InteractionTypes.ResponseCallSummaryResult

export type CallSummaryResultsUpdateVariables = {
  personId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallSummaryResultPut
  query?: void
}

export const callSummaryResultsUpdate = makeData<
  CallSummaryResultsUpdateResponse,
  CallSummaryResultsUpdateKey,
  CallSummaryResultsUpdateVariables
>({
  method: 'put',
  name: 'callSummaryResultsUpdate',
  summary: 'Update call summary results',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, personId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type CallSummaryResultsGetByInteractionKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  'summaries',
  unknown,
]

export type CallSummaryResultsGetByInteractionResponse = InteractionTypes.ResponseCallSummaryResultsPaging

export type CallSummaryResultsGetByInteractionVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
    aICallTranscriptionResultId?: string
  }
}

export const callSummaryResultsGetByInteraction = makeData<
  CallSummaryResultsGetByInteractionResponse,
  CallSummaryResultsGetByInteractionKey,
  CallSummaryResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'callSummaryResultsGetByInteraction',
  summary: 'Get interaction call summaries',
  path: '/people/{personId}/interactions/{interactionId}/call-transcripts/summaries',
  queryKey: ({ query, personId, interactionId }) => [
    'people',
    personId,
    'interactions',
    interactionId,
    'call-transcripts',
    'summaries',
    query,
  ],
})

export type GetInteractionCasesKey = [
  'people',
  string | undefined,
  'interactions',
  string | undefined,
  'cases',
  unknown,
]

export type GetInteractionCasesResponse = CaseTypes.ResponseCasesPaging

export type GetInteractionCasesVariables = {
  personId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
  }
}

export const getInteractionCases = makeData<
  GetInteractionCasesResponse,
  GetInteractionCasesKey,
  GetInteractionCasesVariables
>({
  method: 'get',
  name: 'getInteractionCases',
  summary: 'Get cases associated with an interaction',
  path: '/people/{personId}/interactions/{interactionId}/cases',
  queryKey: ({ query, personId, interactionId }) => ['people', personId, 'interactions', interactionId, 'cases', query],
})

export type InteractionSearchKey = ['people', string | undefined, 'interactions', 'search', unknown]

export type InteractionSearchResponse = InteractionTypes.ResponseInteractionSearchPaging

export type InteractionSearchVariables = {
  personId: string | undefined
  body?: void
  query?: {
    _hasTheWords?: string
    _notTheWords?: string
    channel?: Array<InteractionTypes.InteractionChannel>
    content?: string
    caseId?: string
    theme?: Array<InteractionTypes.InteractionTheme>
    startedBefore?: string
    startedAfter?: string
    statusDetails?: Array<InteractionTypes.InteractionStatusDetails>
    _forceIndex?: string
  }
}

export const interactionSearch = makeData<InteractionSearchResponse, InteractionSearchKey, InteractionSearchVariables>({
  method: 'get',
  name: 'interactionSearch',
  summary: "Search a borrower's interactions",
  path: '/people/{personId}/interactions/search',
  queryKey: ({ query, personId }) => ['people', personId, 'interactions', 'search', query],
})

export type InternalUnboundInteractionGetAllKey = ['companies', string | undefined, 'interactions', unknown]

export type InternalUnboundInteractionGetAllResponse = InteractionTypes.ResponseInteractionsPaging

export type InternalUnboundInteractionGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    hasNoPerson?: boolean
    channel?: Array<InteractionTypes.InteractionChannel>
    direction?: InteractionTypes.InteractionDirection
    status?: Array<InteractionTypes.InteractionStatus>
    statusDetails?: Array<InteractionTypes.InteractionStatusDetails>
    agentOutcome?: Array<InteractionTypes.InteractionAgentOutcome>
    subject?: Array<string>
    theme?: Array<InteractionTypes.InteractionTheme>
    caseIds?: Array<string>
    contentContains?: string
    sortBy?: Array<string>
    scheduledAtFromBefore?: string
    scheduledAtFromAfter?: string
    scheduledAtToBefore?: string
    scheduledAtToAfter?: string
    hasVoicemail?: boolean
    hasRecording?: boolean
    sensitive?: boolean | null
    isExternal?: boolean | null
  }
}

export const internalUnboundInteractionGetAll = makeData<
  InternalUnboundInteractionGetAllResponse,
  InternalUnboundInteractionGetAllKey,
  InternalUnboundInteractionGetAllVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionGetAll',
  summary: 'Get interactions',
  path: '/companies/{companyId}/interactions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'interactions', query],
})

export type UnboundInteractionAddKey = ['companies', string | undefined, 'interactions', unknown]

export type UnboundInteractionAddResponse = InteractionTypes.ResponseInteraction

export type UnboundInteractionAddVariables = {
  companyId: string | undefined
  body?: InteractionTypes.RequestBodyInteractionPost
  query?: {
    searchable?: boolean
  }
}

export const unboundInteractionAdd = makeData<
  UnboundInteractionAddResponse,
  UnboundInteractionAddKey,
  UnboundInteractionAddVariables
>({
  method: 'post',
  name: 'unboundInteractionAdd',
  summary: 'Create unbound interaction',
  path: '/companies/{companyId}/interactions',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'interactions', query],
})

export type InternalUnboundInteractionGetByIdKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionGetByIdResponse = InteractionTypes.ResponseInteraction

export type InternalUnboundInteractionGetByIdVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
  }
}

export const internalUnboundInteractionGetById = makeData<
  InternalUnboundInteractionGetByIdResponse,
  InternalUnboundInteractionGetByIdKey,
  InternalUnboundInteractionGetByIdVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionGetById',
  summary: 'Get unbound interaction by ID',
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ query, companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, query],
})

export type UnboundInteractionUpdateKey = ['companies', string | undefined, 'interactions', string | undefined, unknown]

export type UnboundInteractionUpdateResponse = InteractionTypes.ResponseInteraction

export type UnboundInteractionUpdateVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: InteractionTypes.RequestBodyInteractionPut
  query?: void
}

export const unboundInteractionUpdate = makeData<
  UnboundInteractionUpdateResponse,
  UnboundInteractionUpdateKey,
  UnboundInteractionUpdateVariables
>({
  method: 'put',
  name: 'unboundInteractionUpdate',
  summary: 'Update unbound interaction',
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ query, companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, query],
})

export type InternalUnboundInteractionDeleteKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionDeleteResponse = void

export type InternalUnboundInteractionDeleteVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: void
}

export const internalUnboundInteractionDelete = makeData<
  InternalUnboundInteractionDeleteResponse,
  InternalUnboundInteractionDeleteKey,
  InternalUnboundInteractionDeleteVariables
>({
  method: 'delete',
  name: 'internalUnboundInteractionDelete',
  summary: 'Delete unbound interaction',
  path: '/companies/{companyId}/interactions/{interactionId}',
  queryKey: ({ query, companyId, interactionId }) => ['companies', companyId, 'interactions', interactionId, query],
})

export type InternalUnboundGetManyPreviousInteractionsKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'previous',
  unknown,
]

export type InternalUnboundGetManyPreviousInteractionsResponse = InteractionTypes.ResponseInteractionsManyNoPaging

export type InternalUnboundGetManyPreviousInteractionsVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    include?: Array<'borrower' | 'contact' | 'lastTaskMeta'>
  }
}

export const internalUnboundGetManyPreviousInteractions = makeData<
  InternalUnboundGetManyPreviousInteractionsResponse,
  InternalUnboundGetManyPreviousInteractionsKey,
  InternalUnboundGetManyPreviousInteractionsVariables
>({
  method: 'get',
  name: 'internalUnboundGetManyPreviousInteractions',
  summary: 'Get previous interactions',
  path: '/companies/{companyId}/interactions/{interactionId}/previous',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'previous',
    query,
  ],
})

export type InternalInteractionConvoGetKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'convo',
  unknown,
]

export type InternalInteractionConvoGetResponse = void

export type InternalInteractionConvoGetVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: void
}

export const internalInteractionConvoGet = makeData<
  InternalInteractionConvoGetResponse,
  InternalInteractionConvoGetKey,
  InternalInteractionConvoGetVariables
>({
  method: 'get',
  name: 'internalInteractionConvoGet',
  summary: 'Get interaction convo',
  path: '/companies/{companyId}/interactions/{interactionId}/convo',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'convo',
    query,
  ],
})

export type InternalInteractionConvoJoinKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'convo',
  'join',
  unknown,
]

export type InternalInteractionConvoJoinResponse = void

export type InternalInteractionConvoJoinVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: InteractionTypes.RequestBodyConvoJoin
  query?: void
}

export const internalInteractionConvoJoin = makeData<
  InternalInteractionConvoJoinResponse,
  InternalInteractionConvoJoinKey,
  InternalInteractionConvoJoinVariables
>({
  method: 'post',
  name: 'internalInteractionConvoJoin',
  summary: 'Join interaction convo',
  path: '/companies/{companyId}/interactions/{interactionId}/convo/join',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'convo',
    'join',
    query,
  ],
})

export type InternalInteractionConvoLeaveKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'convo',
  'leave',
  unknown,
]

export type InternalInteractionConvoLeaveResponse = void

export type InternalInteractionConvoLeaveVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: void
}

export const internalInteractionConvoLeave = makeData<
  InternalInteractionConvoLeaveResponse,
  InternalInteractionConvoLeaveKey,
  InternalInteractionConvoLeaveVariables
>({
  method: 'post',
  name: 'internalInteractionConvoLeave',
  summary: 'Leave interaction convo',
  path: '/companies/{companyId}/interactions/{interactionId}/convo/leave',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'convo',
    'leave',
    query,
  ],
})

export type InternalInteractionConvoCloseKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'convo',
  'close',
  unknown,
]

export type InternalInteractionConvoCloseResponse = void

export type InternalInteractionConvoCloseVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: void
}

export const internalInteractionConvoClose = makeData<
  InternalInteractionConvoCloseResponse,
  InternalInteractionConvoCloseKey,
  InternalInteractionConvoCloseVariables
>({
  method: 'post',
  name: 'internalInteractionConvoClose',
  summary: 'Close interaction convo',
  path: '/companies/{companyId}/interactions/{interactionId}/convo/close',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'convo',
    'close',
    query,
  ],
})

export type InternalUnboundInteractionDownloadRecordingKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'recording-content',
  unknown,
]

export type InternalUnboundInteractionDownloadRecordingResponse = InteractionTypes.Response200ReturnUrl

export type InternalUnboundInteractionDownloadRecordingVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const internalUnboundInteractionDownloadRecording = makeData<
  InternalUnboundInteractionDownloadRecordingResponse,
  InternalUnboundInteractionDownloadRecordingKey,
  InternalUnboundInteractionDownloadRecordingVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionDownloadRecording',
  summary: 'Download interaction recording',
  path: '/companies/{companyId}/interactions/{interactionId}/recording-content',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'recording-content',
    query,
  ],
})

export type InternalUnboundInteractionDownloadVoicemailKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'voicemail-content',
  unknown,
]

export type InternalUnboundInteractionDownloadVoicemailResponse = InteractionTypes.Response200ReturnUrl

export type InternalUnboundInteractionDownloadVoicemailVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    returnUrl?: boolean
  }
}

export const internalUnboundInteractionDownloadVoicemail = makeData<
  InternalUnboundInteractionDownloadVoicemailResponse,
  InternalUnboundInteractionDownloadVoicemailKey,
  InternalUnboundInteractionDownloadVoicemailVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionDownloadVoicemail',
  summary: 'Download interaction voicemail',
  path: '/companies/{companyId}/interactions/{interactionId}/voicemail-content',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'voicemail-content',
    query,
  ],
})

export type InternalUnboundInteractionCallTranscriptionResultsGetByInteractionKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type InternalUnboundInteractionCallTranscriptionResultsGetByInteractionResponse =
  InteractionTypes.ResponseCallTranscriptionResultsPaging

export type InternalUnboundInteractionCallTranscriptionResultsGetByInteractionVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
  }
}

export const internalUnboundInteractionCallTranscriptionResultsGetByInteraction = makeData<
  InternalUnboundInteractionCallTranscriptionResultsGetByInteractionResponse,
  InternalUnboundInteractionCallTranscriptionResultsGetByInteractionKey,
  InternalUnboundInteractionCallTranscriptionResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionCallTranscriptionResultsGetByInteraction',
  summary: 'Get call transcription results for an interaction',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    query,
  ],
})

export type InternalUnboundInteractionSubmitCallTranscriptionRequestKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  unknown,
]

export type InternalUnboundInteractionSubmitCallTranscriptionRequestResponse =
  InteractionTypes.ResponseCallTranscriptionResult

export type InternalUnboundInteractionSubmitCallTranscriptionRequestVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    skipBlankCheck?: boolean
  }
}

export const internalUnboundInteractionSubmitCallTranscriptionRequest = makeData<
  InternalUnboundInteractionSubmitCallTranscriptionRequestResponse,
  InternalUnboundInteractionSubmitCallTranscriptionRequestKey,
  InternalUnboundInteractionSubmitCallTranscriptionRequestVariables
>({
  method: 'post',
  name: 'internalUnboundInteractionSubmitCallTranscriptionRequest',
  summary: 'Submit a request for call transcription',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    query,
  ],
})

export type InternalUnboundInteractionCallTranscriptionResultsGetKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionCallTranscriptionResultsGetResponse =
  InteractionTypes.ResponseCallTranscriptionResult

export type InternalUnboundInteractionCallTranscriptionResultsGetVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const internalUnboundInteractionCallTranscriptionResultsGet = makeData<
  InternalUnboundInteractionCallTranscriptionResultsGetResponse,
  InternalUnboundInteractionCallTranscriptionResultsGetKey,
  InternalUnboundInteractionCallTranscriptionResultsGetVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionCallTranscriptionResultsGet',
  summary: 'Get call transcription results',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, companyId, interactionId, aICallTranscriptionResultId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type InternalUnboundInteractionCallTranscriptionResultsUpdateKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionCallTranscriptionResultsUpdateResponse =
  InteractionTypes.ResponseCallTranscriptionResult

export type InternalUnboundInteractionCallTranscriptionResultsUpdateVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallTranscriptionResultPut
  query?: void
}

export const internalUnboundInteractionCallTranscriptionResultsUpdate = makeData<
  InternalUnboundInteractionCallTranscriptionResultsUpdateResponse,
  InternalUnboundInteractionCallTranscriptionResultsUpdateKey,
  InternalUnboundInteractionCallTranscriptionResultsUpdateVariables
>({
  method: 'put',
  name: 'internalUnboundInteractionCallTranscriptionResultsUpdate',
  summary: 'Update call transcription results',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}',
  queryKey: ({ query, companyId, interactionId, aICallTranscriptionResultId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    query,
  ],
})

export type InternalUnboundInteractionSubmitCallSummaryRequestKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  unknown,
]

export type InternalUnboundInteractionSubmitCallSummaryRequestResponse = InteractionTypes.ResponseCallSummaryResult

export type InternalUnboundInteractionSubmitCallSummaryRequestVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  body?: void
  query?: void
}

export const internalUnboundInteractionSubmitCallSummaryRequest = makeData<
  InternalUnboundInteractionSubmitCallSummaryRequestResponse,
  InternalUnboundInteractionSubmitCallSummaryRequestKey,
  InternalUnboundInteractionSubmitCallSummaryRequestVariables
>({
  method: 'post',
  name: 'internalUnboundInteractionSubmitCallSummaryRequest',
  summary: 'Submit a request for call summary',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries',
  queryKey: ({ query, companyId, interactionId, aICallTranscriptionResultId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    query,
  ],
})

export type InternalUnboundInteractionCallSummaryResultsGetKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionCallSummaryResultsGetResponse = InteractionTypes.ResponseCallSummaryResult

export type InternalUnboundInteractionCallSummaryResultsGetVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: void
  query?: void
}

export const internalUnboundInteractionCallSummaryResultsGet = makeData<
  InternalUnboundInteractionCallSummaryResultsGetResponse,
  InternalUnboundInteractionCallSummaryResultsGetKey,
  InternalUnboundInteractionCallSummaryResultsGetVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionCallSummaryResultsGet',
  summary: 'Get call summary results',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, companyId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type InternalUnboundInteractionCallSummaryResultsUpdateKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  string | undefined,
  'summaries',
  string | undefined,
  unknown,
]

export type InternalUnboundInteractionCallSummaryResultsUpdateResponse = InteractionTypes.ResponseCallSummaryResult

export type InternalUnboundInteractionCallSummaryResultsUpdateVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  aICallTranscriptionResultId: string | undefined
  aICallSummaryResultId: string | undefined
  body?: InteractionTypes.RequestBodyCallSummaryResultPut
  query?: void
}

export const internalUnboundInteractionCallSummaryResultsUpdate = makeData<
  InternalUnboundInteractionCallSummaryResultsUpdateResponse,
  InternalUnboundInteractionCallSummaryResultsUpdateKey,
  InternalUnboundInteractionCallSummaryResultsUpdateVariables
>({
  method: 'put',
  name: 'internalUnboundInteractionCallSummaryResultsUpdate',
  summary: 'Update call summary results',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/{aICallTranscriptionResultId}/summaries/{aICallSummaryResultId}',
  queryKey: ({ query, companyId, interactionId, aICallTranscriptionResultId, aICallSummaryResultId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    aICallTranscriptionResultId,
    'summaries',
    aICallSummaryResultId,
    query,
  ],
})

export type InternalUnboundInteractionCallSummaryResultsGetByInteractionKey = [
  'companies',
  string | undefined,
  'interactions',
  string | undefined,
  'call-transcripts',
  'summaries',
  unknown,
]

export type InternalUnboundInteractionCallSummaryResultsGetByInteractionResponse =
  InteractionTypes.ResponseCallSummaryResultsPaging

export type InternalUnboundInteractionCallSummaryResultsGetByInteractionVariables = {
  companyId: string | undefined
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    status?: 'pending' | 'completed' | 'failed'
    aICallTranscriptionResultId?: string
  }
}

export const internalUnboundInteractionCallSummaryResultsGetByInteraction = makeData<
  InternalUnboundInteractionCallSummaryResultsGetByInteractionResponse,
  InternalUnboundInteractionCallSummaryResultsGetByInteractionKey,
  InternalUnboundInteractionCallSummaryResultsGetByInteractionVariables
>({
  method: 'get',
  name: 'internalUnboundInteractionCallSummaryResultsGetByInteraction',
  summary: 'Get interaction call summaries',
  path: '/companies/{companyId}/interactions/{interactionId}/call-transcripts/summaries',
  queryKey: ({ query, companyId, interactionId }) => [
    'companies',
    companyId,
    'interactions',
    interactionId,
    'call-transcripts',
    'summaries',
    query,
  ],
})
