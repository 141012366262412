/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as ContactTypes from './types'

export type InternalListContactsKey = ['companies', string | undefined, 'contacts', unknown]

export type InternalListContactsResponse = ContactTypes.ResponseContactInformationPaging

export type InternalListContactsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    collidesWithEmail?: string
    collidesWithPhone?: string
    contactType?: ContactTypes.ContactType
    affiliation?: ContactTypes.ContactAffiliation
    label?: ContactTypes.ContactLabel
    status?: ContactTypes.ContactStatus
    includeArchived?: boolean
    /**
     * @default
     *     true
     */
    includeLegal?: boolean
    receiveTextMessages?: boolean
    value?: string
    powerOfAttorney?: boolean
    authorizedThirdParty?: boolean
  }
}

export const internalListContacts = makeData<
  InternalListContactsResponse,
  InternalListContactsKey,
  InternalListContactsVariables
>({
  method: 'get',
  name: 'internalListContacts',
  summary: 'List contacts',
  path: '/companies/{companyId}/contacts',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'contacts', query],
})

export type InternalListContactsForBorrowerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  unknown,
]

export type InternalListContactsForBorrowerResponse = ContactTypes.ResponseContactInformationPaging

export type InternalListContactsForBorrowerVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    collidesWithEmail?: string
    collidesWithPhone?: string
    contactType?: ContactTypes.ContactType
    affiliation?: ContactTypes.ContactAffiliation
    label?: ContactTypes.ContactLabel
    status?: ContactTypes.ContactStatus
    includeArchived?: boolean
    /**
     * @default
     *     true
     */
    includeLegal?: boolean
    receiveTextMessages?: boolean
    value?: string
    powerOfAttorney?: boolean
    authorizedThirdParty?: boolean
  }
}

export const internalListContactsForBorrower = makeData<
  InternalListContactsForBorrowerResponse,
  InternalListContactsForBorrowerKey,
  InternalListContactsForBorrowerVariables
>({
  method: 'get',
  name: 'internalListContactsForBorrower',
  summary: 'List contacts of borrower',
  path: '/companies/{companyId}/people/{personId}/contacts',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'contacts', query],
})

export type InternalCreateContactForBorrowerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  unknown,
]

export type InternalCreateContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type InternalCreateContactForBorrowerVariables = {
  companyId: string | undefined
  personId: string | undefined
  body?: ContactTypes.RequestBodyContactBodyInternal
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
    confirmationCode?: string
  }
}

export const internalCreateContactForBorrower = makeData<
  InternalCreateContactForBorrowerResponse,
  InternalCreateContactForBorrowerKey,
  InternalCreateContactForBorrowerVariables
>({
  method: 'post',
  name: 'internalCreateContactForBorrower',
  summary: 'Create contact for borrower',
  path: '/companies/{companyId}/people/{personId}/contacts',
  queryKey: ({ query, companyId, personId }) => ['companies', companyId, 'people', personId, 'contacts', query],
})

export type InternalGetContactForBorrowerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  string | undefined,
  unknown,
]

export type InternalGetContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type InternalGetContactForBorrowerVariables = {
  companyId: string | undefined
  personId: string | undefined
  contactId: string | undefined
  body?: void
  query?: void
}

export const internalGetContactForBorrower = makeData<
  InternalGetContactForBorrowerResponse,
  InternalGetContactForBorrowerKey,
  InternalGetContactForBorrowerVariables
>({
  method: 'get',
  name: 'internalGetContactForBorrower',
  summary: 'Get contact of borrower',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, companyId, personId, contactId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'contacts',
    contactId,
    query,
  ],
})

export type InternalUpdateContactForBorrowerKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  string | undefined,
  unknown,
]

export type InternalUpdateContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type InternalUpdateContactForBorrowerVariables = {
  companyId: string | undefined
  personId: string | undefined
  contactId: string | undefined
  body?: ContactTypes.RequestBodyContactBodyInternal
  query?: {
    confirmationCode?: string
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const internalUpdateContactForBorrower = makeData<
  InternalUpdateContactForBorrowerResponse,
  InternalUpdateContactForBorrowerKey,
  InternalUpdateContactForBorrowerVariables
>({
  method: 'put',
  name: 'internalUpdateContactForBorrower',
  summary: 'Update contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, companyId, personId, contactId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'contacts',
    contactId,
    query,
  ],
})

export type InternalDeleteContactKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  string | undefined,
  unknown,
]

export type InternalDeleteContactResponse = void

export type InternalDeleteContactVariables = {
  companyId: string | undefined
  personId: string | undefined
  contactId: string | undefined
  body?: void
  query?: void
}

export const internalDeleteContact = makeData<
  InternalDeleteContactResponse,
  InternalDeleteContactKey,
  InternalDeleteContactVariables
>({
  method: 'delete',
  name: 'internalDeleteContact',
  summary: 'Delete contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, companyId, personId, contactId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'contacts',
    contactId,
    query,
  ],
})

export type InternalCloneContactKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'contacts',
  string | undefined,
  'clone',
  unknown,
]

export type InternalCloneContactResponse = ContactTypes.ResponseContactInformation

export type InternalCloneContactVariables = {
  companyId: string | undefined
  personId: string | undefined
  contactId: string | undefined
  body?: ContactTypes.RequestBodyContactBodyInternal
  query?: {
    confirmationCode?: string
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const internalCloneContact = makeData<
  InternalCloneContactResponse,
  InternalCloneContactKey,
  InternalCloneContactVariables
>({
  method: 'post',
  name: 'internalCloneContact',
  summary: 'Clone contact',
  path: '/companies/{companyId}/people/{personId}/contacts/{contactId}/clone',
  queryKey: ({ query, companyId, personId, contactId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'contacts',
    contactId,
    'clone',
    query,
  ],
})

export type ListContactsForBorrowerKey = ['people', string | undefined, 'contacts', unknown]

export type ListContactsForBorrowerResponse = ContactTypes.ResponseContactInformationPaging

export type ListContactsForBorrowerVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    collidesWithEmail?: string
    collidesWithPhone?: string
    contactType?: ContactTypes.ContactType
    affiliation?: ContactTypes.ContactAffiliation
    label?: ContactTypes.ContactLabel
    status?: ContactTypes.ContactStatus
    includeArchived?: boolean
    /**
     * @default
     *     true
     */
    includeLegal?: boolean
    receiveTextMessages?: boolean
    value?: string
    powerOfAttorney?: boolean
    authorizedThirdParty?: boolean
  }
}

export const listContactsForBorrower = makeData<
  ListContactsForBorrowerResponse,
  ListContactsForBorrowerKey,
  ListContactsForBorrowerVariables
>({
  method: 'get',
  name: 'listContactsForBorrower',
  summary: 'List contact for borrower',
  path: '/people/{personId}/contacts',
  queryKey: ({ query, personId }) => ['people', personId, 'contacts', query],
})

export type CreateContactForBorrowerKey = ['people', string | undefined, 'contacts', unknown]

export type CreateContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type CreateContactForBorrowerVariables = {
  personId: string | undefined
  body?: ContactTypes.RequestBodyContactBody
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
    confirmationCode?: string
  }
}

export const createContactForBorrower = makeData<
  CreateContactForBorrowerResponse,
  CreateContactForBorrowerKey,
  CreateContactForBorrowerVariables
>({
  method: 'post',
  name: 'createContactForBorrower',
  summary: 'Create contact',
  path: '/people/{personId}/contacts',
  queryKey: ({ query, personId }) => ['people', personId, 'contacts', query],
})

export type GetContactForBorrowerKey = ['people', string | undefined, 'contacts', string | undefined, unknown]

export type GetContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type GetContactForBorrowerVariables = {
  personId: string | undefined
  contactId: string | undefined
  body?: void
  query?: void
}

export const getContactForBorrower = makeData<
  GetContactForBorrowerResponse,
  GetContactForBorrowerKey,
  GetContactForBorrowerVariables
>({
  method: 'get',
  name: 'getContactForBorrower',
  summary: 'Get contact by ID',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, personId, contactId }) => ['people', personId, 'contacts', contactId, query],
})

export type UpdateContactForBorrowerKey = ['people', string | undefined, 'contacts', string | undefined, unknown]

export type UpdateContactForBorrowerResponse = ContactTypes.ResponseContactInformation

export type UpdateContactForBorrowerVariables = {
  personId: string | undefined
  contactId: string | undefined
  body?: ContactTypes.RequestBodyContactBody
  query?: {
    confirmationCode?: string
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const updateContactForBorrower = makeData<
  UpdateContactForBorrowerResponse,
  UpdateContactForBorrowerKey,
  UpdateContactForBorrowerVariables
>({
  method: 'put',
  name: 'updateContactForBorrower',
  summary: 'Update contact',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, personId, contactId }) => ['people', personId, 'contacts', contactId, query],
})

export type DeleteContactKey = ['people', string | undefined, 'contacts', string | undefined, unknown]

export type DeleteContactResponse = void

export type DeleteContactVariables = {
  personId: string | undefined
  contactId: string | undefined
  body?: void
  query?: void
}

export const deleteContact = makeData<DeleteContactResponse, DeleteContactKey, DeleteContactVariables>({
  method: 'delete',
  name: 'deleteContact',
  summary: 'Delete contact',
  path: '/people/{personId}/contacts/{contactId}',
  queryKey: ({ query, personId, contactId }) => ['people', personId, 'contacts', contactId, query],
})

export type CloneContactKey = ['people', string | undefined, 'contacts', string | undefined, 'clone', unknown]

export type CloneContactResponse = ContactTypes.ResponseContactInformation

export type CloneContactVariables = {
  personId: string | undefined
  contactId: string | undefined
  body?: ContactTypes.RequestBodyContactBody
  query?: {
    confirmationCode?: string
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const cloneContact = makeData<CloneContactResponse, CloneContactKey, CloneContactVariables>({
  method: 'post',
  name: 'cloneContact',
  summary: 'Clone contact',
  path: '/people/{personId}/contacts/{contactId}/clone',
  queryKey: ({ query, personId, contactId }) => ['people', personId, 'contacts', contactId, 'clone', query],
})
