/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'

import * as EmployeeTypes from './types'

export type InternalTeamsGetKey = ['companies', string | undefined, 'teams', unknown]

export type InternalTeamsGetResponse = EmployeeTypes.ResponseTeamsPaging

export type InternalTeamsGetVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    sortBy?: Array<string>
    offset?: number
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalTeamsGet = makeData<InternalTeamsGetResponse, InternalTeamsGetKey, InternalTeamsGetVariables>({
  method: 'get',
  name: 'internalTeamsGet',
  summary: 'Get teams',
  path: '/companies/{companyId}/teams',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'teams', query],
})

export type InternalTeamsCreateKey = ['companies', string | undefined, 'teams', unknown]

export type InternalTeamsCreateResponse = EmployeeTypes.ResponseTeam

export type InternalTeamsCreateVariables = {
  companyId: string | undefined
  body?: EmployeeTypes.RequestBodyTeamPost
  query?: void
}

export const internalTeamsCreate = makeData<
  InternalTeamsCreateResponse,
  InternalTeamsCreateKey,
  InternalTeamsCreateVariables
>({
  method: 'post',
  name: 'internalTeamsCreate',
  summary: 'Create team',
  path: '/companies/{companyId}/teams',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'teams', query],
})

export type InternalTeamGetKey = ['companies', string | undefined, 'teams', string | undefined, unknown]

export type InternalTeamGetResponse = EmployeeTypes.ResponseTeam

export type InternalTeamGetVariables = {
  companyId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const internalTeamGet = makeData<InternalTeamGetResponse, InternalTeamGetKey, InternalTeamGetVariables>({
  method: 'get',
  name: 'internalTeamGet',
  summary: 'Get team by ID',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ query, companyId, teamId }) => ['companies', companyId, 'teams', teamId, query],
})

export type InternalTeamUpdateKey = ['companies', string | undefined, 'teams', string | undefined, unknown]

export type InternalTeamUpdateResponse = EmployeeTypes.ResponseTeam

export type InternalTeamUpdateVariables = {
  companyId: string | undefined
  teamId: string | undefined
  body?: EmployeeTypes.RequestBodyTeamPost
  query?: void
}

export const internalTeamUpdate = makeData<
  InternalTeamUpdateResponse,
  InternalTeamUpdateKey,
  InternalTeamUpdateVariables
>({
  method: 'put',
  name: 'internalTeamUpdate',
  summary: 'Update',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ query, companyId, teamId }) => ['companies', companyId, 'teams', teamId, query],
})

export type InternalTeamDeleteKey = ['companies', string | undefined, 'teams', string | undefined, unknown]

export type InternalTeamDeleteResponse = void

export type InternalTeamDeleteVariables = {
  companyId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const internalTeamDelete = makeData<
  InternalTeamDeleteResponse,
  InternalTeamDeleteKey,
  InternalTeamDeleteVariables
>({
  method: 'delete',
  name: 'internalTeamDelete',
  summary: 'Delete team',
  path: '/companies/{companyId}/teams/{teamId}',
  queryKey: ({ query, companyId, teamId }) => ['companies', companyId, 'teams', teamId, query],
})

export type InternalEmployeesGetKey = ['companies', string | undefined, 'employees', unknown]

export type InternalEmployeesGetResponse = EmployeeTypes.ResponseEmployeesPaging

export type InternalEmployeesGetVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    sortBy?: Array<string>
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    startingAfter?: string
    endingBefore?: string
    teamId?: SchemaTypes.AnyId
    teamIds?: Array<SchemaTypes.AnyId>
    excludeTeamIds?: Array<SchemaTypes.AnyId>
    managerEmployeeId?: SchemaTypes.AnyId
    search?: string
  }
}

export const internalEmployeesGet = makeData<
  InternalEmployeesGetResponse,
  InternalEmployeesGetKey,
  InternalEmployeesGetVariables
>({
  method: 'get',
  name: 'internalEmployeesGet',
  summary: 'Get employees',
  path: '/companies/{companyId}/employees',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'employees', query],
})

export type InternalEmployeeCreateKey = ['companies', string | undefined, 'employees', unknown]

export type InternalEmployeeCreateResponse = EmployeeTypes.ResponseEmployee

export type InternalEmployeeCreateVariables = {
  companyId: string | undefined
  body?: EmployeeTypes.RequestBodyEmployeePost
  query?: void
}

export const internalEmployeeCreate = makeData<
  InternalEmployeeCreateResponse,
  InternalEmployeeCreateKey,
  InternalEmployeeCreateVariables
>({
  method: 'post',
  name: 'internalEmployeeCreate',
  summary: 'Create employee',
  path: '/companies/{companyId}/employees',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'employees', query],
})

export type InternalEmployeeGetByIdKey = ['companies', string | undefined, 'employees', string | undefined, unknown]

export type InternalEmployeeGetByIdResponse = EmployeeTypes.ResponseEmployee

export type InternalEmployeeGetByIdVariables = {
  companyId: string | undefined
  employeeId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalEmployeeGetById = makeData<
  InternalEmployeeGetByIdResponse,
  InternalEmployeeGetByIdKey,
  InternalEmployeeGetByIdVariables
>({
  method: 'get',
  name: 'internalEmployeeGetById',
  summary: 'Get employee by ID',
  path: '/companies/{companyId}/employees/{employeeId}',
  queryKey: ({ query, companyId, employeeId }) => ['companies', companyId, 'employees', employeeId, query],
})

export type InternalEmployeeUpdateKey = ['companies', string | undefined, 'employees', string | undefined, unknown]

export type InternalEmployeeUpdateResponse = EmployeeTypes.ResponseEmployee

export type InternalEmployeeUpdateVariables = {
  companyId: string | undefined
  employeeId: string | undefined
  body?: EmployeeTypes.RequestBodyEmployeePut
  query?: void
}

export const internalEmployeeUpdate = makeData<
  InternalEmployeeUpdateResponse,
  InternalEmployeeUpdateKey,
  InternalEmployeeUpdateVariables
>({
  method: 'put',
  name: 'internalEmployeeUpdate',
  summary: 'Update employee',
  path: '/companies/{companyId}/employees/{employeeId}',
  queryKey: ({ query, companyId, employeeId }) => ['companies', companyId, 'employees', employeeId, query],
})

export type InternalSyncTwilioWorkerByEmployeeIdKey = [
  'companies',
  string | undefined,
  'employees',
  string | undefined,
  'sync-twilio-worker',
  unknown,
]

export type InternalSyncTwilioWorkerByEmployeeIdResponse = EmployeeTypes.ResponseEmployee

export type InternalSyncTwilioWorkerByEmployeeIdVariables = {
  companyId: string | undefined
  employeeId: string | undefined
  body?: void
  query?: void
}

export const internalSyncTwilioWorkerByEmployeeId = makeData<
  InternalSyncTwilioWorkerByEmployeeIdResponse,
  InternalSyncTwilioWorkerByEmployeeIdKey,
  InternalSyncTwilioWorkerByEmployeeIdVariables
>({
  method: 'post',
  name: 'internalSyncTwilioWorkerByEmployeeId',
  summary: 'Sync employee to Twilio',
  path: '/companies/{companyId}/employees/{employeeId}/sync-twilio-worker',
  queryKey: ({ query, companyId, employeeId }) => [
    'companies',
    companyId,
    'employees',
    employeeId,
    'sync-twilio-worker',
    query,
  ],
})

export type InternalEmployeeAddToTeamKey = [
  'companies',
  string | undefined,
  'employees',
  string | undefined,
  'teams',
  string | undefined,
  unknown,
]

export type InternalEmployeeAddToTeamResponse = void

export type InternalEmployeeAddToTeamVariables = {
  companyId: string | undefined
  employeeId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const internalEmployeeAddToTeam = makeData<
  InternalEmployeeAddToTeamResponse,
  InternalEmployeeAddToTeamKey,
  InternalEmployeeAddToTeamVariables
>({
  method: 'put',
  name: 'internalEmployeeAddToTeam',
  summary: 'Add employee to team',
  path: '/companies/{companyId}/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ query, companyId, employeeId, teamId }) => [
    'companies',
    companyId,
    'employees',
    employeeId,
    'teams',
    teamId,
    query,
  ],
})

export type InternalEmployeeRemoveFromTeamKey = [
  'companies',
  string | undefined,
  'employees',
  string | undefined,
  'teams',
  string | undefined,
  unknown,
]

export type InternalEmployeeRemoveFromTeamResponse = void

export type InternalEmployeeRemoveFromTeamVariables = {
  companyId: string | undefined
  employeeId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const internalEmployeeRemoveFromTeam = makeData<
  InternalEmployeeRemoveFromTeamResponse,
  InternalEmployeeRemoveFromTeamKey,
  InternalEmployeeRemoveFromTeamVariables
>({
  method: 'delete',
  name: 'internalEmployeeRemoveFromTeam',
  summary: 'Remove employee from team',
  path: '/companies/{companyId}/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ query, companyId, employeeId, teamId }) => [
    'companies',
    companyId,
    'employees',
    employeeId,
    'teams',
    teamId,
    query,
  ],
})

export type TeamsGetKey = ['teams', unknown]

export type TeamsGetResponse = EmployeeTypes.ResponseTeamsPaging

export type TeamsGetVariables = {
  body?: void
  query?: {
    sortBy?: Array<string>
    offset?: number
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const teamsGet = makeData<TeamsGetResponse, TeamsGetKey, TeamsGetVariables>({
  method: 'get',
  name: 'teamsGet',
  summary: 'Get teams',
  path: '/teams',
  queryKey: ({ query }) => ['teams', query],
})

export type TeamsCreateKey = ['teams', unknown]

export type TeamsCreateResponse = EmployeeTypes.ResponseTeam

export type TeamsCreateVariables = {
  body?: EmployeeTypes.RequestBodyTeamPost
  query?: void
}

export const teamsCreate = makeData<TeamsCreateResponse, TeamsCreateKey, TeamsCreateVariables>({
  method: 'post',
  name: 'teamsCreate',
  summary: 'Create team',
  path: '/teams',
  queryKey: ({ query }) => ['teams', query],
})

export type TeamGetKey = ['teams', string | undefined, unknown]

export type TeamGetResponse = EmployeeTypes.ResponseTeam

export type TeamGetVariables = {
  teamId: string | undefined
  body?: void
  query?: void
}

export const teamGet = makeData<TeamGetResponse, TeamGetKey, TeamGetVariables>({
  method: 'get',
  name: 'teamGet',
  summary: 'Get team by ID',
  path: '/teams/{teamId}',
  queryKey: ({ query, teamId }) => ['teams', teamId, query],
})

export type TeamUpdateKey = ['teams', string | undefined, unknown]

export type TeamUpdateResponse = EmployeeTypes.ResponseTeam

export type TeamUpdateVariables = {
  teamId: string | undefined
  body?: EmployeeTypes.RequestBodyTeamPost
  query?: void
}

export const teamUpdate = makeData<TeamUpdateResponse, TeamUpdateKey, TeamUpdateVariables>({
  method: 'put',
  name: 'teamUpdate',
  summary: 'Update',
  path: '/teams/{teamId}',
  queryKey: ({ query, teamId }) => ['teams', teamId, query],
})

export type TeamDeleteKey = ['teams', string | undefined, unknown]

export type TeamDeleteResponse = void

export type TeamDeleteVariables = {
  teamId: string | undefined
  body?: void
  query?: void
}

export const teamDelete = makeData<TeamDeleteResponse, TeamDeleteKey, TeamDeleteVariables>({
  method: 'delete',
  name: 'teamDelete',
  summary: 'Delete team',
  path: '/teams/{teamId}',
  queryKey: ({ query, teamId }) => ['teams', teamId, query],
})

export type EmployeesGetKey = ['employees', unknown]

export type EmployeesGetResponse = EmployeeTypes.ResponseEmployeesPaging

export type EmployeesGetVariables = {
  body?: void
  query?: {
    sortBy?: Array<string>
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    startingAfter?: string
    endingBefore?: string
    teamId?: SchemaTypes.AnyId
    teamIds?: Array<SchemaTypes.AnyId>
    excludeTeamIds?: Array<SchemaTypes.AnyId>
    managerEmployeeId?: SchemaTypes.AnyId
    search?: string
  }
}

export const employeesGet = makeData<EmployeesGetResponse, EmployeesGetKey, EmployeesGetVariables>({
  method: 'get',
  name: 'employeesGet',
  summary: 'Get employees',
  path: '/employees',
  queryKey: ({ query }) => ['employees', query],
})

export type EmployeeCreateKey = ['employees', unknown]

export type EmployeeCreateResponse = EmployeeTypes.ResponseEmployee

export type EmployeeCreateVariables = {
  body?: EmployeeTypes.RequestBodyEmployeePost
  query?: void
}

export const employeeCreate = makeData<EmployeeCreateResponse, EmployeeCreateKey, EmployeeCreateVariables>({
  method: 'post',
  name: 'employeeCreate',
  summary: 'Create employee',
  path: '/employees',
  queryKey: ({ query }) => ['employees', query],
})

export type EmployeeGetByIdKey = ['employees', string | undefined, unknown]

export type EmployeeGetByIdResponse = EmployeeTypes.ResponseEmployee

export type EmployeeGetByIdVariables = {
  employeeId: string | undefined
  body?: void
  query?: void
}

export const employeeGetById = makeData<EmployeeGetByIdResponse, EmployeeGetByIdKey, EmployeeGetByIdVariables>({
  method: 'get',
  name: 'employeeGetById',
  summary: 'Get employee by ID',
  path: '/employees/{employeeId}',
  queryKey: ({ query, employeeId }) => ['employees', employeeId, query],
})

export type EmployeeUpdateKey = ['employees', string | undefined, unknown]

export type EmployeeUpdateResponse = EmployeeTypes.ResponseEmployee

export type EmployeeUpdateVariables = {
  employeeId: string | undefined
  body?: EmployeeTypes.RequestBodyEmployeePut
  query?: void
}

export const employeeUpdate = makeData<EmployeeUpdateResponse, EmployeeUpdateKey, EmployeeUpdateVariables>({
  method: 'put',
  name: 'employeeUpdate',
  summary: 'Update employee',
  path: '/employees/{employeeId}',
  queryKey: ({ query, employeeId }) => ['employees', employeeId, query],
})

export type EmployeeAddToTeamKey = ['employees', string | undefined, 'teams', string | undefined, unknown]

export type EmployeeAddToTeamResponse = void

export type EmployeeAddToTeamVariables = {
  employeeId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const employeeAddToTeam = makeData<EmployeeAddToTeamResponse, EmployeeAddToTeamKey, EmployeeAddToTeamVariables>({
  method: 'put',
  name: 'employeeAddToTeam',
  summary: 'Add employee to team',
  path: '/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ query, employeeId, teamId }) => ['employees', employeeId, 'teams', teamId, query],
})

export type EmployeeRemoveFromTeamKey = ['employees', string | undefined, 'teams', string | undefined, unknown]

export type EmployeeRemoveFromTeamResponse = void

export type EmployeeRemoveFromTeamVariables = {
  employeeId: string | undefined
  teamId: string | undefined
  body?: void
  query?: void
}

export const employeeRemoveFromTeam = makeData<
  EmployeeRemoveFromTeamResponse,
  EmployeeRemoveFromTeamKey,
  EmployeeRemoveFromTeamVariables
>({
  method: 'delete',
  name: 'employeeRemoveFromTeam',
  summary: 'Remove employee from team',
  path: '/employees/{employeeId}/teams/{teamId}',
  queryKey: ({ query, employeeId, teamId }) => ['employees', employeeId, 'teams', teamId, query],
})
