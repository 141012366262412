/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as InteractionTypes from '../interactions/types'
import * as SchemaTypes from '../schema/types'
import * as UtilityTypes from '../utilities/types'

import * as CommunicatorTypes from './types'

export type ListTemplateDescriptorsKey = ['communicator', 'templatedescriptors', unknown]

export type ListTemplateDescriptorsResponse = Array<CommunicatorTypes.TemplateDescriptor>

export type ListTemplateDescriptorsVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    companyId?: CommunicatorTypes.CompanyId
  }
}

export const listTemplateDescriptors = makeData<
  ListTemplateDescriptorsResponse,
  ListTemplateDescriptorsKey,
  ListTemplateDescriptorsVariables
>({
  method: 'get',
  name: 'listTemplateDescriptors',
  summary: 'List template descriptors',
  path: '/communicator/templatedescriptors',
  queryKey: ({ query }) => ['communicator', 'templatedescriptors', query],
})

export type CreateTemplateDescriptorKey = ['communicator', 'templatedescriptors', unknown]

export type CreateTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type CreateTemplateDescriptorVariables = {
  body?: CommunicatorTypes.TemplateDescriptorMutate
  query?: void
}

export const createTemplateDescriptor = makeData<
  CreateTemplateDescriptorResponse,
  CreateTemplateDescriptorKey,
  CreateTemplateDescriptorVariables
>({
  method: 'post',
  name: 'createTemplateDescriptor',
  summary: 'Create template descriptor',
  path: '/communicator/templatedescriptors',
  queryKey: ({ query }) => ['communicator', 'templatedescriptors', query],
})

export type GetTemplateDescriptorKey = ['communicator', 'templatedescriptors', string | undefined, unknown]

export type GetTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type GetTemplateDescriptorVariables = {
  descriptorId: string | undefined
  body?: void
  query?: void
}

export const getTemplateDescriptor = makeData<
  GetTemplateDescriptorResponse,
  GetTemplateDescriptorKey,
  GetTemplateDescriptorVariables
>({
  method: 'get',
  name: 'getTemplateDescriptor',
  summary: 'Get template descriptor',
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, descriptorId }) => ['communicator', 'templatedescriptors', descriptorId, query],
})

export type UpdateTemplateDescriptorKey = ['communicator', 'templatedescriptors', string | undefined, unknown]

export type UpdateTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type UpdateTemplateDescriptorVariables = {
  descriptorId: string | undefined
  body?: CommunicatorTypes.TemplateDescriptorMutate
  query?: void
}

export const updateTemplateDescriptor = makeData<
  UpdateTemplateDescriptorResponse,
  UpdateTemplateDescriptorKey,
  UpdateTemplateDescriptorVariables
>({
  method: 'put',
  name: 'updateTemplateDescriptor',
  summary: 'Update template descriptor',
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, descriptorId }) => ['communicator', 'templatedescriptors', descriptorId, query],
})

export type DeleteTemplateDescriptorKey = ['communicator', 'templatedescriptors', string | undefined, unknown]

export type DeleteTemplateDescriptorResponse = void

export type DeleteTemplateDescriptorVariables = {
  descriptorId: string | undefined
  body?: void
  query?: void
}

export const deleteTemplateDescriptor = makeData<
  DeleteTemplateDescriptorResponse,
  DeleteTemplateDescriptorKey,
  DeleteTemplateDescriptorVariables
>({
  method: 'delete',
  name: 'deleteTemplateDescriptor',
  summary: 'Delete template descriptor',
  path: '/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, descriptorId }) => ['communicator', 'templatedescriptors', descriptorId, query],
})

export type ListTemplateVersionsByDescriptorKey = [
  'communicator',
  'templatedescriptors',
  string | undefined,
  'templates',
  unknown,
]

export type ListTemplateVersionsByDescriptorResponse = Array<CommunicatorTypes.TemplateVersion>

export type ListTemplateVersionsByDescriptorVariables = {
  descriptorId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const listTemplateVersionsByDescriptor = makeData<
  ListTemplateVersionsByDescriptorResponse,
  ListTemplateVersionsByDescriptorKey,
  ListTemplateVersionsByDescriptorVariables
>({
  method: 'get',
  name: 'listTemplateVersionsByDescriptor',
  summary: 'List template versions for descriptor',
  path: '/communicator/templatedescriptors/{descriptorId}/templates',
  queryKey: ({ query, descriptorId }) => ['communicator', 'templatedescriptors', descriptorId, 'templates', query],
})

export type ListTemplateVersionsKey = ['communicator', 'templates', unknown]

export type ListTemplateVersionsResponse = Array<CommunicatorTypes.TemplateVersion>

export type ListTemplateVersionsVariables = {
  body?: void
  query?: {
    companyId?: CommunicatorTypes.CompanyId
  }
}

export const listTemplateVersions = makeData<
  ListTemplateVersionsResponse,
  ListTemplateVersionsKey,
  ListTemplateVersionsVariables
>({
  method: 'get',
  name: 'listTemplateVersions',
  summary: 'List all template versions (Deprecated)',
  path: '/communicator/templates',
  queryKey: ({ query }) => ['communicator', 'templates', query],
})

export type CreateTemplateVersionKey = ['communicator', 'templates', unknown]

export type CreateTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type CreateTemplateVersionVariables = {
  body?: CommunicatorTypes.TemplateVersionMutate
  query?: void
}

export const createTemplateVersion = makeData<
  CreateTemplateVersionResponse,
  CreateTemplateVersionKey,
  CreateTemplateVersionVariables
>({
  method: 'post',
  name: 'createTemplateVersion',
  summary: 'Create template version',
  path: '/communicator/templates',
  queryKey: ({ query }) => ['communicator', 'templates', query],
})

export type ListTemplateVersionsModernKey = ['v2', 'communicator', 'templates', unknown]

export type ListTemplateVersionsModernResponse = UtilityTypes.Paging & {
  data?: Array<CommunicatorTypes.TemplateVersion>
}

export type ListTemplateVersionsModernVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    includeContextVars?: boolean
    /**
     * @default
     *     true
     */
    includeContents?: boolean
    subject?: Array<InteractionTypes.InteractionSubject>
    channel?: Array<InteractionTypes.InteractionChannel>
  }
}

export const listTemplateVersionsModern = makeData<
  ListTemplateVersionsModernResponse,
  ListTemplateVersionsModernKey,
  ListTemplateVersionsModernVariables
>({
  method: 'get',
  name: 'listTemplateVersionsModern',
  summary: 'List all template versions (v2)',
  path: '/v2/communicator/templates',
  queryKey: ({ query }) => ['v2', 'communicator', 'templates', query],
})

export type GetTemplateVersionKey = ['communicator', 'templates', string | undefined, unknown]

export type GetTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type GetTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const getTemplateVersion = makeData<
  GetTemplateVersionResponse,
  GetTemplateVersionKey,
  GetTemplateVersionVariables
>({
  method: 'get',
  name: 'getTemplateVersion',
  summary: 'Get template version',
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, query],
})

export type UpdateTemplateVersionKey = ['communicator', 'templates', string | undefined, unknown]

export type UpdateTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type UpdateTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: CommunicatorTypes.TemplateVersionMutate
  query?: void
}

export const updateTemplateVersion = makeData<
  UpdateTemplateVersionResponse,
  UpdateTemplateVersionKey,
  UpdateTemplateVersionVariables
>({
  method: 'put',
  name: 'updateTemplateVersion',
  summary: 'Update template version',
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, query],
})

export type DeleteTemplateVersionKey = ['communicator', 'templates', string | undefined, unknown]

export type DeleteTemplateVersionResponse = void

export type DeleteTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const deleteTemplateVersion = makeData<
  DeleteTemplateVersionResponse,
  DeleteTemplateVersionKey,
  DeleteTemplateVersionVariables
>({
  method: 'delete',
  name: 'deleteTemplateVersion',
  summary: 'Delete template version',
  path: '/communicator/templates/{templateVersionId}',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, query],
})

export type ActivateTemplateVersionKey = ['communicator', 'templates', string | undefined, 'activate', unknown]

export type ActivateTemplateVersionResponse = void

export type ActivateTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const activateTemplateVersion = makeData<
  ActivateTemplateVersionResponse,
  ActivateTemplateVersionKey,
  ActivateTemplateVersionVariables
>({
  method: 'put',
  name: 'activateTemplateVersion',
  summary: 'Activate template version',
  path: '/communicator/templates/{templateVersionId}/activate',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, 'activate', query],
})

export type PreviewTemplateVersionKey = ['communicator', 'templates', string | undefined, 'preview', unknown]

export type PreviewTemplateVersionResponse = {
  subjectLine?: string
  contentHtml?: string
  contentText?: string
}

export type PreviewTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: {
    personId: SchemaTypes.AnyId
    context?: {}
  }
  query?: void
}

export const previewTemplateVersion = makeData<
  PreviewTemplateVersionResponse,
  PreviewTemplateVersionKey,
  PreviewTemplateVersionVariables
>({
  method: 'post',
  name: 'previewTemplateVersion',
  summary: 'Render template version preview',
  path: '/communicator/templates/{templateVersionId}/preview',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, 'preview', query],
})

export type RenderTemplateVersionKey = ['communicator', 'templates', string | undefined, 'render', unknown]

export type RenderTemplateVersionResponse = void

export type RenderTemplateVersionVariables = {
  templateVersionId: string | undefined
  body?: CommunicatorTypes.TemplateRender & {}
  query?: {
    /**
     * @default
     *     html
     */
    fmt?: 'text' | 'html' | 'pdf'
  }
}

export const renderTemplateVersion = makeData<
  RenderTemplateVersionResponse,
  RenderTemplateVersionKey,
  RenderTemplateVersionVariables
>({
  method: 'post',
  name: 'renderTemplateVersion',
  summary: 'Render template version',
  path: '/communicator/templates/{templateVersionId}/render',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, 'render', query],
})

export type GetTemplateVarsKey = ['communicator', 'templates', string | undefined, 'vars', unknown]

export type GetTemplateVarsResponse = Array<string>

export type GetTemplateVarsVariables = {
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const getTemplateVars = makeData<GetTemplateVarsResponse, GetTemplateVarsKey, GetTemplateVarsVariables>({
  method: 'get',
  name: 'getTemplateVars',
  summary: 'List context vars for template version',
  path: '/communicator/templates/{templateVersionId}/vars',
  queryKey: ({ query, templateVersionId }) => ['communicator', 'templates', templateVersionId, 'vars', query],
})

export type ExportTemplatesKey = ['communicator', 'templates', 'export', unknown]

export type ExportTemplatesResponse = Array<any>

export type ExportTemplatesVariables = {
  body?: void
  query?: {
    companyId: string
    /**
     * @default
     *     true
     */
    activeOnly?: boolean
  }
}

export const exportTemplates = makeData<ExportTemplatesResponse, ExportTemplatesKey, ExportTemplatesVariables>({
  method: 'get',
  name: 'exportTemplates',
  summary: 'Export all template descriptors and versions',
  path: '/communicator/templates/export',
  queryKey: ({ query }) => ['communicator', 'templates', 'export', query],
})

export type ImportTemplatesKey = ['communicator', 'templates', 'import', unknown]

export type ImportTemplatesResponse = void

export type ImportTemplatesVariables = {
  body?: Array<any>
  query?: {
    companyId: string
    /**
     * @default
     *     true
     */
    allowActivate?: boolean
  }
}

export const importTemplates = makeData<ImportTemplatesResponse, ImportTemplatesKey, ImportTemplatesVariables>({
  method: 'post',
  name: 'importTemplates',
  summary: 'Import exported template descriptors and versions',
  path: '/communicator/templates/import',
  queryKey: ({ query }) => ['communicator', 'templates', 'import', query],
})

export type HandleSendKey = ['communicator', 'send', unknown]

export type HandleSendResponse = void

export type HandleSendVariables = {
  body?: CommunicatorTypes.CommunicatorSend
  query?: void
}

export const handleSend = makeData<HandleSendResponse, HandleSendKey, HandleSendVariables>({
  method: 'post',
  name: 'handleSend',
  summary: 'Send message',
  path: '/communicator/send',
  queryKey: ({ query }) => ['communicator', 'send', query],
})

export type HandleReceiveKey = ['communicator', 'receive', unknown]

export type HandleReceiveResponse = UtilityTypes.SingletonResponse & {
  data?: InteractionTypes.Interaction
}

export type HandleReceiveVariables = {
  body?: CommunicatorTypes.RequestBodyCommunicatorReceiveOnlyPost
  query?: void
}

export const handleReceive = makeData<HandleReceiveResponse, HandleReceiveKey, HandleReceiveVariables>({
  method: 'post',
  name: 'handleReceive',
  summary: 'Receive message',
  path: '/communicator/receive',
  queryKey: ({ query }) => ['communicator', 'receive', query],
})

export type ResendKey = ['communicator', 'resend', unknown]

export type ResendResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** ID of the new interaction. */
    interactionId?: string
  }
}

export type ResendVariables = {
  body?: {
    /** The interaction to resend. */
    interactionId: string
  }
  query?: void
}

export const resend = makeData<ResendResponse, ResendKey, ResendVariables>({
  method: 'post',
  name: 'resend',
  summary: 'Resend existing interaction',
  path: '/communicator/resend',
  queryKey: ({ query }) => ['communicator', 'resend', query],
})

export type RenderKey = ['communicator', 'render', unknown]

export type RenderResponse = void

export type RenderVariables = {
  body?: CommunicatorTypes.TemplateRender & {}
  query?: {
    /**
     * @default
     *     html
     */
    fmt?: 'text' | 'html' | 'pdf'
  }
}

export const render = makeData<RenderResponse, RenderKey, RenderVariables>({
  method: 'post',
  name: 'render',
  summary: 'Render to download',
  path: '/communicator/render',
  queryKey: ({ query }) => ['communicator', 'render', query],
})

export type RenderToDocumentKey = ['communicator', 'render-to-document', unknown]

export type RenderToDocumentResponse = void

export type RenderToDocumentVariables = {
  body?: CommunicatorTypes.TemplateRender & {
    /** The unique identifier of the Statement. Can be Peach or lender's external identifier. The statement attributes will be used as dynamic fields in the template. */
    statementId?: string
    /** The unique identifier of the Document Descriptor. Can be Peach or lender's external identifier. The rendered content will create a file in HTML or text format and associate it with the documentId. To convert the rendered document to PDF please use another endpoint, `convert_person_document`, as an extra step. */
    documentId: string
  }
  query?: {
    /**
     * @default
     *     html
     */
    fmt?: 'text' | 'html' | 'pdf'
  }
}

export const renderToDocument = makeData<RenderToDocumentResponse, RenderToDocumentKey, RenderToDocumentVariables>({
  method: 'post',
  name: 'renderToDocument',
  summary: 'Render to document',
  path: '/communicator/render-to-document',
  queryKey: ({ query }) => ['communicator', 'render-to-document', query],
})

export type PreviewKey = ['communicator', 'preview', unknown]

export type PreviewResponse = {
  subjectLine?: string
  contentHtml?: string
  contentText?: string
}

export type PreviewVariables = {
  body?: {
    subject: InteractionTypes.InteractionSubject
    channel: InteractionTypes.InteractionChannel
    personId: string
    loanId?: string
    caseId?: string
    context?: {}
    overrideTemplateId?: CommunicatorTypes.OverrideTemplateId
  }
  query?: void
}

export const preview = makeData<PreviewResponse, PreviewKey, PreviewVariables>({
  method: 'post',
  name: 'preview',
  summary: 'Render template preview for subject',
  path: '/communicator/preview',
  queryKey: ({ query }) => ['communicator', 'preview', query],
})

export type SendFreeFormEmailKey = ['communicator', 'send-free-form-email', unknown]

export type SendFreeFormEmailResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** ID of the new interaction */
    interactionId?: string
  }
}

export type SendFreeFormEmailVariables = {
  body?: {
    /** Specifies the email destination. See the body of POST /mail/send at https://sendgrid.com/docs/api-reference/. */
    personalizations: Array<any>
    theme?: InteractionTypes.InteractionTheme
    language?: CommunicatorTypes.language
    /** The subject line of the email. */
    subjectLine?: string
    /** The content of the email in the HTML format. */
    contentHtml?: string
    /** The content of the email in the plain text format. */
    contentPlain?: string
    /** The ID of an existing interaction to link the new interaction to. */
    previousInteractionId?: string
    isTransactional?: CommunicatorTypes.SendIsTransactionalDefaultFalse
  }
  query?: void
}

export const sendFreeFormEmail = makeData<SendFreeFormEmailResponse, SendFreeFormEmailKey, SendFreeFormEmailVariables>({
  method: 'post',
  name: 'sendFreeFormEmail',
  summary: 'Send free-form email',
  path: '/communicator/send-free-form-email',
  queryKey: ({ query }) => ['communicator', 'send-free-form-email', query],
})

export type PreviewFreeFormEmailKey = ['communicator', 'preview-free-form-email', unknown]

export type PreviewFreeFormEmailResponse = {
  subjectLine?: string
  contentHtml?: string
  contentText?: string
}

export type PreviewFreeFormEmailVariables = {
  body?: {
    /** The subject line of the email. */
    subjectLine?: string
    /** The content of the email in the HTML format. */
    contentHtml?: string
    /** The content of the email in the plain text format. */
    contentPlain?: string
  }
  query?: void
}

export const previewFreeFormEmail = makeData<
  PreviewFreeFormEmailResponse,
  PreviewFreeFormEmailKey,
  PreviewFreeFormEmailVariables
>({
  method: 'post',
  name: 'previewFreeFormEmail',
  summary: 'Preview free-form email',
  path: '/communicator/preview-free-form-email',
  queryKey: ({ query }) => ['communicator', 'preview-free-form-email', query],
})

export type SendConfirmationCodeKey = ['communicator', 'send-confirmation-code', unknown]

export type SendConfirmationCodeResponse = void

export type SendConfirmationCodeVariables = {
  body?: {
    /**
     * The borrower's unique identifier. Can be Peach or lender's
     * external identifier.
     */
    personId: string
    /** The destination email address. */
    email?: string
    /** The destination phone number. */
    phone?: string
    channel?: InteractionTypes.InteractionChannel
    /**
     * Template specific properties to substitute in the corresponding
     * ConfirmationCode template.
     */
    context?: {}
  }
  query?: void
}

export const sendConfirmationCode = makeData<
  SendConfirmationCodeResponse,
  SendConfirmationCodeKey,
  SendConfirmationCodeVariables
>({
  method: 'post',
  name: 'sendConfirmationCode',
  summary: 'Send confirmation code for contact verification',
  path: '/communicator/send-confirmation-code',
  queryKey: ({ query }) => ['communicator', 'send-confirmation-code', query],
})

export type SubjectDescriptorsListKey = ['communicator', 'subjects', unknown]

export type SubjectDescriptorsListResponse = Array<CommunicatorTypes.SubjectDescriptor>

export type SubjectDescriptorsListVariables = {
  body?: void
  query?: void
}

export const subjectDescriptorsList = makeData<
  SubjectDescriptorsListResponse,
  SubjectDescriptorsListKey,
  SubjectDescriptorsListVariables
>({
  method: 'get',
  name: 'subjectDescriptorsList',
  summary: 'List subject desciptors',
  path: '/communicator/subjects',
  queryKey: ({ query }) => ['communicator', 'subjects', query],
})

export type SubjectDescriptorGetBySubjectKey = ['communicator', 'subjects', string | undefined, unknown]

export type SubjectDescriptorGetBySubjectResponse = CommunicatorTypes.SubjectDescriptor

export type SubjectDescriptorGetBySubjectVariables = {
  subject: string | undefined
  body?: void
  query?: void
}

export const subjectDescriptorGetBySubject = makeData<
  SubjectDescriptorGetBySubjectResponse,
  SubjectDescriptorGetBySubjectKey,
  SubjectDescriptorGetBySubjectVariables
>({
  method: 'get',
  name: 'subjectDescriptorGetBySubject',
  summary: 'Get subject descriptor',
  path: '/communicator/subjects/{subject}',
  queryKey: ({ query, subject }) => ['communicator', 'subjects', subject, query],
})

export type SubjectDescriptorUpdateKey = ['communicator', 'subjects', string | undefined, unknown]

export type SubjectDescriptorUpdateResponse = CommunicatorTypes.SubjectDescriptor

export type SubjectDescriptorUpdateVariables = {
  subject: string | undefined
  body?: CommunicatorTypes.SubjectDescriptor
  query?: void
}

export const subjectDescriptorUpdate = makeData<
  SubjectDescriptorUpdateResponse,
  SubjectDescriptorUpdateKey,
  SubjectDescriptorUpdateVariables
>({
  method: 'put',
  name: 'subjectDescriptorUpdate',
  summary: 'Update subject descriptor',
  path: '/communicator/subjects/{subject}',
  queryKey: ({ query, subject }) => ['communicator', 'subjects', subject, query],
})

export type InternalListTemplateDescriptorsKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  unknown,
]

export type InternalListTemplateDescriptorsResponse = Array<CommunicatorTypes.TemplateDescriptor>

export type InternalListTemplateDescriptorsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    companyId?: CommunicatorTypes.CompanyId
  }
}

export const internalListTemplateDescriptors = makeData<
  InternalListTemplateDescriptorsResponse,
  InternalListTemplateDescriptorsKey,
  InternalListTemplateDescriptorsVariables
>({
  method: 'get',
  name: 'internalListTemplateDescriptors',
  summary: 'List template descriptors',
  path: '/companies/{companyId}/communicator/templatedescriptors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templatedescriptors', query],
})

export type InternalCreateTemplateDescriptorKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  unknown,
]

export type InternalCreateTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type InternalCreateTemplateDescriptorVariables = {
  companyId: string | undefined
  body?: CommunicatorTypes.TemplateDescriptor
  query?: void
}

export const internalCreateTemplateDescriptor = makeData<
  InternalCreateTemplateDescriptorResponse,
  InternalCreateTemplateDescriptorKey,
  InternalCreateTemplateDescriptorVariables
>({
  method: 'post',
  name: 'internalCreateTemplateDescriptor',
  summary: 'Create template descriptor',
  path: '/companies/{companyId}/communicator/templatedescriptors',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templatedescriptors', query],
})

export type InternalGetTemplateDescriptorKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  string | undefined,
  unknown,
]

export type InternalGetTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type InternalGetTemplateDescriptorVariables = {
  companyId: string | undefined
  descriptorId: string | undefined
  body?: void
  query?: void
}

export const internalGetTemplateDescriptor = makeData<
  InternalGetTemplateDescriptorResponse,
  InternalGetTemplateDescriptorKey,
  InternalGetTemplateDescriptorVariables
>({
  method: 'get',
  name: 'internalGetTemplateDescriptor',
  summary: 'Get template descriptor',
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
    query,
  ],
})

export type InternalUpdateTemplateDescriptorKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  string | undefined,
  unknown,
]

export type InternalUpdateTemplateDescriptorResponse = CommunicatorTypes.TemplateDescriptor

export type InternalUpdateTemplateDescriptorVariables = {
  companyId: string | undefined
  descriptorId: string | undefined
  body?: CommunicatorTypes.TemplateDescriptor
  query?: void
}

export const internalUpdateTemplateDescriptor = makeData<
  InternalUpdateTemplateDescriptorResponse,
  InternalUpdateTemplateDescriptorKey,
  InternalUpdateTemplateDescriptorVariables
>({
  method: 'put',
  name: 'internalUpdateTemplateDescriptor',
  summary: 'Update template descriptor',
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
    query,
  ],
})

export type InternalDeleteTemplateDescriptorKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  string | undefined,
  unknown,
]

export type InternalDeleteTemplateDescriptorResponse = void

export type InternalDeleteTemplateDescriptorVariables = {
  companyId: string | undefined
  descriptorId: string | undefined
  body?: void
  query?: void
}

export const internalDeleteTemplateDescriptor = makeData<
  InternalDeleteTemplateDescriptorResponse,
  InternalDeleteTemplateDescriptorKey,
  InternalDeleteTemplateDescriptorVariables
>({
  method: 'delete',
  name: 'internalDeleteTemplateDescriptor',
  summary: 'Delete template descriptor',
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}',
  queryKey: ({ query, companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
    query,
  ],
})

export type InternalListTemplateVersionsByDescriptorKey = [
  'companies',
  string | undefined,
  'communicator',
  'templatedescriptors',
  string | undefined,
  'templates',
  unknown,
]

export type InternalListTemplateVersionsByDescriptorResponse = Array<CommunicatorTypes.TemplateVersion>

export type InternalListTemplateVersionsByDescriptorVariables = {
  companyId: string | undefined
  descriptorId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalListTemplateVersionsByDescriptor = makeData<
  InternalListTemplateVersionsByDescriptorResponse,
  InternalListTemplateVersionsByDescriptorKey,
  InternalListTemplateVersionsByDescriptorVariables
>({
  method: 'get',
  name: 'internalListTemplateVersionsByDescriptor',
  summary: 'List templates versions for descriptor',
  path: '/companies/{companyId}/communicator/templatedescriptors/{descriptorId}/templates',
  queryKey: ({ query, companyId, descriptorId }) => [
    'companies',
    companyId,
    'communicator',
    'templatedescriptors',
    descriptorId,
    'templates',
    query,
  ],
})

export type InternalListTemplateVersionsKey = ['companies', string | undefined, 'communicator', 'templates', unknown]

export type InternalListTemplateVersionsResponse = Array<CommunicatorTypes.TemplateVersion>

export type InternalListTemplateVersionsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    companyId?: CommunicatorTypes.CompanyId
  }
}

export const internalListTemplateVersions = makeData<
  InternalListTemplateVersionsResponse,
  InternalListTemplateVersionsKey,
  InternalListTemplateVersionsVariables
>({
  method: 'get',
  name: 'internalListTemplateVersions',
  summary: 'List company templates',
  path: '/companies/{companyId}/communicator/templates',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templates', query],
})

export type InternalCreateTemplateVersionKey = ['companies', string | undefined, 'communicator', 'templates', unknown]

export type InternalCreateTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type InternalCreateTemplateVersionVariables = {
  companyId: string | undefined
  body?: CommunicatorTypes.TemplateVersion
  query?: void
}

export const internalCreateTemplateVersion = makeData<
  InternalCreateTemplateVersionResponse,
  InternalCreateTemplateVersionKey,
  InternalCreateTemplateVersionVariables
>({
  method: 'post',
  name: 'internalCreateTemplateVersion',
  summary: 'Create template',
  path: '/companies/{companyId}/communicator/templates',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templates', query],
})

export type InternalListTemplateVersionsModernKey = [
  'v2',
  'companies',
  string | undefined,
  'communicator',
  'templates',
  unknown,
]

export type InternalListTemplateVersionsModernResponse = UtilityTypes.Paging & {
  data?: Array<CommunicatorTypes.TemplateVersion>
}

export type InternalListTemplateVersionsModernVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    includeContextVars?: boolean
    /**
     * @default
     *     true
     */
    includeContents?: boolean
    subject?: Array<InteractionTypes.InteractionSubject>
    channel?: Array<InteractionTypes.InteractionChannel>
  }
}

export const internalListTemplateVersionsModern = makeData<
  InternalListTemplateVersionsModernResponse,
  InternalListTemplateVersionsModernKey,
  InternalListTemplateVersionsModernVariables
>({
  method: 'get',
  name: 'internalListTemplateVersionsModern',
  summary: 'List company templates (v2)',
  path: '/v2/companies/{companyId}/communicator/templates',
  queryKey: ({ query, companyId }) => ['v2', 'companies', companyId, 'communicator', 'templates', query],
})

export type InternalGetTemplateVersionKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  string | undefined,
  unknown,
]

export type InternalGetTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type InternalGetTemplateVersionVariables = {
  companyId: string | undefined
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const internalGetTemplateVersion = makeData<
  InternalGetTemplateVersionResponse,
  InternalGetTemplateVersionKey,
  InternalGetTemplateVersionVariables
>({
  method: 'get',
  name: 'internalGetTemplateVersion',
  summary: 'Get template version',
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ query, companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
    query,
  ],
})

export type InternalUpdateTemplateVersionKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  string | undefined,
  unknown,
]

export type InternalUpdateTemplateVersionResponse = CommunicatorTypes.TemplateVersion

export type InternalUpdateTemplateVersionVariables = {
  companyId: string | undefined
  templateVersionId: string | undefined
  body?: CommunicatorTypes.TemplateVersion
  query?: void
}

export const internalUpdateTemplateVersion = makeData<
  InternalUpdateTemplateVersionResponse,
  InternalUpdateTemplateVersionKey,
  InternalUpdateTemplateVersionVariables
>({
  method: 'put',
  name: 'internalUpdateTemplateVersion',
  summary: 'Update template version',
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ query, companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
    query,
  ],
})

export type InternalDeleteTemplateVersionKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  string | undefined,
  unknown,
]

export type InternalDeleteTemplateVersionResponse = void

export type InternalDeleteTemplateVersionVariables = {
  companyId: string | undefined
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const internalDeleteTemplateVersion = makeData<
  InternalDeleteTemplateVersionResponse,
  InternalDeleteTemplateVersionKey,
  InternalDeleteTemplateVersionVariables
>({
  method: 'delete',
  name: 'internalDeleteTemplateVersion',
  summary: 'Delete template version',
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}',
  queryKey: ({ query, companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
    query,
  ],
})

export type InternalActivateTemplateVersionKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  string | undefined,
  'activate',
  unknown,
]

export type InternalActivateTemplateVersionResponse = void

export type InternalActivateTemplateVersionVariables = {
  companyId: string | undefined
  templateVersionId: string | undefined
  body?: void
  query?: void
}

export const internalActivateTemplateVersion = makeData<
  InternalActivateTemplateVersionResponse,
  InternalActivateTemplateVersionKey,
  InternalActivateTemplateVersionVariables
>({
  method: 'put',
  name: 'internalActivateTemplateVersion',
  summary: 'Activate template version',
  path: '/companies/{companyId}/communicator/templates/{templateVersionId}/activate',
  queryKey: ({ query, companyId, templateVersionId }) => [
    'companies',
    companyId,
    'communicator',
    'templates',
    templateVersionId,
    'activate',
    query,
  ],
})

export type InternalExportTemplatesKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  'export',
  unknown,
]

export type InternalExportTemplatesResponse = Array<any>

export type InternalExportTemplatesVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     true
     */
    activeOnly?: boolean
  }
}

export const internalExportTemplates = makeData<
  InternalExportTemplatesResponse,
  InternalExportTemplatesKey,
  InternalExportTemplatesVariables
>({
  method: 'get',
  name: 'internalExportTemplates',
  summary: 'Export all template descriptors and versions',
  path: '/companies/{companyId}/communicator/templates/export',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templates', 'export', query],
})

export type InternalImportTemplatesKey = [
  'companies',
  string | undefined,
  'communicator',
  'templates',
  'import',
  unknown,
]

export type InternalImportTemplatesResponse = void

export type InternalImportTemplatesVariables = {
  companyId: string | undefined
  body?: Array<any>
  query?: {
    /**
     * @default
     *     true
     */
    allowActivate?: boolean
  }
}

export const internalImportTemplates = makeData<
  InternalImportTemplatesResponse,
  InternalImportTemplatesKey,
  InternalImportTemplatesVariables
>({
  method: 'post',
  name: 'internalImportTemplates',
  summary: 'Import exported template descriptors and versions',
  path: '/companies/{companyId}/communicator/templates/import',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'templates', 'import', query],
})

export type InternalHandleSendKey = ['companies', string | undefined, 'communicator', 'send', unknown]

export type InternalHandleSendResponse = void

export type InternalHandleSendVariables = {
  companyId: string | undefined
  body?: CommunicatorTypes.CommunicatorSend
  query?: void
}

export const internalHandleSend = makeData<
  InternalHandleSendResponse,
  InternalHandleSendKey,
  InternalHandleSendVariables
>({
  method: 'post',
  name: 'internalHandleSend',
  summary: 'Send message',
  path: '/companies/{companyId}/communicator/send',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'send', query],
})

export type SendTemplatedEmailHandlerKey = [
  'companies',
  string | undefined,
  'communicator',
  'send-templated-email',
  unknown,
]

export type SendTemplatedEmailHandlerResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** ID of the new interaction */
    interactionId?: string
  }
}

export type SendTemplatedEmailHandlerVariables = {
  companyId: string | undefined
  body?: {
    /** A list of email addresses for the message to be sent to. */
    toEmailAddresses: Array<any>
    /** The email address to be sent from. */
    fromEmailAddress: string
    language?: CommunicatorTypes.language
    subject: InteractionTypes.InteractionSubject
    /**
     * Template specific properties to substitute in the corresponding
     * ConfirmationCode template.
     */
    context: {}
    previousInteractionId?: string
  }
  query?: void
}

export const sendTemplatedEmailHandler = makeData<
  SendTemplatedEmailHandlerResponse,
  SendTemplatedEmailHandlerKey,
  SendTemplatedEmailHandlerVariables
>({
  method: 'post',
  name: 'sendTemplatedEmailHandler',
  summary: 'Send templated emails',
  path: '/companies/{companyId}/communicator/send-templated-email',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'send-templated-email', query],
})

export type InternalHandleReceiveKey = ['companies', string | undefined, 'communicator', 'receive', unknown]

export type InternalHandleReceiveResponse = UtilityTypes.SingletonResponse & {
  data?: InteractionTypes.Interaction
}

export type InternalHandleReceiveVariables = {
  companyId: string | undefined
  body?: CommunicatorTypes.RequestBodyCommunicatorReceiveOnlyPost
  query?: void
}

export const internalHandleReceive = makeData<
  InternalHandleReceiveResponse,
  InternalHandleReceiveKey,
  InternalHandleReceiveVariables
>({
  method: 'post',
  name: 'internalHandleReceive',
  summary: 'Receive message',
  path: '/companies/{companyId}/communicator/receive',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'receive', query],
})

export type InternalRenderKey = ['companies', string | undefined, 'communicator', 'render', unknown]

export type InternalRenderResponse = void

export type InternalRenderVariables = {
  companyId: string | undefined
  body?: {
    subject: InteractionTypes.InteractionSubject
    channel?: InteractionTypes.InteractionChannel
    personId: string
    loanId?: string
    caseId?: string
    context?: {}
    overrideTemplateId?: CommunicatorTypes.OverrideTemplateId
  }
  query?: {
    /**
     * @default
     *     html
     */
    fmt?: 'text' | 'html' | 'pdf'
  }
}

export const internalRender = makeData<InternalRenderResponse, InternalRenderKey, InternalRenderVariables>({
  method: 'post',
  name: 'internalRender',
  summary: 'Render message',
  path: '/companies/{companyId}/communicator/render',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'render', query],
})

export type InternalRenderToDocumentKey = [
  'companies',
  string | undefined,
  'communicator',
  'render-to-document',
  unknown,
]

export type InternalRenderToDocumentResponse = void

export type InternalRenderToDocumentVariables = {
  companyId: string | undefined
  body?: {
    subject: InteractionTypes.InteractionSubject
    channel: InteractionTypes.InteractionChannel
    personId: string
    loanId?: string
    caseId?: string
    statementId?: string
    context?: {}
    documentId: string
  }
  query?: {
    /**
     * @default
     *     html
     */
    fmt?: 'text' | 'html' | 'pdf'
  }
}

export const internalRenderToDocument = makeData<
  InternalRenderToDocumentResponse,
  InternalRenderToDocumentKey,
  InternalRenderToDocumentVariables
>({
  method: 'post',
  name: 'internalRenderToDocument',
  summary: 'Render to document',
  path: '/companies/{companyId}/communicator/render-to-document',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'render-to-document', query],
})

export type InternalSubjectDescriptorsListKey = ['companies', string | undefined, 'communicator', 'subjects', unknown]

export type InternalSubjectDescriptorsListResponse = Array<CommunicatorTypes.SubjectDescriptor>

export type InternalSubjectDescriptorsListVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const internalSubjectDescriptorsList = makeData<
  InternalSubjectDescriptorsListResponse,
  InternalSubjectDescriptorsListKey,
  InternalSubjectDescriptorsListVariables
>({
  method: 'get',
  name: 'internalSubjectDescriptorsList',
  summary: 'List subject descriptor',
  path: '/companies/{companyId}/communicator/subjects',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'communicator', 'subjects', query],
})

export type InternalSubjectDescriptorGetBySubjectKey = [
  'companies',
  string | undefined,
  'communicator',
  'subjects',
  string | undefined,
  unknown,
]

export type InternalSubjectDescriptorGetBySubjectResponse = CommunicatorTypes.SubjectDescriptor

export type InternalSubjectDescriptorGetBySubjectVariables = {
  companyId: string | undefined
  subject: string | undefined
  body?: void
  query?: void
}

export const internalSubjectDescriptorGetBySubject = makeData<
  InternalSubjectDescriptorGetBySubjectResponse,
  InternalSubjectDescriptorGetBySubjectKey,
  InternalSubjectDescriptorGetBySubjectVariables
>({
  method: 'get',
  name: 'internalSubjectDescriptorGetBySubject',
  summary: 'Get subject descriptor',
  path: '/companies/{companyId}/communicator/subjects/{subject}',
  queryKey: ({ query, companyId, subject }) => ['companies', companyId, 'communicator', 'subjects', subject, query],
})

export type InternalSubjectDescriptorUpdateKey = [
  'companies',
  string | undefined,
  'communicator',
  'subjects',
  string | undefined,
  unknown,
]

export type InternalSubjectDescriptorUpdateResponse = CommunicatorTypes.SubjectDescriptor

export type InternalSubjectDescriptorUpdateVariables = {
  companyId: string | undefined
  subject: string | undefined
  body?: CommunicatorTypes.SubjectDescriptor
  query?: void
}

export const internalSubjectDescriptorUpdate = makeData<
  InternalSubjectDescriptorUpdateResponse,
  InternalSubjectDescriptorUpdateKey,
  InternalSubjectDescriptorUpdateVariables
>({
  method: 'put',
  name: 'internalSubjectDescriptorUpdate',
  summary: 'Update subject descriptor',
  path: '/companies/{companyId}/communicator/subjects/{subject}',
  queryKey: ({ query, companyId, subject }) => ['companies', companyId, 'communicator', 'subjects', subject, query],
})
