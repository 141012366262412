/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'
import * as UtilityTypes from '../utilities/types'

import * as LocMigrationTypes from './types'

export type LoanMigrateKey = ['people', string | undefined, 'loans', string | undefined, 'migrate', unknown]

export type LoanMigrateResponse = void

export type LoanMigrateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    sync?: boolean
  }
}

export const loanMigrate = makeData<LoanMigrateResponse, LoanMigrateKey, LoanMigrateVariables>({
  method: 'post',
  name: 'loanMigrate',
  summary: 'Migrate loan',
  path: '/people/{personId}/loans/{loanId}/migrate',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migrate', query],
})

export type PastPeriodsDataListKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-periods',
  unknown,
]

export type PastPeriodsDataListResponse = UtilityTypes.NewPaginator & {
  data?: Array<LocMigrationTypes.PastPeriod>
}

export type PastPeriodsDataListVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    dueDate?: string
    startingAfter?: string
    endingBefore?: string
    reportingToAgency?: 'experian' | 'equifax' | 'transUnion' | 'innovis'
  }
}

export const pastPeriodsDataList = makeData<
  PastPeriodsDataListResponse,
  PastPeriodsDataListKey,
  PastPeriodsDataListVariables
>({
  method: 'get',
  name: 'pastPeriodsDataList',
  summary: 'Get past period data',
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods', query],
})

export type PastPeriodsDataUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-periods',
  unknown,
]

export type PastPeriodsDataUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: LocMigrationTypes.PastPeriod
}

export type PastPeriodsDataUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: LocMigrationTypes.PastPeriod
  query?: {
    dueDate: string
  }
}

export const pastPeriodsDataUpdate = makeData<
  PastPeriodsDataUpdateResponse,
  PastPeriodsDataUpdateKey,
  PastPeriodsDataUpdateVariables
>({
  method: 'put',
  name: 'pastPeriodsDataUpdate',
  summary: 'Update past period data',
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods', query],
})

export type PastPeriodsDataCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-periods',
  unknown,
]

export type PastPeriodsDataCreateResponse = UtilityTypes.SingletonResponse & {
  data?: Array<LocMigrationTypes.PastPeriod>
}

export type PastPeriodsDataCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    pastPeriods: Array<LocMigrationTypes.PastPeriodWithRequireds>
  }
  query?: void
}

export const pastPeriodsDataCreate = makeData<
  PastPeriodsDataCreateResponse,
  PastPeriodsDataCreateKey,
  PastPeriodsDataCreateVariables
>({
  method: 'post',
  name: 'pastPeriodsDataCreate',
  summary: 'Create past periods data',
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods', query],
})

export type PastPeriodsDataDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-periods',
  unknown,
]

export type PastPeriodsDataDeleteResponse = void

export type PastPeriodsDataDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    dueDate: string
  }
}

export const pastPeriodsDataDelete = makeData<
  PastPeriodsDataDeleteResponse,
  PastPeriodsDataDeleteKey,
  PastPeriodsDataDeleteVariables
>({
  method: 'delete',
  name: 'pastPeriodsDataDelete',
  summary: 'Delete past period data',
  path: '/people/{personId}/loans/{loanId}/migration/past-periods',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'past-periods', query],
})

export type PastTransactionDataGetAllKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-transaction',
  unknown,
]

export type PastTransactionDataGetAllResponse = LocMigrationTypes.PastTransactionsPaging

export type PastTransactionDataGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    status?: Array<SchemaTypes.TransactionStatusReadOnly>
    paymentInstrumentId?: SchemaTypes.AnyId
    transactionType?: 'payment' | 'serviceCredit'
    serviceCreditTypes?: Array<SchemaTypes.serviceCreditTypes>
    toEffectiveDate?: string
    fromEffectiveDate?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<
      | 'appliedAt'
      | '+appliedAt'
      | '-appliedAt'
      | 'createdAt'
      | '+createdAt'
      | '-createdAt'
      | 'effectiveDate'
      | '+effectiveDate'
      | '-effectiveDate'
    >
  }
}

export const pastTransactionDataGetAll = makeData<
  PastTransactionDataGetAllResponse,
  PastTransactionDataGetAllKey,
  PastTransactionDataGetAllVariables
>({
  method: 'get',
  name: 'pastTransactionDataGetAll',
  summary: 'Get past transactions',
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction',
  queryKey: ({ query, personId, loanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    query,
  ],
})

export type PastTransactionDataCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-transaction',
  unknown,
]

export type PastTransactionDataCreateResponse = UtilityTypes.SingletonResponse & {
  data?: LocMigrationTypes.PastTransactionResponse
}

export type PastTransactionDataCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: LocMigrationTypes.PastTransaction
  query?: void
}

export const pastTransactionDataCreate = makeData<
  PastTransactionDataCreateResponse,
  PastTransactionDataCreateKey,
  PastTransactionDataCreateVariables
>({
  method: 'post',
  name: 'pastTransactionDataCreate',
  summary: 'Create past transaction',
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction',
  queryKey: ({ query, personId, loanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    query,
  ],
})

export type PastTransactionDataGetOneKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-transaction',
  string | undefined,
  unknown,
]

export type PastTransactionDataGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: LocMigrationTypes.PastTransactionResponse
}

export type PastTransactionDataGetOneVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const pastTransactionDataGetOne = makeData<
  PastTransactionDataGetOneResponse,
  PastTransactionDataGetOneKey,
  PastTransactionDataGetOneVariables
>({
  method: 'get',
  name: 'pastTransactionDataGetOne',
  summary: 'Get past transaction by ID',
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction/{transactionId}',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    transactionId,
    query,
  ],
})

export type PastTransactionDataUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'past-transaction',
  string | undefined,
  unknown,
]

export type PastTransactionDataUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: LocMigrationTypes.PastTransactionResponse
}

export type PastTransactionDataUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: LocMigrationTypes.PastTransactionUpdate
  query?: void
}

export const pastTransactionDataUpdate = makeData<
  PastTransactionDataUpdateResponse,
  PastTransactionDataUpdateKey,
  PastTransactionDataUpdateVariables
>({
  method: 'put',
  name: 'pastTransactionDataUpdate',
  summary: 'Update past transaction',
  path: '/people/{personId}/loans/{loanId}/migration/past-transaction/{transactionId}',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'migration',
    'past-transaction',
    transactionId,
    query,
  ],
})

export type MigrationPeriodLocGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodLocGetResponse = LocMigrationTypes.MigrationPeriodLOCDataResponse

export type MigrationPeriodLocGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const migrationPeriodLocGet = makeData<
  MigrationPeriodLocGetResponse,
  MigrationPeriodLocGetKey,
  MigrationPeriodLocGetVariables
>({
  method: 'get',
  name: 'migrationPeriodLocGet',
  summary: 'Get migration period LOC data',
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period', query],
})

export type MigrationPeriodLocUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodLocUpdateResponse = LocMigrationTypes.MigrationPeriodLOCDataResponse

export type MigrationPeriodLocUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: LocMigrationTypes.MigrationPeriodLOCData
  query?: void
}

export const migrationPeriodLocUpdate = makeData<
  MigrationPeriodLocUpdateResponse,
  MigrationPeriodLocUpdateKey,
  MigrationPeriodLocUpdateVariables
>({
  method: 'put',
  name: 'migrationPeriodLocUpdate',
  summary: 'Update migration period LOC data',
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period', query],
})

export type MigrationPeriodLocCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodLocCreateResponse = LocMigrationTypes.MigrationPeriodLOCDataResponse

export type MigrationPeriodLocCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: LocMigrationTypes.MigrationPeriodLOCDataWithRequired
  query?: void
}

export const migrationPeriodLocCreate = makeData<
  MigrationPeriodLocCreateResponse,
  MigrationPeriodLocCreateKey,
  MigrationPeriodLocCreateVariables
>({
  method: 'post',
  name: 'migrationPeriodLocCreate',
  summary: 'Create migration period LOC data',
  path: '/people/{personId}/loans/{loanId}/migration/period',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'migration', 'period', query],
})

export type MigrationPeriodDrawGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodDrawGetResponse = LocMigrationTypes.MigrationPeriodDrawDataResponse

export type MigrationPeriodDrawGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const migrationPeriodDrawGet = makeData<
  MigrationPeriodDrawGetResponse,
  MigrationPeriodDrawGetKey,
  MigrationPeriodDrawGetVariables
>({
  method: 'get',
  name: 'migrationPeriodDrawGet',
  summary: 'Get migration period draw data',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
    query,
  ],
})

export type MigrationPeriodDrawUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodDrawUpdateResponse = LocMigrationTypes.MigrationPeriodDrawDataResponse

export type MigrationPeriodDrawUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: LocMigrationTypes.MigrationPeriodDrawData
  query?: void
}

export const migrationPeriodDrawUpdate = makeData<
  MigrationPeriodDrawUpdateResponse,
  MigrationPeriodDrawUpdateKey,
  MigrationPeriodDrawUpdateVariables
>({
  method: 'put',
  name: 'migrationPeriodDrawUpdate',
  summary: 'Update migration period draw data',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
    query,
  ],
})

export type MigrationPeriodDrawCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'migration',
  'period',
  unknown,
]

export type MigrationPeriodDrawCreateResponse = LocMigrationTypes.MigrationPeriodDrawDataResponse

export type MigrationPeriodDrawCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: LocMigrationTypes.MigrationPeriodDrawDataWithRequired
  query?: void
}

export const migrationPeriodDrawCreate = makeData<
  MigrationPeriodDrawCreateResponse,
  MigrationPeriodDrawCreateKey,
  MigrationPeriodDrawCreateVariables
>({
  method: 'post',
  name: 'migrationPeriodDrawCreate',
  summary: 'Create migration period draw data',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/migration/period',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'migration',
    'period',
    query,
  ],
})
