import { BaseException } from 'pdfjs-dist'

import { ErrorMessage, hasStatus } from 'core/components/lib/ErrorBoundary/defaultErrorBoundaries'
import { addToast } from 'core/stores/toasts'

import * as R from './remeda'
import * as S from './string'

export const parseError = (error: unknown, fallback = 'Unknown error', companyId?: string) => {
  if (R.isObject(error) && error.status === 403) {
    const permissions =
      'data' in error && R.isArray(error.data) ?
        R.pipe(
          error.data,
          R.filter(
            (tuple): tuple is [string, string] =>
              R.isArray(tuple) && tuple.length === 2 && tuple[0] === companyId && R.isString(tuple[1]),
          ),
          R.map(R.prop(1)),
        )
      : []

    return permissions.length > 0 ?
        `You do not have the ${R.join(permissions, ', ')} permission(s).`
      : 'You do not have the required permissions.'
  }

  return (
    error instanceof BaseException ? 'Unable to load the PDF.'
    : !error || (R.isEmpty(error as any) && !(error instanceof Error)) ? undefined
    : S.capitalize(
        R.isString(error) ? error
        : typeof error !== 'object' ? fallback
        : 'detail' in error && typeof error.detail === 'string' ? error.detail
        : 'message' in error && typeof error.message === 'string' ? error.message
        : fallback,
      )
  )
}

export const reportOperationError = (operation: string, error: unknown = {}) => {
  if (R.isObject(error)) {
    error.operation = S.dropEnd(operation, '.')
  }

  addToast(
    hasStatus(error) && error.status === 504 ?
      <>
        This request has timed out: <i>{operation}</i>. Please try again at a later time. Consider refreshing the page.
      </>
    : <ErrorMessage error={error} />,
    'error',
  )
}

export const isClientError = (error: unknown) => hasStatus(error) && error.status >= 400 && error.status < 500

export const isNotFoundError = (error: unknown) => hasStatus(error) && (error.status === 404 || error.status === 422)

export const isForbiddenError = (error: unknown) => hasStatus(error) && error.status === 403
