/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as CaseTypes from '../cases/types'
import * as UtilityTypes from '../utilities/types'

import * as SupercaseTypes from './types'

export type SupercaseListKey = ['supercases', unknown]

export type SupercaseListResponse = SupercaseTypes.ResponseSupercasePagingResponse

export type SupercaseListVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | '-id'
      | 'id'
      | '+id'
      | '-createdAt'
      | 'createdAt'
      | '+createdAt'
      | '-name'
      | 'name'
      | '+name'
      | '-status'
      | 'status'
      | '+status'
    >
    supercaseType?: SupercaseTypes.SupercaseType
    femaDisasterNumber?: number
    status?: Array<CaseTypes.ProcessStatus>
  }
}

export const supercaseList = makeData<SupercaseListResponse, SupercaseListKey, SupercaseListVariables>({
  method: 'get',
  name: 'supercaseList',
  summary: 'List supercases',
  path: '/supercases',
  queryKey: ({ query }) => ['supercases', query],
})

export type SupercaseCreateKey = ['supercases', unknown]

export type SupercaseCreateResponse = SupercaseTypes.ResponseSupercaseResponse

export type SupercaseCreateVariables = {
  body?: SupercaseTypes.RequestBodySupercasePost
  query?: void
}

export const supercaseCreate = makeData<SupercaseCreateResponse, SupercaseCreateKey, SupercaseCreateVariables>({
  method: 'post',
  name: 'supercaseCreate',
  summary: 'Create supercase',
  path: '/supercases',
  queryKey: ({ query }) => ['supercases', query],
})

export type SupercaseMembersTemplateCsvKey = ['supercases', 'members-template.csv', unknown]

export type SupercaseMembersTemplateCsvResponse = void

export type SupercaseMembersTemplateCsvVariables = {
  body?: void
  query?: void
}

export const supercaseMembersTemplateCsv = makeData<
  SupercaseMembersTemplateCsvResponse,
  SupercaseMembersTemplateCsvKey,
  SupercaseMembersTemplateCsvVariables
>({
  method: 'get',
  name: 'supercaseMembersTemplateCsv',
  summary: 'Get supercase members.csv template',
  path: '/supercases/members-template.csv',
  queryKey: ({ query }) => ['supercases', 'members-template.csv', query],
})

export type SupercaseGetKey = ['supercases', string | undefined, unknown]

export type SupercaseGetResponse = SupercaseTypes.ResponseSupercaseResponse

export type SupercaseGetVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const supercaseGet = makeData<SupercaseGetResponse, SupercaseGetKey, SupercaseGetVariables>({
  method: 'get',
  name: 'supercaseGet',
  summary: 'Get supercase by ID',
  path: '/supercases/{supercaseId}',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, query],
})

export type SupercaseUpdateKey = ['supercases', string | undefined, unknown]

export type SupercaseUpdateResponse = SupercaseTypes.ResponseSupercaseResponse

export type SupercaseUpdateVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodySupercasePut
  query?: void
}

export const supercaseUpdate = makeData<SupercaseUpdateResponse, SupercaseUpdateKey, SupercaseUpdateVariables>({
  method: 'put',
  name: 'supercaseUpdate',
  summary: 'Update supercase',
  path: '/supercases/{supercaseId}',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, query],
})

export type PopulationListKey = ['supercases', string | undefined, 'populations', unknown]

export type PopulationListResponse = SupercaseTypes.ResponsePopulationPagingResponse

export type PopulationListVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const populationList = makeData<PopulationListResponse, PopulationListKey, PopulationListVariables>({
  method: 'get',
  name: 'populationList',
  summary: 'List populations',
  path: '/supercases/{supercaseId}/populations',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', query],
})

export type CommittedMembersListCsvKey = ['supercases', string | undefined, 'populations', 'members.csv', unknown]

export type CommittedMembersListCsvResponse = SupercaseTypes.ResponsePopulationMemberCollectionCsvResponse

export type CommittedMembersListCsvVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const committedMembersListCsv = makeData<
  CommittedMembersListCsvResponse,
  CommittedMembersListCsvKey,
  CommittedMembersListCsvVariables
>({
  method: 'get',
  name: 'committedMembersListCsv',
  summary: 'Get committed membership as CSV',
  path: '/supercases/{supercaseId}/populations/members.csv',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'members.csv', query],
})

export type DraftPopulationGetKey = ['supercases', string | undefined, 'populations', 'draft', unknown]

export type DraftPopulationGetResponse = SupercaseTypes.ResponsePopulationResponse

export type DraftPopulationGetVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const draftPopulationGet = makeData<
  DraftPopulationGetResponse,
  DraftPopulationGetKey,
  DraftPopulationGetVariables
>({
  method: 'get',
  name: 'draftPopulationGet',
  summary: 'Get draft population',
  path: '/supercases/{supercaseId}/populations/draft',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', query],
})

export type DraftPopulationMembersListKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members',
  unknown,
]

export type DraftPopulationMembersListResponse = SupercaseTypes.ResponsePopulationMemberCollectionResponse

export type DraftPopulationMembersListVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const draftPopulationMembersList = makeData<
  DraftPopulationMembersListResponse,
  DraftPopulationMembersListKey,
  DraftPopulationMembersListVariables
>({
  method: 'get',
  name: 'draftPopulationMembersList',
  summary: 'Get the draft population members',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members', query],
})

export type DraftPopulationMembersSetKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members',
  unknown,
]

export type DraftPopulationMembersSetResponse = void

export type DraftPopulationMembersSetVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodyPopulationMemberCollectionPut
  query?: {
    /**
     * @default
     *     true
     */
    publishReviewTask?: boolean
  }
}

export const draftPopulationMembersSet = makeData<
  DraftPopulationMembersSetResponse,
  DraftPopulationMembersSetKey,
  DraftPopulationMembersSetVariables
>({
  method: 'put',
  name: 'draftPopulationMembersSet',
  summary: 'Set the draft population membership',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members', query],
})

export type DraftPopulationMembersAddKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members',
  unknown,
]

export type DraftPopulationMembersAddResponse = void

export type DraftPopulationMembersAddVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodyPopulationMemberCollectionPost
  query?: {
    /**
     * @default
     *     true
     */
    publishReviewTask?: boolean
  }
}

export const draftPopulationMembersAdd = makeData<
  DraftPopulationMembersAddResponse,
  DraftPopulationMembersAddKey,
  DraftPopulationMembersAddVariables
>({
  method: 'post',
  name: 'draftPopulationMembersAdd',
  summary: 'Add to the draft population membership',
  path: '/supercases/{supercaseId}/populations/draft/members',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members', query],
})

export type DraftPopulationMembersListCsvKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members.csv',
  unknown,
]

export type DraftPopulationMembersListCsvResponse = SupercaseTypes.ResponsePopulationMemberCollectionCsvResponse

export type DraftPopulationMembersListCsvVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const draftPopulationMembersListCsv = makeData<
  DraftPopulationMembersListCsvResponse,
  DraftPopulationMembersListCsvKey,
  DraftPopulationMembersListCsvVariables
>({
  method: 'get',
  name: 'draftPopulationMembersListCsv',
  summary: 'Get membership as CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv', query],
})

export type DraftPopulationMembersSetCsvKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members.csv',
  unknown,
]

export type DraftPopulationMembersSetCsvResponse = void

export type DraftPopulationMembersSetCsvVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodyPopulationMemberCollectionCsvPut
  query?: void
}

export const draftPopulationMembersSetCsv = makeData<
  DraftPopulationMembersSetCsvResponse,
  DraftPopulationMembersSetCsvKey,
  DraftPopulationMembersSetCsvVariables
>({
  method: 'put',
  name: 'draftPopulationMembersSetCsv',
  summary: 'Set membership with CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv', query],
})

export type DraftPopulationMembersAddCsvKey = [
  'supercases',
  string | undefined,
  'populations',
  'draft',
  'members.csv',
  unknown,
]

export type DraftPopulationMembersAddCsvResponse = void

export type DraftPopulationMembersAddCsvVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodyPopulationMemberCollectionCsvPost
  query?: void
}

export const draftPopulationMembersAddCsv = makeData<
  DraftPopulationMembersAddCsvResponse,
  DraftPopulationMembersAddCsvKey,
  DraftPopulationMembersAddCsvVariables
>({
  method: 'post',
  name: 'draftPopulationMembersAddCsv',
  summary: 'Add to membership with CSV',
  path: '/supercases/{supercaseId}/populations/draft/members.csv',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'members.csv', query],
})

export type DraftPopulationCommitKey = ['supercases', string | undefined, 'populations', 'draft', 'commit', unknown]

export type DraftPopulationCommitResponse = void

export type DraftPopulationCommitVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const draftPopulationCommit = makeData<
  DraftPopulationCommitResponse,
  DraftPopulationCommitKey,
  DraftPopulationCommitVariables
>({
  method: 'post',
  name: 'draftPopulationCommit',
  summary: 'Commit draft population',
  path: '/supercases/{supercaseId}/populations/draft/commit',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'populations', 'draft', 'commit', query],
})

export type PopulationGetKey = ['supercases', string | undefined, 'populations', string | undefined, unknown]

export type PopulationGetResponse = SupercaseTypes.ResponsePopulationResponse

export type PopulationGetVariables = {
  supercaseId: string | undefined
  populationId: string | undefined
  body?: void
  query?: void
}

export const populationGet = makeData<PopulationGetResponse, PopulationGetKey, PopulationGetVariables>({
  method: 'get',
  name: 'populationGet',
  summary: 'Get population by ID',
  path: '/supercases/{supercaseId}/populations/{populationId}',
  queryKey: ({ query, supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId, query],
})

export type PopulationUpdateKey = ['supercases', string | undefined, 'populations', string | undefined, unknown]

export type PopulationUpdateResponse = SupercaseTypes.ResponsePopulationResponse

export type PopulationUpdateVariables = {
  supercaseId: string | undefined
  populationId: string | undefined
  body?: SupercaseTypes.RequestBodyPopulationPut
  query?: void
}

export const populationUpdate = makeData<PopulationUpdateResponse, PopulationUpdateKey, PopulationUpdateVariables>({
  method: 'put',
  name: 'populationUpdate',
  summary: 'Update population',
  path: '/supercases/{supercaseId}/populations/{populationId}',
  queryKey: ({ query, supercaseId, populationId }) => ['supercases', supercaseId, 'populations', populationId, query],
})

export type PopulationMembersListKey = [
  'supercases',
  string | undefined,
  'populations',
  string | undefined,
  'members',
  unknown,
]

export type PopulationMembersListResponse = SupercaseTypes.ResponsePopulationMemberCollectionResponse

export type PopulationMembersListVariables = {
  supercaseId: string | undefined
  populationId: string | undefined
  body?: void
  query?: void
}

export const populationMembersList = makeData<
  PopulationMembersListResponse,
  PopulationMembersListKey,
  PopulationMembersListVariables
>({
  method: 'get',
  name: 'populationMembersList',
  summary: 'Get population membership',
  path: '/supercases/{supercaseId}/populations/{populationId}/members',
  queryKey: ({ query, supercaseId, populationId }) => [
    'supercases',
    supercaseId,
    'populations',
    populationId,
    'members',
    query,
  ],
})

export type PopulationMembersListCsvKey = [
  'supercases',
  string | undefined,
  'populations',
  string | undefined,
  'members.csv',
  unknown,
]

export type PopulationMembersListCsvResponse = SupercaseTypes.ResponsePopulationMemberCollectionCsvResponse

export type PopulationMembersListCsvVariables = {
  supercaseId: string | undefined
  populationId: string | undefined
  body?: void
  query?: void
}

export const populationMembersListCsv = makeData<
  PopulationMembersListCsvResponse,
  PopulationMembersListCsvKey,
  PopulationMembersListCsvVariables
>({
  method: 'get',
  name: 'populationMembersListCsv',
  summary: 'Get membership as CSV',
  path: '/supercases/{supercaseId}/populations/{populationId}/members.csv',
  queryKey: ({ query, supercaseId, populationId }) => [
    'supercases',
    supercaseId,
    'populations',
    populationId,
    'members.csv',
    query,
  ],
})

export type InternalSupercaseListKey = ['companies', string | undefined, 'supercases', unknown]

export type InternalSupercaseListResponse = SupercaseTypes.ResponseSupercasePagingResponse

export type InternalSupercaseListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    offset?: number
    sortBy?: Array<
      | '-id'
      | 'id'
      | '+id'
      | '-createdAt'
      | 'createdAt'
      | '+createdAt'
      | '-name'
      | 'name'
      | '+name'
      | '-status'
      | 'status'
      | '+status'
    >
    supercaseType?: SupercaseTypes.SupercaseType
    femaDisasterNumber?: number
    status?: Array<CaseTypes.ProcessStatus>
  }
}

export const internalSupercaseList = makeData<
  InternalSupercaseListResponse,
  InternalSupercaseListKey,
  InternalSupercaseListVariables
>({
  method: 'get',
  name: 'internalSupercaseList',
  summary: 'List company supercases',
  path: '/companies/{companyId}/supercases',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'supercases', query],
})

export type InternalSupercaseCreateKey = ['companies', string | undefined, 'supercases', unknown]

export type InternalSupercaseCreateResponse = SupercaseTypes.ResponseSupercaseResponse

export type InternalSupercaseCreateVariables = {
  companyId: string | undefined
  body?: SupercaseTypes.RequestBodySupercasePost
  query?: void
}

export const internalSupercaseCreate = makeData<
  InternalSupercaseCreateResponse,
  InternalSupercaseCreateKey,
  InternalSupercaseCreateVariables
>({
  method: 'post',
  name: 'internalSupercaseCreate',
  summary: 'Create supercase',
  path: '/companies/{companyId}/supercases',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'supercases', query],
})

export type PrivateNoteListKey = ['supercases', string | undefined, 'notes', unknown]

export type PrivateNoteListResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type PrivateNoteListVariables = {
  supercaseId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    includeArchived?: boolean
    pinned?: boolean | null
    sortBy?: Array<string>
  }
}

export const privateNoteList = makeData<PrivateNoteListResponse, PrivateNoteListKey, PrivateNoteListVariables>({
  method: 'get',
  name: 'privateNoteList',
  summary: 'Get private notes',
  path: '/supercases/{supercaseId}/notes',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'notes', query],
})

export type PrivateNoteCreateKey = ['supercases', string | undefined, 'notes', unknown]

export type PrivateNoteCreateResponse = CaseTypes.ResponseNote

export type PrivateNoteCreateVariables = {
  supercaseId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const privateNoteCreate = makeData<PrivateNoteCreateResponse, PrivateNoteCreateKey, PrivateNoteCreateVariables>({
  method: 'post',
  name: 'privateNoteCreate',
  summary: 'Create private note',
  path: '/supercases/{supercaseId}/notes',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'notes', query],
})

export type PrivateNoteGetKey = ['supercases', string | undefined, 'notes', string | undefined, unknown]

export type PrivateNoteGetResponse = CaseTypes.ResponseNote

export type PrivateNoteGetVariables = {
  supercaseId: string | undefined
  noteId: string | undefined
  body?: void
  query?: void
}

export const privateNoteGet = makeData<PrivateNoteGetResponse, PrivateNoteGetKey, PrivateNoteGetVariables>({
  method: 'get',
  name: 'privateNoteGet',
  summary: 'Get private note by ID',
  path: '/supercases/{supercaseId}/notes/{noteId}',
  queryKey: ({ query, supercaseId, noteId }) => ['supercases', supercaseId, 'notes', noteId, query],
})

export type PrivateNoteUpdateKey = ['supercases', string | undefined, 'notes', string | undefined, unknown]

export type PrivateNoteUpdateResponse = CaseTypes.ResponseNote

export type PrivateNoteUpdateVariables = {
  supercaseId: string | undefined
  noteId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const privateNoteUpdate = makeData<PrivateNoteUpdateResponse, PrivateNoteUpdateKey, PrivateNoteUpdateVariables>({
  method: 'put',
  name: 'privateNoteUpdate',
  summary: 'Update private note',
  path: '/supercases/{supercaseId}/notes/{noteId}',
  queryKey: ({ query, supercaseId, noteId }) => ['supercases', supercaseId, 'notes', noteId, query],
})

export type GlobalNoteListKey = ['supercases', string | undefined, 'global-notes', unknown]

export type GlobalNoteListResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type GlobalNoteListVariables = {
  supercaseId: string | undefined
  body?: void
  query?: {
    startingAfter?: string
    /**
     * @default
     *     25
     */
    limit?: number
    includeArchived?: boolean
    pinned?: boolean | null
    sortBy?: Array<string>
  }
}

export const globalNoteList = makeData<GlobalNoteListResponse, GlobalNoteListKey, GlobalNoteListVariables>({
  method: 'get',
  name: 'globalNoteList',
  summary: 'Get global notes',
  path: '/supercases/{supercaseId}/global-notes',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'global-notes', query],
})

export type GlobalNoteCreateKey = ['supercases', string | undefined, 'global-notes', unknown]

export type GlobalNoteCreateResponse = CaseTypes.ResponseNote

export type GlobalNoteCreateVariables = {
  supercaseId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const globalNoteCreate = makeData<GlobalNoteCreateResponse, GlobalNoteCreateKey, GlobalNoteCreateVariables>({
  method: 'post',
  name: 'globalNoteCreate',
  summary: 'Create global note',
  path: '/supercases/{supercaseId}/global-notes',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'global-notes', query],
})

export type GlobalNoteGetKey = ['supercases', string | undefined, 'global-notes', string | undefined, unknown]

export type GlobalNoteGetResponse = CaseTypes.ResponseNote

export type GlobalNoteGetVariables = {
  supercaseId: string | undefined
  noteId: string | undefined
  body?: void
  query?: void
}

export const globalNoteGet = makeData<GlobalNoteGetResponse, GlobalNoteGetKey, GlobalNoteGetVariables>({
  method: 'get',
  name: 'globalNoteGet',
  summary: 'Get global note by ID',
  path: '/supercases/{supercaseId}/global-notes/{noteId}',
  queryKey: ({ query, supercaseId, noteId }) => ['supercases', supercaseId, 'global-notes', noteId, query],
})

export type GlobalNoteUpdateKey = ['supercases', string | undefined, 'global-notes', string | undefined, unknown]

export type GlobalNoteUpdateResponse = CaseTypes.ResponseNote

export type GlobalNoteUpdateVariables = {
  supercaseId: string | undefined
  noteId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const globalNoteUpdate = makeData<GlobalNoteUpdateResponse, GlobalNoteUpdateKey, GlobalNoteUpdateVariables>({
  method: 'put',
  name: 'globalNoteUpdate',
  summary: 'Update global note',
  path: '/supercases/{supercaseId}/global-notes/{noteId}',
  queryKey: ({ query, supercaseId, noteId }) => ['supercases', supercaseId, 'global-notes', noteId, query],
})

export type GetSupercaseDocumentsKey = ['supercases', string | undefined, 'documents', unknown]

export type GetSupercaseDocumentsResponse = SupercaseTypes.ResponseSupercaseDocumentsResponse

export type GetSupercaseDocumentsVariables = {
  supercaseId: string | undefined
  body?: void
  query?: {
    fetchDetails?: boolean
  }
}

export const getSupercaseDocuments = makeData<
  GetSupercaseDocumentsResponse,
  GetSupercaseDocumentsKey,
  GetSupercaseDocumentsVariables
>({
  method: 'get',
  name: 'getSupercaseDocuments',
  summary: 'Get the list of supercase documents',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'documents', query],
})

export type SetSupercaseDocumentsKey = ['supercases', string | undefined, 'documents', unknown]

export type SetSupercaseDocumentsResponse = void

export type SetSupercaseDocumentsVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodySupercaseDocumentList
  query?: void
}

export const setSupercaseDocuments = makeData<
  SetSupercaseDocumentsResponse,
  SetSupercaseDocumentsKey,
  SetSupercaseDocumentsVariables
>({
  method: 'put',
  name: 'setSupercaseDocuments',
  summary: 'Set the documents associated with the supercase',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'documents', query],
})

export type AddSupercaseDocumentsKey = ['supercases', string | undefined, 'documents', unknown]

export type AddSupercaseDocumentsResponse = void

export type AddSupercaseDocumentsVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodySupercaseDocumentList
  query?: void
}

export const addSupercaseDocuments = makeData<
  AddSupercaseDocumentsResponse,
  AddSupercaseDocumentsKey,
  AddSupercaseDocumentsVariables
>({
  method: 'post',
  name: 'addSupercaseDocuments',
  summary: 'Associate documents with the supercase',
  path: '/supercases/{supercaseId}/documents',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'documents', query],
})

export type SupercaseBulkUpdateKey = ['supercases', string | undefined, 'bulk-update', unknown]

export type SupercaseBulkUpdateResponse = SupercaseTypes.ResponseBulkOperationResponse

export type SupercaseBulkUpdateVariables = {
  supercaseId: string | undefined
  body?: CaseTypes.RequestBodyCasePut
  query?: void
}

export const supercaseBulkUpdate = makeData<
  SupercaseBulkUpdateResponse,
  SupercaseBulkUpdateKey,
  SupercaseBulkUpdateVariables
>({
  method: 'post',
  name: 'supercaseBulkUpdate',
  summary: 'Update all subcases',
  path: '/supercases/{supercaseId}/bulk-update',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-update', query],
})

export type SupercaseBulkSendMessageKey = ['supercases', string | undefined, 'bulk-send', unknown]

export type SupercaseBulkSendMessageResponse = SupercaseTypes.ResponseBulkOperationResponse

export type SupercaseBulkSendMessageVariables = {
  supercaseId: string | undefined
  body?: SupercaseTypes.RequestBodyBulkSendRequestBody
  query?: void
}

export const supercaseBulkSendMessage = makeData<
  SupercaseBulkSendMessageResponse,
  SupercaseBulkSendMessageKey,
  SupercaseBulkSendMessageVariables
>({
  method: 'post',
  name: 'supercaseBulkSendMessage',
  summary: 'Send a message to the borrowers on all subcases',
  path: '/supercases/{supercaseId}/bulk-send',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-send', query],
})

export type PopulationBulkSendMessageKey = [
  'supercases',
  string | undefined,
  'populations',
  string | undefined,
  'bulk-send',
  unknown,
]

export type PopulationBulkSendMessageResponse = SupercaseTypes.ResponseBulkOperationResponse

export type PopulationBulkSendMessageVariables = {
  supercaseId: string | undefined
  populationId: string | undefined
  body?: SupercaseTypes.RequestBodyBulkSendRequestBody
  query?: void
}

export const populationBulkSendMessage = makeData<
  PopulationBulkSendMessageResponse,
  PopulationBulkSendMessageKey,
  PopulationBulkSendMessageVariables
>({
  method: 'post',
  name: 'populationBulkSendMessage',
  summary: 'Send a message to the borrowers on all subcases of the population',
  path: '/supercases/{supercaseId}/populations/{populationId}/bulk-send',
  queryKey: ({ query, supercaseId, populationId }) => [
    'supercases',
    supercaseId,
    'populations',
    populationId,
    'bulk-send',
    query,
  ],
})

export type SupercaseBulkCreateDniKey = ['supercases', string | undefined, 'bulk-create-do-not-interact', unknown]

export type SupercaseBulkCreateDniResponse = SupercaseTypes.ResponseBulkOperationResponse

export type SupercaseBulkCreateDniVariables = {
  supercaseId: string | undefined
  body?: CaseTypes.RequestBodyDoNotInteractCreate
  query?: void
}

export const supercaseBulkCreateDni = makeData<
  SupercaseBulkCreateDniResponse,
  SupercaseBulkCreateDniKey,
  SupercaseBulkCreateDniVariables
>({
  method: 'post',
  name: 'supercaseBulkCreateDni',
  summary: 'Create Do Not Interact instances on all subcases',
  path: '/supercases/{supercaseId}/bulk-create-do-not-interact',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-create-do-not-interact', query],
})

export type SupercaseBulkDeleteDniKey = ['supercases', string | undefined, 'bulk-delete-do-not-interact', unknown]

export type SupercaseBulkDeleteDniResponse = SupercaseTypes.ResponseBulkOperationResponse

export type SupercaseBulkDeleteDniVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const supercaseBulkDeleteDni = makeData<
  SupercaseBulkDeleteDniResponse,
  SupercaseBulkDeleteDniKey,
  SupercaseBulkDeleteDniVariables
>({
  method: 'post',
  name: 'supercaseBulkDeleteDni',
  summary: 'Delete supercase related Do Not Interact instances on all subcases',
  path: '/supercases/{supercaseId}/bulk-delete-do-not-interact',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-delete-do-not-interact', query],
})

export type SupercaseBulkOperationListKey = ['supercases', string | undefined, 'bulk-operations', unknown]

export type SupercaseBulkOperationListResponse = UtilityTypes.NewPaginator & {
  data?: Array<SupercaseTypes.SupercaseBulkOperation>
}

export type SupercaseBulkOperationListVariables = {
  supercaseId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    sortBy?: Array<string>
    operationTypes?: Array<
      'update' | 'sendMessage' | 'createDoNotInteract' | 'deleteDoNotInteract' | 'sendInstructionsByTask'
    >
  }
}

export const supercaseBulkOperationList = makeData<
  SupercaseBulkOperationListResponse,
  SupercaseBulkOperationListKey,
  SupercaseBulkOperationListVariables
>({
  method: 'get',
  name: 'supercaseBulkOperationList',
  summary: 'List bulk operations on this supercase',
  path: '/supercases/{supercaseId}/bulk-operations',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-operations', query],
})

export type SupercaseBulkOperationSearchKey = ['supercases', string | undefined, 'bulk-operations', 'search', unknown]

export type SupercaseBulkOperationSearchResponse = {
  data?: Array<SupercaseTypes.SupercaseBulkOperation>
}

export type SupercaseBulkOperationSearchVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const supercaseBulkOperationSearch = makeData<
  SupercaseBulkOperationSearchResponse,
  SupercaseBulkOperationSearchKey,
  SupercaseBulkOperationSearchVariables
>({
  method: 'get',
  name: 'supercaseBulkOperationSearch',
  summary: 'Search bulk operations on this supercase',
  path: '/supercases/{supercaseId}/bulk-operations/search',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'bulk-operations', 'search', query],
})

export type SupercaseBulkOperationGetKey = [
  'supercases',
  string | undefined,
  'bulk-operations',
  string | undefined,
  unknown,
]

export type SupercaseBulkOperationGetResponse = SupercaseTypes.SupercaseBulkOperation

export type SupercaseBulkOperationGetVariables = {
  supercaseId: string | undefined
  supercaseBulkOperationId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
  }
}

export const supercaseBulkOperationGet = makeData<
  SupercaseBulkOperationGetResponse,
  SupercaseBulkOperationGetKey,
  SupercaseBulkOperationGetVariables
>({
  method: 'get',
  name: 'supercaseBulkOperationGet',
  summary: 'Get supercase bulk operation by ID',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ query, supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    query,
  ],
})

export type SupercaseBulkOperationUpdateKey = [
  'supercases',
  string | undefined,
  'bulk-operations',
  string | undefined,
  unknown,
]

export type SupercaseBulkOperationUpdateResponse = SupercaseTypes.SupercaseBulkOperation

export type SupercaseBulkOperationUpdateVariables = {
  supercaseId: string | undefined
  supercaseBulkOperationId: string | undefined
  body?: SupercaseTypes.SupercaseBulkOperation
  query?: void
}

export const supercaseBulkOperationUpdate = makeData<
  SupercaseBulkOperationUpdateResponse,
  SupercaseBulkOperationUpdateKey,
  SupercaseBulkOperationUpdateVariables
>({
  method: 'put',
  name: 'supercaseBulkOperationUpdate',
  summary: 'Update supercase bulk operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ query, supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    query,
  ],
})

export type GetBulkOperationTargetPopulationsKey = [
  'supercases',
  string | undefined,
  'bulk-operations',
  string | undefined,
  'target-populations',
  unknown,
]

export type GetBulkOperationTargetPopulationsResponse = UtilityTypes.Paging & {
  data?: Array<SupercaseTypes.TargetPopulation>
}

export type GetBulkOperationTargetPopulationsVariables = {
  supercaseId: string | undefined
  supercaseBulkOperationId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const getBulkOperationTargetPopulations = makeData<
  GetBulkOperationTargetPopulationsResponse,
  GetBulkOperationTargetPopulationsKey,
  GetBulkOperationTargetPopulationsVariables
>({
  method: 'get',
  name: 'getBulkOperationTargetPopulations',
  summary: 'Get target populations for the supercase bulk operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/target-populations',
  queryKey: ({ query, supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'target-populations',
    query,
  ],
})

export type GetMemberBulkOperationResultsByOperationKey = [
  'supercases',
  string | undefined,
  'bulk-operations',
  string | undefined,
  'member-bulk-operation-results',
  unknown,
]

export type GetMemberBulkOperationResultsByOperationResponse = UtilityTypes.Paging & {
  data?: Array<SupercaseTypes.MemberBulkOperationResult>
}

export type GetMemberBulkOperationResultsByOperationVariables = {
  supercaseId: string | undefined
  supercaseBulkOperationId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    status?: 'success' | 'failed' | 'ineligible' | 'notProcessed'
  }
}

export const getMemberBulkOperationResultsByOperation = makeData<
  GetMemberBulkOperationResultsByOperationResponse,
  GetMemberBulkOperationResultsByOperationKey,
  GetMemberBulkOperationResultsByOperationVariables
>({
  method: 'get',
  name: 'getMemberBulkOperationResultsByOperation',
  summary: 'Get member bulk operation results by operation',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/member-bulk-operation-results',
  queryKey: ({ query, supercaseId, supercaseBulkOperationId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'member-bulk-operation-results',
    query,
  ],
})

export type UpdateMemberBulkOperationResultKey = [
  'supercases',
  string | undefined,
  'bulk-operations',
  string | undefined,
  'member-bulk-operation-results',
  string | undefined,
  unknown,
]

export type UpdateMemberBulkOperationResultResponse = SupercaseTypes.MemberBulkOperationResult

export type UpdateMemberBulkOperationResultVariables = {
  supercaseId: string | undefined
  supercaseBulkOperationId: string | undefined
  populationMembershipId: string | undefined
  body?: SupercaseTypes.RequestBodyMemberBulkOperationResultUpdate
  query?: void
}

export const updateMemberBulkOperationResult = makeData<
  UpdateMemberBulkOperationResultResponse,
  UpdateMemberBulkOperationResultKey,
  UpdateMemberBulkOperationResultVariables
>({
  method: 'put',
  name: 'updateMemberBulkOperationResult',
  summary: 'Update member bulk operation result',
  path: '/supercases/{supercaseId}/bulk-operations/{supercaseBulkOperationId}/member-bulk-operation-results/{populationMembershipId}',
  queryKey: ({ query, supercaseId, supercaseBulkOperationId, populationMembershipId }) => [
    'supercases',
    supercaseId,
    'bulk-operations',
    supercaseBulkOperationId,
    'member-bulk-operation-results',
    populationMembershipId,
    query,
  ],
})

export type InternalSupercaseBulkOperationGetKey = [
  'companies',
  string | undefined,
  'bulk-operations',
  string | undefined,
  unknown,
]

export type InternalSupercaseBulkOperationGetResponse = SupercaseTypes.SupercaseBulkOperation

export type InternalSupercaseBulkOperationGetVariables = {
  companyId: string | undefined
  supercaseBulkOperationId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
  }
}

export const internalSupercaseBulkOperationGet = makeData<
  InternalSupercaseBulkOperationGetResponse,
  InternalSupercaseBulkOperationGetKey,
  InternalSupercaseBulkOperationGetVariables
>({
  method: 'get',
  name: 'internalSupercaseBulkOperationGet',
  summary: 'Get supercase bulk operation by ID',
  path: '/companies/{companyId}/bulk-operations/{supercaseBulkOperationId}',
  queryKey: ({ query, companyId, supercaseBulkOperationId }) => [
    'companies',
    companyId,
    'bulk-operations',
    supercaseBulkOperationId,
    query,
  ],
})

export type InternalSupercaseBulkOperationListKey = ['companies', string | undefined, 'bulk-operations', unknown]

export type InternalSupercaseBulkOperationListResponse = UtilityTypes.Paging & {
  data?: Array<SupercaseTypes.SupercaseBulkOperation>
}

export type InternalSupercaseBulkOperationListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    searchable?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    sortBy?: Array<string>
    operationTypes?: Array<
      'update' | 'sendMessage' | 'createDoNotInteract' | 'deleteDoNotInteract' | 'sendInstructionsByTask'
    >
  }
}

export const internalSupercaseBulkOperationList = makeData<
  InternalSupercaseBulkOperationListResponse,
  InternalSupercaseBulkOperationListKey,
  InternalSupercaseBulkOperationListVariables
>({
  method: 'get',
  name: 'internalSupercaseBulkOperationList',
  summary: 'List bulk operations for this company',
  path: '/companies/{companyId}/bulk-operations',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'bulk-operations', query],
})

export type SupercaseCaseCountKey = ['supercases', string | undefined, 'case-count', unknown]

export type SupercaseCaseCountResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** The number of cases associated with the supercase */
    caseCount?: number
  }
}

export type SupercaseCaseCountVariables = {
  supercaseId: string | undefined
  body?: void
  query?: void
}

export const supercaseCaseCount = makeData<
  SupercaseCaseCountResponse,
  SupercaseCaseCountKey,
  SupercaseCaseCountVariables
>({
  method: 'get',
  name: 'supercaseCaseCount',
  summary: 'Get the number of cases associated with the supercase',
  path: '/supercases/{supercaseId}/case-count',
  queryKey: ({ query, supercaseId }) => ['supercases', supercaseId, 'case-count', query],
})
