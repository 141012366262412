/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as CaseTypes from '../cases/types'
import * as InteractionTypes from '../interactions/types'
import * as RequestBodyTypes from '../request-bodies/types'
import * as ResponseTypes from '../responses/types'
import * as ResponsesRoleTypes from '../responses-roles/types'
import * as SchemaTypes from '../schema/types'
import * as SchemasEventTypes from '../schemas-events/types'
import * as UtilityTypes from '../utilities/types'

export type GetEventsKey = ['events', unknown]

export type GetEventsResponse = ResponseTypes.EventsPublic

export type GetEventsVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    personId?: SchemaTypes.AnyId
    periodId?: string
    loanId?: SchemaTypes.AnyId
    caseId?: SchemaTypes.AnyId
    transactionId?: SchemaTypes.AnyId
    createdAtFrom?: string
    createdAtTo?: string
    effectiveAtFrom?: string
    effectiveAtTo?: string
    accrualDate?: string
    /**
     * @default
     *     true
     */
    includeDeleted?: boolean
    events?: string
    excludeEvents?: string
    webhookId?: string
    supercaseId?: string
    creatingMessageIds?: Array<string>
  }
}

export const getEvents = makeData<GetEventsResponse, GetEventsKey, GetEventsVariables>({
  method: 'get',
  name: 'getEvents',
  summary: 'Retrieve events',
  path: '/events',
  queryKey: ({ query }) => ['events', query],
})

export type CreateEventKey = ['events', unknown]

export type CreateEventResponse = ResponseTypes.EventPublic

export type CreateEventVariables = {
  body?: RequestBodyTypes.EventPostPublic
  query?: {
    firingMode?: 'full' | 'database' | 'queue'
    priority?:
      | 'low'
      | 'normal'
      | 'high'
      | 'paymentProcessing'
      | 'createPurchaseSync'
      | 'datcon'
      | 'autopay'
      | 'loan'
      | 'DLM'
      | 'monitor'
      | 'scra'
      | 'loanSnapshot'
      | 'elasticsearch'
      | 'long'
  }
}

export const createEvent = makeData<CreateEventResponse, CreateEventKey, CreateEventVariables>({
  method: 'post',
  name: 'createEvent',
  summary: 'Create event',
  path: '/events',
  queryKey: ({ query }) => ['events', query],
})

export type CreateEventsBulkKey = ['events', 'bulk', unknown]

export type CreateEventsBulkResponse = ResponseTypes.EventsPublic

export type CreateEventsBulkVariables = {
  body?: Array<SchemasEventTypes.EventPublic>
  query?: {
    firingMode?: 'full' | 'database' | 'queue'
    priority?:
      | 'low'
      | 'normal'
      | 'high'
      | 'paymentProcessing'
      | 'createPurchaseSync'
      | 'datcon'
      | 'autopay'
      | 'loan'
      | 'DLM'
      | 'monitor'
      | 'scra'
      | 'loanSnapshot'
      | 'elasticsearch'
      | 'long'
  }
}

export const createEventsBulk = makeData<CreateEventsBulkResponse, CreateEventsBulkKey, CreateEventsBulkVariables>({
  method: 'post',
  name: 'createEventsBulk',
  summary: 'Create many events',
  path: '/events/bulk',
  queryKey: ({ query }) => ['events', 'bulk', query],
})

export type AmortizationGetKey = ['amortize', unknown]

export type AmortizationGetResponse = ResponseTypes.Amortize

export type AmortizationGetVariables = {
  body?: SchemaTypes.AmortizableLoan
  query?: void
}

export const amortizationGet = makeData<AmortizationGetResponse, AmortizationGetKey, AmortizationGetVariables>({
  method: 'post',
  name: 'amortizationGet',
  summary: 'Amortize loan',
  path: '/amortize',
  queryKey: ({ query }) => ['amortize', query],
})

export type ConsentGetKey = ['people', string | undefined, 'consents', unknown]

export type ConsentGetResponse = ResponseTypes.Consent

export type ConsentGetVariables = {
  personId: string | undefined
  body?: void
  query?: void
}

export const consentGet = makeData<ConsentGetResponse, ConsentGetKey, ConsentGetVariables>({
  method: 'get',
  name: 'consentGet',
  summary: 'Get consents',
  path: '/people/{personId}/consents',
  queryKey: ({ query, personId }) => ['people', personId, 'consents', query],
})

export type ConsentUpdateKey = ['people', string | undefined, 'consents', unknown]

export type ConsentUpdateResponse = ResponseTypes.Consent

export type ConsentUpdateVariables = {
  personId: string | undefined
  body?: RequestBodyTypes.UpdateConsent
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const consentUpdate = makeData<ConsentUpdateResponse, ConsentUpdateKey, ConsentUpdateVariables>({
  method: 'put',
  name: 'consentUpdate',
  summary: 'Update consents',
  path: '/people/{personId}/consents',
  queryKey: ({ query, personId }) => ['people', personId, 'consents', query],
})

export type DoNotInteractTypeGetAllKey = ['do-not-interact-types', unknown]

export type DoNotInteractTypeGetAllResponse = CaseTypes.ResponseDoNotInteractTypesPaging

export type DoNotInteractTypeGetAllVariables = {
  body?: void
  query?: {
    loanTypeId?: Array<SchemaTypes.AnyId>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const doNotInteractTypeGetAll = makeData<
  DoNotInteractTypeGetAllResponse,
  DoNotInteractTypeGetAllKey,
  DoNotInteractTypeGetAllVariables
>({
  method: 'get',
  name: 'doNotInteractTypeGetAll',
  summary: 'Get types',
  path: '/do-not-interact-types',
  queryKey: ({ query }) => ['do-not-interact-types', query],
})

export type DoNotInteractTypeCreateKey = ['do-not-interact-types', unknown]

export type DoNotInteractTypeCreateResponse = CaseTypes.ResponseDoNotInteractType

export type DoNotInteractTypeCreateVariables = {
  body?: CaseTypes.RequestBodyDoNotInteractType
  query?: {
    typeId?: number
    version?: number
  }
}

export const doNotInteractTypeCreate = makeData<
  DoNotInteractTypeCreateResponse,
  DoNotInteractTypeCreateKey,
  DoNotInteractTypeCreateVariables
>({
  method: 'post',
  name: 'doNotInteractTypeCreate',
  summary: 'Create type',
  path: '/do-not-interact-types',
  queryKey: ({ query }) => ['do-not-interact-types', query],
})

export type DoNotInteractTypeGetByIdKey = ['do-not-interact-types', string | undefined, unknown]

export type DoNotInteractTypeGetByIdResponse = CaseTypes.ResponseDoNotInteractType

export type DoNotInteractTypeGetByIdVariables = {
  doNotInteractTypeId: string | undefined
  body?: void
  query?: {
    version?: number
  }
}

export const doNotInteractTypeGetById = makeData<
  DoNotInteractTypeGetByIdResponse,
  DoNotInteractTypeGetByIdKey,
  DoNotInteractTypeGetByIdVariables
>({
  method: 'get',
  name: 'doNotInteractTypeGetById',
  summary: 'Get type by ID',
  path: '/do-not-interact-types/{doNotInteractTypeId}',
  queryKey: ({ query, doNotInteractTypeId }) => ['do-not-interact-types', doNotInteractTypeId, query],
})

export type DoNotInteractTypeUpdateKey = ['do-not-interact-types', string | undefined, unknown]

export type DoNotInteractTypeUpdateResponse = CaseTypes.ResponseDoNotInteractType

export type DoNotInteractTypeUpdateVariables = {
  doNotInteractTypeId: string | undefined
  body?: CaseTypes.RequestBodyDoNotInteractType
  query?: {
    version?: number
  }
}

export const doNotInteractTypeUpdate = makeData<
  DoNotInteractTypeUpdateResponse,
  DoNotInteractTypeUpdateKey,
  DoNotInteractTypeUpdateVariables
>({
  method: 'put',
  name: 'doNotInteractTypeUpdate',
  summary: 'Update type',
  path: '/do-not-interact-types/{doNotInteractTypeId}',
  queryKey: ({ query, doNotInteractTypeId }) => ['do-not-interact-types', doNotInteractTypeId, query],
})

export type NotificationTypeGetAllKey = ['notification-types', unknown]

export type NotificationTypeGetAllResponse = CaseTypes.ResponseNotificationTypesPaging

export type NotificationTypeGetAllVariables = {
  body?: void
  query?: {
    loanTypeId?: Array<SchemaTypes.AnyId>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const notificationTypeGetAll = makeData<
  NotificationTypeGetAllResponse,
  NotificationTypeGetAllKey,
  NotificationTypeGetAllVariables
>({
  method: 'get',
  name: 'notificationTypeGetAll',
  summary: 'Get types',
  path: '/notification-types',
  queryKey: ({ query }) => ['notification-types', query],
})

export type NotificationTypeCreateKey = ['notification-types', unknown]

export type NotificationTypeCreateResponse = CaseTypes.ResponseNotificationType

export type NotificationTypeCreateVariables = {
  body?: CaseTypes.RequestBodyNotificationType
  query?: {
    typeId?: number
    version?: number
  }
}

export const notificationTypeCreate = makeData<
  NotificationTypeCreateResponse,
  NotificationTypeCreateKey,
  NotificationTypeCreateVariables
>({
  method: 'post',
  name: 'notificationTypeCreate',
  summary: 'Create type',
  path: '/notification-types',
  queryKey: ({ query }) => ['notification-types', query],
})

export type NotificationTypeGetByIdKey = ['notification-types', string | undefined, unknown]

export type NotificationTypeGetByIdResponse = CaseTypes.ResponseNotificationType

export type NotificationTypeGetByIdVariables = {
  notificationTypeId: string | undefined
  body?: void
  query?: {
    version?: number
  }
}

export const notificationTypeGetById = makeData<
  NotificationTypeGetByIdResponse,
  NotificationTypeGetByIdKey,
  NotificationTypeGetByIdVariables
>({
  method: 'get',
  name: 'notificationTypeGetById',
  summary: 'Get type by ID',
  path: '/notification-types/{notificationTypeId}',
  queryKey: ({ query, notificationTypeId }) => ['notification-types', notificationTypeId, query],
})

export type NotificationTypeUpdateKey = ['notification-types', string | undefined, unknown]

export type NotificationTypeUpdateResponse = CaseTypes.ResponseNotificationType

export type NotificationTypeUpdateVariables = {
  notificationTypeId: string | undefined
  body?: CaseTypes.RequestBodyNotificationType
  query?: {
    version?: number
  }
}

export const notificationTypeUpdate = makeData<
  NotificationTypeUpdateResponse,
  NotificationTypeUpdateKey,
  NotificationTypeUpdateVariables
>({
  method: 'put',
  name: 'notificationTypeUpdate',
  summary: 'Update type',
  path: '/notification-types/{notificationTypeId}',
  queryKey: ({ query, notificationTypeId }) => ['notification-types', notificationTypeId, query],
})

export type SnoozeCaseKey = ['people', string | undefined, 'cases', string | undefined, 'snooze', unknown]

export type SnoozeCaseResponse = void

export type SnoozeCaseVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: {
    snoozeFor: /**
     * The number of days to snooze for. To cancel snoozing set to `null`.
     * If the current time has caught up to the `snoozedUntil` date then setting
     * this `snoozeFor` to `null` also causes the snooze to "pop" which initiates
     * a case review task into the task system.
     */
    | number /**
       * The number of days to snooze for. To cancel snoozing set to `null`.
       * If the current time has caught up to the `snoozedUntil` date then setting
       * this `snoozeFor` to `null` also causes the snooze to "pop" which initiates
       * a case review task into the task system.
       */
      | null
  }
  query?: void
}

export const snoozeCase = makeData<SnoozeCaseResponse, SnoozeCaseKey, SnoozeCaseVariables>({
  method: 'post',
  name: 'snoozeCase',
  summary: 'Snooze case',
  path: '/people/{personId}/cases/{caseId}/snooze',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'snooze', query],
})

export type CaseDoNotInteractGetAllKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'do-not-interacts',
  unknown,
]

export type CaseDoNotInteractGetAllResponse = CaseTypes.ResponseDoNotInteractPaging

export type CaseDoNotInteractGetAllVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const caseDoNotInteractGetAll = makeData<
  CaseDoNotInteractGetAllResponse,
  CaseDoNotInteractGetAllKey,
  CaseDoNotInteractGetAllVariables
>({
  method: 'get',
  name: 'caseDoNotInteractGetAll',
  summary: 'Get case do not interacts',
  path: '/people/{personId}/cases/{caseId}/do-not-interacts',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'do-not-interacts', query],
})

export type DoNotInteractCreateKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'do-not-interacts',
  unknown,
]

export type DoNotInteractCreateResponse = CaseTypes.ResponseDoNotInteract

export type DoNotInteractCreateVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyDoNotInteractCreate
  query?: void
}

export const doNotInteractCreate = makeData<
  DoNotInteractCreateResponse,
  DoNotInteractCreateKey,
  DoNotInteractCreateVariables
>({
  method: 'post',
  name: 'doNotInteractCreate',
  summary: 'Create do not interact',
  path: '/people/{personId}/cases/{caseId}/do-not-interacts',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'do-not-interacts', query],
})

export type DoNotInteractGetByIdKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'do-not-interacts',
  string | undefined,
  unknown,
]

export type DoNotInteractGetByIdResponse = CaseTypes.ResponseDoNotInteract

export type DoNotInteractGetByIdVariables = {
  personId: string | undefined
  caseId: string | undefined
  doNotInteractId: string | undefined
  body?: void
  query?: void
}

export const doNotInteractGetById = makeData<
  DoNotInteractGetByIdResponse,
  DoNotInteractGetByIdKey,
  DoNotInteractGetByIdVariables
>({
  method: 'get',
  name: 'doNotInteractGetById',
  summary: 'Get do not interact by ID',
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ query, personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
    query,
  ],
})

export type DoNotInteractUpdateKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'do-not-interacts',
  string | undefined,
  unknown,
]

export type DoNotInteractUpdateResponse = CaseTypes.ResponseDoNotInteract

export type DoNotInteractUpdateVariables = {
  personId: string | undefined
  caseId: string | undefined
  doNotInteractId: string | undefined
  body?: CaseTypes.RequestBodyDoNotInteractUpdate
  query?: void
}

export const doNotInteractUpdate = makeData<
  DoNotInteractUpdateResponse,
  DoNotInteractUpdateKey,
  DoNotInteractUpdateVariables
>({
  method: 'put',
  name: 'doNotInteractUpdate',
  summary: 'Update do not interact',
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ query, personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
    query,
  ],
})

export type DoNotInteractDeleteKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'do-not-interacts',
  string | undefined,
  unknown,
]

export type DoNotInteractDeleteResponse = void

export type DoNotInteractDeleteVariables = {
  personId: string | undefined
  caseId: string | undefined
  doNotInteractId: string | undefined
  body?: void
  query?: void
}

export const doNotInteractDelete = makeData<
  DoNotInteractDeleteResponse,
  DoNotInteractDeleteKey,
  DoNotInteractDeleteVariables
>({
  method: 'delete',
  name: 'doNotInteractDelete',
  summary: 'Remove do not interact',
  path: '/people/{personId}/cases/{caseId}/do-not-interacts/{doNotInteractId}',
  queryKey: ({ query, personId, caseId, doNotInteractId }) => [
    'people',
    personId,
    'cases',
    caseId,
    'do-not-interacts',
    doNotInteractId,
    query,
  ],
})

export type ListCaseNotesKey = ['people', string | undefined, 'cases', string | undefined, 'notes', unknown]

export type ListCaseNotesResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type ListCaseNotesVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    categories?: Array<CaseTypes.NoteCategory>
    sortBy?: Array<string>
    includeArchived?: boolean
    pinned?: boolean | null
  }
}

export const listCaseNotes = makeData<ListCaseNotesResponse, ListCaseNotesKey, ListCaseNotesVariables>({
  method: 'get',
  name: 'listCaseNotes',
  summary: 'Get notes',
  path: '/people/{personId}/cases/{caseId}/notes',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'notes', query],
})

export type CreateCaseNoteKey = ['people', string | undefined, 'cases', string | undefined, 'notes', unknown]

export type CreateCaseNoteResponse = CaseTypes.ResponseNote

export type CreateCaseNoteVariables = {
  personId: string | undefined
  caseId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const createCaseNote = makeData<CreateCaseNoteResponse, CreateCaseNoteKey, CreateCaseNoteVariables>({
  method: 'post',
  name: 'createCaseNote',
  summary: 'Create note',
  path: '/people/{personId}/cases/{caseId}/notes',
  queryKey: ({ query, personId, caseId }) => ['people', personId, 'cases', caseId, 'notes', query],
})

export type GetCaseNoteKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'notes',
  string | undefined,
  unknown,
]

export type GetCaseNoteResponse = CaseTypes.ResponseNote

export type GetCaseNoteVariables = {
  personId: string | undefined
  caseId: string | undefined
  noteId: string | undefined
  body?: void
  query?: void
}

export const getCaseNote = makeData<GetCaseNoteResponse, GetCaseNoteKey, GetCaseNoteVariables>({
  method: 'get',
  name: 'getCaseNote',
  summary: 'Get note by ID',
  path: '/people/{personId}/cases/{caseId}/notes/{noteId}',
  queryKey: ({ query, personId, caseId, noteId }) => ['people', personId, 'cases', caseId, 'notes', noteId, query],
})

export type UpdateCaseNoteKey = [
  'people',
  string | undefined,
  'cases',
  string | undefined,
  'notes',
  string | undefined,
  unknown,
]

export type UpdateCaseNoteResponse = CaseTypes.ResponseNote

export type UpdateCaseNoteVariables = {
  personId: string | undefined
  caseId: string | undefined
  noteId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const updateCaseNote = makeData<UpdateCaseNoteResponse, UpdateCaseNoteKey, UpdateCaseNoteVariables>({
  method: 'put',
  name: 'updateCaseNote',
  summary: 'Update note',
  path: '/people/{personId}/cases/{caseId}/notes/{noteId}',
  queryKey: ({ query, personId, caseId, noteId }) => ['people', personId, 'cases', caseId, 'notes', noteId, query],
})

export type ListInteractionNotesKey = ['interactions', string | undefined, 'notes', unknown]

export type ListInteractionNotesResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type ListInteractionNotesVariables = {
  interactionId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    categories?: Array<CaseTypes.NoteCategory>
    sortBy?: Array<string>
    includeArchived?: boolean
    pinned?: boolean | null
  }
}

export const listInteractionNotes = makeData<
  ListInteractionNotesResponse,
  ListInteractionNotesKey,
  ListInteractionNotesVariables
>({
  method: 'get',
  name: 'listInteractionNotes',
  summary: 'Get notes',
  path: '/interactions/{interactionId}/notes',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'notes', query],
})

export type CreateInteractionNoteKey = ['interactions', string | undefined, 'notes', unknown]

export type CreateInteractionNoteResponse = CaseTypes.ResponseNote

export type CreateInteractionNoteVariables = {
  interactionId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const createInteractionNote = makeData<
  CreateInteractionNoteResponse,
  CreateInteractionNoteKey,
  CreateInteractionNoteVariables
>({
  method: 'post',
  name: 'createInteractionNote',
  summary: 'Create note',
  path: '/interactions/{interactionId}/notes',
  queryKey: ({ query, interactionId }) => ['interactions', interactionId, 'notes', query],
})

export type GetInteractionNoteKey = ['interactions', string | undefined, 'notes', string | undefined, unknown]

export type GetInteractionNoteResponse = CaseTypes.ResponseNote

export type GetInteractionNoteVariables = {
  interactionId: string | undefined
  noteId: string | undefined
  body?: void
  query?: void
}

export const getInteractionNote = makeData<
  GetInteractionNoteResponse,
  GetInteractionNoteKey,
  GetInteractionNoteVariables
>({
  method: 'get',
  name: 'getInteractionNote',
  summary: 'Get note by ID',
  path: '/interactions/{interactionId}/notes/{noteId}',
  queryKey: ({ query, interactionId, noteId }) => ['interactions', interactionId, 'notes', noteId, query],
})

export type UpdateInteractionNoteKey = ['interactions', string | undefined, 'notes', string | undefined, unknown]

export type UpdateInteractionNoteResponse = CaseTypes.ResponseNote

export type UpdateInteractionNoteVariables = {
  interactionId: string | undefined
  noteId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const updateInteractionNote = makeData<
  UpdateInteractionNoteResponse,
  UpdateInteractionNoteKey,
  UpdateInteractionNoteVariables
>({
  method: 'put',
  name: 'updateInteractionNote',
  summary: 'Update note',
  path: '/interactions/{interactionId}/notes/{noteId}',
  queryKey: ({ query, interactionId, noteId }) => ['interactions', interactionId, 'notes', noteId, query],
})

export type ListBorrowerNotesKey = ['people', string | undefined, 'notes', unknown]

export type ListBorrowerNotesResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type ListBorrowerNotesVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    categories?: Array<CaseTypes.NoteCategory>
    sortBy?: Array<string>
    includeArchived?: boolean
    pinned?: boolean | null
  }
}

export const listBorrowerNotes = makeData<ListBorrowerNotesResponse, ListBorrowerNotesKey, ListBorrowerNotesVariables>({
  method: 'get',
  name: 'listBorrowerNotes',
  summary: 'Get notes',
  path: '/people/{personId}/notes',
  queryKey: ({ query, personId }) => ['people', personId, 'notes', query],
})

export type CreateBorrowerNoteKey = ['people', string | undefined, 'notes', unknown]

export type CreateBorrowerNoteResponse = CaseTypes.ResponseNote

export type CreateBorrowerNoteVariables = {
  personId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const createBorrowerNote = makeData<
  CreateBorrowerNoteResponse,
  CreateBorrowerNoteKey,
  CreateBorrowerNoteVariables
>({
  method: 'post',
  name: 'createBorrowerNote',
  summary: 'Create note',
  path: '/people/{personId}/notes',
  queryKey: ({ query, personId }) => ['people', personId, 'notes', query],
})

export type GetBorrowerNoteKey = ['people', string | undefined, 'notes', string | undefined, unknown]

export type GetBorrowerNoteResponse = CaseTypes.ResponseNote

export type GetBorrowerNoteVariables = {
  personId: string | undefined
  noteId: string | undefined
  body?: void
  query?: void
}

export const getBorrowerNote = makeData<GetBorrowerNoteResponse, GetBorrowerNoteKey, GetBorrowerNoteVariables>({
  method: 'get',
  name: 'getBorrowerNote',
  summary: 'Get note by ID',
  path: '/people/{personId}/notes/{noteId}',
  queryKey: ({ query, personId, noteId }) => ['people', personId, 'notes', noteId, query],
})

export type UpdateBorrowerNoteKey = ['people', string | undefined, 'notes', string | undefined, unknown]

export type UpdateBorrowerNoteResponse = CaseTypes.ResponseNote

export type UpdateBorrowerNoteVariables = {
  personId: string | undefined
  noteId: string | undefined
  body?: CaseTypes.RequestBodyNote
  query?: void
}

export const updateBorrowerNote = makeData<
  UpdateBorrowerNoteResponse,
  UpdateBorrowerNoteKey,
  UpdateBorrowerNoteVariables
>({
  method: 'put',
  name: 'updateBorrowerNote',
  summary: 'Update note',
  path: '/people/{personId}/notes/{noteId}',
  queryKey: ({ query, personId, noteId }) => ['people', personId, 'notes', noteId, query],
})

export type PeopleDoNotInteractGetAllKey = ['people', string | undefined, 'do-not-interacts', unknown]

export type PeopleDoNotInteractGetAllResponse = CaseTypes.ResponseDoNotInteractPaging

export type PeopleDoNotInteractGetAllVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const peopleDoNotInteractGetAll = makeData<
  PeopleDoNotInteractGetAllResponse,
  PeopleDoNotInteractGetAllKey,
  PeopleDoNotInteractGetAllVariables
>({
  method: 'get',
  name: 'peopleDoNotInteractGetAll',
  summary: 'Get all do not interacts',
  path: '/people/{personId}/do-not-interacts',
  queryKey: ({ query, personId }) => ['people', personId, 'do-not-interacts', query],
})

export type ListBorrowerInteractionNotesKey = ['people', string | undefined, 'interaction-notes', unknown]

export type ListBorrowerInteractionNotesResponse = UtilityTypes.NewPaginator & {
  data?: Array<CaseTypes.NoteResponse>
}

export type ListBorrowerInteractionNotesVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    includeArchived?: boolean
    pinned?: boolean | null
    channels?: Array<InteractionTypes.InteractionChannel>
  }
}

export const listBorrowerInteractionNotes = makeData<
  ListBorrowerInteractionNotesResponse,
  ListBorrowerInteractionNotesKey,
  ListBorrowerInteractionNotesVariables
>({
  method: 'get',
  name: 'listBorrowerInteractionNotes',
  summary: 'Get interaction notes for a person',
  path: '/people/{personId}/interaction-notes',
  queryKey: ({ query, personId }) => ['people', personId, 'interaction-notes', query],
})

export type LoansGetByCompanyKey = ['loans', unknown]

export type LoansGetByCompanyResponse = SchemaTypes.LoansPaging | SchemaTypes.LoansIdsOnlyPaging

export type LoansGetByCompanyVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     all
     */
    fields?: 'all' | 'idsOnly'
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    managedByPeach?: boolean | null
    includeAdditionalBorrowers?: boolean
    loanTypeId?: Array<SchemaTypes.AnyId>
    labels?: Array<string>
    createdAtBefore?: string
    createdAtAfter?: string
    updatedAtBefore?: string
    updatedAtAfter?: string
    originatedAtAfter?: string
    originatedAtBefore?: string
    activatedAtBefore?: string
    activatedAtAfter?: string
    sortBy?: Array<
      | 'createdAt'
      | '+createdAt'
      | '-createdAt'
      | 'updatedAt'
      | '+updatedAt'
      | '-updatedAt'
      | 'originatedAt'
      | '+originatedAt'
      | '-originatedAt'
      | 'activatedAt'
      | '+activatedAt'
      | '-activatedAt'
    >
  }
}

export const loansGetByCompany = makeData<LoansGetByCompanyResponse, LoansGetByCompanyKey, LoansGetByCompanyVariables>({
  method: 'get',
  name: 'loansGetByCompany',
  summary: 'Get loans',
  path: '/loans',
  queryKey: ({ query }) => ['loans', query],
})

export type LoansGetKey = ['people', string | undefined, 'loans', unknown]

export type LoansGetResponse = SchemaTypes.LoansPaging

export type LoansGetVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    status?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    loanTypeId?: Array<SchemaTypes.AnyId>
    includeAdditionalBorrowers?: boolean
    chargeOffBalance?: 'all' | 'hasBalance' | 'zeroBalance'
  }
}

export const loansGet = makeData<LoansGetResponse, LoansGetKey, LoansGetVariables>({
  method: 'get',
  name: 'loansGet',
  summary: "Get borrower's loans",
  path: '/people/{personId}/loans',
  queryKey: ({ query, personId }) => ['people', personId, 'loans', query],
})

export type LoansAddKey = ['people', string | undefined, 'loans', unknown]

export type LoansAddResponse = ResponseTypes.Loan

export type LoansAddVariables = {
  personId: string | undefined
  body?: RequestBodyTypes.LoanPost
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const loansAdd = makeData<LoansAddResponse, LoansAddKey, LoansAddVariables>({
  method: 'post',
  name: 'loansAdd',
  summary: 'Create loan',
  path: '/people/{personId}/loans',
  queryKey: ({ query, personId }) => ['people', personId, 'loans', query],
})

export type LoanGetKey = ['people', string | undefined, 'loans', string | undefined, unknown]

export type LoanGetResponse = ResponseTypes.Loan

export type LoanGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    includeAdditionalBorrowers?: boolean
  }
}

export const loanGet = makeData<LoanGetResponse, LoanGetKey, LoanGetVariables>({
  method: 'get',
  name: 'loanGet',
  summary: 'Get loan by ID',
  path: '/people/{personId}/loans/{loanId}',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, query],
})

export type LoanUpdateKey = ['people', string | undefined, 'loans', string | undefined, unknown]

export type LoanUpdateResponse = ResponseTypes.Loan

export type LoanUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanUpdate
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
    force?: boolean
  }
}

export const loanUpdate = makeData<LoanUpdateResponse, LoanUpdateKey, LoanUpdateVariables>({
  method: 'put',
  name: 'loanUpdate',
  summary: 'Update loan',
  path: '/people/{personId}/loans/{loanId}',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, query],
})

export type GetLockStatusKey = ['people', string | undefined, 'loans', string | undefined, 'lock-status', unknown]

export type GetLockStatusResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.IsLocked
}

export type GetLockStatusVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const getLockStatus = makeData<GetLockStatusResponse, GetLockStatusKey, GetLockStatusVariables>({
  method: 'get',
  name: 'getLockStatus',
  summary: 'Get loan lock status',
  path: '/people/{personId}/loans/{loanId}/lock-status',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'lock-status', query],
})

export type GetLoanAdvancesKey = ['people', string | undefined, 'loans', string | undefined, 'advances', unknown]

export type GetLoanAdvancesResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /**
     * The total of advances amounts in `pending` or `settled` status. In other words, the total of advances that
     * were written to the ledger.
     */
    totalAdvancedAmount?: number
    /** The total of advances amounts in `scheduled` status. */
    totalScheduledAmount?: number
    advances?: Array<SchemaTypes.Advance>
  }
}

export type GetLoanAdvancesVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    status?: 'scheduled' | 'pending' | 'settled' | 'canceled'
  }
}

export const getLoanAdvances = makeData<GetLoanAdvancesResponse, GetLoanAdvancesKey, GetLoanAdvancesVariables>({
  method: 'get',
  name: 'getLoanAdvances',
  summary: 'Get advances',
  path: '/people/{personId}/loans/{loanId}/advances',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'advances', query],
})

export type CreateLoanAdvanceKey = ['people', string | undefined, 'loans', string | undefined, 'advances', unknown]

export type CreateLoanAdvanceResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Advance
}

export type CreateLoanAdvanceVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {} & SchemaTypes.Advance
  query?: {
    sync?: boolean
  }
}

export const createLoanAdvance = makeData<CreateLoanAdvanceResponse, CreateLoanAdvanceKey, CreateLoanAdvanceVariables>({
  method: 'post',
  name: 'createLoanAdvance',
  summary: 'Create advance',
  path: '/people/{personId}/loans/{loanId}/advances',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'advances', query],
})

export type GetLoanAdvanceKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'advances',
  string | undefined,
  unknown,
]

export type GetLoanAdvanceResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Advance
}

export type GetLoanAdvanceVariables = {
  personId: string | undefined
  loanId: string | undefined
  advanceId: string | undefined
  body?: void
  query?: void
}

export const getLoanAdvance = makeData<GetLoanAdvanceResponse, GetLoanAdvanceKey, GetLoanAdvanceVariables>({
  method: 'get',
  name: 'getLoanAdvance',
  summary: 'Get advance by ID',
  path: '/people/{personId}/loans/{loanId}/advances/{advanceId}',
  queryKey: ({ query, personId, loanId, advanceId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'advances',
    advanceId,
    query,
  ],
})

export type UpdateLoanAdvanceKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'advances',
  string | undefined,
  unknown,
]

export type UpdateLoanAdvanceResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Advance
}

export type UpdateLoanAdvanceVariables = {
  personId: string | undefined
  loanId: string | undefined
  advanceId: string | undefined
  body?: SchemaTypes.Advance
  query?: {
    sync?: boolean
  }
}

export const updateLoanAdvance = makeData<UpdateLoanAdvanceResponse, UpdateLoanAdvanceKey, UpdateLoanAdvanceVariables>({
  method: 'put',
  name: 'updateLoanAdvance',
  summary: 'Update advance',
  path: '/people/{personId}/loans/{loanId}/advances/{advanceId}',
  queryKey: ({ query, personId, loanId, advanceId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'advances',
    advanceId,
    query,
  ],
})

export type GetLoanPeriodsKey = ['people', string | undefined, 'loans', string | undefined, 'periods', unknown]

export type GetLoanPeriodsResponse = ResponseTypes.LoanPeriodsResponse

export type GetLoanPeriodsVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    fromDate?: string
    toDate?: string
    dueDate?: string
  }
}

export const getLoanPeriods = makeData<GetLoanPeriodsResponse, GetLoanPeriodsKey, GetLoanPeriodsVariables>({
  method: 'get',
  name: 'getLoanPeriods',
  summary: 'Get loan periods',
  path: '/people/{personId}/loans/{loanId}/periods',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'periods', query],
})

export type GetLoanPeriodByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  string | undefined,
  unknown,
]

export type GetLoanPeriodByIdResponse = ResponseTypes.LoanPeriodResponse

export type GetLoanPeriodByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  loanPeriodId: string | undefined
  body?: void
  query?: void
}

export const getLoanPeriodById = makeData<GetLoanPeriodByIdResponse, GetLoanPeriodByIdKey, GetLoanPeriodByIdVariables>({
  method: 'get',
  name: 'getLoanPeriodById',
  summary: 'Get loan period by id',
  path: '/people/{personId}/loans/{loanId}/periods/{loanPeriodId}',
  queryKey: ({ query, personId, loanId, loanPeriodId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    loanPeriodId,
    query,
  ],
})

export type LoanScheduleOptionsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'schedule-options',
  unknown,
]

export type LoanScheduleOptionsGetResponse = ResponseTypes.LoanScheduleOptions

export type LoanScheduleOptionsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isAutopay?: boolean
  }
}

export const loanScheduleOptionsGet = makeData<
  LoanScheduleOptionsGetResponse,
  LoanScheduleOptionsGetKey,
  LoanScheduleOptionsGetVariables
>({
  method: 'get',
  name: 'loanScheduleOptionsGet',
  summary: 'Get loan schedule options',
  path: '/people/{personId}/loans/{loanId}/schedule-options',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'schedule-options', query],
})

export type LoanActivateKey = ['people', string | undefined, 'loans', string | undefined, 'activate', unknown]

export type LoanActivateResponse = ResponseTypes.LoanActivate

export type LoanActivateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanActivate
  query?: {
    sync?: boolean
  }
}

export const loanActivate = makeData<LoanActivateResponse, LoanActivateKey, LoanActivateVariables>({
  method: 'post',
  name: 'loanActivate',
  summary: 'Activate loan',
  path: '/people/{personId}/loans/{loanId}/activate',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'activate', query],
})

export type LoanReimburseKey = ['people', string | undefined, 'loans', string | undefined, 'reimburse', unknown]

export type LoanReimburseResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type LoanReimburseVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanReimburse
  query?: void
}

export const loanReimburse = makeData<LoanReimburseResponse, LoanReimburseKey, LoanReimburseVariables>({
  method: 'post',
  name: 'loanReimburse',
  summary: 'Reimburse',
  path: '/people/{personId}/loans/{loanId}/reimburse',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'reimburse', query],
})

export type LoanRefundsGetKey = ['people', string | undefined, 'loans', string | undefined, 'refunds', unknown]

export type LoanRefundsGetResponse = ResponseTypes.LoanRefundCollection

export type LoanRefundsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanRefundsGet = makeData<LoanRefundsGetResponse, LoanRefundsGetKey, LoanRefundsGetVariables>({
  method: 'get',
  name: 'loanRefundsGet',
  summary: 'Get refunds',
  path: '/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds', query],
})

export type LoanRefundsPostKey = ['people', string | undefined, 'loans', string | undefined, 'refunds', unknown]

export type LoanRefundsPostResponse = ResponseTypes.LoanRefundPost

export type LoanRefundsPostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRefund
  query?: void
}

export const loanRefundsPost = makeData<LoanRefundsPostResponse, LoanRefundsPostKey, LoanRefundsPostVariables>({
  method: 'post',
  name: 'loanRefundsPost',
  summary: 'Create refund',
  path: '/people/{personId}/loans/{loanId}/refunds',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds', query],
})

export type LoanRefundsCancelKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'refunds',
  string | undefined,
  'cancel',
  unknown,
]

export type LoanRefundsCancelResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanRefund
}

export type LoanRefundsCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  loanRefundId: string | undefined
  body?: RequestBodyTypes.LoanRefundCancel
  query?: void
}

export const loanRefundsCancel = makeData<LoanRefundsCancelResponse, LoanRefundsCancelKey, LoanRefundsCancelVariables>({
  method: 'post',
  name: 'loanRefundsCancel',
  summary: 'Cancel refund',
  path: '/people/{personId}/loans/{loanId}/refunds/{loanRefundId}/cancel',
  queryKey: ({ query, personId, loanId, loanRefundId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'refunds',
    loanRefundId,
    'cancel',
    query,
  ],
})

export type LoanRefundsV2PostKey = ['people', string | undefined, 'loans', string | undefined, 'refunds-v2', unknown]

export type LoanRefundsV2PostResponse = ResponseTypes.LoanRefundV2Post

export type LoanRefundsV2PostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRefundV2
  query?: void
}

export const loanRefundsV2Post = makeData<LoanRefundsV2PostResponse, LoanRefundsV2PostKey, LoanRefundsV2PostVariables>({
  method: 'post',
  name: 'loanRefundsV2Post',
  summary: 'Create refund v2',
  path: '/people/{personId}/loans/{loanId}/refunds-v2',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'refunds-v2', query],
})

export type LoanCancelKey = ['people', string | undefined, 'loans', string | undefined, 'cancel', unknown]

export type LoanCancelResponse = void

export type LoanCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanCancel
  query?: {
    force?: boolean
  }
}

export const loanCancel = makeData<LoanCancelResponse, LoanCancelKey, LoanCancelVariables>({
  method: 'post',
  name: 'loanCancel',
  summary: 'Cancel loan',
  path: '/people/{personId}/loans/{loanId}/cancel',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'cancel', query],
})

export type LoanCloseKey = ['people', string | undefined, 'loans', string | undefined, 'close', unknown]

export type LoanCloseResponse = void

export type LoanCloseVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
    sendNotice?: SchemaTypes.LoanCloseSendNotice
    closeReason?: SchemaTypes.CloseReason
    closeRequestedByBorrower?: SchemaTypes.CloseRequestedByBorrower
    closeReasonDetails?: SchemaTypes.CloseReasonDetails
  }
  query?: void
}

export const loanClose = makeData<LoanCloseResponse, LoanCloseKey, LoanCloseVariables>({
  method: 'post',
  name: 'loanClose',
  summary: 'Close loan',
  path: '/people/{personId}/loans/{loanId}/close',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'close', query],
})

export type LoanReopenKey = ['people', string | undefined, 'loans', string | undefined, 'reopen', unknown]

export type LoanReopenResponse = void

export type LoanReopenVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
    /** The reason the loan is being reopened. */
    reopenReason?: string
  }
  query?: void
}

export const loanReopen = makeData<LoanReopenResponse, LoanReopenKey, LoanReopenVariables>({
  method: 'post',
  name: 'loanReopen',
  summary: 'Reopen a closed loan',
  path: '/people/{personId}/loans/{loanId}/reopen',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'reopen', query],
})

export type DrawDynamicFeesAtOriginationUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'dynamic-fees',
  unknown,
]

export type DrawDynamicFeesAtOriginationUpdateResponse = ResponseTypes.DynamicFeesAtOrigination

export type DrawDynamicFeesAtOriginationUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: SchemaTypes.AtOriginationDynamicFees
  query?: void
}

export const drawDynamicFeesAtOriginationUpdate = makeData<
  DrawDynamicFeesAtOriginationUpdateResponse,
  DrawDynamicFeesAtOriginationUpdateKey,
  DrawDynamicFeesAtOriginationUpdateVariables
>({
  method: 'put',
  name: 'drawDynamicFeesAtOriginationUpdate',
  summary: 'Update draw fees',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/dynamic-fees',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'dynamic-fees',
    query,
  ],
})

export type DrawCloseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'close',
  unknown,
]

export type DrawCloseResponse = void

export type DrawCloseVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
    /** Set to true if a borrower requested to close the account. */
    closeRequestedByBorrower?: boolean
  }
  query?: void
}

export const drawClose = makeData<DrawCloseResponse, DrawCloseKey, DrawCloseVariables>({
  method: 'post',
  name: 'drawClose',
  summary: 'Close draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/close',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'close',
    query,
  ],
})

export type DrawReopenKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'reopen',
  unknown,
]

export type DrawReopenResponse = void

export type DrawReopenVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
    /** The reason the loan is being reopened. */
    reopenReason?: string
  }
  query?: void
}

export const drawReopen = makeData<DrawReopenResponse, DrawReopenKey, DrawReopenVariables>({
  method: 'post',
  name: 'drawReopen',
  summary: 'Reopen draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/reopen',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'reopen',
    query,
  ],
})

export type DrawCancelKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'cancel',
  unknown,
]

export type DrawCancelResponse = void

export type DrawCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /**
     * The cancellation reason:
     *
     * `technicalIssue` - loan was created due to a technical issue.
     *
     * `wrongLoanTerms` - loan was created with the wrong terms.
     *
     * `wrongBorrower` - loan was created on the wrong borrower.
     *
     * `other` - for any other reason requiring cancellation.
     */
    cancellationReason?: 'technicalIssue' | 'wrongLoanTerms' | 'wrongBorrower' | 'other'
  }
  query?: {
    force?: boolean
  }
}

export const drawCancel = makeData<DrawCancelResponse, DrawCancelKey, DrawCancelVariables>({
  method: 'post',
  name: 'drawCancel',
  summary: 'Cancel draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/cancel',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'cancel',
    query,
  ],
})

export type LoanGetCreditLimitKey = ['people', string | undefined, 'loans', string | undefined, 'credit-limit', unknown]

export type LoanGetCreditLimitResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.CreditLimitProperties
}

export type LoanGetCreditLimitVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    effectiveDate?: string
  }
}

export const loanGetCreditLimit = makeData<
  LoanGetCreditLimitResponse,
  LoanGetCreditLimitKey,
  LoanGetCreditLimitVariables
>({
  method: 'get',
  name: 'loanGetCreditLimit',
  summary: 'Get loan credit limit',
  path: '/people/{personId}/loans/{loanId}/credit-limit',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-limit', query],
})

export type LoanUpdateCreditLimitKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-limit',
  unknown,
]

export type LoanUpdateCreditLimitResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LineOfCredit
}

export type LoanUpdateCreditLimitVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.CreditLimitProperties
  query?: void
}

export const loanUpdateCreditLimit = makeData<
  LoanUpdateCreditLimitResponse,
  LoanUpdateCreditLimitKey,
  LoanUpdateCreditLimitVariables
>({
  method: 'post',
  name: 'loanUpdateCreditLimit',
  summary: 'Update loan credit limit',
  path: '/people/{personId}/loans/{loanId}/credit-limit',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-limit', query],
})

export type DrawGetCreditLimitKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'credit-limit',
  unknown,
]

export type DrawGetCreditLimitResponse = UtilityTypes.SingletonResponse & {
  data?: {
    /** The credit limit amount. */
    creditLimitAmount?: number
  } & CaseTypes.CaseId
}

export type DrawGetCreditLimitVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    effectiveDate?: string
  }
}

export const drawGetCreditLimit = makeData<
  DrawGetCreditLimitResponse,
  DrawGetCreditLimitKey,
  DrawGetCreditLimitVariables
>({
  method: 'get',
  name: 'drawGetCreditLimit',
  summary: 'Get draw credit limit',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/credit-limit',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'credit-limit',
    query,
  ],
})

export type DrawUpdateCreditLimitKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'credit-limit',
  unknown,
]

export type DrawUpdateCreditLimitResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.DrawResponse
}

export type DrawUpdateCreditLimitVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /** The credit limit amount. */
    creditLimitAmount?: number
    creditLimitPercentage?: SchemaTypes.CreditLimitPercentage
  } & CaseTypes.CaseId
  query?: void
}

export const drawUpdateCreditLimit = makeData<
  DrawUpdateCreditLimitResponse,
  DrawUpdateCreditLimitKey,
  DrawUpdateCreditLimitVariables
>({
  method: 'post',
  name: 'drawUpdateCreditLimit',
  summary: 'Update draw credit limit',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/credit-limit',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'credit-limit',
    query,
  ],
})

export type FeeTypesGetKey = ['loan-types', string | undefined, 'fee-types', unknown]

export type FeeTypesGetResponse = UtilityTypes.SingletonResponse & {
  data?: Array<{
    apiName?: SchemaTypes.FeeApiName
    displayName?: SchemaTypes.FeeDisplayName
  }>
}

export type FeeTypesGetVariables = {
  loanTypeId: string | undefined
  body?: void
  query?: void
}

export const feeTypesGet = makeData<FeeTypesGetResponse, FeeTypesGetKey, FeeTypesGetVariables>({
  method: 'get',
  name: 'feeTypesGet',
  summary: 'Get fee types',
  path: '/loan-types/{loanTypeId}/fee-types',
  queryKey: ({ query, loanTypeId }) => ['loan-types', loanTypeId, 'fee-types', query],
})

export type DynamicFeeUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  unknown,
]

export type DynamicFeeUpdateResponse = void

export type DynamicFeeUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: {
    externalId?: /** The lender's identifier for the fee. */ string /** The lender's identifier for the fee. */ | null
    customDisplayName?: /**
     * A custom description of the fee. If provided, this value will be displayed in Peach UI/App and Statements
     * (or can be used by lender in their App). If the value is not provided, the Peach UI/App and Statements
     * logic will use displayName attribute. To remind, the displayName is populated from the fee type configuration object.
     */
    | string /**
       * A custom description of the fee. If provided, this value will be displayed in Peach UI/App and Statements
       * (or can be used by lender in their App). If the value is not provided, the Peach UI/App and Statements
       * logic will use displayName attribute. To remind, the displayName is populated from the fee type configuration object.
       */
      | null
  }
  query?: void
}

export const dynamicFeeUpdate = makeData<DynamicFeeUpdateResponse, DynamicFeeUpdateKey, DynamicFeeUpdateVariables>({
  method: 'put',
  name: 'dynamicFeeUpdate',
  summary: 'Update dynamic fee',
  path: '/people/{personId}/loans/{loanId}/fees/{loanFeeId}',
  queryKey: ({ query, personId, loanId, loanFeeId }) => ['people', personId, 'loans', loanId, 'fees', loanFeeId, query],
})

export type FeesGetKey = ['people', string | undefined, 'loans', string | undefined, 'fees', unknown]

export type FeesGetResponse = UtilityTypes.NewPaginator & {
  data?: Array<SchemaTypes.LoanFee>
}

export type FeesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     true
     */
    includeDraws?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const feesGet = makeData<FeesGetResponse, FeesGetKey, FeesGetVariables>({
  method: 'get',
  name: 'feesGet',
  summary: 'Get fees',
  path: '/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'fees', query],
})

export type FeesPostKey = ['people', string | undefined, 'loans', string | undefined, 'fees', unknown]

export type FeesPostResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.LoanFee>
}

export type FeesPostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.FeesPost
  query?: {
    sync?: boolean
  }
}

export const feesPost = makeData<FeesPostResponse, FeesPostKey, FeesPostVariables>({
  method: 'post',
  name: 'feesPost',
  summary: 'Charge dynamic fee',
  path: '/people/{personId}/loans/{loanId}/fees',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'fees', query],
})

export type FeesCancelOneKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  string | undefined,
  'cancel',
  unknown,
]

export type FeesCancelOneResponse = void

export type FeesCancelOneVariables = {
  personId: string | undefined
  loanId: string | undefined
  loanFeeId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: void
}

export const feesCancelOne = makeData<FeesCancelOneResponse, FeesCancelOneKey, FeesCancelOneVariables>({
  method: 'post',
  name: 'feesCancelOne',
  summary: 'Cancel dynamic fee',
  path: '/people/{personId}/loans/{loanId}/fees/{loanFeeId}/cancel',
  queryKey: ({ query, personId, loanId, loanFeeId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'fees',
    loanFeeId,
    'cancel',
    query,
  ],
})

export type FeesCancelKey = ['people', string | undefined, 'loans', string | undefined, 'cancel-fees', unknown]

export type FeesCancelResponse = void

export type FeesCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    loanFeeIds?: Array<string>
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: void
}

export const feesCancel = makeData<FeesCancelResponse, FeesCancelKey, FeesCancelVariables>({
  method: 'post',
  name: 'feesCancel',
  summary: 'Cancel dynamic fees',
  path: '/people/{personId}/loans/{loanId}/cancel-fees',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'cancel-fees', query],
})

export type UpdateOriginationFeeKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'fees',
  'origination',
  unknown,
]

export type UpdateOriginationFeeResponse = void

export type UpdateOriginationFeeVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    /** The updated amount of the origination fee. The new amount cannot be higher than the amount passed on loan creation in atOrigination. */
    feeAmount?: number
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: {
    sync?: boolean
  }
}

export const updateOriginationFee = makeData<
  UpdateOriginationFeeResponse,
  UpdateOriginationFeeKey,
  UpdateOriginationFeeVariables
>({
  method: 'put',
  name: 'updateOriginationFee',
  summary: 'Update origination fee',
  path: '/people/{personId}/loans/{loanId}/fees/origination',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'fees', 'origination', query],
})

export type UpdateDrawFeeKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'fees',
  'draw',
  unknown,
]

export type UpdateDrawFeeResponse = void

export type UpdateDrawFeeVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /** The updated amount of the draw fee. The new amount cannot be higher than the amount passed on draw creation in atOrigination. */
    feeAmount?: number
    /** An identifier for an existing case. */
    caseId?: string
  }
  query?: {
    sync?: boolean
  }
}

export const updateDrawFee = makeData<UpdateDrawFeeResponse, UpdateDrawFeeKey, UpdateDrawFeeVariables>({
  method: 'put',
  name: 'updateDrawFee',
  summary: 'Update draw fee',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/fees/draw',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'fees',
    'draw',
    query,
  ],
})

export type LoanFreezeKey = ['people', string | undefined, 'loans', string | undefined, 'freeze', unknown]

export type LoanFreezeResponse = void

export type LoanFreezeVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    /** An identifier for an existing case. */
    caseId?: string
    /**
     * When set to `false`, the system will NOT send a `loanFreeze` notice to the borrower.
     *
     * @default
     *     true
     */
    sendNotice?: boolean
  }
  query?: void
}

export const loanFreeze = makeData<LoanFreezeResponse, LoanFreezeKey, LoanFreezeVariables>({
  method: 'post',
  name: 'loanFreeze',
  summary: 'Freeze loan',
  path: '/people/{personId}/loans/{loanId}/freeze',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'freeze', query],
})

export type LoanUnfreezeKey = ['people', string | undefined, 'loans', string | undefined, 'unfreeze', unknown]

export type LoanUnfreezeResponse = ResponseTypes.ExpectedPayments

export type LoanUnfreezeVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanUnfreeze
  query?: void
}

export const loanUnfreeze = makeData<LoanUnfreezeResponse, LoanUnfreezeKey, LoanUnfreezeVariables>({
  method: 'post',
  name: 'loanUnfreeze',
  summary: 'Unfreeze loan',
  path: '/people/{personId}/loans/{loanId}/unfreeze',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'unfreeze', query],
})

export type LoanChargeOffKey = ['people', string | undefined, 'loans', string | undefined, 'charge-off', unknown]

export type LoanChargeOffResponse = ResponseTypes.LoanChargeOff

export type LoanChargeOffVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanChargeOff
  query?: void
}

export const loanChargeOff = makeData<LoanChargeOffResponse, LoanChargeOffKey, LoanChargeOffVariables>({
  method: 'post',
  name: 'loanChargeOff',
  summary: 'Charge-off loan',
  path: '/people/{personId}/loans/{loanId}/charge-off',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'charge-off', query],
})

export type LoanChargeOffReverseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'reverse-charge-off',
  unknown,
]

export type LoanChargeOffReverseResponse = ResponseTypes.LoanReverseChargeOff

export type LoanChargeOffReverseVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.CaseId
  query?: void
}

export const loanChargeOffReverse = makeData<
  LoanChargeOffReverseResponse,
  LoanChargeOffReverseKey,
  LoanChargeOffReverseVariables
>({
  method: 'post',
  name: 'loanChargeOffReverse',
  summary: 'Reverse charge-off',
  path: '/people/{personId}/loans/{loanId}/reverse-charge-off',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-charge-off', query],
})

export type LoanAccelerateKey = ['people', string | undefined, 'loans', string | undefined, 'accelerate', unknown]

export type LoanAccelerateResponse = ResponseTypes.LoanAccelerate

export type LoanAccelerateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanAccelerate
  query?: void
}

export const loanAccelerate = makeData<LoanAccelerateResponse, LoanAccelerateKey, LoanAccelerateVariables>({
  method: 'post',
  name: 'loanAccelerate',
  summary: 'Accelerate loan',
  path: '/people/{personId}/loans/{loanId}/accelerate',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'accelerate', query],
})

export type LoanAccelerateReverseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'reverse-accelerate',
  unknown,
]

export type LoanAccelerateReverseResponse = ResponseTypes.LoanReverseAccelerate

export type LoanAccelerateReverseVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.CaseId
  query?: void
}

export const loanAccelerateReverse = makeData<
  LoanAccelerateReverseResponse,
  LoanAccelerateReverseKey,
  LoanAccelerateReverseVariables
>({
  method: 'post',
  name: 'loanAccelerateReverse',
  summary: 'Reverse accelerate',
  path: '/people/{personId}/loans/{loanId}/reverse-accelerate',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'reverse-accelerate', query],
})

export type LoanAgentDashboardToolsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'agent-dashboard-tools',
  unknown,
]

export type LoanAgentDashboardToolsGetResponse = ResponseTypes.AgentDashboardTools

export type LoanAgentDashboardToolsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanAgentDashboardToolsGet = makeData<
  LoanAgentDashboardToolsGetResponse,
  LoanAgentDashboardToolsGetKey,
  LoanAgentDashboardToolsGetVariables
>({
  method: 'get',
  name: 'loanAgentDashboardToolsGet',
  summary: 'Get agent dashboard tools',
  path: '/people/{personId}/loans/{loanId}/agent-dashboard-tools',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'agent-dashboard-tools', query],
})

export type LoanCustomPaymentPlanGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'custom-payment-plans',
  unknown,
]

export type LoanCustomPaymentPlanGetResponse = ResponseTypes.LoanCustomPaymentPlans

export type LoanCustomPaymentPlanGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    status?: 'active' | 'inactive' | 'canceled'
  }
}

export const loanCustomPaymentPlanGet = makeData<
  LoanCustomPaymentPlanGetResponse,
  LoanCustomPaymentPlanGetKey,
  LoanCustomPaymentPlanGetVariables
>({
  method: 'get',
  name: 'loanCustomPaymentPlanGet',
  summary: 'Get payment plans',
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'custom-payment-plans', query],
})

export type LoanCustomPaymentPlanCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'custom-payment-plans',
  unknown,
]

export type LoanCustomPaymentPlanCreateResponse = ResponseTypes.LoanCustomPaymentPlan

export type LoanCustomPaymentPlanCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanCustomPaymentPlan
  query?: void
}

export const loanCustomPaymentPlanCreate = makeData<
  LoanCustomPaymentPlanCreateResponse,
  LoanCustomPaymentPlanCreateKey,
  LoanCustomPaymentPlanCreateVariables
>({
  method: 'post',
  name: 'loanCustomPaymentPlanCreate',
  summary: 'Create payment plan',
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'custom-payment-plans', query],
})

export type LoanCustomPaymentPlanGetByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'custom-payment-plans',
  string | undefined,
  unknown,
]

export type LoanCustomPaymentPlanGetByIdResponse = ResponseTypes.LoanCustomPaymentPlan

export type LoanCustomPaymentPlanGetByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  paymentPlanId: string | undefined
  body?: void
  query?: void
}

export const loanCustomPaymentPlanGetById = makeData<
  LoanCustomPaymentPlanGetByIdResponse,
  LoanCustomPaymentPlanGetByIdKey,
  LoanCustomPaymentPlanGetByIdVariables
>({
  method: 'get',
  name: 'loanCustomPaymentPlanGetById',
  summary: 'Get payment plan by id',
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans/{paymentPlanId}',
  queryKey: ({ query, personId, loanId, paymentPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    paymentPlanId,
    query,
  ],
})

export type LoanCustomPaymentPlanCancelKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'custom-payment-plans',
  string | undefined,
  'cancel',
  unknown,
]

export type LoanCustomPaymentPlanCancelResponse = void

export type LoanCustomPaymentPlanCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  paymentPlanId: string | undefined
  body?: RequestBodyTypes.LoanCustomPaymentPlanCancel
  query?: void
}

export const loanCustomPaymentPlanCancel = makeData<
  LoanCustomPaymentPlanCancelResponse,
  LoanCustomPaymentPlanCancelKey,
  LoanCustomPaymentPlanCancelVariables
>({
  method: 'post',
  name: 'loanCustomPaymentPlanCancel',
  summary: 'Cancel payment plan',
  path: '/people/{personId}/loans/{loanId}/custom-payment-plans/{paymentPlanId}/cancel',
  queryKey: ({ query, personId, loanId, paymentPlanId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'custom-payment-plans',
    paymentPlanId,
    'cancel',
    query,
  ],
})

export type LoanChangeTermsKey = ['people', string | undefined, 'loans', string | undefined, 'payment-plan', unknown]

export type LoanChangeTermsResponse = ResponseTypes.ChangeSchedule

export type LoanChangeTermsVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanChangeLoanTerms
  query?: void
}

export const loanChangeTerms = makeData<LoanChangeTermsResponse, LoanChangeTermsKey, LoanChangeTermsVariables>({
  method: 'post',
  name: 'loanChangeTerms',
  summary: 'Change loan terms',
  path: '/people/{personId}/loans/{loanId}/payment-plan',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'payment-plan', query],
})

export type LoanChangeTermsScraKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'payment-plan-scra',
  unknown,
]

export type LoanChangeTermsScraResponse = ResponseTypes.ChangeScheduleSCRA

export type LoanChangeTermsScraVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanChangeLoanTermsSCRA
  query?: {
    sync?: boolean
  }
}

export const loanChangeTermsScra = makeData<
  LoanChangeTermsScraResponse,
  LoanChangeTermsScraKey,
  LoanChangeTermsScraVariables
>({
  method: 'post',
  name: 'loanChangeTermsScra',
  summary: 'Change loan terms SCRA',
  path: '/people/{personId}/loans/{loanId}/payment-plan-scra',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'payment-plan-scra', query],
})

export type LoanDueDateDeferKey = ['people', string | undefined, 'loans', string | undefined, 'defer-due-date', unknown]

export type LoanDueDateDeferResponse = ResponseTypes.LoanDeferDueDate

export type LoanDueDateDeferVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanDeferDueDate
  query?: void
}

export const loanDueDateDefer = makeData<LoanDueDateDeferResponse, LoanDueDateDeferKey, LoanDueDateDeferVariables>({
  method: 'post',
  name: 'loanDueDateDefer',
  summary: 'Defer single due date',
  path: '/people/{personId}/loans/{loanId}/defer-due-date',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'defer-due-date', query],
})

export type LoanScheduleDeferKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'defer-schedule',
  unknown,
]

export type LoanScheduleDeferResponse = ResponseTypes.LoanDeferSchedule

export type LoanScheduleDeferVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanDeferSchedule
  query?: void
}

export const loanScheduleDefer = makeData<LoanScheduleDeferResponse, LoanScheduleDeferKey, LoanScheduleDeferVariables>({
  method: 'post',
  name: 'loanScheduleDefer',
  summary: 'Defer schedule',
  path: '/people/{personId}/loans/{loanId}/defer-schedule',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'defer-schedule', query],
})

export type LoanRefreshKey = ['people', string | undefined, 'loans', string | undefined, 'refresh', unknown]

export type LoanRefreshResponse = void

export type LoanRefreshVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanRefresh = makeData<LoanRefreshResponse, LoanRefreshKey, LoanRefreshVariables>({
  method: 'post',
  name: 'loanRefresh',
  summary: 'Refresh loan',
  path: '/people/{personId}/loans/{loanId}/refresh',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'refresh', query],
})

export type LoanRatesGetKey = ['people', string | undefined, 'loans', string | undefined, 'rates', unknown]

export type LoanRatesGetResponse = ResponseTypes.LoanRatesResponse

export type LoanRatesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanRatesGet = makeData<LoanRatesGetResponse, LoanRatesGetKey, LoanRatesGetVariables>({
  method: 'get',
  name: 'loanRatesGet',
  summary: 'Get loan rates',
  path: '/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'rates', query],
})

export type LoanRatesAddKey = ['people', string | undefined, 'loans', string | undefined, 'rates', unknown]

export type LoanRatesAddResponse = ResponseTypes.CreateOrUpdateInterestRatesResponse

export type LoanRatesAddVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.LoanRateAdd
  query?: void
}

export const loanRatesAdd = makeData<LoanRatesAddResponse, LoanRatesAddKey, LoanRatesAddVariables>({
  method: 'post',
  name: 'loanRatesAdd',
  summary: 'Add loan rate',
  path: '/people/{personId}/loans/{loanId}/rates',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'rates', query],
})

export type LoanEffectiveRatesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'effective-rates',
  unknown,
]

export type LoanEffectiveRatesGetResponse = ResponseTypes.LoanEffectiveRatesResponse

export type LoanEffectiveRatesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    fromDate?: string
    toDate?: string
  }
}

export const loanEffectiveRatesGet = makeData<
  LoanEffectiveRatesGetResponse,
  LoanEffectiveRatesGetKey,
  LoanEffectiveRatesGetVariables
>({
  method: 'get',
  name: 'loanEffectiveRatesGet',
  summary: 'Get loan effective rates',
  path: '/people/{personId}/loans/{loanId}/effective-rates',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'effective-rates', query],
})

export type LoanRatesUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'rates',
  string | undefined,
  unknown,
]

export type LoanRatesUpdateResponse = ResponseTypes.CreateOrUpdateInterestRatesResponse

export type LoanRatesUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  rateId: string | undefined
  body?: RequestBodyTypes.LoanRateUpdate
  query?: void
}

export const loanRatesUpdate = makeData<LoanRatesUpdateResponse, LoanRatesUpdateKey, LoanRatesUpdateVariables>({
  method: 'put',
  name: 'loanRatesUpdate',
  summary: 'Update loan rate',
  path: '/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ query, personId, loanId, rateId }) => ['people', personId, 'loans', loanId, 'rates', rateId, query],
})

export type LoanRatesDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'rates',
  string | undefined,
  unknown,
]

export type LoanRatesDeleteResponse = void

export type LoanRatesDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  rateId: string | undefined
  body?: void
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const loanRatesDelete = makeData<LoanRatesDeleteResponse, LoanRatesDeleteKey, LoanRatesDeleteVariables>({
  method: 'delete',
  name: 'loanRatesDelete',
  summary: 'Remove loan rate',
  path: '/people/{personId}/loans/{loanId}/rates/{rateId}',
  queryKey: ({ query, personId, loanId, rateId }) => ['people', personId, 'loans', loanId, 'rates', rateId, query],
})

export type DrawRatesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'rates',
  unknown,
]

export type DrawRatesGetResponse = ResponseTypes.LoanRatesResponse

export type DrawRatesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const drawRatesGet = makeData<DrawRatesGetResponse, DrawRatesGetKey, DrawRatesGetVariables>({
  method: 'get',
  name: 'drawRatesGet',
  summary: 'Get draw rates',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    query,
  ],
})

export type DrawRatesAddKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'rates',
  unknown,
]

export type DrawRatesAddResponse = ResponseTypes.CreateOrUpdateInterestRatesResponse

export type DrawRatesAddVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: RequestBodyTypes.LoanRateAdd
  query?: void
}

export const drawRatesAdd = makeData<DrawRatesAddResponse, DrawRatesAddKey, DrawRatesAddVariables>({
  method: 'post',
  name: 'drawRatesAdd',
  summary: 'Add draw rate',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    query,
  ],
})

export type DrawEffectiveRatesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'effective-rates',
  unknown,
]

export type DrawEffectiveRatesGetResponse = ResponseTypes.LoanEffectiveRatesResponse

export type DrawEffectiveRatesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    fromDate?: string
    toDate?: string
  }
}

export const drawEffectiveRatesGet = makeData<
  DrawEffectiveRatesGetResponse,
  DrawEffectiveRatesGetKey,
  DrawEffectiveRatesGetVariables
>({
  method: 'get',
  name: 'drawEffectiveRatesGet',
  summary: 'Get draw effective rates',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/effective-rates',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'effective-rates',
    query,
  ],
})

export type DrawRatesUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'rates',
  string | undefined,
  unknown,
]

export type DrawRatesUpdateResponse = ResponseTypes.CreateOrUpdateInterestRatesResponse

export type DrawRatesUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  rateId: string | undefined
  body?: RequestBodyTypes.LoanRateUpdate
  query?: void
}

export const drawRatesUpdate = makeData<DrawRatesUpdateResponse, DrawRatesUpdateKey, DrawRatesUpdateVariables>({
  method: 'put',
  name: 'drawRatesUpdate',
  summary: 'Update draw rate',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates/{rateId}',
  queryKey: ({ query, personId, loanId, drawId, rateId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    rateId,
    query,
  ],
})

export type DrawRatesDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'rates',
  string | undefined,
  unknown,
]

export type DrawRatesDeleteResponse = void

export type DrawRatesDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  rateId: string | undefined
  body?: void
  query?: {
    caseId?: SchemaTypes.AnyId
  }
}

export const drawRatesDelete = makeData<DrawRatesDeleteResponse, DrawRatesDeleteKey, DrawRatesDeleteVariables>({
  method: 'delete',
  name: 'drawRatesDelete',
  summary: 'Remove draw rate',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/rates/{rateId}',
  queryKey: ({ query, personId, loanId, drawId, rateId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'rates',
    rateId,
    query,
  ],
})

export type LoanExpectedPaymentsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  unknown,
]

export type LoanExpectedPaymentsGetResponse = ResponseTypes.ExpectedPayments

export type LoanExpectedPaymentsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanExpectedPaymentsGet = makeData<
  LoanExpectedPaymentsGetResponse,
  LoanExpectedPaymentsGetKey,
  LoanExpectedPaymentsGetVariables
>({
  method: 'get',
  name: 'loanExpectedPaymentsGet',
  summary: 'Get loan expected payments',
  path: '/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'expected-payments', query],
})

export type LoanChangeDueDatesSchedulePostKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'expected-payments',
  unknown,
]

export type LoanChangeDueDatesSchedulePostResponse = ResponseTypes.ChangeSchedule

export type LoanChangeDueDatesSchedulePostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.DueDatesPost
  query?: void
}

export const loanChangeDueDatesSchedulePost = makeData<
  LoanChangeDueDatesSchedulePostResponse,
  LoanChangeDueDatesSchedulePostKey,
  LoanChangeDueDatesSchedulePostVariables
>({
  method: 'post',
  name: 'loanChangeDueDatesSchedulePost',
  summary: 'Change due dates schedule',
  path: '/people/{personId}/loans/{loanId}/expected-payments',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'expected-payments', query],
})

export type DrawExpectedPaymentsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'expected-payments',
  unknown,
]

export type DrawExpectedPaymentsGetResponse = ResponseTypes.ExpectedPayments

export type DrawExpectedPaymentsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const drawExpectedPaymentsGet = makeData<
  DrawExpectedPaymentsGetResponse,
  DrawExpectedPaymentsGetKey,
  DrawExpectedPaymentsGetVariables
>({
  method: 'get',
  name: 'drawExpectedPaymentsGet',
  summary: 'Get draw expected payments',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/expected-payments',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'expected-payments',
    query,
  ],
})

export type LoanTypeIndexRateGetAllKey = ['loan-types', string | undefined, 'index-rates', unknown]

export type LoanTypeIndexRateGetAllResponse = ResponseTypes.IndexRatePaging

export type LoanTypeIndexRateGetAllVariables = {
  loanTypeId: string | undefined
  body?: void
  query?: {
    fromDate?: string
    toDate?: string
  }
}

export const loanTypeIndexRateGetAll = makeData<
  LoanTypeIndexRateGetAllResponse,
  LoanTypeIndexRateGetAllKey,
  LoanTypeIndexRateGetAllVariables
>({
  method: 'get',
  name: 'loanTypeIndexRateGetAll',
  summary: 'Get index interest rates',
  path: '/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ query, loanTypeId }) => ['loan-types', loanTypeId, 'index-rates', query],
})

export type LoanTypeIndexRateAddKey = ['loan-types', string | undefined, 'index-rates', unknown]

export type LoanTypeIndexRateAddResponse = ResponseTypes.IndexRateResponse

export type LoanTypeIndexRateAddVariables = {
  loanTypeId: string | undefined
  body?: RequestBodyTypes.IndexRateAdd
  query?: void
}

export const loanTypeIndexRateAdd = makeData<
  LoanTypeIndexRateAddResponse,
  LoanTypeIndexRateAddKey,
  LoanTypeIndexRateAddVariables
>({
  method: 'post',
  name: 'loanTypeIndexRateAdd',
  summary: 'Add index interest rate',
  path: '/loan-types/{loanTypeId}/index-rates',
  queryKey: ({ query, loanTypeId }) => ['loan-types', loanTypeId, 'index-rates', query],
})

export type LoanTypeIndexRateGetKey = ['loan-types', string | undefined, 'index-rates', string | undefined, unknown]

export type LoanTypeIndexRateGetResponse = ResponseTypes.IndexRateResponse

export type LoanTypeIndexRateGetVariables = {
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: void
  query?: void
}

export const loanTypeIndexRateGet = makeData<
  LoanTypeIndexRateGetResponse,
  LoanTypeIndexRateGetKey,
  LoanTypeIndexRateGetVariables
>({
  method: 'get',
  name: 'loanTypeIndexRateGet',
  summary: 'Get index interest rate by ID',
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId, query],
})

export type LoanTypeIndexRateUpdateKey = ['loan-types', string | undefined, 'index-rates', string | undefined, unknown]

export type LoanTypeIndexRateUpdateResponse = ResponseTypes.IndexRateResponse

export type LoanTypeIndexRateUpdateVariables = {
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: RequestBodyTypes.IndexRateUpdate
  query?: void
}

export const loanTypeIndexRateUpdate = makeData<
  LoanTypeIndexRateUpdateResponse,
  LoanTypeIndexRateUpdateKey,
  LoanTypeIndexRateUpdateVariables
>({
  method: 'put',
  name: 'loanTypeIndexRateUpdate',
  summary: 'Update index interest rate',
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId, query],
})

export type LoanTypeIndexRateDeleteKey = ['loan-types', string | undefined, 'index-rates', string | undefined, unknown]

export type LoanTypeIndexRateDeleteResponse = void

export type LoanTypeIndexRateDeleteVariables = {
  loanTypeId: string | undefined
  indexRateId: string | undefined
  body?: void
  query?: void
}

export const loanTypeIndexRateDelete = makeData<
  LoanTypeIndexRateDeleteResponse,
  LoanTypeIndexRateDeleteKey,
  LoanTypeIndexRateDeleteVariables
>({
  method: 'delete',
  name: 'loanTypeIndexRateDelete',
  summary: 'Cancel index interest rate',
  path: '/loan-types/{loanTypeId}/index-rates/{indexRateId}',
  queryKey: ({ query, loanTypeId, indexRateId }) => ['loan-types', loanTypeId, 'index-rates', indexRateId, query],
})

export type ObligationsGetAllKey = ['people', string | undefined, 'loans', string | undefined, 'obligations', unknown]

export type ObligationsGetAllResponse = ResponseTypes.LOCObligations

export type ObligationsGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    periodId?: string
    status?: 'unfulfilled' | 'overdue'
    fromDate?: string
    toDate?: string
    dueDate?: string
    isOpen?: boolean
  }
}

export const obligationsGetAll = makeData<ObligationsGetAllResponse, ObligationsGetAllKey, ObligationsGetAllVariables>({
  method: 'get',
  name: 'obligationsGetAll',
  summary: 'Get loan obligations',
  path: '/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations', query],
})

export type ObligationCreateKey = ['people', string | undefined, 'loans', string | undefined, 'obligations', unknown]

export type ObligationCreateResponse = ResponseTypes.Obligation

export type ObligationCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.ObligationPost
  query?: void
}

export const obligationCreate = makeData<ObligationCreateResponse, ObligationCreateKey, ObligationCreateVariables>({
  method: 'post',
  name: 'obligationCreate',
  summary: 'Create obligation',
  path: '/people/{personId}/loans/{loanId}/obligations',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'obligations', query],
})

export type DrawObligationsGetAllKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'obligations',
  unknown,
]

export type DrawObligationsGetAllResponse = ResponseTypes.Obligations

export type DrawObligationsGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    periodId?: string
    status?: 'unfulfilled' | 'overdue'
    fromDate?: string
    toDate?: string
    dueDate?: string
    isOpen?: boolean
  }
}

export const drawObligationsGetAll = makeData<
  DrawObligationsGetAllResponse,
  DrawObligationsGetAllKey,
  DrawObligationsGetAllVariables
>({
  method: 'get',
  name: 'drawObligationsGetAll',
  summary: 'Get draw obligations',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/obligations',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'obligations',
    query,
  ],
})

export type ObligationGetByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  string | undefined,
  unknown,
]

export type ObligationGetByIdResponse = ResponseTypes.LOCObligation

export type ObligationGetByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  obligationId: string | undefined
  body?: void
  query?: void
}

export const obligationGetById = makeData<ObligationGetByIdResponse, ObligationGetByIdKey, ObligationGetByIdVariables>({
  method: 'get',
  name: 'obligationGetById',
  summary: 'Get loan obligation by ID',
  path: '/people/{personId}/loans/{loanId}/obligations/{obligationId}',
  queryKey: ({ query, personId, loanId, obligationId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    obligationId,
    query,
  ],
})

export type ObligationUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'obligations',
  string | undefined,
  unknown,
]

export type ObligationUpdateResponse = ResponseTypes.Obligation

export type ObligationUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  obligationId: string | undefined
  body?: RequestBodyTypes.ObligationPut
  query?: void
}

export const obligationUpdate = makeData<ObligationUpdateResponse, ObligationUpdateKey, ObligationUpdateVariables>({
  method: 'put',
  name: 'obligationUpdate',
  summary: 'Update obligation',
  path: '/people/{personId}/loans/{loanId}/obligations/{obligationId}',
  queryKey: ({ query, personId, loanId, obligationId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    obligationId,
    query,
  ],
})

export type DrawObligationGetByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'obligations',
  string | undefined,
  unknown,
]

export type DrawObligationGetByIdResponse = ResponseTypes.Obligation

export type DrawObligationGetByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  obligationId: string | undefined
  body?: void
  query?: void
}

export const drawObligationGetById = makeData<
  DrawObligationGetByIdResponse,
  DrawObligationGetByIdKey,
  DrawObligationGetByIdVariables
>({
  method: 'get',
  name: 'drawObligationGetById',
  summary: 'Get draw obligation by ID',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/obligations/{obligationId}',
  queryKey: ({ query, personId, loanId, drawId, obligationId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'obligations',
    obligationId,
    query,
  ],
})

export type LoanDrawsGetKey = ['people', string | undefined, 'loans', string | undefined, 'draws', unknown]

export type LoanDrawsGetResponse = ResponseTypes.DrawsPaging

export type LoanDrawsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    statuses?: Array<
      | 'all'
      | 'active'
      | 'pending'
      | 'originated'
      | 'declined'
      | 'frozen'
      | 'accelerated'
      | 'chargedOff'
      | 'paidOff'
      | 'canceled'
    >
    isClosed?: boolean
    isAmortized?: boolean
    startedBefore?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const loanDrawsGet = makeData<LoanDrawsGetResponse, LoanDrawsGetKey, LoanDrawsGetVariables>({
  method: 'get',
  name: 'loanDrawsGet',
  summary: 'Get draws',
  path: '/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'draws', query],
})

export type LoanDrawsPostKey = ['people', string | undefined, 'loans', string | undefined, 'draws', unknown]

export type LoanDrawsPostResponse = ResponseTypes.Draw

export type LoanDrawsPostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.DrawsPost
  query?: void
}

export const loanDrawsPost = makeData<LoanDrawsPostResponse, LoanDrawsPostKey, LoanDrawsPostVariables>({
  method: 'post',
  name: 'loanDrawsPost',
  summary: 'Create draw',
  path: '/people/{personId}/loans/{loanId}/draws',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'draws', query],
})

export type LoanDrawGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  unknown,
]

export type LoanDrawGetResponse = ResponseTypes.Draw

export type LoanDrawGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const loanDrawGet = makeData<LoanDrawGetResponse, LoanDrawGetKey, LoanDrawGetVariables>({
  method: 'get',
  name: 'loanDrawGet',
  summary: 'Get draw by ID',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}',
  queryKey: ({ query, personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, query],
})

export type DrawUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  unknown,
]

export type DrawUpdateResponse = ResponseTypes.Draw

export type DrawUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: RequestBodyTypes.DrawUpdate
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
    force?: boolean
  }
}

export const drawUpdate = makeData<DrawUpdateResponse, DrawUpdateKey, DrawUpdateVariables>({
  method: 'put',
  name: 'drawUpdate',
  summary: 'Update draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}',
  queryKey: ({ query, personId, loanId, drawId }) => ['people', personId, 'loans', loanId, 'draws', drawId, query],
})

export type DrawActivateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'activate',
  unknown,
]

export type DrawActivateResponse = ResponseTypes.DrawActivate

export type DrawActivateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    sync?: boolean
  }
}

export const drawActivate = makeData<DrawActivateResponse, DrawActivateKey, DrawActivateVariables>({
  method: 'post',
  name: 'drawActivate',
  summary: 'Activate draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/activate',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'activate',
    query,
  ],
})

export type PreviewDrawAmortizeKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'amortize-preview',
  unknown,
]

export type PreviewDrawAmortizeResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanSchedule
}

export type PreviewDrawAmortizeVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: {
    /** The total amount of the purchases for preview. */
    purchaseAmount: number
    /**
     * The new duration of the draw as a number of `paymentFrequency` of the line of credit.
     *
     * If this endpoint is called when the draw has already been active for some number of periods, the `duration` parameter
     * will generate `duration` _additional_ periods from the time at which the endpoint is called. For example, if a draw has
     * duration 10, and it is amortized again in period 5 with `duration=10`, the result will be a draw with a total duration of 15.
     *
     * The caller needs to pass one of the values only, `duration` or `recurringPaymentAmount`.
     */
    duration?: number
    /**
     * The recurring payment amount. The system will calculate the needed duration. Pass one of the values only,
     * `duration` or `recurringPaymentAmount`.
     */
    recurringAmount?: number
    /** The start date of the draw. The system will assume today if not specified. */
    startDate?: string
    /** The draw fee. */
    drawFee?: number
    /**
     * The service fee amount that will be charged recurrently. Only required if the service fee `chargeLogic` is `[loanPeriod, everyOtherLoanPeriod, calendarPeriod]` and
     * `amountLogic` is configured as `type=passedAtOrigination`. In all other cases, the system will calculate the fee based on pre-configured logic.
     */
    serviceFeeAmount?: number
    /**
     * The maximum amount of the service fee that can be charged over the life of the loan or draw. Only required if the service fee `chargeLogic` is
     * `[loanPeriod, everyOtherLoanPeriod, calendarPeriod]` and `capLogic` is configured as `type=capPassedAtOrigination`. In all other cases, the system will calculate
     * the fee based on pre-configured logic.
     */
    serviceFeeCapAmount?: number
  }
  query?: void
}

export const previewDrawAmortize = makeData<
  PreviewDrawAmortizeResponse,
  PreviewDrawAmortizeKey,
  PreviewDrawAmortizeVariables
>({
  method: 'post',
  name: 'previewDrawAmortize',
  summary: 'Preview draw amortization',
  path: '/people/{personId}/loans/{loanId}/amortize-preview',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'amortize-preview', query],
})

export type LoanDrawAmortizeKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'amortize',
  unknown,
]

export type LoanDrawAmortizeResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanSchedule
}

export type LoanDrawAmortizeVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: {
    /** In `previewMode=true`, the draw doesn't change, and no changes are saved to the database. */
    previewMode?: boolean
    /**
     * The new duration of the draw as a number of `paymentFrequency` of the line of credit. Only applicable if the draw
     * needs to be amortized. The caller needs to pass one of the values only, `duration` or `recurringPaymentAmount`.
     *
     * If this endpoint is called when the draw has already been active for some number of periods, the `duration` parameter
     * will generate `duration` _additional_ periods from the time at which the endpoint is called. For example, if a draw has
     * duration 10, and it is amortized again in period 5 with `duration=10`, the result will be a draw with a total duration of 15.
     */
    duration?: number
    /**
     * The recurring payment amount. The system will calculate the needed duration. Pass one of the values only,
     * `duration` or `recurringPaymentAmount`. This field is only valid when the amortizationMethod on the loan type is
     * `equalPayments`.
     */
    recurringAmount?: number
  }
  query?: void
}

export const loanDrawAmortize = makeData<LoanDrawAmortizeResponse, LoanDrawAmortizeKey, LoanDrawAmortizeVariables>({
  method: 'post',
  name: 'loanDrawAmortize',
  summary: 'Amortize draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/amortize',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'amortize',
    query,
  ],
})

export type DrawPurchasesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  unknown,
]

export type DrawPurchasesGetResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.Purchase>
}

export type DrawPurchasesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    types?: Array<'regular' | 'refund' | 'cashback'>
    statuses?: Array<'authorized' | 'pending' | 'settled' | 'canceled' | 'declined' | 'disputed'>
    fromEffectiveAt?: string
    toEffectiveAt?: string
    fromCreatedAt?: string
    toCreatedAt?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<'effectiveAt' | '+effectiveAt' | '-effectiveAt' | 'createdAt' | '+createdAt' | '-createdAt'>
  }
}

export const drawPurchasesGet = makeData<DrawPurchasesGetResponse, DrawPurchasesGetKey, DrawPurchasesGetVariables>({
  method: 'get',
  name: 'drawPurchasesGet',
  summary: 'Get draw purchases',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    query,
  ],
})

export type DrawPurchasesCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  unknown,
]

export type DrawPurchasesCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type DrawPurchasesCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: SchemaTypes.CreatePurchase & SchemaTypes.PurchaseExtraInput
  query?: {
    force?: boolean
    sync?: boolean
  }
}

export const drawPurchasesCreate = makeData<
  DrawPurchasesCreateResponse,
  DrawPurchasesCreateKey,
  DrawPurchasesCreateVariables
>({
  method: 'post',
  name: 'drawPurchasesCreate',
  summary: 'Create purchase',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    query,
  ],
})

export type DrawPurchasesTimelineGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases-timeline',
  unknown,
]

export type DrawPurchasesTimelineGetResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.Purchase>
}

export type DrawPurchasesTimelineGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    types?: Array<'regular' | 'refund' | 'cashback'>
    statuses?: Array<'authorized' | 'pending' | 'settled' | 'canceled' | 'declined' | 'disputed'>
    fromEffectiveAt?: string
    toEffectiveAt?: string
    fromCreatedAt?: string
    toCreatedAt?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    timelineOrdering?: Array<'effectiveAt' | 'authorizedAt' | 'createdAt'>
    sortOrder?: 'asc' | 'desc'
  }
}

export const drawPurchasesTimelineGet = makeData<
  DrawPurchasesTimelineGetResponse,
  DrawPurchasesTimelineGetKey,
  DrawPurchasesTimelineGetVariables
>({
  method: 'get',
  name: 'drawPurchasesTimelineGet',
  summary: 'Get draw purchases timeline',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases-timeline',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases-timeline',
    query,
  ],
})

export type DrawPurchaseGetOneKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  unknown,
]

export type DrawPurchaseGetOneResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type DrawPurchaseGetOneVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: void
  query?: void
}

export const drawPurchaseGetOne = makeData<
  DrawPurchaseGetOneResponse,
  DrawPurchaseGetOneKey,
  DrawPurchaseGetOneVariables
>({
  method: 'get',
  name: 'drawPurchaseGetOne',
  summary: 'Get purchase by ID',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ query, personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    query,
  ],
})

export type DrawPurchaseUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  unknown,
]

export type DrawPurchaseUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Purchase
}

export type DrawPurchaseUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: RequestBodyTypes.PurchaseUpdateInternal
  query?: {
    force?: boolean
    sync?: boolean
  }
}

export const drawPurchaseUpdate = makeData<
  DrawPurchaseUpdateResponse,
  DrawPurchaseUpdateKey,
  DrawPurchaseUpdateVariables
>({
  method: 'put',
  name: 'drawPurchaseUpdate',
  summary: 'Update purchase',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}',
  queryKey: ({ query, personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    query,
  ],
})

export type LoanPurchasesGetKey = ['people', string | undefined, 'loans', string | undefined, 'purchases', unknown]

export type LoanPurchasesGetResponse = ResponseTypes.PurchasesPaging

export type LoanPurchasesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    types?: Array<'regular' | 'refund' | 'cashback'>
    statuses?: Array<'authorized' | 'pending' | 'settled' | 'canceled' | 'declined' | 'disputed'>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    fromEffectiveAt?: string
    toEffectiveAt?: string
    fromCreatedAt?: string
    toCreatedAt?: string
    sortBy?: Array<'effectiveAt' | '+effectiveAt' | '-effectiveAt' | 'createdAt' | '+createdAt' | '-createdAt'>
  }
}

export const loanPurchasesGet = makeData<LoanPurchasesGetResponse, LoanPurchasesGetKey, LoanPurchasesGetVariables>({
  method: 'get',
  name: 'loanPurchasesGet',
  summary: 'Get line of credit purchases',
  path: '/people/{personId}/loans/{loanId}/purchases',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'purchases', query],
})

export type LoanPurchasesTimelineGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'purchases-timeline',
  unknown,
]

export type LoanPurchasesTimelineGetResponse = ResponseTypes.PurchasesPaging

export type LoanPurchasesTimelineGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    types?: Array<'regular' | 'refund' | 'cashback'>
    statuses?: Array<'authorized' | 'pending' | 'settled' | 'canceled' | 'declined' | 'disputed'>
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    fromEffectiveAt?: string
    toEffectiveAt?: string
    fromCreatedAt?: string
    toCreatedAt?: string
    timelineOrdering?: Array<'effectiveAt' | 'authorizedAt' | 'createdAt'>
    sortOrder?: 'asc' | 'desc'
  }
}

export const loanPurchasesTimelineGet = makeData<
  LoanPurchasesTimelineGetResponse,
  LoanPurchasesTimelineGetKey,
  LoanPurchasesTimelineGetVariables
>({
  method: 'get',
  name: 'loanPurchasesTimelineGet',
  summary: 'Get line of credit purchases timeline',
  path: '/people/{personId}/loans/{loanId}/purchases-timeline',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'purchases-timeline', query],
})

export type LoanTransactionsGetAllKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  unknown,
]

export type LoanTransactionsGetAllResponse = ResponseTypes.TransactionCollection

export type LoanTransactionsGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    status?: Array<SchemaTypes.TransactionStatusReadOnly>
    paymentInstrumentId?: SchemaTypes.AnyId
    isExternal?: boolean
    isVirtual?: boolean
    transactionType?: 'payment' | 'serviceCredit'
    serviceCreditTypes?: Array<SchemaTypes.serviceCreditTypes>
    toEffectiveDate?: string
    fromEffectiveDate?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<
      | 'appliedAt'
      | '+appliedAt'
      | '-appliedAt'
      | 'createdAt'
      | '+createdAt'
      | '-createdAt'
      | 'effectiveDate'
      | '+effectiveDate'
      | '-effectiveDate'
    >
  }
}

export const loanTransactionsGetAll = makeData<
  LoanTransactionsGetAllResponse,
  LoanTransactionsGetAllKey,
  LoanTransactionsGetAllVariables
>({
  method: 'get',
  name: 'loanTransactionsGetAll',
  summary: 'Get transactions',
  path: '/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'transactions', query],
})

export type TransactionsCreateKey = ['people', string | undefined, 'loans', string | undefined, 'transactions', unknown]

export type TransactionsCreateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type TransactionsCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.CreateTransaction
  query?: {
    sync?: boolean
  }
}

export const transactionsCreate = makeData<
  TransactionsCreateResponse,
  TransactionsCreateKey,
  TransactionsCreateVariables
>({
  method: 'post',
  name: 'transactionsCreate',
  summary: 'Create transaction',
  path: '/people/{personId}/loans/{loanId}/transactions',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'transactions', query],
})

export type TransactionsGetByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  unknown,
]

export type TransactionsGetByIdResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type TransactionsGetByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const transactionsGetById = makeData<
  TransactionsGetByIdResponse,
  TransactionsGetByIdKey,
  TransactionsGetByIdVariables
>({
  method: 'get',
  name: 'transactionsGetById',
  summary: 'Get transaction by ID',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    query,
  ],
})

export type TransactionsUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  unknown,
]

export type TransactionsUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.Transaction
}

export type TransactionsUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: SchemaTypes.TransactionUpdateExternal
  query?: {
    sync?: boolean
  }
}

export const transactionsUpdate = makeData<
  TransactionsUpdateResponse,
  TransactionsUpdateKey,
  TransactionsUpdateVariables
>({
  method: 'put',
  name: 'transactionsUpdate',
  summary: 'Update transaction',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    query,
  ],
})

export type TransactionCancelKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'cancel',
  unknown,
]

export type TransactionCancelResponse = void

export type TransactionCancelVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: {
    cancelReason?: SchemaTypes.TransactionCancelReason
  }
}

export const transactionCancel = makeData<TransactionCancelResponse, TransactionCancelKey, TransactionCancelVariables>({
  method: 'post',
  name: 'transactionCancel',
  summary: 'Cancel transaction',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/cancel',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'cancel',
    query,
  ],
})

export type TransactionReverseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'reverse',
  unknown,
]

export type TransactionReverseResponse = ResponseTypes.Transaction

export type TransactionReverseVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: RequestBodyTypes.ReversalPost
  query?: {
    sync?: boolean
  }
}

export const transactionReverse = makeData<
  TransactionReverseResponse,
  TransactionReverseKey,
  TransactionReverseVariables
>({
  method: 'post',
  name: 'transactionReverse',
  summary: 'Reverse transaction',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/reverse',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'reverse',
    query,
  ],
})

export type GetChargebacksKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  unknown,
]

export type GetChargebacksResponse = ResponseTypes.Chargebacks

export type GetChargebacksVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: void
  query?: void
}

export const getChargebacks = makeData<GetChargebacksResponse, GetChargebacksKey, GetChargebacksVariables>({
  method: 'get',
  name: 'getChargebacks',
  summary: 'Get transaction chargebacks',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    query,
  ],
})

export type TransactionChargebackKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  unknown,
]

export type TransactionChargebackResponse = ResponseTypes.Chargeback

export type TransactionChargebackVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: RequestBodyTypes.ChargebackPost
  query?: {
    sync?: boolean
  }
}

export const transactionChargeback = makeData<
  TransactionChargebackResponse,
  TransactionChargebackKey,
  TransactionChargebackVariables
>({
  method: 'post',
  name: 'transactionChargeback',
  summary: 'Create transaction chargeback',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    query,
  ],
})

export type GetChargebackByIdKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  string | undefined,
  unknown,
]

export type GetChargebackByIdResponse = ResponseTypes.Chargeback

export type GetChargebackByIdVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  chargebackId: string | undefined
  body?: void
  query?: void
}

export const getChargebackById = makeData<GetChargebackByIdResponse, GetChargebackByIdKey, GetChargebackByIdVariables>({
  method: 'get',
  name: 'getChargebackById',
  summary: 'Get chargeback by ID',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ query, personId, loanId, transactionId, chargebackId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
    query,
  ],
})

export type UpdateChargebackKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'chargebacks',
  string | undefined,
  unknown,
]

export type UpdateChargebackResponse = ResponseTypes.Chargeback

export type UpdateChargebackVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  chargebackId: string | undefined
  body?: RequestBodyTypes.ChargebackPut
  query?: {
    sync?: boolean
  }
}

export const updateChargeback = makeData<UpdateChargebackResponse, UpdateChargebackKey, UpdateChargebackVariables>({
  method: 'put',
  name: 'updateChargeback',
  summary: 'Update chargeback',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/chargebacks/{chargebackId}',
  queryKey: ({ query, personId, loanId, transactionId, chargebackId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
    query,
  ],
})

export type TransactionBackdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'transactions',
  string | undefined,
  'backdate',
  unknown,
]

export type TransactionBackdateResponse = void

export type TransactionBackdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  transactionId: string | undefined
  body?: {
    /** New effective date for the transaction. */
    effectiveDate: string
    effectiveTimeOfDay?: SchemaTypes.TimeOfDay /**
     * The effective time of day on effectiveDate in the product timezone. If the time of day is not provided, the Peach system will set the time of
     * day based on the following logic:
     *
     * - If backdated to the loan activation date, the system will set the timestamp to the activation timestamp plus a few seconds.
     * - If backdated to a loan non-activation date, the system will set the timestamp to a few minutes after the loan accrued interest on the effectiveDate.
     */ & {}
    /** The identifier of an existing case. */
    caseId?: string
  }
  query?: {
    sync?: boolean
  }
}

export const transactionBackdate = makeData<
  TransactionBackdateResponse,
  TransactionBackdateKey,
  TransactionBackdateVariables
>({
  method: 'post',
  name: 'transactionBackdate',
  summary: 'Backdate transaction',
  path: '/people/{personId}/loans/{loanId}/transactions/{transactionId}/backdate',
  queryKey: ({ query, personId, loanId, transactionId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'backdate',
    query,
  ],
})

export type GetStatementsKey = ['people', string | undefined, 'loans', string | undefined, 'statements', unknown]

export type GetStatementsResponse = ResponseTypes.StatementCollection

export type GetStatementsVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
    year?: string
    includeAllVersions?: boolean
    includeTestVersions?: boolean
    documentDescriptorIds?: Array<SchemaTypes.AnyId>
  }
}

export const getStatements = makeData<GetStatementsResponse, GetStatementsKey, GetStatementsVariables>({
  method: 'get',
  name: 'getStatements',
  summary: 'Get statements',
  path: '/people/{personId}/loans/{loanId}/statements',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'statements', query],
})

export type UpdateStatementKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'statements',
  string | undefined,
  unknown,
]

export type UpdateStatementResponse = ResponseTypes.Statement

export type UpdateStatementVariables = {
  personId: string | undefined
  loanId: string | undefined
  statementId: string | undefined
  body?: {
    /**
     * Identifier for the document descriptor to be attached to the statement.
     * This value can only be updated if `isCreateAndSendPDF` is set to false on the loan type configuration.
     */
    documentDescriptorId: string
  }
  query?: void
}

export const updateStatement = makeData<UpdateStatementResponse, UpdateStatementKey, UpdateStatementVariables>({
  method: 'put',
  name: 'updateStatement',
  summary: 'Update statement',
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}',
  queryKey: ({ query, personId, loanId, statementId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    query,
  ],
})

export type GetStatementDetailsKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'statements',
  string | undefined,
  'details',
  unknown,
]

export type GetStatementDetailsResponse = ResponseTypes.StatementDetails

export type GetStatementDetailsVariables = {
  personId: string | undefined
  loanId: string | undefined
  statementId: string | undefined
  body?: void
  query?: void
}

export const getStatementDetails = makeData<
  GetStatementDetailsResponse,
  GetStatementDetailsKey,
  GetStatementDetailsVariables
>({
  method: 'get',
  name: 'getStatementDetails',
  summary: 'Get statement details',
  path: '/people/{personId}/loans/{loanId}/statements/{statementId}/details',
  queryKey: ({ query, personId, loanId, statementId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'statements',
    statementId,
    'details',
    query,
  ],
})

export type GenerateStatementKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'periods',
  string | undefined,
  'generate-statement',
  unknown,
]

export type GenerateStatementResponse = SchemaTypes.ProcessingCompleteGeneric &
  UtilityTypes.SingletonResponse & {
    data?: SchemaTypes.Statement
  }

export type GenerateStatementVariables = {
  personId: string | undefined
  loanId: string | undefined
  periodId: string | undefined
  body?: void
  query?: {
    sync?: boolean
    sendNotice?: boolean
  }
}

export const generateStatement = makeData<GenerateStatementResponse, GenerateStatementKey, GenerateStatementVariables>({
  method: 'post',
  name: 'generateStatement',
  summary: 'Generate statement',
  path: '/people/{personId}/loans/{loanId}/periods/{periodId}/generate-statement',
  queryKey: ({ query, personId, loanId, periodId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'periods',
    periodId,
    'generate-statement',
    query,
  ],
})

export type LoanAutopayGetKey = ['people', string | undefined, 'loans', string | undefined, 'autopay', unknown]

export type LoanAutopayGetResponse = ResponseTypes.Autopay

export type LoanAutopayGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     [
     *       "booked",
     *       "modified"
     *     ]
     */
    statuses?: Array<'booked' | 'skipped' | 'canceled' | 'modified' | 'draft' | 'processed'>
  }
}

export const loanAutopayGet = makeData<LoanAutopayGetResponse, LoanAutopayGetKey, LoanAutopayGetVariables>({
  method: 'get',
  name: 'loanAutopayGet',
  summary: 'Get Autopay plan',
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay', query],
})

export type LoanAutopayPutKey = ['people', string | undefined, 'loans', string | undefined, 'autopay', unknown]

export type LoanAutopayPutResponse = ResponseTypes.AutopayPut

export type LoanAutopayPutVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayPut
  query?: void
}

export const loanAutopayPut = makeData<LoanAutopayPutResponse, LoanAutopayPutKey, LoanAutopayPutVariables>({
  method: 'put',
  name: 'loanAutopayPut',
  summary: 'Update Autopay plan',
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay', query],
})

export type LoanAutopayPostKey = ['people', string | undefined, 'loans', string | undefined, 'autopay', unknown]

export type LoanAutopayPostResponse = ResponseTypes.AutopayPost

export type LoanAutopayPostVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: RequestBodyTypes.AutopayPost
  query?: void
}

export const loanAutopayPost = makeData<LoanAutopayPostResponse, LoanAutopayPostKey, LoanAutopayPostVariables>({
  method: 'post',
  name: 'loanAutopayPost',
  summary: 'Create Autopay plan',
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay', query],
})

export type LoanAutopayDeleteKey = ['people', string | undefined, 'loans', string | undefined, 'autopay', unknown]

export type LoanAutopayDeleteResponse = void

export type LoanAutopayDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     canceledByUser
     */
    cancellationReason?:
      | 'invalidPaymentMethod'
      | 'paymentMethodRemoved'
      | 'tooManyFailedAttempts'
      | 'loanFrozen'
      | 'loanAccelerated'
      | 'loanChargedOff'
      | 'loanPaidOff'
      | 'canceledByUser'
      | 'loanTermsChanged'
    caseId?: SchemaTypes.AnyId
    /**
     * @default
     *     true
     */
    sendNotice?: boolean
  }
}

export const loanAutopayDelete = makeData<LoanAutopayDeleteResponse, LoanAutopayDeleteKey, LoanAutopayDeleteVariables>({
  method: 'delete',
  name: 'loanAutopayDelete',
  summary: 'Cancel Autopay plan',
  path: '/people/{personId}/loans/{loanId}/autopay',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'autopay', query],
})

export type LoanInterestGetKey = ['people', string | undefined, 'loans', string | undefined, 'interest', unknown]

export type LoanInterestGetResponse = ResponseTypes.Interest

export type LoanInterestGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    drawId?: SchemaTypes.AnyId
    fromEffectiveDate?: string
    toEffectiveDate?: string
  }
}

export const loanInterestGet = makeData<LoanInterestGetResponse, LoanInterestGetKey, LoanInterestGetVariables>({
  method: 'get',
  name: 'loanInterestGet',
  summary: 'Get loan interest',
  path: '/people/{personId}/loans/{loanId}/interest',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'interest', query],
})

export type LoanBalancesGetKey = ['people', string | undefined, 'loans', string | undefined, 'balances', unknown]

export type LoanBalancesGetResponse = ResponseTypes.Balances

export type LoanBalancesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
    skipYtd?: boolean
    fast?: boolean
    toEffectiveDate?: string
  }
}

export const loanBalancesGet = makeData<LoanBalancesGetResponse, LoanBalancesGetKey, LoanBalancesGetVariables>({
  method: 'get',
  name: 'loanBalancesGet',
  summary: 'Get loan balances',
  path: '/people/{personId}/loans/{loanId}/balances',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'balances', query],
})

export type LoanFutureBalancesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'future-balances',
  unknown,
]

export type LoanFutureBalancesGetResponse = ResponseTypes.FutureBalances

export type LoanFutureBalancesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
  }
}

export const loanFutureBalancesGet = makeData<
  LoanFutureBalancesGetResponse,
  LoanFutureBalancesGetKey,
  LoanFutureBalancesGetVariables
>({
  method: 'get',
  name: 'loanFutureBalancesGet',
  summary: 'Get loan future balances',
  path: '/people/{personId}/loans/{loanId}/future-balances',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'future-balances', query],
})

export type DrawBalancesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'balances',
  unknown,
]

export type DrawBalancesGetResponse = ResponseTypes.Balances

export type DrawBalancesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
    skipYtd?: boolean
    fast?: boolean
    toEffectiveDate?: string
    skipInterestAccrual?: boolean
  }
}

export const drawBalancesGet = makeData<DrawBalancesGetResponse, DrawBalancesGetKey, DrawBalancesGetVariables>({
  method: 'get',
  name: 'drawBalancesGet',
  summary: 'Get draw balances',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/balances',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'balances',
    query,
  ],
})

export type DrawFutureBalancesGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'future-balances',
  unknown,
]

export type DrawFutureBalancesGetResponse = ResponseTypes.FutureBalances

export type DrawFutureBalancesGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: {
    isRounded?: boolean
  }
}

export const drawFutureBalancesGet = makeData<
  DrawFutureBalancesGetResponse,
  DrawFutureBalancesGetKey,
  DrawFutureBalancesGetVariables
>({
  method: 'get',
  name: 'drawFutureBalancesGet',
  summary: 'Get draw future balances',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/future-balances',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'future-balances',
    query,
  ],
})

export type LoanInvestorsGetAllKey = ['people', string | undefined, 'loans', string | undefined, 'investors', unknown]

export type LoanInvestorsGetAllResponse = SchemaTypes.LoanInvestorsPaging

export type LoanInvestorsGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const loanInvestorsGetAll = makeData<
  LoanInvestorsGetAllResponse,
  LoanInvestorsGetAllKey,
  LoanInvestorsGetAllVariables
>({
  method: 'get',
  name: 'loanInvestorsGetAll',
  summary: 'Get loan investors',
  path: '/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'investors', query],
})

export type LoanInvestorsUpdateKey = ['people', string | undefined, 'loans', string | undefined, 'investors', unknown]

export type LoanInvestorsUpdateResponse = void

export type LoanInvestorsUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.LoanInvestors
  query?: void
}

export const loanInvestorsUpdate = makeData<
  LoanInvestorsUpdateResponse,
  LoanInvestorsUpdateKey,
  LoanInvestorsUpdateVariables
>({
  method: 'put',
  name: 'loanInvestorsUpdate',
  summary: 'Update loan investors',
  path: '/people/{personId}/loans/{loanId}/investors',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'investors', query],
})

export type PersonPaymentInstrumentGetAllKey = ['people', string | undefined, 'payment-instruments', unknown]

export type PersonPaymentInstrumentGetAllResponse = UtilityTypes.Paging & {
  count: number
  data: Array<SchemaTypes.PaymentInstrumentGet>
}

export type PersonPaymentInstrumentGetAllVariables = {
  personId: string | undefined
  body?: void
  query?: {
    isExternal?: boolean
    includeDeleted?: boolean
    showFullAccountNumber?: boolean
  }
}

export const personPaymentInstrumentGetAll = makeData<
  PersonPaymentInstrumentGetAllResponse,
  PersonPaymentInstrumentGetAllKey,
  PersonPaymentInstrumentGetAllVariables
>({
  method: 'get',
  name: 'personPaymentInstrumentGetAll',
  summary: 'Get payment instruments',
  path: '/people/{personId}/payment-instruments',
  queryKey: ({ query, personId }) => ['people', personId, 'payment-instruments', query],
})

export type PersonPaymentInstrumentCreateKey = ['people', string | undefined, 'payment-instruments', unknown]

export type PersonPaymentInstrumentCreateResponse = UtilityTypes.Paging & {
  count: number
  data: Array<SchemaTypes.PaymentInstrument>
}

export type PersonPaymentInstrumentCreateVariables = {
  personId: string | undefined
  body?: SchemaTypes.PaymentInstrumentPost
  query?: {
    force?: boolean
    sync?: boolean
    allowDuplicates?: boolean
  }
}

export const personPaymentInstrumentCreate = makeData<
  PersonPaymentInstrumentCreateResponse,
  PersonPaymentInstrumentCreateKey,
  PersonPaymentInstrumentCreateVariables
>({
  method: 'post',
  name: 'personPaymentInstrumentCreate',
  summary: 'Create payment instrument',
  path: '/people/{personId}/payment-instruments',
  queryKey: ({ query, personId }) => ['people', personId, 'payment-instruments', query],
})

export type PersonPaymentInstrumentGetKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type PersonPaymentInstrumentGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentInstrumentGet
}

export type PersonPaymentInstrumentGetVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: {
    showFullAccountNumber?: boolean
  }
}

export const personPaymentInstrumentGet = makeData<
  PersonPaymentInstrumentGetResponse,
  PersonPaymentInstrumentGetKey,
  PersonPaymentInstrumentGetVariables
>({
  method: 'get',
  name: 'personPaymentInstrumentGet',
  summary: 'Get payment instrument by ID',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type PersonPaymentInstrumentUpdateKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type PersonPaymentInstrumentUpdateResponse = void

export type PersonPaymentInstrumentUpdateVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: SchemaTypes.PaymentInstrumentMutable
  query?: {
    sync?: boolean
  }
}

export const personPaymentInstrumentUpdate = makeData<
  PersonPaymentInstrumentUpdateResponse,
  PersonPaymentInstrumentUpdateKey,
  PersonPaymentInstrumentUpdateVariables
>({
  method: 'put',
  name: 'personPaymentInstrumentUpdate',
  summary: 'Update payment instrument',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type PersonPaymentInstrumentDeleteKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  unknown,
]

export type PersonPaymentInstrumentDeleteResponse = void

export type PersonPaymentInstrumentDeleteVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const personPaymentInstrumentDelete = makeData<
  PersonPaymentInstrumentDeleteResponse,
  PersonPaymentInstrumentDeleteKey,
  PersonPaymentInstrumentDeleteVariables
>({
  method: 'delete',
  name: 'personPaymentInstrumentDelete',
  summary: 'Delete payment instrument',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    query,
  ],
})

export type PersonPaymentInstrumentVerifyKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'verify',
  unknown,
]

export type PersonPaymentInstrumentVerifyResponse = void

export type PersonPaymentInstrumentVerifyVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: {
    deposit1: number
    deposit2: number
  }
  query?: void
}

export const personPaymentInstrumentVerify = makeData<
  PersonPaymentInstrumentVerifyResponse,
  PersonPaymentInstrumentVerifyKey,
  PersonPaymentInstrumentVerifyVariables
>({
  method: 'post',
  name: 'personPaymentInstrumentVerify',
  summary: 'Verify amounts of microdeposits',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/verify',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'verify',
    query,
  ],
})

export type AccountLinkPutKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'account-link',
  unknown,
]

export type AccountLinkPutResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLink
}

export type AccountLinkPutVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: SchemaTypes.AccountLinkUpdate
  query?: void
}

export const accountLinkPut = makeData<AccountLinkPutResponse, AccountLinkPutKey, AccountLinkPutVariables>({
  method: 'put',
  name: 'accountLinkPut',
  summary: 'Update account link',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
    query,
  ],
})

export type AccountLinkPostKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'account-link',
  unknown,
]

export type AccountLinkPostResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLink
}

export type AccountLinkPostVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: SchemaTypes.AccountLink
  query?: void
}

export const accountLinkPost = makeData<AccountLinkPostResponse, AccountLinkPostKey, AccountLinkPostVariables>({
  method: 'post',
  name: 'accountLinkPost',
  summary: 'Create account link',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
    query,
  ],
})

export type AccountLinkDeleteKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'account-link',
  unknown,
]

export type AccountLinkDeleteResponse = void

export type AccountLinkDeleteVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const accountLinkDelete = makeData<AccountLinkDeleteResponse, AccountLinkDeleteKey, AccountLinkDeleteVariables>({
  method: 'delete',
  name: 'accountLinkDelete',
  summary: 'Delete account link',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/account-link',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'account-link',
    query,
  ],
})

export type BalancesGetKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type BalancesGetResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLinkBalanceEntry
}

export type BalancesGetVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const balancesGet = makeData<BalancesGetResponse, BalancesGetKey, BalancesGetVariables>({
  method: 'get',
  name: 'balancesGet',
  summary: 'Get balance data',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
    query,
  ],
})

export type BalancesPostKey = [
  'people',
  string | undefined,
  'payment-instruments',
  string | undefined,
  'balance',
  unknown,
]

export type BalancesPostResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AccountLinkBalanceEntry
}

export type BalancesPostVariables = {
  personId: string | undefined
  paymentInstrumentId: string | undefined
  body?: void
  query?: void
}

export const balancesPost = makeData<BalancesPostResponse, BalancesPostKey, BalancesPostVariables>({
  method: 'post',
  name: 'balancesPost',
  summary: 'Refresh balance data',
  path: '/people/{personId}/payment-instruments/{paymentInstrumentId}/balance',
  queryKey: ({ query, personId, paymentInstrumentId }) => [
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
    query,
  ],
})

export type LegalRepresentativeGetAllKey = ['people', string | undefined, 'legal-representatives', unknown]

export type LegalRepresentativeGetAllResponse = ResponseTypes.LegalRepresentativesPaging

export type LegalRepresentativeGetAllVariables = {
  personId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    includeArchived?: boolean
  }
}

export const legalRepresentativeGetAll = makeData<
  LegalRepresentativeGetAllResponse,
  LegalRepresentativeGetAllKey,
  LegalRepresentativeGetAllVariables
>({
  method: 'get',
  name: 'legalRepresentativeGetAll',
  summary: 'Get representatives',
  path: '/people/{personId}/legal-representatives',
  queryKey: ({ query, personId }) => ['people', personId, 'legal-representatives', query],
})

export type LegalRepresentativeAddKey = ['people', string | undefined, 'legal-representatives', unknown]

export type LegalRepresentativeAddResponse = ResponseTypes.LegalRepresentative

export type LegalRepresentativeAddVariables = {
  personId: string | undefined
  body?: RequestBodyTypes.LegalRepresentative
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const legalRepresentativeAdd = makeData<
  LegalRepresentativeAddResponse,
  LegalRepresentativeAddKey,
  LegalRepresentativeAddVariables
>({
  method: 'post',
  name: 'legalRepresentativeAdd',
  summary: 'Create representative',
  path: '/people/{personId}/legal-representatives',
  queryKey: ({ query, personId }) => ['people', personId, 'legal-representatives', query],
})

export type LegalRepresentativeGetByIdKey = [
  'people',
  string | undefined,
  'legal-representatives',
  string | undefined,
  unknown,
]

export type LegalRepresentativeGetByIdResponse = ResponseTypes.LegalRepresentative

export type LegalRepresentativeGetByIdVariables = {
  personId: string | undefined
  legalRepresentativeId: string | undefined
  body?: void
  query?: void
}

export const legalRepresentativeGetById = makeData<
  LegalRepresentativeGetByIdResponse,
  LegalRepresentativeGetByIdKey,
  LegalRepresentativeGetByIdVariables
>({
  method: 'get',
  name: 'legalRepresentativeGetById',
  summary: 'Get representative by ID',
  path: '/people/{personId}/legal-representatives/{legalRepresentativeId}',
  queryKey: ({ query, personId, legalRepresentativeId }) => [
    'people',
    personId,
    'legal-representatives',
    legalRepresentativeId,
    query,
  ],
})

export type LegalRepresentativeUpdateKey = [
  'people',
  string | undefined,
  'legal-representatives',
  string | undefined,
  unknown,
]

export type LegalRepresentativeUpdateResponse = ResponseTypes.LegalRepresentative

export type LegalRepresentativeUpdateVariables = {
  personId: string | undefined
  legalRepresentativeId: string | undefined
  body?: RequestBodyTypes.LegalRepresentative
  query?: {
    /**
     * @default
     *     true
     */
    validate?: boolean
  }
}

export const legalRepresentativeUpdate = makeData<
  LegalRepresentativeUpdateResponse,
  LegalRepresentativeUpdateKey,
  LegalRepresentativeUpdateVariables
>({
  method: 'put',
  name: 'legalRepresentativeUpdate',
  summary: 'Update representative',
  path: '/people/{personId}/legal-representatives/{legalRepresentativeId}',
  queryKey: ({ query, personId, legalRepresentativeId }) => [
    'people',
    personId,
    'legal-representatives',
    legalRepresentativeId,
    query,
  ],
})

export type LoanCreditreportingGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'creditreportings',
  string | undefined,
  unknown,
]

export type LoanCreditreportingGetResponse = SchemaTypes.LoanCreditReporting

export type LoanCreditreportingGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  creditReportingId: string | undefined
  body?: void
  query?: void
}

export const loanCreditreportingGet = makeData<
  LoanCreditreportingGetResponse,
  LoanCreditreportingGetKey,
  LoanCreditreportingGetVariables
>({
  method: 'get',
  name: 'loanCreditreportingGet',
  summary: 'Get credit reporting by ID',
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ query, personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
    query,
  ],
})

export type LoanCreditreportingUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'creditreportings',
  string | undefined,
  unknown,
]

export type LoanCreditreportingUpdateResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanCreditReporting
}

export type LoanCreditreportingUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  creditReportingId: string | undefined
  body?: SchemaTypes.LoanCreditReporting
  query?: void
}

export const loanCreditreportingUpdate = makeData<
  LoanCreditreportingUpdateResponse,
  LoanCreditreportingUpdateKey,
  LoanCreditreportingUpdateVariables
>({
  method: 'put',
  name: 'loanCreditreportingUpdate',
  summary: 'Update credit reporting',
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ query, personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
    query,
  ],
})

export type LoanCreditreportingDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'creditreportings',
  string | undefined,
  unknown,
]

export type LoanCreditreportingDeleteResponse = void

export type LoanCreditreportingDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  creditReportingId: string | undefined
  body?: void
  query?: void
}

export const loanCreditreportingDelete = makeData<
  LoanCreditreportingDeleteResponse,
  LoanCreditreportingDeleteKey,
  LoanCreditreportingDeleteVariables
>({
  method: 'delete',
  name: 'loanCreditreportingDelete',
  summary: 'Delete credit reporting',
  path: '/people/{personId}/loans/{loanId}/creditreportings/{creditReportingId}',
  queryKey: ({ query, personId, loanId, creditReportingId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'creditreportings',
    creditReportingId,
    query,
  ],
})

export type LoanCreditreportingsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'creditreportings',
  unknown,
]

export type LoanCreditreportingsGetResponse = SchemaTypes.LoanCreditReportingPaging

export type LoanCreditreportingsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const loanCreditreportingsGet = makeData<
  LoanCreditreportingsGetResponse,
  LoanCreditreportingsGetKey,
  LoanCreditreportingsGetVariables
>({
  method: 'get',
  name: 'loanCreditreportingsGet',
  summary: 'Get credit reportings',
  path: '/people/{personId}/loans/{loanId}/creditreportings',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'creditreportings', query],
})

export type LoanCreditreportingsAddKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'creditreportings',
  unknown,
]

export type LoanCreditreportingsAddResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.LoanCreditReporting
}

export type LoanCreditreportingsAddVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: SchemaTypes.LoanCreditReporting
  query?: void
}

export const loanCreditreportingsAdd = makeData<
  LoanCreditreportingsAddResponse,
  LoanCreditreportingsAddKey,
  LoanCreditreportingsAddVariables
>({
  method: 'post',
  name: 'loanCreditreportingsAdd',
  summary: 'Add credit reporting',
  path: '/people/{personId}/loans/{loanId}/creditreportings',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'creditreportings', query],
})

export type UsersGetKey = ['users', unknown]

export type UsersGetResponse = ResponseTypes.UsersPaging

export type UsersGetVariables = {
  body?: void
  query?: {
    sortBy?: Array<string>
    userType?: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const usersGet = makeData<UsersGetResponse, UsersGetKey, UsersGetVariables>({
  method: 'get',
  name: 'usersGet',
  summary: 'Get users',
  path: '/users',
  queryKey: ({ query }) => ['users', query],
})

export type UserCreateKey = ['users', unknown]

export type UserCreateResponse = ResponseTypes.User

export type UserCreateVariables = {
  body?: SchemaTypes.UserCreate
  query?: void
}

export const userCreate = makeData<UserCreateResponse, UserCreateKey, UserCreateVariables>({
  method: 'post',
  name: 'userCreate',
  summary: 'Create user',
  path: '/users',
  queryKey: ({ query }) => ['users', query],
})

export type UserGetKey = ['users', string | undefined, unknown]

export type UserGetResponse = ResponseTypes.User

export type UserGetVariables = {
  userId: string | undefined
  body?: void
  query?: void
}

export const userGet = makeData<UserGetResponse, UserGetKey, UserGetVariables>({
  method: 'get',
  name: 'userGet',
  summary: 'Get user',
  path: '/users/{userId}',
  queryKey: ({ query, userId }) => ['users', userId, query],
})

export type UserUpdateKey = ['users', string | undefined, unknown]

export type UserUpdateResponse = ResponseTypes.User

export type UserUpdateVariables = {
  userId: string | undefined
  body?: SchemaTypes.UserUpdate
  query?: void
}

export const userUpdate = makeData<UserUpdateResponse, UserUpdateKey, UserUpdateVariables>({
  method: 'put',
  name: 'userUpdate',
  summary: 'Update user',
  path: '/users/{userId}',
  queryKey: ({ query, userId }) => ['users', userId, query],
})

export type UserRoleGetKey = ['users', string | undefined, 'roles', unknown]

export type UserRoleGetResponse = ResponsesRoleTypes.RolesPaging

export type UserRoleGetVariables = {
  userId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const userRoleGet = makeData<UserRoleGetResponse, UserRoleGetKey, UserRoleGetVariables>({
  method: 'get',
  name: 'userRoleGet',
  summary: 'Get user roles',
  path: '/users/{userId}/roles',
  queryKey: ({ query, userId }) => ['users', userId, 'roles', query],
})

export type UserRoleUpdateKey = ['users', string | undefined, 'roles', string | undefined, unknown]

export type UserRoleUpdateResponse = void

export type UserRoleUpdateVariables = {
  userId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const userRoleUpdate = makeData<UserRoleUpdateResponse, UserRoleUpdateKey, UserRoleUpdateVariables>({
  method: 'put',
  name: 'userRoleUpdate',
  summary: 'Add role to user',
  path: '/users/{userId}/roles/{roleId}',
  queryKey: ({ query, userId, roleId }) => ['users', userId, 'roles', roleId, query],
})

export type UserRoleDeleteKey = ['users', string | undefined, 'roles', string | undefined, unknown]

export type UserRoleDeleteResponse = void

export type UserRoleDeleteVariables = {
  userId: string | undefined
  roleId: string | undefined
  body?: void
  query?: void
}

export const userRoleDelete = makeData<UserRoleDeleteResponse, UserRoleDeleteKey, UserRoleDeleteVariables>({
  method: 'delete',
  name: 'userRoleDelete',
  summary: 'Remove role from user',
  path: '/users/{userId}/roles/{roleId}',
  queryKey: ({ query, userId, roleId }) => ['users', userId, 'roles', roleId, query],
})

export type UserGetPermissionsKey = ['users', string | undefined, 'permissions', unknown]

export type UserGetPermissionsResponse = void

export type UserGetPermissionsVariables = {
  userId: string | undefined
  body?: void
  query?: void
}

export const userGetPermissions = makeData<
  UserGetPermissionsResponse,
  UserGetPermissionsKey,
  UserGetPermissionsVariables
>({
  method: 'get',
  name: 'userGetPermissions',
  summary: "Get user's current permissions",
  path: '/users/{userId}/permissions',
  queryKey: ({ query, userId }) => ['users', userId, 'permissions', query],
})

export type GetPaymentProcessorsKey = ['payment-processors', unknown]

export type GetPaymentProcessorsResponse = UtilityTypes.SingletonResponse & {
  data?: Array<SchemaTypes.PaymentProcessor>
}

export type GetPaymentProcessorsVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
    sortBy?: Array<string>
  }
}

export const getPaymentProcessors = makeData<
  GetPaymentProcessorsResponse,
  GetPaymentProcessorsKey,
  GetPaymentProcessorsVariables
>({
  method: 'get',
  name: 'getPaymentProcessors',
  summary: 'Get payment processors',
  path: '/payment-processors',
  queryKey: ({ query }) => ['payment-processors', query],
})

export type CreatePaymentProcessorKey = ['payment-processors', unknown]

export type CreatePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type CreatePaymentProcessorVariables = {
  body?: SchemaTypes.PaymentProcessor
  query?: {
    skipConnectionTest?: boolean
  }
}

export const createPaymentProcessor = makeData<
  CreatePaymentProcessorResponse,
  CreatePaymentProcessorKey,
  CreatePaymentProcessorVariables
>({
  method: 'post',
  name: 'createPaymentProcessor',
  summary: 'Create payment processor',
  path: '/payment-processors',
  queryKey: ({ query }) => ['payment-processors', query],
})

export type GetOnePaymentProcessorKey = ['payment-processors', string | undefined, unknown]

export type GetOnePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type GetOnePaymentProcessorVariables = {
  paymentProcessorId: string | undefined
  body?: void
  query?: void
}

export const getOnePaymentProcessor = makeData<
  GetOnePaymentProcessorResponse,
  GetOnePaymentProcessorKey,
  GetOnePaymentProcessorVariables
>({
  method: 'get',
  name: 'getOnePaymentProcessor',
  summary: 'Get payment processor',
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, paymentProcessorId }) => ['payment-processors', paymentProcessorId, query],
})

export type UpdatePaymentProcessorKey = ['payment-processors', string | undefined, unknown]

export type UpdatePaymentProcessorResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.PaymentProcessor
}

export type UpdatePaymentProcessorVariables = {
  paymentProcessorId: string | undefined
  body?: SchemaTypes.PaymentProcessorUpdateRequest
  query?: void
}

export const updatePaymentProcessor = makeData<
  UpdatePaymentProcessorResponse,
  UpdatePaymentProcessorKey,
  UpdatePaymentProcessorVariables
>({
  method: 'put',
  name: 'updatePaymentProcessor',
  summary: 'Update payment processor',
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, paymentProcessorId }) => ['payment-processors', paymentProcessorId, query],
})

export type DeletePaymentProcessorKey = ['payment-processors', string | undefined, unknown]

export type DeletePaymentProcessorResponse = void

export type DeletePaymentProcessorVariables = {
  paymentProcessorId: string | undefined
  body?: void
  query?: void
}

export const deletePaymentProcessor = makeData<
  DeletePaymentProcessorResponse,
  DeletePaymentProcessorKey,
  DeletePaymentProcessorVariables
>({
  method: 'delete',
  name: 'deletePaymentProcessor',
  summary: 'Delete payment processor',
  path: '/payment-processors/{paymentProcessorId}',
  queryKey: ({ query, paymentProcessorId }) => ['payment-processors', paymentProcessorId, query],
})

export type GetAchFileContentKey = [
  'companies',
  string | undefined,
  'payment-processors',
  string | undefined,
  'ach-files',
  string | undefined,
  'content',
  unknown,
]

export type GetAchFileContentResponse = UtilityTypes.SingletonResponse & {
  data?: SchemaTypes.AchFile
}

export type GetAchFileContentVariables = {
  companyId: string | undefined
  paymentProcessorId: string | undefined
  achFileId: string | undefined
  body?: void
  query?: {
    returnUrl?: boolean
  }
}

export const getAchFileContent = makeData<GetAchFileContentResponse, GetAchFileContentKey, GetAchFileContentVariables>({
  method: 'get',
  name: 'getAchFileContent',
  summary: 'Get ACH file content',
  path: '/companies/{companyId}/payment-processors/{paymentProcessorId}/ach-files/{achFileId}/content',
  queryKey: ({ query, companyId, paymentProcessorId, achFileId }) => [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'ach-files',
    achFileId,
    'content',
    query,
  ],
})

export type OperationStatusGetKey = ['operations', string | undefined, unknown]

export type OperationStatusGetResponse = UtilityTypes.SingletonResponse

export type OperationStatusGetVariables = {
  operationId: string | undefined
  body?: void
  query?: void
}

export const operationStatusGet = makeData<
  OperationStatusGetResponse,
  OperationStatusGetKey,
  OperationStatusGetVariables
>({
  method: 'get',
  name: 'operationStatusGet',
  summary: 'Get operation status',
  path: '/operations/{operationId}',
  queryKey: ({ query, operationId }) => ['operations', operationId, query],
})
