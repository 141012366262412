/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'
import * as UtilityTypes from '../utilities/types'

import * as RepaymentEngineTypes from './types'

export type GetNotificationDataInternalKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  string | undefined,
  'reminders',
  unknown,
]

export type GetNotificationDataInternalResponse = UtilityTypes.NewPaginator & {
  data?: Array<{
    companyId?: string
    loanId?: string
    personId?: SchemaTypes.PersonId
    subject?: RepaymentEngineTypes.RepaymentEngineInteractionSubject
    sendChannels?: Array<RepaymentEngineTypes.ValidInteractionChannel>
  }>
}

export type GetNotificationDataInternalVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  repaymentNotificationTypeId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
  }
}

export const getNotificationDataInternal = makeData<
  GetNotificationDataInternalResponse,
  GetNotificationDataInternalKey,
  GetNotificationDataInternalVariables
>({
  method: 'get',
  name: 'getNotificationDataInternal',
  summary: 'Get reminders that should be sent today.',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}/reminders',
  queryKey: ({ query, companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    'reminders',
    query,
  ],
})

export type GetNotificationTypesByCompanyKey = [
  'companies',
  string | undefined,
  'repayment-engine',
  'notifications',
  unknown,
]

export type GetNotificationTypesByCompanyResponse = UtilityTypes.NewPaginator & {
  data?: Array<RepaymentEngineTypes.RepaymentNotificationType>
}

export type GetNotificationTypesByCompanyVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    sortBy?: Array<string>
    productId?: SchemaTypes.AnyId
    trigger?: RepaymentEngineTypes.RepaymentNotificationTrigger
    subject?: RepaymentEngineTypes.RepaymentEngineInteractionSubject
    status?: 'active' | 'inactive'
    dayType?: SchemaTypes.DayType
    sendChannels?: Array<RepaymentEngineTypes.ValidInteractionChannel>
  }
}

export const getNotificationTypesByCompany = makeData<
  GetNotificationTypesByCompanyResponse,
  GetNotificationTypesByCompanyKey,
  GetNotificationTypesByCompanyVariables
>({
  method: 'get',
  name: 'getNotificationTypesByCompany',
  summary: 'Get all company notification types',
  path: '/companies/{companyId}/repayment-engine/notifications',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'repayment-engine', 'notifications', query],
})

export type GetProductsWithNotificationsKey = [
  'companies',
  string | undefined,
  'repayment-engine',
  'products-with-notifications',
  unknown,
]

export type GetProductsWithNotificationsResponse = UtilityTypes.NewPaginator & {
  data?: Array<{
    productId?: string
    loanTypeAssetName?: string
  }>
}

export type GetProductsWithNotificationsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    sortBy?: Array<string>
    trigger?: RepaymentEngineTypes.RepaymentNotificationTrigger
    subject?: RepaymentEngineTypes.RepaymentEngineInteractionSubject
  }
}

export const getProductsWithNotifications = makeData<
  GetProductsWithNotificationsResponse,
  GetProductsWithNotificationsKey,
  GetProductsWithNotificationsVariables
>({
  method: 'get',
  name: 'getProductsWithNotifications',
  summary: 'Get all products with notifications for a company',
  path: '/companies/{companyId}/repayment-engine/products-with-notifications',
  queryKey: ({ query, companyId }) => [
    'companies',
    companyId,
    'repayment-engine',
    'products-with-notifications',
    query,
  ],
})

export type GetSubjectsWithNotificationsKey = [
  'companies',
  string | undefined,
  'repayment-engine',
  'subjects-with-notifications',
  unknown,
]

export type GetSubjectsWithNotificationsResponse = {
  data?: Array<RepaymentEngineTypes.RepaymentEngineInteractionSubject>
}

export type GetSubjectsWithNotificationsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    sortBy?: Array<string>
    productId?: SchemaTypes.AnyId
    trigger?: RepaymentEngineTypes.RepaymentNotificationTrigger
  }
}

export const getSubjectsWithNotifications = makeData<
  GetSubjectsWithNotificationsResponse,
  GetSubjectsWithNotificationsKey,
  GetSubjectsWithNotificationsVariables
>({
  method: 'get',
  name: 'getSubjectsWithNotifications',
  summary: 'Get distinct subjects with notifications for a company',
  path: '/companies/{companyId}/repayment-engine/subjects-with-notifications',
  queryKey: ({ query, companyId }) => [
    'companies',
    companyId,
    'repayment-engine',
    'subjects-with-notifications',
    query,
  ],
})

export type GetTriggersWithNotificationsKey = [
  'companies',
  string | undefined,
  'repayment-engine',
  'triggers-with-notifications',
  unknown,
]

export type GetTriggersWithNotificationsResponse = {
  data?: Array<RepaymentEngineTypes.RepaymentNotificationTrigger>
}

export type GetTriggersWithNotificationsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    offset?: number
    sortBy?: Array<string>
    productId?: SchemaTypes.AnyId
    subject?: RepaymentEngineTypes.RepaymentEngineInteractionSubject
  }
}

export const getTriggersWithNotifications = makeData<
  GetTriggersWithNotificationsResponse,
  GetTriggersWithNotificationsKey,
  GetTriggersWithNotificationsVariables
>({
  method: 'get',
  name: 'getTriggersWithNotifications',
  summary: 'Get distinct triggers with notifications for a company',
  path: '/companies/{companyId}/repayment-engine/triggers-with-notifications',
  queryKey: ({ query, companyId }) => [
    'companies',
    companyId,
    'repayment-engine',
    'triggers-with-notifications',
    query,
  ],
})

export type GetNotificationTypesInternalKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  'notifications',
  unknown,
]

export type GetNotificationTypesInternalResponse = UtilityTypes.SingletonResponse & {
  data?: Array<RepaymentEngineTypes.RepaymentNotificationType>
}

export type GetNotificationTypesInternalVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: void
  query?: {
    trigger?: RepaymentEngineTypes.RepaymentNotificationTrigger
    subject?: RepaymentEngineTypes.RepaymentEngineInteractionSubject
    status?: 'active' | 'inactive'
    sendChannels?: Array<RepaymentEngineTypes.ValidInteractionChannel>
  }
}

export const getNotificationTypesInternal = makeData<
  GetNotificationTypesInternalResponse,
  GetNotificationTypesInternalKey,
  GetNotificationTypesInternalVariables
>({
  method: 'get',
  name: 'getNotificationTypesInternal',
  summary: 'Get product notification types',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/notifications',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    'notifications',
    query,
  ],
})

export type CreateNotificationTypeInternalKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  'notifications',
  unknown,
]

export type CreateNotificationTypeInternalResponse = UtilityTypes.SingletonResponse & {
  data?: RepaymentEngineTypes.RepaymentNotificationType
}

export type CreateNotificationTypeInternalVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  body?: RepaymentEngineTypes.RepaymentNotificationType
  query?: void
}

export const createNotificationTypeInternal = makeData<
  CreateNotificationTypeInternalResponse,
  CreateNotificationTypeInternalKey,
  CreateNotificationTypeInternalVariables
>({
  method: 'post',
  name: 'createNotificationTypeInternal',
  summary: 'Create a notification type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/notifications',
  queryKey: ({ query, companyId, loanTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    'notifications',
    query,
  ],
})

export type GetNotificationTypeByIdKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  string | undefined,
  unknown,
]

export type GetNotificationTypeByIdResponse = UtilityTypes.SingletonResponse & {
  data?: RepaymentEngineTypes.RepaymentNotificationType
}

export type GetNotificationTypeByIdVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  repaymentNotificationTypeId: string | undefined
  body?: void
  query?: void
}

export const getNotificationTypeById = makeData<
  GetNotificationTypeByIdResponse,
  GetNotificationTypeByIdKey,
  GetNotificationTypeByIdVariables
>({
  method: 'get',
  name: 'getNotificationTypeById',
  summary: 'Get notification type by ID',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ query, companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    query,
  ],
})

export type UpdateNotificationTypeInternalKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  string | undefined,
  unknown,
]

export type UpdateNotificationTypeInternalResponse = UtilityTypes.SingletonResponse & {
  data?: RepaymentEngineTypes.RepaymentNotificationType
}

export type UpdateNotificationTypeInternalVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  repaymentNotificationTypeId: string | undefined
  body?: RepaymentEngineTypes.RepaymentNotificationTypeWithoutSubject
  query?: void
}

export const updateNotificationTypeInternal = makeData<
  UpdateNotificationTypeInternalResponse,
  UpdateNotificationTypeInternalKey,
  UpdateNotificationTypeInternalVariables
>({
  method: 'put',
  name: 'updateNotificationTypeInternal',
  summary: 'Update a notification type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ query, companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    query,
  ],
})

export type DeleteNotificationTypeInternalKey = [
  'companies',
  string | undefined,
  'loan-types',
  string | undefined,
  'repayment-engine',
  string | undefined,
  unknown,
]

export type DeleteNotificationTypeInternalResponse = void

export type DeleteNotificationTypeInternalVariables = {
  companyId: string | undefined
  loanTypeId: string | undefined
  repaymentNotificationTypeId: string | undefined
  body?: void
  query?: void
}

export const deleteNotificationTypeInternal = makeData<
  DeleteNotificationTypeInternalResponse,
  DeleteNotificationTypeInternalKey,
  DeleteNotificationTypeInternalVariables
>({
  method: 'delete',
  name: 'deleteNotificationTypeInternal',
  summary: 'Delete a notification type',
  path: '/companies/{companyId}/loan-types/{loanTypeId}/repayment-engine/{repaymentNotificationTypeId}',
  queryKey: ({ query, companyId, loanTypeId, repaymentNotificationTypeId }) => [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    query,
  ],
})
