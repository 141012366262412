export const makeData = <Resp, Key, Vars>(data: {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  name: string
  summary: string
  path: string
  queryKey: (vars: Vars) => Key
}) => ({
  ...data,
  resp: (res: unknown) => res as Resp,
})
