import {
  format,
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  differenceInCalendarMonths,
  differenceInCalendarYears,
  differenceInCalendarDays,
  differenceInHours,
  addDays,
  isAfter,
  isToday,
  startOfDay,
  add,
  Duration,
} from 'date-fns'

import { SIMPLE_DATE_FORMAT, formatDatetime, formatToday, parseDatetime } from 'core/helpers/datetime'
import * as R from 'core/helpers/remeda'

import { checkTimeordate, checkTimestring, isTimestring } from './types'

const addLocal = (value: string, duration: Duration) => {
  checkTimeordate(value)

  const mDate = add(parseDatetime(value), duration)

  return isTimestring(value) ? mDate.toISOString() : format(mDate, 'y-MM-dd')
}

const addDaysLocal = (value: string, days: number) => {
  checkTimeordate(value)

  const mDate = addDays(parseDatetime(value), days)

  return isTimestring(value) ? mDate.toISOString() : format(mDate, 'y-MM-dd')
}

const closestTo = (value: string, array: Array<string>) => {
  checkTimeordate(value)
  R.forEach(array, (date) => checkTimeordate(date))

  const subject = parseDatetime(value)
  return R.firstBy(array, (date) => Math.abs(differenceInMilliseconds(parseDatetime(date), subject)))
}

const difference = (
  later: string | null | undefined,
  earlier: string | null | undefined,
  unit: 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years' = 'milliseconds',
) => {
  if (!later || !earlier) return 0

  checkTimeordate(later)
  checkTimeordate(earlier)

  const laterDate = parseDatetime(later)
  const earlierDate = parseDatetime(earlier)

  return (
    unit === 'milliseconds' ? differenceInMilliseconds(laterDate, earlierDate)
    : unit === 'seconds' ? differenceInSeconds(laterDate, earlierDate)
    : unit === 'minutes' ? differenceInMinutes(laterDate, earlierDate)
    : unit === 'hours' ? differenceInHours(laterDate, earlierDate)
    : unit === 'days' ? differenceInCalendarDays(laterDate, earlierDate)
    : unit === 'months' ? differenceInCalendarMonths(laterDate, earlierDate)
    : unit === 'years' ? differenceInCalendarYears(laterDate, earlierDate)
    : 0
  )
}

const formatMoment = (value: string | null | undefined, formatString = 'D MMM Y, h:mm A', timezone?: string) => {
  if (!value) return null

  checkTimeordate(value)

  return format(
    parseDatetime(value, timezone),
    formatString
      .replace(/\[(.+)]/g, `'$1'`)
      .replace(/LT/g, 'p')
      .replace(/LTS/g, 'pp')
      .replace(/LLLL|llll/g, 'PPPPp')
      .replace(/LLL|lll/g, 'PPp')
      .replace(/[Ll]/g, 'P')
      .replace(/Y/g, 'y')
      .replace(/g/g, 'Y')
      .replace(/G/g, 'R')
      .replace(/W/g, 'l')
      .replace(/E/g, 'i')
      .replace(/(D+|d+)o?/g, (inp: string) => {
        switch (inp) {
          case 'D': // 1 2 ... 30 31
          case 'Do': // 1st 2nd ... 30th 31st
          case 'DD': // 01 02 ... 30 31
            return inp.replace(/D/g, 'd')
          case 'DDD': // 1 2 ... 364 365
            return 'D'
          case 'DDDo': // 1st 2nd ... 364th 365th
            return 'Do'
          case 'DDDD': // 001 002 ... 364 365
            return 'DDD'

          case 'dd': // Su Mo ... Fr Sa
            return 'eeeeee'
          case 'd': // 0 1 ... 5 6
          case 'do': // 0th 1st ... 5th 6th
          case 'ddd': // Sun Mon ... Fri Sat
          case 'dddd': // Sunday Monday ... Friday Saturday
            return inp.replace(/d/g, 'e') // will actually return value between 1 and 7 for the numerical results
        }
        return inp
      })
      .replace(/a/g, `aaaaa'm'`)
      .replace(/A/g, 'a')
      .replace(/T/g, `'T'`)
      .replace(/X/g, 't')
      .replace(/x/g, 'T')
      .replace(/ZZ|zz/g, 'xx')
      .replace(/[Zz]/g, 'xxx'),
  )
}

const formatRelative = (
  value: string,
  { isCapitalized, showTime }: { isCapitalized?: boolean; showTime?: boolean } = {},
) => {
  checkTimeordate(value)

  const earlier = parseDatetime(value)
  const now = Date.now()

  const days = differenceInCalendarDays(now, earlier)
  const years = differenceInCalendarYears(now, earlier)

  return (
    days === 0 ?
      `${isCapitalized ? 'Today' : 'today'}${showTime ? ',' : ''} ${showTime ? format(earlier, 'h:mm a') : ''}`
    : days === 1 ?
      `${isCapitalized ? 'Yesterday' : 'yesterday'}${showTime ? ',' : ''} ${showTime ? format(earlier, 'h:mm a') : ''}`
    : days <= 6 ? format(earlier, `eeee${showTime ? ', h:mm a' : ''}`)
    : years === 0 ? format(earlier, `d MMM${showTime ? ', h:mm a' : ''}`)
    : format(earlier, `d MMM y${showTime ? ', h:mm a' : ''}`)
  )
}

const getNow = () => new Date().toISOString()

const isAfterLocal = (after: string | null | undefined, before: string | null | undefined) => {
  if (!before || !after) {
    return false
  }

  checkTimeordate(before)
  checkTimeordate(after)

  return isAfter(parseDatetime(after), parseDatetime(before))
}

const isTodayLocal = (value: string | null | undefined) => {
  checkTimeordate(value)

  // We don't want a nully value to get interpreted as "now" and accidentally
  // report that it is today.
  return !!value && isToday(parseDatetime(value))
}

const startOfDayLocal = (value: string) => {
  checkTimeordate(value)

  return startOfDay(parseDatetime(value)).toISOString()
}

const timestringToDatestring = (value: string | null | undefined) => {
  if (!value) return undefined

  checkTimestring(value)

  return formatDatetime(value, SIMPLE_DATE_FORMAT)
}

/** @deprecated Use date-fns in combination with core/helpers/datetime */
const useTime = () => ({
  add: addLocal,
  addDays: addDaysLocal,
  closestTo,
  difference,
  format: formatMoment,
  formatRelative,
  getNow,
  getToday: formatToday,
  isAfter: isAfterLocal,
  isToday: isTodayLocal,
  startOfDay: startOfDayLocal,
  timestringToDatestring,
})

export default useTime
