import { QueryClient, QueryKey, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'

import { peachApi } from 'core/api'
import { reportOperationError } from 'core/helpers/error'
import * as R from 'core/helpers/remeda'

import { validate } from './helpers'
import getDefaultOptions from './hooks/defaultOptions'

export type OperationData<Resp, Key, Vars> = {
  method: 'get' | 'post' | 'put' | 'delete' | 'patch'
  name: string
  summary: string
  path: string
  queryKey: (vars: Vars) => Key
  resp: (res: unknown) => Resp
}

type MutationContext = { queryClient: QueryClient }

export type MutationOptions<Resp, Vars> = Vars & {
  options?: Omit<UseMutationOptions<Resp, unknown, Vars, MutationContext>, 'onSuccess' | 'onError'> & {
    onSuccess?: (
      data: Resp,
      variables: Vars,
      context: MutationContext,
    ) => Promise<boolean | undefined | void> | boolean | undefined | void
    onError?: (
      error: unknown,
      variables: Vars,
      context: MutationContext,
    ) => Promise<boolean | undefined | void> | boolean | undefined | void
  }
}

export const callAPI = async <Resp, Key, Vars extends { body?: unknown; query?: { [index: string]: unknown } }>(
  { method, name, path }: OperationData<Resp, Key, Vars>,
  { body, query, ...args }: Vars,
) => {
  const res = (await peachApi.fetch({
    body,
    query,
    method,
    url: path.replace(/\{(\w+)\}/g, (_, p1) => {
      const variable = args[p1]

      if (!variable) {
        throw new Error(`Path variable "${p1}" is not defined.`)
      }

      return variable
    }),
    ...args,
  })) as Resp

  if (import.meta.env.VITE_ENABLE_RESPONSE_VALIDATION) {
    if (res) {
      validate(`http://peachfinance.com/schemas/${name}`, res)
    }
  }

  return res
}

const makeMutationHook =
  <Resp, Key extends QueryKey, Vars extends { body?: unknown }>(data: OperationData<Resp, Key, Vars>) =>
  (args: MutationOptions<Resp, Vars>) => {
    const queryClient = useQueryClient()

    const defaultOptions = getDefaultOptions(data)
    const { options, body, ...parameters } =
      defaultOptions ? (R.mergeDeep(defaultOptions, args) as unknown as typeof args) : args

    const res = useMutation<Resp, unknown, Vars, MutationContext>({
      mutationFn: (variables) => callAPI(data, variables),
      ...options,
      onSuccess: (reponse, variables, context) => {
        const ctx = context ?? { queryClient }
        if (args.options?.onSuccess?.(reponse, variables, ctx) === true) return
        if (defaultOptions?.options?.onSuccess?.(reponse, variables, ctx) === true) return

        void queryClient.invalidateQueries({
          queryKey: R.dropLast(data.queryKey(variables), data.method === 'post' || !data.path.endsWith('}') ? 1 : 2),
        })
      },
      onError: (e, variables, context) => {
        const ctx = context ?? { queryClient }
        if (args.options?.onError?.(e, variables, ctx) === true) return
        if (defaultOptions?.options?.onError?.(e, variables, ctx) === true) return

        reportOperationError(data.summary, e)
        console.error(e)
      },
    })

    return {
      ...res,
      mutate: (body: Vars['body'], override?: Partial<Vars>) =>
        res.mutate({ ...(parameters as Vars), ...override, body }),
      mutateAsync: (body: Vars['body'], override?: Partial<Vars>) =>
        res.mutateAsync({ ...(parameters as Vars), ...override, body }),
    }
  }

export default makeMutationHook
