/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as ResponseTypes from '../responses/types'

import * as CompanyTypes from './types'

export type CompanyGetAllKey = ['companies', unknown]

export type CompanyGetAllResponse = ResponseTypes.CompanyPaging

export type CompanyGetAllVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    femaPolicy?: boolean
    excludeInactive?: boolean
    peachUploadingToAgency?: 'experian' | 'equifax' | 'transUnion' | 'innovis'
    timezone?: string | null
    processedByPeach?: boolean
    scheduleAutopayTransactions?: boolean
    hasTwilio?: boolean
  }
}

export const companyGetAll = makeData<CompanyGetAllResponse, CompanyGetAllKey, CompanyGetAllVariables>({
  method: 'get',
  name: 'companyGetAll',
  summary: 'Get all companies',
  path: '/companies',
  queryKey: ({ query }) => ['companies', query],
})

export type CreateCompanyKey = ['companies', unknown]

export type CreateCompanyResponse = CompanyTypes.Company

export type CreateCompanyVariables = {
  body?: CompanyTypes.Company
  query?: void
}

export const createCompany = makeData<CreateCompanyResponse, CreateCompanyKey, CreateCompanyVariables>({
  method: 'post',
  name: 'createCompany',
  summary: 'Create a new company',
  path: '/companies',
  queryKey: ({ query }) => ['companies', query],
})

export type CompanyGetKey = ['companies', string | undefined, unknown]

export type CompanyGetResponse = ResponseTypes.Company

export type CompanyGetVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const companyGet = makeData<CompanyGetResponse, CompanyGetKey, CompanyGetVariables>({
  method: 'get',
  name: 'companyGet',
  summary: 'Get a company',
  path: '/companies/{companyId}',
  queryKey: ({ query, companyId }) => ['companies', companyId, query],
})

export type UpdateCompanyKey = ['companies', string | undefined, unknown]

export type UpdateCompanyResponse = CompanyTypes.Company

export type UpdateCompanyVariables = {
  companyId: string | undefined
  body?: CompanyTypes.Company
  query?: void
}

export const updateCompany = makeData<UpdateCompanyResponse, UpdateCompanyKey, UpdateCompanyVariables>({
  method: 'put',
  name: 'updateCompany',
  summary: 'Update a company',
  path: '/companies/{companyId}',
  queryKey: ({ query, companyId }) => ['companies', companyId, query],
})

export type CompanyAppDomainGetAllKey = ['companies', string | undefined, 'app-domains', unknown]

export type CompanyAppDomainGetAllResponse = ResponseTypes.CompanyAppDomainPaging

export type CompanyAppDomainGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const companyAppDomainGetAll = makeData<
  CompanyAppDomainGetAllResponse,
  CompanyAppDomainGetAllKey,
  CompanyAppDomainGetAllVariables
>({
  method: 'get',
  name: 'companyAppDomainGetAll',
  summary: 'Get all app domains for a company',
  path: '/companies/{companyId}/app-domains',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'app-domains', query],
})

export type CompanyAppDomainPostKey = ['companies', string | undefined, 'app-domains', unknown]

export type CompanyAppDomainPostResponse = CompanyTypes.CompanyAppDomain

export type CompanyAppDomainPostVariables = {
  companyId: string | undefined
  body?: CompanyTypes.CompanyAppDomain
  query?: void
}

export const companyAppDomainPost = makeData<
  CompanyAppDomainPostResponse,
  CompanyAppDomainPostKey,
  CompanyAppDomainPostVariables
>({
  method: 'post',
  name: 'companyAppDomainPost',
  summary: 'Create a company app domain.',
  path: '/companies/{companyId}/app-domains',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'app-domains', query],
})

export type CompanyAppDomainGetKey = ['companies', string | undefined, 'app-domains', string | undefined, unknown]

export type CompanyAppDomainGetResponse = ResponseTypes.CompanyAppDomain

export type CompanyAppDomainGetVariables = {
  companyId: string | undefined
  companyAppDomainId: string | undefined
  body?: void
  query?: void
}

export const companyAppDomainGet = makeData<
  CompanyAppDomainGetResponse,
  CompanyAppDomainGetKey,
  CompanyAppDomainGetVariables
>({
  method: 'get',
  name: 'companyAppDomainGet',
  summary: 'Get an app domain for a company',
  path: '/companies/{companyId}/app-domains/{companyAppDomainId}',
  queryKey: ({ query, companyId, companyAppDomainId }) => [
    'companies',
    companyId,
    'app-domains',
    companyAppDomainId,
    query,
  ],
})

export type CompanyAppDomainPutKey = ['companies', string | undefined, 'app-domains', string | undefined, unknown]

export type CompanyAppDomainPutResponse = ResponseTypes.CompanyAppDomain

export type CompanyAppDomainPutVariables = {
  companyId: string | undefined
  companyAppDomainId: string | undefined
  body?: CompanyTypes.CompanyAppDomain
  query?: void
}

export const companyAppDomainPut = makeData<
  CompanyAppDomainPutResponse,
  CompanyAppDomainPutKey,
  CompanyAppDomainPutVariables
>({
  method: 'put',
  name: 'companyAppDomainPut',
  summary: 'Update an app domain for a company',
  path: '/companies/{companyId}/app-domains/{companyAppDomainId}',
  queryKey: ({ query, companyId, companyAppDomainId }) => [
    'companies',
    companyId,
    'app-domains',
    companyAppDomainId,
    query,
  ],
})

export type GetCompanyConfigKey = ['companies', string | undefined, 'config', unknown]

export type GetCompanyConfigResponse = CompanyTypes.CompanyConfigSubsetObject

export type GetCompanyConfigVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    paths?: string
  }
}

export const getCompanyConfig = makeData<GetCompanyConfigResponse, GetCompanyConfigKey, GetCompanyConfigVariables>({
  method: 'get',
  name: 'getCompanyConfig',
  summary: 'Get the company configuration',
  path: '/companies/{companyId}/config',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'config', query],
})

export type SetCompanyConfigKey = ['companies', string | undefined, 'config', unknown]

export type SetCompanyConfigResponse = void

export type SetCompanyConfigVariables = {
  companyId: string | undefined
  body?: CompanyTypes.CompanyConfigSubsetObject
  query?: {
    paths?: string
  }
}

export const setCompanyConfig = makeData<SetCompanyConfigResponse, SetCompanyConfigKey, SetCompanyConfigVariables>({
  method: 'put',
  name: 'setCompanyConfig',
  summary: 'Set the company configuration',
  path: '/companies/{companyId}/config',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'config', query],
})

export type LegacyUpdatePlaidCredentialsKey = ['companies', string | undefined, 'credentials', 'plaid', unknown]

export type LegacyUpdatePlaidCredentialsResponse = void

export type LegacyUpdatePlaidCredentialsVariables = {
  companyId: string | undefined
  body?: CompanyTypes.RequestBodyPlaid
  query?: void
}

export const legacyUpdatePlaidCredentials = makeData<
  LegacyUpdatePlaidCredentialsResponse,
  LegacyUpdatePlaidCredentialsKey,
  LegacyUpdatePlaidCredentialsVariables
>({
  method: 'post',
  name: 'legacyUpdatePlaidCredentials',
  summary: "Update a company's plaid credentials (DEPRECATED)",
  path: '/companies/{companyId}/credentials/plaid',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'credentials', 'plaid', query],
})

export type UpdatePlaidCredentialsKey = ['companies', string | undefined, '3rdparty', 'plaid', 'credentials', unknown]

export type UpdatePlaidCredentialsResponse = void

export type UpdatePlaidCredentialsVariables = {
  companyId: string | undefined
  body?: CompanyTypes.RequestBodyPlaid
  query?: void
}

export const updatePlaidCredentials = makeData<
  UpdatePlaidCredentialsResponse,
  UpdatePlaidCredentialsKey,
  UpdatePlaidCredentialsVariables
>({
  method: 'post',
  name: 'updatePlaidCredentials',
  summary: "Update a company's Plaid credentials",
  path: '/companies/{companyId}/3rdparty/plaid/credentials',
  queryKey: ({ query, companyId }) => ['companies', companyId, '3rdparty', 'plaid', 'credentials', query],
})

export type GetByoTwilioKey = ['companies', string | undefined, '3rdparty', 'byo-twilio', unknown]

export type GetByoTwilioResponse = CompanyTypes.Response200ByoTwilio

export type GetByoTwilioVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const getByoTwilio = makeData<GetByoTwilioResponse, GetByoTwilioKey, GetByoTwilioVariables>({
  method: 'get',
  name: 'getByoTwilio',
  summary: "Get a company's BYO TWilio",
  path: '/companies/{companyId}/3rdparty/byo-twilio',
  queryKey: ({ query, companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio', query],
})

export type UpdateByoTwilioKey = ['companies', string | undefined, '3rdparty', 'byo-twilio', unknown]

export type UpdateByoTwilioResponse = CompanyTypes.Response200ByoTwilio

export type UpdateByoTwilioVariables = {
  companyId: string | undefined
  body?: CompanyTypes.RequestBodyByoTwilio
  query?: {
    /**
     * @default
     *     true
     */
    sync?: boolean
  }
}

export const updateByoTwilio = makeData<UpdateByoTwilioResponse, UpdateByoTwilioKey, UpdateByoTwilioVariables>({
  method: 'put',
  name: 'updateByoTwilio',
  summary: "Update a company's BYO TWilio",
  path: '/companies/{companyId}/3rdparty/byo-twilio',
  queryKey: ({ query, companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio', query],
})

export type SyncByoTwilioKey = ['companies', string | undefined, '3rdparty', 'byo-twilio', 'sync', unknown]

export type SyncByoTwilioResponse = CompanyTypes.Response200ByoTwilio

export type SyncByoTwilioVariables = {
  companyId: string | undefined
  body?: CompanyTypes.RequestBodyByoTwilio
  query?: void
}

export const syncByoTwilio = makeData<SyncByoTwilioResponse, SyncByoTwilioKey, SyncByoTwilioVariables>({
  method: 'post',
  name: 'syncByoTwilio',
  summary: "Sync a company's BYO Twilio settings",
  path: '/companies/{companyId}/3rdparty/byo-twilio/sync',
  queryKey: ({ query, companyId }) => ['companies', companyId, '3rdparty', 'byo-twilio', 'sync', query],
})
