/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as ResponseTypes from '../responses/types'

import * as CardIssuerTypes from './types'

export type CardIssuersListKey = ['card-issuers', unknown]

export type CardIssuersListResponse = CardIssuerTypes.ResponseCardIssuerPagingResponse

export type CardIssuersListVariables = {
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const cardIssuersList = makeData<CardIssuersListResponse, CardIssuersListKey, CardIssuersListVariables>({
  method: 'get',
  name: 'cardIssuersList',
  summary: 'Get card issuers',
  path: '/card-issuers',
  queryKey: ({ query }) => ['card-issuers', query],
})

export type CardIssuerCreateKey = ['card-issuers', unknown]

export type CardIssuerCreateResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type CardIssuerCreateVariables = {
  body?: CardIssuerTypes.RequestBodyCardIssuerPost
  query?: void
}

export const cardIssuerCreate = makeData<CardIssuerCreateResponse, CardIssuerCreateKey, CardIssuerCreateVariables>({
  method: 'post',
  name: 'cardIssuerCreate',
  summary: 'Create card issuer',
  path: '/card-issuers',
  queryKey: ({ query }) => ['card-issuers', query],
})

export type CardIssuerGetKey = ['card-issuers', string | undefined, unknown]

export type CardIssuerGetResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type CardIssuerGetVariables = {
  cardIssuerId: string | undefined
  body?: void
  query?: void
}

export const cardIssuerGet = makeData<CardIssuerGetResponse, CardIssuerGetKey, CardIssuerGetVariables>({
  method: 'get',
  name: 'cardIssuerGet',
  summary: 'Get card issuer by ID',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ query, cardIssuerId }) => ['card-issuers', cardIssuerId, query],
})

export type CardIssuerUpdateKey = ['card-issuers', string | undefined, unknown]

export type CardIssuerUpdateResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type CardIssuerUpdateVariables = {
  cardIssuerId: string | undefined
  body?: CardIssuerTypes.RequestBodyCardIssuerPut
  query?: void
}

export const cardIssuerUpdate = makeData<CardIssuerUpdateResponse, CardIssuerUpdateKey, CardIssuerUpdateVariables>({
  method: 'put',
  name: 'cardIssuerUpdate',
  summary: 'Update card issuer',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ query, cardIssuerId }) => ['card-issuers', cardIssuerId, query],
})

export type CardIssuerDeleteKey = ['card-issuers', string | undefined, unknown]

export type CardIssuerDeleteResponse = void

export type CardIssuerDeleteVariables = {
  cardIssuerId: string | undefined
  body?: void
  query?: void
}

export const cardIssuerDelete = makeData<CardIssuerDeleteResponse, CardIssuerDeleteKey, CardIssuerDeleteVariables>({
  method: 'delete',
  name: 'cardIssuerDelete',
  summary: 'Delete card issuer',
  path: '/card-issuers/{cardIssuerId}',
  queryKey: ({ query, cardIssuerId }) => ['card-issuers', cardIssuerId, query],
})

export type CardsListKey = ['people', string | undefined, 'loans', string | undefined, 'cards', unknown]

export type CardsListResponse = CardIssuerTypes.ResponseCardPagingResponse

export type CardsListVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    showPan?: boolean
    showCvv?: boolean
    refresh?: boolean
  }
}

export const cardsList = makeData<CardsListResponse, CardsListKey, CardsListVariables>({
  method: 'get',
  name: 'cardsList',
  summary: 'Get cards',
  path: '/people/{personId}/loans/{loanId}/cards',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'cards', query],
})

export type CardCreateKey = ['people', string | undefined, 'loans', string | undefined, 'cards', unknown]

export type CardCreateResponse = CardIssuerTypes.ResponseCardResponse

export type CardCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: CardIssuerTypes.RequestBodyCardPost
  query?: void
}

export const cardCreate = makeData<CardCreateResponse, CardCreateKey, CardCreateVariables>({
  method: 'post',
  name: 'cardCreate',
  summary: 'Create card',
  path: '/people/{personId}/loans/{loanId}/cards',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'cards', query],
})

export type CardGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'cards',
  string | undefined,
  unknown,
]

export type CardGetResponse = CardIssuerTypes.ResponseCardResponseWithPanAndCvv

export type CardGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  cardId: string | undefined
  body?: void
  query?: {
    showPan?: boolean
    showCvv?: boolean
    refresh?: boolean
  }
}

export const cardGet = makeData<CardGetResponse, CardGetKey, CardGetVariables>({
  method: 'get',
  name: 'cardGet',
  summary: 'Get card',
  path: '/people/{personId}/loans/{loanId}/cards/{cardId}',
  queryKey: ({ query, personId, loanId, cardId }) => ['people', personId, 'loans', loanId, 'cards', cardId, query],
})

export type CardUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'cards',
  string | undefined,
  unknown,
]

export type CardUpdateResponse = CardIssuerTypes.ResponseCardResponse

export type CardUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  cardId: string | undefined
  body?: CardIssuerTypes.RequestBodyCardPut
  query?: void
}

export const cardUpdate = makeData<CardUpdateResponse, CardUpdateKey, CardUpdateVariables>({
  method: 'put',
  name: 'cardUpdate',
  summary: 'Update card',
  path: '/people/{personId}/loans/{loanId}/cards/{cardId}',
  queryKey: ({ query, personId, loanId, cardId }) => ['people', personId, 'loans', loanId, 'cards', cardId, query],
})

export type InternalCardIssuersListKey = ['companies', string | undefined, 'card-issuers', unknown]

export type InternalCardIssuersListResponse = CardIssuerTypes.ResponseCardIssuerPagingResponse

export type InternalCardIssuersListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const internalCardIssuersList = makeData<
  InternalCardIssuersListResponse,
  InternalCardIssuersListKey,
  InternalCardIssuersListVariables
>({
  method: 'get',
  name: 'internalCardIssuersList',
  summary: 'Get card issuers',
  path: '/companies/{companyId}/card-issuers',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'card-issuers', query],
})

export type InternalCardIssuerCreateKey = ['companies', string | undefined, 'card-issuers', unknown]

export type InternalCardIssuerCreateResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type InternalCardIssuerCreateVariables = {
  companyId: string | undefined
  body?: CardIssuerTypes.RequestBodyCardIssuerPost
  query?: void
}

export const internalCardIssuerCreate = makeData<
  InternalCardIssuerCreateResponse,
  InternalCardIssuerCreateKey,
  InternalCardIssuerCreateVariables
>({
  method: 'post',
  name: 'internalCardIssuerCreate',
  summary: 'Create card issuer',
  path: '/companies/{companyId}/card-issuers',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'card-issuers', query],
})

export type InternalCardIssuerGetKey = ['companies', string | undefined, 'card-issuers', string | undefined, unknown]

export type InternalCardIssuerGetResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type InternalCardIssuerGetVariables = {
  companyId: string | undefined
  cardIssuerId: string | undefined
  body?: void
  query?: void
}

export const internalCardIssuerGet = makeData<
  InternalCardIssuerGetResponse,
  InternalCardIssuerGetKey,
  InternalCardIssuerGetVariables
>({
  method: 'get',
  name: 'internalCardIssuerGet',
  summary: 'Get card issuer by ID',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ query, companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId, query],
})

export type InternalCardIssuerUpdateKey = ['companies', string | undefined, 'card-issuers', string | undefined, unknown]

export type InternalCardIssuerUpdateResponse = CardIssuerTypes.ResponseCardIssuerResponse

export type InternalCardIssuerUpdateVariables = {
  companyId: string | undefined
  cardIssuerId: string | undefined
  body?: CardIssuerTypes.RequestBodyCardIssuerPut
  query?: void
}

export const internalCardIssuerUpdate = makeData<
  InternalCardIssuerUpdateResponse,
  InternalCardIssuerUpdateKey,
  InternalCardIssuerUpdateVariables
>({
  method: 'put',
  name: 'internalCardIssuerUpdate',
  summary: 'Update card issuer',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ query, companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId, query],
})

export type InternalCardIssuerDeleteKey = ['companies', string | undefined, 'card-issuers', string | undefined, unknown]

export type InternalCardIssuerDeleteResponse = void

export type InternalCardIssuerDeleteVariables = {
  companyId: string | undefined
  cardIssuerId: string | undefined
  body?: void
  query?: void
}

export const internalCardIssuerDelete = makeData<
  InternalCardIssuerDeleteResponse,
  InternalCardIssuerDeleteKey,
  InternalCardIssuerDeleteVariables
>({
  method: 'delete',
  name: 'internalCardIssuerDelete',
  summary: 'Delete card issuer',
  path: '/companies/{companyId}/card-issuers/{cardIssuerId}',
  queryKey: ({ query, companyId, cardIssuerId }) => ['companies', companyId, 'card-issuers', cardIssuerId, query],
})

export type MarqetaReceiveEventKey = ['marqeta', string | undefined, 'receive-event', unknown]

export type MarqetaReceiveEventResponse = ResponseTypes.NoContent

export type MarqetaReceiveEventVariables = {
  cardIssuerId: string | undefined
  body?: {
    [key: string]: any
  }
  query?: void
}

export const marqetaReceiveEvent = makeData<
  MarqetaReceiveEventResponse,
  MarqetaReceiveEventKey,
  MarqetaReceiveEventVariables
>({
  method: 'post',
  name: 'marqetaReceiveEvent',
  summary: 'Receive marqeta webhook event',
  path: '/marqeta/{cardIssuerId}/receive-event',
  queryKey: ({ query, cardIssuerId }) => ['marqeta', cardIssuerId, 'receive-event', query],
})

export type ReceiveJitKey = ['marqeta', string | undefined, 'receive-jit', unknown]

export type ReceiveJitResponse = void

export type ReceiveJitVariables = {
  cardIssuerId: string | undefined
  body?: {
    [key: string]: any
  }
  query?: void
}

export const receiveJit = makeData<ReceiveJitResponse, ReceiveJitKey, ReceiveJitVariables>({
  method: 'post',
  name: 'receiveJit',
  summary: 'Receive marqeta JIT funding request',
  path: '/marqeta/{cardIssuerId}/receive-jit',
  queryKey: ({ query, cardIssuerId }) => ['marqeta', cardIssuerId, 'receive-jit', query],
})

export type LithicReceiveEventKey = ['lithic', string | undefined, 'receive-event', unknown]

export type LithicReceiveEventResponse = ResponseTypes.NoContent

export type LithicReceiveEventVariables = {
  cardIssuerId: string | undefined
  body?: {
    [key: string]: any
  }
  query?: void
}

export const lithicReceiveEvent = makeData<
  LithicReceiveEventResponse,
  LithicReceiveEventKey,
  LithicReceiveEventVariables
>({
  method: 'post',
  name: 'lithicReceiveEvent',
  summary: 'Receive lithic webhook event',
  path: '/lithic/{cardIssuerId}/receive-event',
  queryKey: ({ query, cardIssuerId }) => ['lithic', cardIssuerId, 'receive-event', query],
})

export type ReceiveAsaKey = ['lithic', string | undefined, 'receive-asa', unknown]

export type ReceiveAsaResponse = ResponseTypes.NoContent

export type ReceiveAsaVariables = {
  cardIssuerId: string | undefined
  body?: {
    [key: string]: any
  }
  query?: void
}

export const receiveAsa = makeData<ReceiveAsaResponse, ReceiveAsaKey, ReceiveAsaVariables>({
  method: 'post',
  name: 'receiveAsa',
  summary: 'Receive lithic ASA event',
  path: '/lithic/{cardIssuerId}/receive-asa',
  queryKey: ({ query, cardIssuerId }) => ['lithic', cardIssuerId, 'receive-asa', query],
})
