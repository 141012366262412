/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as ResponseTypes from '../responses/types'
import * as SchemaTypes from '../schema/types'

import * as CreditAgencyTypes from './types'

export type CreditAgenciesListKey = ['companies', string | undefined, 'credit-agencies', unknown]

export type CreditAgenciesListResponse = CreditAgencyTypes.ResponseCreditAgencyPagingResponse

export type CreditAgenciesListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    uploadedByLender?: boolean
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const creditAgenciesList = makeData<
  CreditAgenciesListResponse,
  CreditAgenciesListKey,
  CreditAgenciesListVariables
>({
  method: 'get',
  name: 'creditAgenciesList',
  summary: 'Get credit agencies',
  path: '/companies/{companyId}/credit-agencies',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'credit-agencies', query],
})

export type CreditAgencyCreateKey = ['companies', string | undefined, 'credit-agencies', unknown]

export type CreditAgencyCreateResponse = CreditAgencyTypes.ResponseCreditAgencyResponse

export type CreditAgencyCreateVariables = {
  companyId: string | undefined
  body?: CreditAgencyTypes.RequestBodyCreditAgencyPost
  query?: void
}

export const creditAgencyCreate = makeData<
  CreditAgencyCreateResponse,
  CreditAgencyCreateKey,
  CreditAgencyCreateVariables
>({
  method: 'post',
  name: 'creditAgencyCreate',
  summary: 'Create credit agency',
  path: '/companies/{companyId}/credit-agencies',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'credit-agencies', query],
})

export type ReportedLoansKey = ['companies', string | undefined, 'credit-reporting', 'reported-loans', unknown]

export type ReportedLoansResponse = CreditAgencyTypes.ResponseReportedLoanPagingResponse

export type ReportedLoansVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    loanTypeId?: Array<SchemaTypes.AnyId>
    agencyName?: 'experian' | 'equifax' | 'transUnion' | 'innovis'
    reportDate: string
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const reportedLoans = makeData<ReportedLoansResponse, ReportedLoansKey, ReportedLoansVariables>({
  method: 'get',
  name: 'reportedLoans',
  summary: 'Get reported loans',
  path: '/companies/{companyId}/credit-reporting/reported-loans',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'credit-reporting', 'reported-loans', query],
})

export type CreditAgencyGetKey = ['companies', string | undefined, 'credit-agencies', string | undefined, unknown]

export type CreditAgencyGetResponse = CreditAgencyTypes.ResponseCreditAgencyResponse

export type CreditAgencyGetVariables = {
  companyId: string | undefined
  creditAgencyId: string | undefined
  body?: void
  query?: void
}

export const creditAgencyGet = makeData<CreditAgencyGetResponse, CreditAgencyGetKey, CreditAgencyGetVariables>({
  method: 'get',
  name: 'creditAgencyGet',
  summary: 'Get credit agency by ID',
  path: '/companies/{companyId}/credit-agencies/{creditAgencyId}',
  queryKey: ({ query, companyId, creditAgencyId }) => [
    'companies',
    companyId,
    'credit-agencies',
    creditAgencyId,
    query,
  ],
})

export type CreditAgencyUpdateKey = ['companies', string | undefined, 'credit-agencies', string | undefined, unknown]

export type CreditAgencyUpdateResponse = CreditAgencyTypes.ResponseCreditAgencyResponse

export type CreditAgencyUpdateVariables = {
  companyId: string | undefined
  creditAgencyId: string | undefined
  body?: CreditAgencyTypes.RequestBodyCreditAgencyPut
  query?: void
}

export const creditAgencyUpdate = makeData<
  CreditAgencyUpdateResponse,
  CreditAgencyUpdateKey,
  CreditAgencyUpdateVariables
>({
  method: 'put',
  name: 'creditAgencyUpdate',
  summary: 'Update credit agency',
  path: '/companies/{companyId}/credit-agencies/{creditAgencyId}',
  queryKey: ({ query, companyId, creditAgencyId }) => [
    'companies',
    companyId,
    'credit-agencies',
    creditAgencyId,
    query,
  ],
})

export type GenerateMetro2FilesKey = ['companies', string | undefined, 'generate-metro2-files', unknown]

export type GenerateMetro2FilesResponse = CreditAgencyTypes.ResponseGenerateMetro2FilesResponse

export type GenerateMetro2FilesVariables = {
  companyId: string | undefined
  body?: CreditAgencyTypes.RequestBodyGenerateMetro2FilesPost
  query?: void
}

export const generateMetro2Files = makeData<
  GenerateMetro2FilesResponse,
  GenerateMetro2FilesKey,
  GenerateMetro2FilesVariables
>({
  method: 'post',
  name: 'generateMetro2Files',
  summary: 'Generate company credit reports',
  path: '/companies/{companyId}/generate-metro2-files',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'generate-metro2-files', query],
})

export type CreditReportingStatusesListKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-reporting',
  unknown,
]

export type CreditReportingStatusesListResponse = CreditAgencyTypes.ResponseCreditReportingStatusPagingResponse

export type CreditReportingStatusesListVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const creditReportingStatusesList = makeData<
  CreditReportingStatusesListResponse,
  CreditReportingStatusesListKey,
  CreditReportingStatusesListVariables
>({
  method: 'get',
  name: 'creditReportingStatusesList',
  summary: 'Get credit reporting statuses',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting', query],
})

export type CreditReportingFieldsUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-reporting',
  unknown,
]

export type CreditReportingFieldsUpdateResponse = ResponseTypes.Loan

export type CreditReportingFieldsUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: CreditAgencyTypes.RequestBodyCreditReportingFieldsPut
  query?: void
}

export const creditReportingFieldsUpdate = makeData<
  CreditReportingFieldsUpdateResponse,
  CreditReportingFieldsUpdateKey,
  CreditReportingFieldsUpdateVariables
>({
  method: 'put',
  name: 'creditReportingFieldsUpdate',
  summary: 'Update reporting fields',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting', query],
})

export type CreditReportingStatusCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-reporting',
  unknown,
]

export type CreditReportingStatusCreateResponse = CreditAgencyTypes.ResponseCreditReportingStatusResponse

export type CreditReportingStatusCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: CreditAgencyTypes.RequestBodyCreditReportingStatusPost
  query?: void
}

export const creditReportingStatusCreate = makeData<
  CreditReportingStatusCreateResponse,
  CreditReportingStatusCreateKey,
  CreditReportingStatusCreateVariables
>({
  method: 'post',
  name: 'creditReportingStatusCreate',
  summary: 'Start credit reporting',
  path: '/people/{personId}/loans/{loanId}/credit-reporting',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'credit-reporting', query],
})

export type CreditReportingStatusDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-reporting',
  string | undefined,
  'delete',
  unknown,
]

export type CreditReportingStatusDeleteResponse = CreditAgencyTypes.ResponseCreditReportingStatusResponse

export type CreditReportingStatusDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  creditAgencyId: string | undefined
  body?: CreditAgencyTypes.RequestBodyCreditReportingStatusDelete
  query?: void
}

export const creditReportingStatusDelete = makeData<
  CreditReportingStatusDeleteResponse,
  CreditReportingStatusDeleteKey,
  CreditReportingStatusDeleteVariables
>({
  method: 'post',
  name: 'creditReportingStatusDelete',
  summary: 'Delete from credit reporting',
  path: '/people/{personId}/loans/{loanId}/credit-reporting/{creditAgencyId}/delete',
  queryKey: ({ query, personId, loanId, creditAgencyId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'credit-reporting',
    creditAgencyId,
    'delete',
    query,
  ],
})

export type GetMetro2DataKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'credit-reporting',
  string | undefined,
  'metro2-data',
  unknown,
]

export type GetMetro2DataResponse = {}

export type GetMetro2DataVariables = {
  personId: string | undefined
  loanId: string | undefined
  agencyName: string | undefined
  body?: void
  query?: {
    segments?: Array<'header' | 'baseSegment' | 'k1Segment' | 'k2Segment'>
    reportDate?: string
  }
}

export const getMetro2Data = makeData<GetMetro2DataResponse, GetMetro2DataKey, GetMetro2DataVariables>({
  method: 'get',
  name: 'getMetro2Data',
  summary: 'Get metro2 data',
  path: '/people/{personId}/loans/{loanId}/credit-reporting/{agencyName}/metro2-data',
  queryKey: ({ query, personId, loanId, agencyName }) => [
    'people',
    personId,
    'loans',
    loanId,
    'credit-reporting',
    agencyName,
    'metro2-data',
    query,
  ],
})

export type FileCreationRecordCreateKey = ['credit-reporting', 'create-file-record', unknown]

export type FileCreationRecordCreateResponse = {}

export type FileCreationRecordCreateVariables = {
  body?: CreditAgencyTypes.RequestBodyFileCreationRecordPost
  query?: void
}

export const fileCreationRecordCreate = makeData<
  FileCreationRecordCreateResponse,
  FileCreationRecordCreateKey,
  FileCreationRecordCreateVariables
>({
  method: 'post',
  name: 'fileCreationRecordCreate',
  summary: 'Create file log record',
  path: '/credit-reporting/create-file-record',
  queryKey: ({ query }) => ['credit-reporting', 'create-file-record', query],
})
