/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as DocustoreTypes from './types'

export type GetDocumentUploadLinksKey = ['people', string | undefined, 'document-upload-links', unknown]

export type GetDocumentUploadLinksResponse = Array<DocustoreTypes.DocumentUploadLink>

export type GetDocumentUploadLinksVariables = {
  personId: string | undefined
  body?: void
  query?: void
}

export const getDocumentUploadLinks = makeData<
  GetDocumentUploadLinksResponse,
  GetDocumentUploadLinksKey,
  GetDocumentUploadLinksVariables
>({
  method: 'get',
  name: 'getDocumentUploadLinks',
  summary: 'Get document upload links',
  path: '/people/{personId}/document-upload-links',
  queryKey: ({ query, personId }) => ['people', personId, 'document-upload-links', query],
})

export type CreateDocumentUploadLinkKey = ['people', string | undefined, 'document-upload-links', unknown]

export type CreateDocumentUploadLinkResponse = DocustoreTypes.DocumentUploadLink

export type CreateDocumentUploadLinkVariables = {
  personId: string | undefined
  body?: DocustoreTypes.DocumentUploadLink
  query?: void
}

export const createDocumentUploadLink = makeData<
  CreateDocumentUploadLinkResponse,
  CreateDocumentUploadLinkKey,
  CreateDocumentUploadLinkVariables
>({
  method: 'post',
  name: 'createDocumentUploadLink',
  summary: 'Create document upload link',
  path: '/people/{personId}/document-upload-links',
  queryKey: ({ query, personId }) => ['people', personId, 'document-upload-links', query],
})

export type GetDocumentUploadLinkKey = [
  'people',
  string | undefined,
  'document-upload-links',
  string | undefined,
  unknown,
]

export type GetDocumentUploadLinkResponse = DocustoreTypes.DocumentUploadLink

export type GetDocumentUploadLinkVariables = {
  personId: string | undefined
  documentUploadLinkId: string | undefined
  body?: void
  query?: void
}

export const getDocumentUploadLink = makeData<
  GetDocumentUploadLinkResponse,
  GetDocumentUploadLinkKey,
  GetDocumentUploadLinkVariables
>({
  method: 'get',
  name: 'getDocumentUploadLink',
  summary: 'Get doc upload link by ID',
  path: '/people/{personId}/document-upload-links/{documentUploadLinkId}',
  queryKey: ({ query, personId, documentUploadLinkId }) => [
    'people',
    personId,
    'document-upload-links',
    documentUploadLinkId,
    query,
  ],
})

export type ListPersonDocumentsKey = ['people', string | undefined, 'documents', unknown]

export type ListPersonDocumentsResponse = Array<DocustoreTypes.DocumentDescriptorResponse>

export type ListPersonDocumentsVariables = {
  personId: string | undefined
  body?: void
  query?: {
    type?: Array<DocustoreTypes.DocumentType>
    loanId?: string
    includeArchived?: boolean
    uploadedBy?: string
    autogenerated?: boolean
    isHumanBorrowerUpload?: boolean
  }
}

export const listPersonDocuments = makeData<
  ListPersonDocumentsResponse,
  ListPersonDocumentsKey,
  ListPersonDocumentsVariables
>({
  method: 'get',
  name: 'listPersonDocuments',
  summary: 'Get document descriptors',
  path: '/people/{personId}/documents',
  queryKey: ({ query, personId }) => ['people', personId, 'documents', query],
})

export type CreatePersonDocumentKey = ['people', string | undefined, 'documents', unknown]

export type CreatePersonDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type CreatePersonDocumentVariables = {
  personId: string | undefined
  body?: DocustoreTypes.DocumentDescriptorCreate
  query?: void
}

export const createPersonDocument = makeData<
  CreatePersonDocumentResponse,
  CreatePersonDocumentKey,
  CreatePersonDocumentVariables
>({
  method: 'post',
  name: 'createPersonDocument',
  summary: 'Create new document descriptor',
  path: '/people/{personId}/documents',
  queryKey: ({ query, personId }) => ['people', personId, 'documents', query],
})

export type ListCompanyDocumentsKey = ['companies', string | undefined, 'documents', unknown]

export type ListCompanyDocumentsResponse = Array<DocustoreTypes.DocumentDescriptorResponse>

export type ListCompanyDocumentsVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    type?: Array<DocustoreTypes.DocumentType>
    personId?: string | null
    includeArchived?: boolean
    autogenerated?: boolean
    isHumanBorrowerUpload?: boolean
  }
}

export const listCompanyDocuments = makeData<
  ListCompanyDocumentsResponse,
  ListCompanyDocumentsKey,
  ListCompanyDocumentsVariables
>({
  method: 'get',
  name: 'listCompanyDocuments',
  summary: 'Get document descriptors',
  path: '/companies/{companyId}/documents',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'documents', query],
})

export type CreateCompanyDocumentKey = ['companies', string | undefined, 'documents', unknown]

export type CreateCompanyDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type CreateCompanyDocumentVariables = {
  companyId: string | undefined
  body?: DocustoreTypes.DocumentDescriptorCreate
  query?: void
}

export const createCompanyDocument = makeData<
  CreateCompanyDocumentResponse,
  CreateCompanyDocumentKey,
  CreateCompanyDocumentVariables
>({
  method: 'post',
  name: 'createCompanyDocument',
  summary: 'Create new document descriptor',
  path: '/companies/{companyId}/documents',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'documents', query],
})

export type GetPersonDocumentKey = ['people', string | undefined, 'documents', string | undefined, unknown]

export type GetPersonDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type GetPersonDocumentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: void
}

export const getPersonDocument = makeData<GetPersonDocumentResponse, GetPersonDocumentKey, GetPersonDocumentVariables>({
  method: 'get',
  name: 'getPersonDocument',
  summary: 'Get document descriptor by ID',
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    query,
  ],
})

export type UpdatePersonDocumentKey = ['people', string | undefined, 'documents', string | undefined, unknown]

export type UpdatePersonDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type UpdatePersonDocumentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: DocustoreTypes.DocumentDescriptorUpdate
  query?: void
}

export const updatePersonDocument = makeData<
  UpdatePersonDocumentResponse,
  UpdatePersonDocumentKey,
  UpdatePersonDocumentVariables
>({
  method: 'put',
  name: 'updatePersonDocument',
  summary: 'Update document descriptor',
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    query,
  ],
})

export type DeleteDocumentKey = ['people', string | undefined, 'documents', string | undefined, unknown]

export type DeleteDocumentResponse = void

export type DeleteDocumentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: void
}

export const deleteDocument = makeData<DeleteDocumentResponse, DeleteDocumentKey, DeleteDocumentVariables>({
  method: 'delete',
  name: 'deleteDocument',
  summary: 'Delete document',
  path: '/people/{personId}/documents/{documentDescriptorId}',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    query,
  ],
})

export type GetCompanyDocumentKey = ['companies', string | undefined, 'documents', string | undefined, unknown]

export type GetCompanyDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type GetCompanyDocumentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: void
}

export const getCompanyDocument = makeData<
  GetCompanyDocumentResponse,
  GetCompanyDocumentKey,
  GetCompanyDocumentVariables
>({
  method: 'get',
  name: 'getCompanyDocument',
  summary: 'Get document descriptor by ID',
  path: '/companies/{companyId}/documents/{documentDescriptorId}',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    query,
  ],
})

export type UpdateCompanyDocumentKey = ['companies', string | undefined, 'documents', string | undefined, unknown]

export type UpdateCompanyDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type UpdateCompanyDocumentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: DocustoreTypes.DocumentDescriptorUpdate
  query?: void
}

export const updateCompanyDocument = makeData<
  UpdateCompanyDocumentResponse,
  UpdateCompanyDocumentKey,
  UpdateCompanyDocumentVariables
>({
  method: 'put',
  name: 'updateCompanyDocument',
  summary: 'Update document descriptor',
  path: '/companies/{companyId}/documents/{documentDescriptorId}',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    query,
  ],
})

export type DownloadPersonDocumentContentKey = [
  'people',
  string | undefined,
  'documents',
  string | undefined,
  'content',
  unknown,
]

export type DownloadPersonDocumentContentResponse = void

export type DownloadPersonDocumentContentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    token?: string
    returnUrl?: boolean
  }
}

export const downloadPersonDocumentContent = makeData<
  DownloadPersonDocumentContentResponse,
  DownloadPersonDocumentContentKey,
  DownloadPersonDocumentContentVariables
>({
  method: 'get',
  name: 'downloadPersonDocumentContent',
  summary: 'Download document content',
  path: '/people/{personId}/documents/{documentDescriptorId}/content',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    'content',
    query,
  ],
})

export type UploadPersonDocumentContentKey = [
  'people',
  string | undefined,
  'documents',
  string | undefined,
  'content',
  unknown,
]

export type UploadPersonDocumentContentResponse = DocustoreTypes.DocumentDescriptorResponse

export type UploadPersonDocumentContentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: FormData
  query?: void
}

export const uploadPersonDocumentContent = makeData<
  UploadPersonDocumentContentResponse,
  UploadPersonDocumentContentKey,
  UploadPersonDocumentContentVariables
>({
  method: 'post',
  name: 'uploadPersonDocumentContent',
  summary: 'Upload document content',
  path: '/people/{personId}/documents/{documentDescriptorId}/content',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    'content',
    query,
  ],
})

export type DownloadCompanyDocumentContentKey = [
  'companies',
  string | undefined,
  'documents',
  string | undefined,
  'content',
  unknown,
]

export type DownloadCompanyDocumentContentResponse = void

export type DownloadCompanyDocumentContentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: {
    disposition?: 'inline' | 'attachment'
    token?: string
    returnUrl?: boolean
  }
}

export const downloadCompanyDocumentContent = makeData<
  DownloadCompanyDocumentContentResponse,
  DownloadCompanyDocumentContentKey,
  DownloadCompanyDocumentContentVariables
>({
  method: 'get',
  name: 'downloadCompanyDocumentContent',
  summary: 'Download document content',
  path: '/companies/{companyId}/documents/{documentDescriptorId}/content',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'content',
    query,
  ],
})

export type UploadCompanyDocumentContentKey = [
  'companies',
  string | undefined,
  'documents',
  string | undefined,
  'content',
  unknown,
]

export type UploadCompanyDocumentContentResponse = void

export type UploadCompanyDocumentContentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: FormData
  query?: void
}

export const uploadCompanyDocumentContent = makeData<
  UploadCompanyDocumentContentResponse,
  UploadCompanyDocumentContentKey,
  UploadCompanyDocumentContentVariables
>({
  method: 'post',
  name: 'uploadCompanyDocumentContent',
  summary: 'Upload document content',
  path: '/companies/{companyId}/documents/{documentDescriptorId}/content',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'content',
    query,
  ],
})

export type ConvertPersonDocumentKey = [
  'people',
  string | undefined,
  'documents',
  string | undefined,
  'convert',
  unknown,
]

export type ConvertPersonDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type ConvertPersonDocumentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: {
    format: 'pdf'
    sign?: boolean
    deleteSource?: boolean
  }
}

export const convertPersonDocument = makeData<
  ConvertPersonDocumentResponse,
  ConvertPersonDocumentKey,
  ConvertPersonDocumentVariables
>({
  method: 'post',
  name: 'convertPersonDocument',
  summary: 'Convert document to the specified format',
  path: '/people/{personId}/documents/{documentDescriptorId}/convert',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    'convert',
    query,
  ],
})

export type ConvertCompanyDocumentKey = [
  'companies',
  string | undefined,
  'documents',
  string | undefined,
  'convert',
  unknown,
]

export type ConvertCompanyDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type ConvertCompanyDocumentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: {
    format: 'pdf'
    sign?: boolean
    deleteSource?: boolean
  }
}

export const convertCompanyDocument = makeData<
  ConvertCompanyDocumentResponse,
  ConvertCompanyDocumentKey,
  ConvertCompanyDocumentVariables
>({
  method: 'post',
  name: 'convertCompanyDocument',
  summary: 'Convert document to the specified format',
  path: '/companies/{companyId}/documents/{documentDescriptorId}/convert',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'convert',
    query,
  ],
})

export type SignPersonPdfDocumentKey = ['people', string | undefined, 'documents', string | undefined, 'sign', unknown]

export type SignPersonPdfDocumentResponse = DocustoreTypes.DocumentDescriptorResponse

export type SignPersonPdfDocumentVariables = {
  personId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: void
}

export const signPersonPdfDocument = makeData<
  SignPersonPdfDocumentResponse,
  SignPersonPdfDocumentKey,
  SignPersonPdfDocumentVariables
>({
  method: 'post',
  name: 'signPersonPdfDocument',
  summary: 'Digitally sign document',
  path: '/people/{personId}/documents/{documentDescriptorId}/sign',
  queryKey: ({ query, personId, documentDescriptorId }) => [
    'people',
    personId,
    'documents',
    documentDescriptorId,
    'sign',
    query,
  ],
})

export type SignCompanyPdfDocumentKey = [
  'companies',
  string | undefined,
  'documents',
  string | undefined,
  'sign',
  unknown,
]

export type SignCompanyPdfDocumentResponse = void

export type SignCompanyPdfDocumentVariables = {
  companyId: string | undefined
  documentDescriptorId: string | undefined
  body?: void
  query?: void
}

export const signCompanyPdfDocument = makeData<
  SignCompanyPdfDocumentResponse,
  SignCompanyPdfDocumentKey,
  SignCompanyPdfDocumentVariables
>({
  method: 'post',
  name: 'signCompanyPdfDocument',
  summary: 'Digitally sign document',
  path: '/companies/{companyId}/documents/{documentDescriptorId}/sign',
  queryKey: ({ query, companyId, documentDescriptorId }) => [
    'companies',
    companyId,
    'documents',
    documentDescriptorId,
    'sign',
    query,
  ],
})

export type CreateDocumentViaLinkKey = ['document-uploads', string | undefined, 'documents', unknown]

export type CreateDocumentViaLinkResponse = DocustoreTypes.DocumentDescriptorResponse

export type CreateDocumentViaLinkVariables = {
  documentUploadLinkKey: string | undefined
  body?: DocustoreTypes.DocumentDescriptorCreate
  query?: void
}

export const createDocumentViaLink = makeData<
  CreateDocumentViaLinkResponse,
  CreateDocumentViaLinkKey,
  CreateDocumentViaLinkVariables
>({
  method: 'post',
  name: 'createDocumentViaLink',
  summary: 'Create new document via link',
  path: '/document-uploads/{documentUploadLinkKey}/documents',
  queryKey: ({ query, documentUploadLinkKey }) => ['document-uploads', documentUploadLinkKey, 'documents', query],
})

export type UploadDocumentContentViaLinkKey = [
  'document-uploads',
  string | undefined,
  'documents',
  string | undefined,
  'content',
  unknown,
]

export type UploadDocumentContentViaLinkResponse = void

export type UploadDocumentContentViaLinkVariables = {
  documentUploadLinkKey: string | undefined
  documentDescriptorId: string | undefined
  body?: FormData
  query?: void
}

export const uploadDocumentContentViaLink = makeData<
  UploadDocumentContentViaLinkResponse,
  UploadDocumentContentViaLinkKey,
  UploadDocumentContentViaLinkVariables
>({
  method: 'post',
  name: 'uploadDocumentContentViaLink',
  summary: 'Upload document content via link',
  path: '/document-uploads/{documentUploadLinkKey}/documents/{documentDescriptorId}/content',
  queryKey: ({ query, documentUploadLinkKey, documentDescriptorId }) => [
    'document-uploads',
    documentUploadLinkKey,
    'documents',
    documentDescriptorId,
    'content',
    query,
  ],
})
