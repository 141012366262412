/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'

import * as CollectionAgencyTypes from './types'

export type CollectionAgenciesListKey = ['companies', string | undefined, 'collection-agencies', unknown]

export type CollectionAgenciesListResponse = CollectionAgencyTypes.ResponseCollectionAgencyPagingResponse

export type CollectionAgenciesListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    includeArchived?: boolean
  }
}

export const collectionAgenciesList = makeData<
  CollectionAgenciesListResponse,
  CollectionAgenciesListKey,
  CollectionAgenciesListVariables
>({
  method: 'get',
  name: 'collectionAgenciesList',
  summary: 'Get collection agencies',
  path: '/companies/{companyId}/collection-agencies',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'collection-agencies', query],
})

export type CollectionAgencyCreateKey = ['companies', string | undefined, 'collection-agencies', unknown]

export type CollectionAgencyCreateResponse = CollectionAgencyTypes.ResponseCollectionAgencyResponse

export type CollectionAgencyCreateVariables = {
  companyId: string | undefined
  body?: CollectionAgencyTypes.RequestBodyCollectionAgencyPost
  query?: void
}

export const collectionAgencyCreate = makeData<
  CollectionAgencyCreateResponse,
  CollectionAgencyCreateKey,
  CollectionAgencyCreateVariables
>({
  method: 'post',
  name: 'collectionAgencyCreate',
  summary: 'Create collection agency',
  path: '/companies/{companyId}/collection-agencies',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'collection-agencies', query],
})

export type CollectionAgencyGetKey = [
  'companies',
  string | undefined,
  'collection-agencies',
  string | undefined,
  unknown,
]

export type CollectionAgencyGetResponse = CollectionAgencyTypes.ResponseCollectionAgencyResponse

export type CollectionAgencyGetVariables = {
  companyId: string | undefined
  collectionAgencyId: string | undefined
  body?: void
  query?: void
}

export const collectionAgencyGet = makeData<
  CollectionAgencyGetResponse,
  CollectionAgencyGetKey,
  CollectionAgencyGetVariables
>({
  method: 'get',
  name: 'collectionAgencyGet',
  summary: 'Get collection agency by ID',
  path: '/companies/{companyId}/collection-agencies/{collectionAgencyId}',
  queryKey: ({ query, companyId, collectionAgencyId }) => [
    'companies',
    companyId,
    'collection-agencies',
    collectionAgencyId,
    query,
  ],
})

export type CollectionAgencyUpdateKey = [
  'companies',
  string | undefined,
  'collection-agencies',
  string | undefined,
  unknown,
]

export type CollectionAgencyUpdateResponse = CollectionAgencyTypes.ResponseCollectionAgencyResponse

export type CollectionAgencyUpdateVariables = {
  companyId: string | undefined
  collectionAgencyId: string | undefined
  body?: CollectionAgencyTypes.RequestBodyCollectionAgencyPut
  query?: void
}

export const collectionAgencyUpdate = makeData<
  CollectionAgencyUpdateResponse,
  CollectionAgencyUpdateKey,
  CollectionAgencyUpdateVariables
>({
  method: 'put',
  name: 'collectionAgencyUpdate',
  summary: 'Update collection agency',
  path: '/companies/{companyId}/collection-agencies/{collectionAgencyId}',
  queryKey: ({ query, companyId, collectionAgencyId }) => [
    'companies',
    companyId,
    'collection-agencies',
    collectionAgencyId,
    query,
  ],
})

export type CollectionAgencyDeleteKey = [
  'companies',
  string | undefined,
  'collection-agencies',
  string | undefined,
  unknown,
]

export type CollectionAgencyDeleteResponse = void

export type CollectionAgencyDeleteVariables = {
  companyId: string | undefined
  collectionAgencyId: string | undefined
  body?: void
  query?: void
}

export const collectionAgencyDelete = makeData<
  CollectionAgencyDeleteResponse,
  CollectionAgencyDeleteKey,
  CollectionAgencyDeleteVariables
>({
  method: 'delete',
  name: 'collectionAgencyDelete',
  summary: 'Delete collection agency',
  path: '/companies/{companyId}/collection-agencies/{collectionAgencyId}',
  queryKey: ({ query, companyId, collectionAgencyId }) => [
    'companies',
    companyId,
    'collection-agencies',
    collectionAgencyId,
    query,
  ],
})

export type CollectionAgencyLoanDetailsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'collection-agency',
  unknown,
]

export type CollectionAgencyLoanDetailsGetResponse = CollectionAgencyTypes.ResponseCollectionAgencyLoanDetailsResponse

export type CollectionAgencyLoanDetailsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const collectionAgencyLoanDetailsGet = makeData<
  CollectionAgencyLoanDetailsGetResponse,
  CollectionAgencyLoanDetailsGetKey,
  CollectionAgencyLoanDetailsGetVariables
>({
  method: 'get',
  name: 'collectionAgencyLoanDetailsGet',
  summary: 'Get collection agency',
  path: '/people/{personId}/loans/{loanId}/collection-agency',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'collection-agency', query],
})

export type CollectionAgencyLoanDetailsUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'collection-agency',
  unknown,
]

export type CollectionAgencyLoanDetailsUpdateResponse =
  CollectionAgencyTypes.ResponseCollectionAgencyLoanDetailsResponse

export type CollectionAgencyLoanDetailsUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: CollectionAgencyTypes.RequestBodyCollectionAgencyLoanDetailsPut
  query?: void
}

export const collectionAgencyLoanDetailsUpdate = makeData<
  CollectionAgencyLoanDetailsUpdateResponse,
  CollectionAgencyLoanDetailsUpdateKey,
  CollectionAgencyLoanDetailsUpdateVariables
>({
  method: 'post',
  name: 'collectionAgencyLoanDetailsUpdate',
  summary: 'Update collection agency',
  path: '/people/{personId}/loans/{loanId}/collection-agency',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'collection-agency', query],
})

export type CollectionAgencyLoanDetailsUnassignKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'collection-agency',
  unknown,
]

export type CollectionAgencyLoanDetailsUnassignResponse =
  CollectionAgencyTypes.ResponseCollectionAgencyLoanDetailsResponse

export type CollectionAgencyLoanDetailsUnassignVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    servicedBy: SchemaTypes.LoanServicer
  }
}

export const collectionAgencyLoanDetailsUnassign = makeData<
  CollectionAgencyLoanDetailsUnassignResponse,
  CollectionAgencyLoanDetailsUnassignKey,
  CollectionAgencyLoanDetailsUnassignVariables
>({
  method: 'delete',
  name: 'collectionAgencyLoanDetailsUnassign',
  summary: 'Unassign collection agency',
  path: '/people/{personId}/loans/{loanId}/collection-agency',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'collection-agency', query],
})
