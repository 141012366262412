/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as CaseTypes from '../cases/types'
import * as ResponseTypes from '../responses/types'
import * as UtilityTypes from '../utilities/types'

import * as TwilioTaskTypes from './types'

export type GetTasktypeAvailabilityKey = ['twilio', 'tasktype-availability', unknown]

export type GetTasktypeAvailabilityResponse = UtilityTypes.SingletonResponse & {
  data?: {
    availableByUserConfig?: {}
    availableByDefault?: {}
    permitted?: {}
  }
}

export type GetTasktypeAvailabilityVariables = {
  body?: void
  query?: void
}

export const getTasktypeAvailability = makeData<
  GetTasktypeAvailabilityResponse,
  GetTasktypeAvailabilityKey,
  GetTasktypeAvailabilityVariables
>({
  method: 'get',
  name: 'getTasktypeAvailability',
  summary: 'Get task availability',
  path: '/twilio/tasktype-availability',
  queryKey: ({ query }) => ['twilio', 'tasktype-availability', query],
})

export type UpdateTasktypeAvailabilityKey = ['twilio', 'tasktype-availability', unknown]

export type UpdateTasktypeAvailabilityResponse = UtilityTypes.SingletonResponse & {
  data?: {
    availableByUserConfig?: {}
    availableByDefault?: {}
    permitted?: {}
  }
}

export type UpdateTasktypeAvailabilityVariables = {
  body?: {
    availableByUserConfig?: {}
  }
  query?: void
}

export const updateTasktypeAvailability = makeData<
  UpdateTasktypeAvailabilityResponse,
  UpdateTasktypeAvailabilityKey,
  UpdateTasktypeAvailabilityVariables
>({
  method: 'put',
  name: 'updateTasktypeAvailability',
  summary: 'Update task type availability',
  path: '/twilio/tasktype-availability',
  queryKey: ({ query }) => ['twilio', 'tasktype-availability', query],
})

export type TaskHistoryListKey = ['companies', string | undefined, 'twilio-terminated-tasks', unknown]

export type TaskHistoryListResponse = void

export type TaskHistoryListVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const taskHistoryList = makeData<TaskHistoryListResponse, TaskHistoryListKey, TaskHistoryListVariables>({
  method: 'get',
  name: 'taskHistoryList',
  summary: 'List Terminated Tasks',
  path: '/companies/{companyId}/twilio-terminated-tasks',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-terminated-tasks', query],
})

export type TaskHistoryGetKey = [
  'companies',
  string | undefined,
  'twilio-terminated-tasks',
  string | undefined,
  unknown,
]

export type TaskHistoryGetResponse = void

export type TaskHistoryGetVariables = {
  companyId: string | undefined
  taskHistoryId: string | undefined
  body?: void
  query?: void
}

export const taskHistoryGet = makeData<TaskHistoryGetResponse, TaskHistoryGetKey, TaskHistoryGetVariables>({
  method: 'get',
  name: 'taskHistoryGet',
  summary: 'Get Terminated Task by ID',
  path: '/companies/{companyId}/twilio-terminated-tasks/{taskHistoryId}',
  queryKey: ({ query, companyId, taskHistoryId }) => [
    'companies',
    companyId,
    'twilio-terminated-tasks',
    taskHistoryId,
    query,
  ],
})

export type TaskHistoryCloneKey = [
  'companies',
  string | undefined,
  'twilio-terminated-tasks',
  string | undefined,
  'clone',
  unknown,
]

export type TaskHistoryCloneResponse = void

export type TaskHistoryCloneVariables = {
  companyId: string | undefined
  taskHistoryId: string | undefined
  body?: void
  query?: void
}

export const taskHistoryClone = makeData<TaskHistoryCloneResponse, TaskHistoryCloneKey, TaskHistoryCloneVariables>({
  method: 'post',
  name: 'taskHistoryClone',
  summary: 'Clone Terminated Task by ID',
  path: '/companies/{companyId}/twilio-terminated-tasks/{taskHistoryId}/clone',
  queryKey: ({ query, companyId, taskHistoryId }) => [
    'companies',
    companyId,
    'twilio-terminated-tasks',
    taskHistoryId,
    'clone',
    query,
  ],
})

export type ListWorkersKey = ['companies', string | undefined, 'twilio-workers', unknown]

export type ListWorkersResponse = {}

export type ListWorkersVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    include?: Array<'statsToday'>
  }
}

export const listWorkers = makeData<ListWorkersResponse, ListWorkersKey, ListWorkersVariables>({
  method: 'get',
  name: 'listWorkers',
  summary: 'List Taskrouter Workers',
  path: '/companies/{companyId}/twilio-workers',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-workers', query],
})

export type ListUnterminatedTasksKey = ['companies', string | undefined, 'twilio-unterminated-tasks', unknown]

export type ListUnterminatedTasksResponse = {
  data?: Array<TwilioTaskTypes.Task>
}

export type ListUnterminatedTasksVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    include?: Array<'reservation'>
    sortBy?: Array<string>
    taskQueueSid?: string
    assignmentStatus?: Array<'pending' | 'reserved' | 'assigned' | 'wrapping'>
    taskType?: Array<TwilioTaskTypes.TaskType>
    caseType?: Array<CaseTypes.BaseCaseType>
    caseId?: Array<string>
    supercaseId?: Array<string>
    interactionId?: Array<string>
    unableToComplete?: boolean
    taskAttrMatchExpr?: string
    hasCase?: boolean
    hasCaseOwner?: boolean
  }
}

export const listUnterminatedTasks = makeData<
  ListUnterminatedTasksResponse,
  ListUnterminatedTasksKey,
  ListUnterminatedTasksVariables
>({
  method: 'get',
  name: 'listUnterminatedTasks',
  summary: 'List Unterminated Tasks',
  path: '/companies/{companyId}/twilio-unterminated-tasks',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-unterminated-tasks', query],
})

export type DeleteUnterminatedTasksKey = ['companies', string | undefined, 'twilio-unterminated-tasks', unknown]

export type DeleteUnterminatedTasksResponse = ResponseTypes.SingletonResponse

export type DeleteUnterminatedTasksVariables = {
  companyId: string | undefined
  body?: {
    /** List of task SIDs to delete. */
    taskSids?: Array<string>
  }
  query?: void
}

export const deleteUnterminatedTasks = makeData<
  DeleteUnterminatedTasksResponse,
  DeleteUnterminatedTasksKey,
  DeleteUnterminatedTasksVariables
>({
  method: 'delete',
  name: 'deleteUnterminatedTasks',
  summary: 'Bulk Delete Unterminated Tasks',
  path: '/companies/{companyId}/twilio-unterminated-tasks',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-unterminated-tasks', query],
})

export type ReflowUnterminatedTasksKey = [
  'companies',
  string | undefined,
  'twilio-unterminated-tasks',
  'reflow',
  unknown,
]

export type ReflowUnterminatedTasksResponse = {
  /** A message indicating the state of the operation. */
  message?: string
  data?: Array<{
    oldTaskSide?: string
    newTaskSid?: string | null
    success?: boolean
    error?: string | null
  }>
}

export type ReflowUnterminatedTasksVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    /**
     * @default
     *     1
     */
    limit?: number
    taskAttrMatchExpr?: string
  }
}

export const reflowUnterminatedTasks = makeData<
  ReflowUnterminatedTasksResponse,
  ReflowUnterminatedTasksKey,
  ReflowUnterminatedTasksVariables
>({
  method: 'post',
  name: 'reflowUnterminatedTasks',
  summary: 'Reflow All Unterminated Tasks',
  path: '/companies/{companyId}/twilio-unterminated-tasks/reflow',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-unterminated-tasks', 'reflow', query],
})

export type ReadUnterminatedTaskKey = [
  'companies',
  string | undefined,
  'twilio-unterminated-tasks',
  string | undefined,
  unknown,
]

export type ReadUnterminatedTaskResponse = {
  data?: TwilioTaskTypes.Task
}

export type ReadUnterminatedTaskVariables = {
  companyId: string | undefined
  taskSid: string | undefined
  body?: void
  query?: void
}

export const readUnterminatedTask = makeData<
  ReadUnterminatedTaskResponse,
  ReadUnterminatedTaskKey,
  ReadUnterminatedTaskVariables
>({
  method: 'get',
  name: 'readUnterminatedTask',
  summary: 'Read Unterminated Task by SID',
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}',
  queryKey: ({ query, companyId, taskSid }) => ['companies', companyId, 'twilio-unterminated-tasks', taskSid, query],
})

export type DeleteUnterminatedTaskKey = [
  'companies',
  string | undefined,
  'twilio-unterminated-tasks',
  string | undefined,
  unknown,
]

export type DeleteUnterminatedTaskResponse = ResponseTypes.SingletonResponse

export type DeleteUnterminatedTaskVariables = {
  companyId: string | undefined
  taskSid: string | undefined
  body?: void
  query?: void
}

export const deleteUnterminatedTask = makeData<
  DeleteUnterminatedTaskResponse,
  DeleteUnterminatedTaskKey,
  DeleteUnterminatedTaskVariables
>({
  method: 'delete',
  name: 'deleteUnterminatedTask',
  summary: 'Deletes Unterminated Task by SID',
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}',
  queryKey: ({ query, companyId, taskSid }) => ['companies', companyId, 'twilio-unterminated-tasks', taskSid, query],
})

export type AbandonAcceptedTaskKey = [
  'companies',
  string | undefined,
  'twilio-unterminated-tasks',
  string | undefined,
  'abandon',
  unknown,
]

export type AbandonAcceptedTaskResponse = void

export type AbandonAcceptedTaskVariables = {
  companyId: string | undefined
  taskSid: string | undefined
  body?: void
  query?: void
}

export const abandonAcceptedTask = makeData<
  AbandonAcceptedTaskResponse,
  AbandonAcceptedTaskKey,
  AbandonAcceptedTaskVariables
>({
  method: 'post',
  name: 'abandonAcceptedTask',
  summary: 'Abandon Unterminated Task by SID',
  path: '/companies/{companyId}/twilio-unterminated-tasks/{taskSid}/abandon',
  queryKey: ({ query, companyId, taskSid }) => [
    'companies',
    companyId,
    'twilio-unterminated-tasks',
    taskSid,
    'abandon',
    query,
  ],
})

export type GetAllQueuesKey = ['companies', string | undefined, 'twilio-queues', unknown]

export type GetAllQueuesResponse = TwilioTaskTypes.ResponseTaskQueueData

export type GetAllQueuesVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const getAllQueues = makeData<GetAllQueuesResponse, GetAllQueuesKey, GetAllQueuesVariables>({
  method: 'get',
  name: 'getAllQueues',
  summary: 'Get information on Twilio task queues',
  path: '/companies/{companyId}/twilio-queues',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'twilio-queues', query],
})

export type TaskWorkerPairingConfigSyncKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  'sync',
  unknown,
]

export type TaskWorkerPairingConfigSyncResponse = void

export type TaskWorkerPairingConfigSyncVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const taskWorkerPairingConfigSync = makeData<
  TaskWorkerPairingConfigSyncResponse,
  TaskWorkerPairingConfigSyncKey,
  TaskWorkerPairingConfigSyncVariables
>({
  method: 'post',
  name: 'taskWorkerPairingConfigSync',
  summary: 'Sync task-worker pairing configs to Twilio',
  path: '/companies/{companyId}/task-worker-pairing-configs/sync',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'sync', query],
})

export type TaskWorkerPairingConfigListKey = ['companies', string | undefined, 'task-worker-pairing-configs', unknown]

export type TaskWorkerPairingConfigListResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfigPaging

export type TaskWorkerPairingConfigListVariables = {
  companyId: string | undefined
  body?: void
  query?: {
    sortBy?: Array<
      'id' | '+id' | '-id' | 'priority' | '-priority' | '+priority' | 'createdAt' | '+createdAt' | '-createdAt'
    >
    /**
     * @default
     *     25
     */
    limit?: number
    startingAfter?: string
    endingBefore?: string
  }
}

export const taskWorkerPairingConfigList = makeData<
  TaskWorkerPairingConfigListResponse,
  TaskWorkerPairingConfigListKey,
  TaskWorkerPairingConfigListVariables
>({
  method: 'get',
  name: 'taskWorkerPairingConfigList',
  summary: 'List task-worker pairing configs',
  path: '/companies/{companyId}/task-worker-pairing-configs',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'task-worker-pairing-configs', query],
})

export type TaskWorkerPairingConfigCreateKey = ['companies', string | undefined, 'task-worker-pairing-configs', unknown]

export type TaskWorkerPairingConfigCreateResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfig

export type TaskWorkerPairingConfigCreateVariables = {
  companyId: string | undefined
  body?: TwilioTaskTypes.TaskWorkerPairingConfig
  query?: void
}

export const taskWorkerPairingConfigCreate = makeData<
  TaskWorkerPairingConfigCreateResponse,
  TaskWorkerPairingConfigCreateKey,
  TaskWorkerPairingConfigCreateVariables
>({
  method: 'post',
  name: 'taskWorkerPairingConfigCreate',
  summary: 'Create task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'task-worker-pairing-configs', query],
})

export type TaskWorkerPairingConfigGetKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  string | undefined,
  unknown,
]

export type TaskWorkerPairingConfigGetResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfig

export type TaskWorkerPairingConfigGetVariables = {
  companyId: string | undefined
  taskWorkerPairingConfigId: string | undefined
  body?: void
  query?: void
}

export const taskWorkerPairingConfigGet = makeData<
  TaskWorkerPairingConfigGetResponse,
  TaskWorkerPairingConfigGetKey,
  TaskWorkerPairingConfigGetVariables
>({
  method: 'get',
  name: 'taskWorkerPairingConfigGet',
  summary: 'Get task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ query, companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
    query,
  ],
})

export type TaskWorkerPairingConfigUpdateKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  string | undefined,
  unknown,
]

export type TaskWorkerPairingConfigUpdateResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfig

export type TaskWorkerPairingConfigUpdateVariables = {
  companyId: string | undefined
  taskWorkerPairingConfigId: string | undefined
  body?: TwilioTaskTypes.TaskWorkerPairingConfig
  query?: void
}

export const taskWorkerPairingConfigUpdate = makeData<
  TaskWorkerPairingConfigUpdateResponse,
  TaskWorkerPairingConfigUpdateKey,
  TaskWorkerPairingConfigUpdateVariables
>({
  method: 'put',
  name: 'taskWorkerPairingConfigUpdate',
  summary: 'Update task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ query, companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
    query,
  ],
})

export type TaskWorkerPairingConfigDeleteKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  string | undefined,
  unknown,
]

export type TaskWorkerPairingConfigDeleteResponse = void

export type TaskWorkerPairingConfigDeleteVariables = {
  companyId: string | undefined
  taskWorkerPairingConfigId: string | undefined
  body?: void
  query?: void
}

export const taskWorkerPairingConfigDelete = makeData<
  TaskWorkerPairingConfigDeleteResponse,
  TaskWorkerPairingConfigDeleteKey,
  TaskWorkerPairingConfigDeleteVariables
>({
  method: 'delete',
  name: 'taskWorkerPairingConfigDelete',
  summary: 'Delete task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs/{taskWorkerPairingConfigId}',
  queryKey: ({ query, companyId, taskWorkerPairingConfigId }) => [
    'companies',
    companyId,
    'task-worker-pairing-configs',
    taskWorkerPairingConfigId,
    query,
  ],
})

export type TaskWorkerPairingConfigDefaultGetKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  'default',
  unknown,
]

export type TaskWorkerPairingConfigDefaultGetResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfig

export type TaskWorkerPairingConfigDefaultGetVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const taskWorkerPairingConfigDefaultGet = makeData<
  TaskWorkerPairingConfigDefaultGetResponse,
  TaskWorkerPairingConfigDefaultGetKey,
  TaskWorkerPairingConfigDefaultGetVariables
>({
  method: 'get',
  name: 'taskWorkerPairingConfigDefaultGet',
  summary: 'Get default task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs/default',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'default', query],
})

export type TaskWorkerPairingConfigDefaultUpdateKey = [
  'companies',
  string | undefined,
  'task-worker-pairing-configs',
  'default',
  unknown,
]

export type TaskWorkerPairingConfigDefaultUpdateResponse = TwilioTaskTypes.ResponseTaskWorkerPairingConfig

export type TaskWorkerPairingConfigDefaultUpdateVariables = {
  companyId: string | undefined
  body?: TwilioTaskTypes.TaskWorkerPairingConfig
  query?: void
}

export const taskWorkerPairingConfigDefaultUpdate = makeData<
  TaskWorkerPairingConfigDefaultUpdateResponse,
  TaskWorkerPairingConfigDefaultUpdateKey,
  TaskWorkerPairingConfigDefaultUpdateVariables
>({
  method: 'put',
  name: 'taskWorkerPairingConfigDefaultUpdate',
  summary: 'Update default task-worker pairing config',
  path: '/companies/{companyId}/task-worker-pairing-configs/default',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'task-worker-pairing-configs', 'default', query],
})

export type ForcePublishTaskKey = ['twilio', 'force-publish-task', unknown]

export type ForcePublishTaskResponse = {}

export type ForcePublishTaskVariables = {
  /**
   * Depending on the particular `taskType`, different task attributes are
   * required.
   */
  body?: {
    /**
     * Task type to create. Not all possible task types are
     * supported here.
     */
    taskType:
      | 'handleInboundInteraction'
      | 'handleUnboundInboundInteraction'
      | 'makeScheduledCall'
      | 'reviewAutoCreatedCase'
      | 'reviewAutoUpdatedCase'
      | 'reviewDocumentUpload'
      | 'reviewEscalatedCaseAgent'
      | 'reviewEscalatedCaseAgentUrgent'
      | 'reviewEscalatedCaseTeam'
      | 'reviewEscalatedCaseTeamUrgent'
      | 'reviewSnoozedCase'
      | 'answerInboundVoiceCall'
      | 'replyToNewText'
      | 'replyToNewWebChat'
    /** ID of the interaction */
    interactionId?: string
    /** ID of the case */
    caseId?: string
    /** ID of the borrower */
    borrowerId?: string
    /** ID of the document descriptor */
    documentId?: string
  }
  query?: void
}

export const forcePublishTask = makeData<ForcePublishTaskResponse, ForcePublishTaskKey, ForcePublishTaskVariables>({
  method: 'post',
  name: 'forcePublishTask',
  summary: 'Force publish a task to Twilio Taskrouter',
  path: '/twilio/force-publish-task',
  queryKey: ({ query }) => ['twilio', 'force-publish-task', query],
})
