/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as PurchaseDisputeTypes from './types'

export type PurchaseDisputesListForPurchaseKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  'disputes',
  unknown,
]

export type PurchaseDisputesListForPurchaseResponse = PurchaseDisputeTypes.ResponsePurchaseDisputePagingResponse

export type PurchaseDisputesListForPurchaseVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: void
  query?: void
}

export const purchaseDisputesListForPurchase = makeData<
  PurchaseDisputesListForPurchaseResponse,
  PurchaseDisputesListForPurchaseKey,
  PurchaseDisputesListForPurchaseVariables
>({
  method: 'get',
  name: 'purchaseDisputesListForPurchase',
  summary: 'Get purchase disputes',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes',
  queryKey: ({ query, personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    query,
  ],
})

export type PurchaseDisputeCreateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  'disputes',
  unknown,
]

export type PurchaseDisputeCreateResponse = PurchaseDisputeTypes.ResponsePurchaseDisputeResponse

export type PurchaseDisputeCreateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  body?: PurchaseDisputeTypes.RequestBodyPurchaseDisputePost
  query?: void
}

export const purchaseDisputeCreate = makeData<
  PurchaseDisputeCreateResponse,
  PurchaseDisputeCreateKey,
  PurchaseDisputeCreateVariables
>({
  method: 'post',
  name: 'purchaseDisputeCreate',
  summary: 'Create purchase dispute',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes',
  queryKey: ({ query, personId, loanId, drawId, purchaseId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    query,
  ],
})

export type PurchaseDisputesListForDrawKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'disputes',
  unknown,
]

export type PurchaseDisputesListForDrawResponse = PurchaseDisputeTypes.ResponsePurchaseDisputePagingResponse

export type PurchaseDisputesListForDrawVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: void
  query?: void
}

export const purchaseDisputesListForDraw = makeData<
  PurchaseDisputesListForDrawResponse,
  PurchaseDisputesListForDrawKey,
  PurchaseDisputesListForDrawVariables
>({
  method: 'get',
  name: 'purchaseDisputesListForDraw',
  summary: 'Get draw purchase disputes',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/disputes',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'disputes',
    query,
  ],
})

export type PurchaseDisputesListForLocKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'disputes',
  unknown,
]

export type PurchaseDisputesListForLocResponse = PurchaseDisputeTypes.ResponsePurchaseDisputePagingResponse

export type PurchaseDisputesListForLocVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const purchaseDisputesListForLoc = makeData<
  PurchaseDisputesListForLocResponse,
  PurchaseDisputesListForLocKey,
  PurchaseDisputesListForLocVariables
>({
  method: 'get',
  name: 'purchaseDisputesListForLoc',
  summary: 'Get line of credit purchase disputes',
  path: '/people/{personId}/loans/{loanId}/disputes',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'disputes', query],
})

export type PurchaseDisputeGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  'disputes',
  string | undefined,
  unknown,
]

export type PurchaseDisputeGetResponse = PurchaseDisputeTypes.ResponsePurchaseDisputeResponse

export type PurchaseDisputeGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  purchaseDisputeId: string | undefined
  body?: void
  query?: void
}

export const purchaseDisputeGet = makeData<
  PurchaseDisputeGetResponse,
  PurchaseDisputeGetKey,
  PurchaseDisputeGetVariables
>({
  method: 'get',
  name: 'purchaseDisputeGet',
  summary: 'Get purchase dispute by ID',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes/{purchaseDisputeId}',
  queryKey: ({ query, personId, loanId, drawId, purchaseId, purchaseDisputeId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    purchaseDisputeId,
    query,
  ],
})

export type PurchaseDisputeUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'purchases',
  string | undefined,
  'disputes',
  string | undefined,
  unknown,
]

export type PurchaseDisputeUpdateResponse = PurchaseDisputeTypes.ResponsePurchaseDisputeResponse

export type PurchaseDisputeUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  purchaseId: string | undefined
  purchaseDisputeId: string | undefined
  body?: PurchaseDisputeTypes.RequestBodyPurchaseDisputePut
  query?: {
    sync?: boolean
  }
}

export const purchaseDisputeUpdate = makeData<
  PurchaseDisputeUpdateResponse,
  PurchaseDisputeUpdateKey,
  PurchaseDisputeUpdateVariables
>({
  method: 'put',
  name: 'purchaseDisputeUpdate',
  summary: 'Update purchase dispute',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/purchases/{purchaseId}/disputes/{purchaseDisputeId}',
  queryKey: ({ query, personId, loanId, drawId, purchaseId, purchaseDisputeId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    'disputes',
    purchaseDisputeId,
    query,
  ],
})
