/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { makeData } from 'core/types/makeData'

import * as SchemaTypes from '../schema/types'
import * as UtilityTypes from '../utilities/types'

import * as PromoprogramTypes from './types'

export type InternalPromoProgramTypesGetAllKey = ['companies', string | undefined, 'promo-program-types', unknown]

export type InternalPromoProgramTypesGetAllResponse = PromoprogramTypes.ResponsePromoProgramTypePaging

export type InternalPromoProgramTypesGetAllVariables = {
  companyId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramTypesGetAll = makeData<
  InternalPromoProgramTypesGetAllResponse,
  InternalPromoProgramTypesGetAllKey,
  InternalPromoProgramTypesGetAllVariables
>({
  method: 'get',
  name: 'internalPromoProgramTypesGetAll',
  summary: 'Get promo program types',
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'promo-program-types', query],
})

export type InternalPromoProgramTypesAddKey = ['companies', string | undefined, 'promo-program-types', unknown]

export type InternalPromoProgramTypesAddResponse = PromoprogramTypes.ResponsePromoProgramType

export type InternalPromoProgramTypesAddVariables = {
  companyId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramTypePost
  query?: void
}

export const internalPromoProgramTypesAdd = makeData<
  InternalPromoProgramTypesAddResponse,
  InternalPromoProgramTypesAddKey,
  InternalPromoProgramTypesAddVariables
>({
  method: 'post',
  name: 'internalPromoProgramTypesAdd',
  summary: 'Create promo program type',
  path: '/companies/{companyId}/promo-program-types',
  queryKey: ({ query, companyId }) => ['companies', companyId, 'promo-program-types', query],
})

export type InternalPromoProgramTypesGetKey = [
  'companies',
  string | undefined,
  'promo-program-types',
  string | undefined,
  unknown,
]

export type InternalPromoProgramTypesGetResponse = PromoprogramTypes.ResponsePromoProgramType

export type InternalPromoProgramTypesGetVariables = {
  companyId: string | undefined
  promoProgramTypeId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramTypesGet = makeData<
  InternalPromoProgramTypesGetResponse,
  InternalPromoProgramTypesGetKey,
  InternalPromoProgramTypesGetVariables
>({
  method: 'get',
  name: 'internalPromoProgramTypesGet',
  summary: 'Get promo program type by ID',
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ query, companyId, promoProgramTypeId }) => [
    'companies',
    companyId,
    'promo-program-types',
    promoProgramTypeId,
    query,
  ],
})

export type InternalPromoProgramTypesUpdateKey = [
  'companies',
  string | undefined,
  'promo-program-types',
  string | undefined,
  unknown,
]

export type InternalPromoProgramTypesUpdateResponse = PromoprogramTypes.ResponsePromoProgramType

export type InternalPromoProgramTypesUpdateVariables = {
  companyId: string | undefined
  promoProgramTypeId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramTypePut
  query?: void
}

export const internalPromoProgramTypesUpdate = makeData<
  InternalPromoProgramTypesUpdateResponse,
  InternalPromoProgramTypesUpdateKey,
  InternalPromoProgramTypesUpdateVariables
>({
  method: 'put',
  name: 'internalPromoProgramTypesUpdate',
  summary: 'Update promo program type',
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ query, companyId, promoProgramTypeId }) => [
    'companies',
    companyId,
    'promo-program-types',
    promoProgramTypeId,
    query,
  ],
})

export type InternalPromoProgramTypesDeleteKey = [
  'companies',
  string | undefined,
  'promo-program-types',
  string | undefined,
  unknown,
]

export type InternalPromoProgramTypesDeleteResponse = void

export type InternalPromoProgramTypesDeleteVariables = {
  companyId: string | undefined
  promoProgramTypeId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramTypesDelete = makeData<
  InternalPromoProgramTypesDeleteResponse,
  InternalPromoProgramTypesDeleteKey,
  InternalPromoProgramTypesDeleteVariables
>({
  method: 'delete',
  name: 'internalPromoProgramTypesDelete',
  summary: 'Cancel promo program type',
  path: '/companies/{companyId}/promo-program-types/{promoProgramTypeId}',
  queryKey: ({ query, companyId, promoProgramTypeId }) => [
    'companies',
    companyId,
    'promo-program-types',
    promoProgramTypeId,
    query,
  ],
})

export type PromoProgramsGetAllKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  unknown,
]

export type PromoProgramsGetAllResponse = PromoprogramTypes.ResponsePromoProgramPaging

export type PromoProgramsGetAllVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    promoEffectiveEndDateAfter?: string
    statuses?: Array<'pending' | 'active' | 'exercised' | 'canceled'>
  }
}

export const promoProgramsGetAll = makeData<
  PromoProgramsGetAllResponse,
  PromoProgramsGetAllKey,
  PromoProgramsGetAllVariables
>({
  method: 'get',
  name: 'promoProgramsGetAll',
  summary: 'Get promo programs',
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs', query],
})

export type PromoProgramsAddKey = ['people', string | undefined, 'loans', string | undefined, 'promo-programs', unknown]

export type PromoProgramsAddResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsAddVariables = {
  personId: string | undefined
  loanId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramPost
  query?: void
}

export const promoProgramsAdd = makeData<PromoProgramsAddResponse, PromoProgramsAddKey, PromoProgramsAddVariables>({
  method: 'post',
  name: 'promoProgramsAdd',
  summary: 'Create promo program',
  path: '/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ query, personId, loanId }) => ['people', personId, 'loans', loanId, 'promo-programs', query],
})

export type PromoProgramsGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsGetResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const promoProgramsGet = makeData<PromoProgramsGetResponse, PromoProgramsGetKey, PromoProgramsGetVariables>({
  method: 'get',
  name: 'promoProgramsGet',
  summary: 'Get promo program by ID',
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsUpdateResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramPut
  query?: void
}

export const promoProgramsUpdate = makeData<
  PromoProgramsUpdateResponse,
  PromoProgramsUpdateKey,
  PromoProgramsUpdateVariables
>({
  method: 'put',
  name: 'promoProgramsUpdate',
  summary: 'Update promo program',
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsDeleteResponse = PromoprogramTypes.ResponsePromoProgramDelete

export type PromoProgramsDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: {
    promoCancelReason?: PromoprogramTypes.PromoCancelReason
    /**
     * @default
     *     true
     */
    sendNotice?: boolean
    caseId?: SchemaTypes.AnyId
  }
}

export const promoProgramsDelete = makeData<
  PromoProgramsDeleteResponse,
  PromoProgramsDeleteKey,
  PromoProgramsDeleteVariables
>({
  method: 'delete',
  name: 'promoProgramsDelete',
  summary: 'Cancel promo program',
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsActivateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  'activate',
  unknown,
]

export type PromoProgramsActivateResponse = void

export type PromoProgramsActivateVariables = {
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const promoProgramsActivate = makeData<
  PromoProgramsActivateResponse,
  PromoProgramsActivateKey,
  PromoProgramsActivateVariables
>({
  method: 'post',
  name: 'promoProgramsActivate',
  summary: 'Activate promo program',
  path: '/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/activate',
  queryKey: ({ query, personId, loanId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'activate',
    query,
  ],
})

export type PromoProgramsDrawAddKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'promo-programs',
  unknown,
]

export type PromoProgramsDrawAddResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsDrawAddVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramPost
  query?: void
}

export const promoProgramsDrawAdd = makeData<
  PromoProgramsDrawAddResponse,
  PromoProgramsDrawAddKey,
  PromoProgramsDrawAddVariables
>({
  method: 'post',
  name: 'promoProgramsDrawAdd',
  summary: 'Create promo program for draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs',
  queryKey: ({ query, personId, loanId, drawId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    query,
  ],
})

export type PromoProgramsDrawGetKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsDrawGetResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsDrawGetVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const promoProgramsDrawGet = makeData<
  PromoProgramsDrawGetResponse,
  PromoProgramsDrawGetKey,
  PromoProgramsDrawGetVariables
>({
  method: 'get',
  name: 'promoProgramsDrawGet',
  summary: 'Get promo program for draw by ID',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsDrawUpdateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsDrawUpdateResponse = PromoprogramTypes.ResponsePromoProgram

export type PromoProgramsDrawUpdateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  promoProgramId: string | undefined
  body?: PromoprogramTypes.RequestBodyPromoProgramPutDraw
  query?: void
}

export const promoProgramsDrawUpdate = makeData<
  PromoProgramsDrawUpdateResponse,
  PromoProgramsDrawUpdateKey,
  PromoProgramsDrawUpdateVariables
>({
  method: 'put',
  name: 'promoProgramsDrawUpdate',
  summary: 'Update promo program for draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsDrawDeleteKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type PromoProgramsDrawDeleteResponse = PromoprogramTypes.ResponsePromoProgramDelete

export type PromoProgramsDrawDeleteVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: {
    promoCancelReason?: PromoprogramTypes.PromoCancelReason
    /**
     * @default
     *     true
     */
    sendNotice?: boolean
    caseId?: SchemaTypes.AnyId
  }
}

export const promoProgramsDrawDelete = makeData<
  PromoProgramsDrawDeleteResponse,
  PromoProgramsDrawDeleteKey,
  PromoProgramsDrawDeleteVariables
>({
  method: 'delete',
  name: 'promoProgramsDrawDelete',
  summary: 'Cancel promo program for draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type PromoProgramsDrawActivateKey = [
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'draws',
  string | undefined,
  'promo-programs',
  string | undefined,
  'activate',
  unknown,
]

export type PromoProgramsDrawActivateResponse = void

export type PromoProgramsDrawActivateVariables = {
  personId: string | undefined
  loanId: string | undefined
  drawId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const promoProgramsDrawActivate = makeData<
  PromoProgramsDrawActivateResponse,
  PromoProgramsDrawActivateKey,
  PromoProgramsDrawActivateVariables
>({
  method: 'post',
  name: 'promoProgramsDrawActivate',
  summary: 'Activate promo program for draw',
  path: '/people/{personId}/loans/{loanId}/draws/{drawId}/promo-programs/{promoProgramId}/activate',
  queryKey: ({ query, personId, loanId, drawId, promoProgramId }) => [
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'promo-programs',
    promoProgramId,
    'activate',
    query,
  ],
})

export type InternalPromoProgramsExerciseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  'exercise',
  unknown,
]

export type InternalPromoProgramsExerciseResponse = void

export type InternalPromoProgramsExerciseVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramsExercise = makeData<
  InternalPromoProgramsExerciseResponse,
  InternalPromoProgramsExerciseKey,
  InternalPromoProgramsExerciseVariables
>({
  method: 'post',
  name: 'internalPromoProgramsExercise',
  summary: 'Daily check (and exercising) promo program',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/exercise',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    'exercise',
    query,
  ],
})

export type InternalPromoProgramsReverseKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  'reverse',
  unknown,
]

export type InternalPromoProgramsReverseResponse = void

export type InternalPromoProgramsReverseVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramsReverse = makeData<
  InternalPromoProgramsReverseResponse,
  InternalPromoProgramsReverseKey,
  InternalPromoProgramsReverseVariables
>({
  method: 'post',
  name: 'internalPromoProgramsReverse',
  summary: 'Reverse promo program',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/reverse',
  queryKey: ({ query, companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'reverse',
    query,
  ],
})

export type InternalPromoProgramsGetAllKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  unknown,
]

export type InternalPromoProgramsGetAllResponse = PromoprogramTypes.ResponsePromoProgramPaging

export type InternalPromoProgramsGetAllVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  body?: void
  query?: {
    promoEffectiveEndDateAfter?: string
    statuses?: Array<'pending' | 'active' | 'exercised' | 'canceled'>
  }
}

export const internalPromoProgramsGetAll = makeData<
  InternalPromoProgramsGetAllResponse,
  InternalPromoProgramsGetAllKey,
  InternalPromoProgramsGetAllVariables
>({
  method: 'get',
  name: 'internalPromoProgramsGetAll',
  summary: 'Get promo programs',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs',
  queryKey: ({ query, companyId, personId, loanId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    query,
  ],
})

export type InternalPromoProgramsGetKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  unknown,
]

export type InternalPromoProgramsGetResponse = PromoprogramTypes.ResponsePromoProgram

export type InternalPromoProgramsGetVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramsGet = makeData<
  InternalPromoProgramsGetResponse,
  InternalPromoProgramsGetKey,
  InternalPromoProgramsGetVariables
>({
  method: 'get',
  name: 'internalPromoProgramsGet',
  summary: 'Get promo program by ID',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}',
  queryKey: ({ query, companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    query,
  ],
})

export type InternalActivePromoProgramsKey = ['promo-programs', 'active', unknown]

export type InternalActivePromoProgramsResponse = UtilityTypes.SingletonResponse & {
  data?: Array<{
    /** Promo program ID */
    id?: string
    promoType?: PromoprogramTypes.PromoType
  }>
}

export type InternalActivePromoProgramsVariables = {
  body?: void
  query?: {
    loanIds: Array<SchemaTypes.AnyId>
    effectiveDate: string
  }
}

export const internalActivePromoPrograms = makeData<
  InternalActivePromoProgramsResponse,
  InternalActivePromoProgramsKey,
  InternalActivePromoProgramsVariables
>({
  method: 'get',
  name: 'internalActivePromoPrograms',
  summary: 'Get active promo programs',
  path: '/promo-programs/active',
  queryKey: ({ query }) => ['promo-programs', 'active', query],
})

export type InternalPromoProgramsExerciseDeferredInterestKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  'exercise-deferred-interest',
  unknown,
]

export type InternalPromoProgramsExerciseDeferredInterestResponse = void

export type InternalPromoProgramsExerciseDeferredInterestVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: void
}

export const internalPromoProgramsExerciseDeferredInterest = makeData<
  InternalPromoProgramsExerciseDeferredInterestResponse,
  InternalPromoProgramsExerciseDeferredInterestKey,
  InternalPromoProgramsExerciseDeferredInterestVariables
>({
  method: 'post',
  name: 'internalPromoProgramsExerciseDeferredInterest',
  summary: 'Exercise deferred interest promo program',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/exercise-deferred-interest',
  queryKey: ({ query, companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'exercise-deferred-interest',
    query,
  ],
})

export type InternalPromoProgramsRecognizeDeferredInterestKey = [
  'companies',
  string | undefined,
  'people',
  string | undefined,
  'loans',
  string | undefined,
  'promo-programs',
  string | undefined,
  'recognize-deferred-interest',
  unknown,
]

export type InternalPromoProgramsRecognizeDeferredInterestResponse = void

export type InternalPromoProgramsRecognizeDeferredInterestVariables = {
  companyId: string | undefined
  personId: string | undefined
  loanId: string | undefined
  promoProgramId: string | undefined
  body?: void
  query?: {
    eventId: string
  }
}

export const internalPromoProgramsRecognizeDeferredInterest = makeData<
  InternalPromoProgramsRecognizeDeferredInterestResponse,
  InternalPromoProgramsRecognizeDeferredInterestKey,
  InternalPromoProgramsRecognizeDeferredInterestVariables
>({
  method: 'post',
  name: 'internalPromoProgramsRecognizeDeferredInterest',
  summary: 'Realize deferred interest promo program',
  path: '/companies/{companyId}/people/{personId}/loans/{loanId}/promo-programs/{promoProgramId}/recognize-deferred-interest',
  queryKey: ({ query, companyId, personId, loanId, promoProgramId }) => [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    'recognize-deferred-interest',
    query,
  ],
})
